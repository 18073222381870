import { ContentBlock11Props } from '@customTypes/storyblok-types';
import { storyblokEditable } from '@storyblok/react';
import useBreakpoint, { Breakpoints } from '@hooks/useBreakpoint';
import { primaryColorDict } from '@lib/color';
import classNames from '@utils/ClassNames';
import { ImageHover } from '@components/Image/ImageHover';
import { StoryblokImageOrVideo } from '@components/Image/StoryblokImageOrVideo';
import { RichText } from '@components/RichText';

export const ContentBlock11 = ({ blok }: { blok: ContentBlock11Props }) => {
  const isMobileView = useBreakpoint(Breakpoints.lg);
  const backgroundColor = primaryColorDict[blok.content_branch] || primaryColorDict.sprenger;
  const contentBranchSelected = blok.content_branch !== 'noBranding';

  return (
    <div
      /* @ts-ignore*/
      {...storyblokEditable(blok)}
      className={classNames("relative my-11 md:my-16", blok.blue_background && "bg-accent-primary_005")}
    >
      <div
        className={classNames(
          'relative flex flex-col-reverse gap-4 lg:gap-1 !mt-0 overflow-hidden h-full',
          blok.image_left ? 'lg:flex-row-reverse' : 'lg:flex-row',
        )}
      >
        <div className="flex flex-col lg:max-w-lg lg:self-end lg:pb-16 grow-0 box-x-wrapper pb-4">
          <div className="text-h-32 pb-4 hyphens-auto">{blok.title}</div>
          <RichText className="text-p-16" text={blok.description} />
        </div>
        <div className="flex-1 relative">
          {blok.image_1.filename && blok.image_2.filename && !blok.video_id && (
            <ImageHover
              className="max-w-full right-0 box-x-wrapper"
              image_1={blok.image_1}
              image_2={blok.image_2}
            />
          )}
          {((blok.image_1.filename && !blok.image_2.filename) ||
            blok.video_id ||
            blok.shopTheLook) && (
              <StoryblokImageOrVideo
                filename={blok.image_1?.filename}
                alt={blok.image_1?.alt ?? undefined}
                focus={blok.image_1.focus}
                layout="responsive"
                width="2560"
                height="1440"
                sizes="75vw"
                objectFit="cover"
                video_id={blok.video_id}
                autoplay={blok.autoplay}
                shopTheLook={blok.shopTheLook}
              />
            )}
          {contentBranchSelected && isMobileView && (
            <BorderElement color={backgroundColor} isLeft={!blok.image_left} />
          )}
        </div>
      </div>
      {contentBranchSelected && !isMobileView && (
        <BorderElement color={backgroundColor} isLeft={!blok.image_left} />
      )}
    </div>
  );
};

const BorderElement = ({ color, isLeft }: { color: string; isLeft: boolean }) => (
  <div
    style={{ backgroundColor: color }}
    className={classNames(
      'absolute bottom-0 h-4 w-[65%] lg:h-[23px] z-10',
      isLeft ? 'left-0' : 'right-0',
    )}
  />
);
