import { ProductCardHorizontal } from './Card/ProductCardHorizontal';
import { LoadingIndicator } from '@components/LoadingIndicator';
import { SideDrawer } from '@components/SideDrawer';
import { useCart } from '@hooks/useCart';
import { T, useTranslate } from '@tolgee/react';
import { Divider } from '@components/Divider';
import { ButtonPrimary } from '@components/Button/Primary';
import { ButtonSecondary } from '@components/Button/Secondary';
import { ProductCardWithAmountSelection } from './Card/ProductCardWithAmountSelection';
import { ProductVariantDataFragment } from '@generated/graphql/types';
import useBreakpoint, { Breakpoints } from '@hooks/useBreakpoint';
import { getVariantIdFromShopifyUri } from '@lib/utils';

interface ProductAddedToCartProps {
  className?: string;
  show: boolean;
  onClose: () => void;
  variant: Pick<
    ProductVariantDataFragment,
    | 'justSku'
    | 'sku'
    | 'product'
    | 'priceV2'
    | 'quantityAvailable'
    | 'highlights'
    | 'compareAtPriceV2'
    | 'image_urls'
    | 'selectedOptions'
    | 'id'
  >;
  variants?: Pick<
    ProductVariantDataFragment,
    | 'justSku'
    | 'sku'
    | 'product'
    | 'priceV2'
    | 'quantityAvailable'
    | 'highlights'
    | 'compareAtPriceV2'
    | 'image_urls'
    | 'selectedOptions'
    | 'id'
  >[];
}

export const ProductAddedToCart = ({
  show,
  onClose,
  variant,
  variants,
}: ProductAddedToCartProps) => {
  const cart = useCart();
  const { t } = useTranslate();
  const isMobile = useBreakpoint(Breakpoints.md);

  return (
    <SideDrawer
      show={show}
      onClose={onClose}
      preventOverflow={!isMobile}
      className="h-full"
      closeIconClassName="text-white w-10 h-10"
      header={
        <div className="flex-1 py-1">
          <div className="text-h-24">
            <T keyName="product.addedToCart.headline" />
          </div>
        </div>
      }
    >
      <div className="overflow-y-auto overflow-x-hidden md:overflow-hidden w-full flex flex-col">
        {variants ? (
          variants.map((item) => (
            <ProductCardWithAmountSelection
              handle={item.product.handle}
              collections={item.product.collections}
              title={item.product.title}
              price={item.priceV2}
              highlights={JSON.parse(item.highlights?.value ?? '[]')}
              compareAtPrice={item.compareAtPriceV2}
              selectedOptions={item.selectedOptions}
              wrapUnitPrice={true}
              variantId={item.id}
              image_urls={item.image_urls?.value}
              sku={item.justSku?.value ?? item.sku?.replace('Sprenger_', '') ?? undefined}
              actionsClassName="lg:flex-col items-stretch"
            />
          ))
        ) : (
          <ProductCardWithAmountSelection
            handle={variant.product.handle}
            collections={variant.product.collections}
            title={variant.product.title}
            price={variant.priceV2}
            highlights={JSON.parse(variant.highlights?.value ?? '[]')}
            compareAtPrice={variant.compareAtPriceV2}
            selectedOptions={variant.selectedOptions}
            wrapUnitPrice={true}
            variantId={variant.id}
            image_urls={variant.image_urls?.value}
            sku={variant.justSku?.value ?? variant.sku?.replace('Sprenger_', '') ?? undefined}
            actionsClassName="lg:flex-col items-stretch"
          />
        )}
        {(cart.crossSellProducts.error ||
          cart.crossSellProducts.loading ||
          (cart.crossSellProducts.data && cart.crossSellProducts.data.length > 0)) && (
          <div className="space-y-4 flex flex-col overflow-hidden flex-1 pb-4">
            <Divider className="mb-4 flex-0" />
            <div className="text-h-24 flex-0">
              <T keyName="product.matchingProducts" />
            </div>

            {cart.crossSellProducts.loading ? (
              <LoadingIndicator />
            ) : cart.crossSellProducts.error ? (
              <div>{t('error.default')}</div>
            ) : cart.crossSellProducts.data ? (
              <div className="overflow-y-auto flex-1 flex flex-col gap-5 pr-1">
                {cart.crossSellProducts.data.slice(-3).reverse().map((item) => {
                  if (item.__typename === 'ProductVariant') {
                    return (
                      <ProductCardHorizontal
                        image_urls={item.image_urls?.value}
                        price={item.price}
                        collections={item.product.collections}
                        title={item.product.title}
                        highlights={JSON.parse(item.highlights?.value ?? '[]')}
                        variantTitle={item.title}
                        selectedOptions={item.selectedOptions}
                        handle={item.product.handle}
                        key={item.id}
                        onClick={onClose}
                        totalInventory={item.product.totalInventory}
                        variantId={getVariantIdFromShopifyUri(item.id)}
                        compareAtPrice={item.compareAtPriceV2}
                      />
                    );
                  }

                  return (
                    <ProductCardHorizontal
                      image_urls={item.image_urls?.value}
                      price={item.priceRange}
                      collections={item.collections}
                      title={item.title}
                      handle={item.handle}
                      highlights={JSON.parse(item.highlights?.value ?? '[]')}
                      key={item.id}
                      onClick={onClose}
                      totalInventory={item.totalInventory}
                      compareAtPriceRange={item.compareAtPriceRange}
                    />
                  );
                })}
              </div>
            ) : (
              <div>{t('error.empty')}</div>
            )}
          </div>
        )}
      </div>
      <div className="flex-0 sticky bottom-0 bg-white">
        <ButtonPrimary className="w-full" text={t('toCart')} href="/cart" />
        <ButtonSecondary className="w-full mt-4" text={t('goOnShopping')} onClick={onClose} />
      </div>
    </SideDrawer>
  );
};
