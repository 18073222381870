import useResponsive from '@hooks/useResponsive';
import classNames from 'classnames';
import { StoryblokImageOrVideo } from './StoryblokImageOrVideo';
import { ShopTheLookProps } from '../storyblok/StoryblokImageWithAnnotations';
import { ImageStoryblok } from '../../customTypes/storyblok-types';

interface ImagePairProps {
  className?: string;
  breakpoint?: number;
  layout: 'medium-medium' | 'small-big';
  image1?: ImageStoryblok;
  image2?: ImageStoryblok;
  video_id1?: string;
  autoplay1?: boolean;
  video_id2?: string;
  autoplay2?: boolean;
  image_1_parallax?: boolean;
  image_2_parallax?: boolean;
  shopTheLook1?: ShopTheLookProps;
  shopTheLook2?: ShopTheLookProps;
}

const LAYOUTS = {
  'medium-medium': {
    className1: 'w-1/2 mr-3',
    className2: 'w-1/2',
    marginBottom1: '-50%',
    paddingBottom1: '50%',
    paddingBottom2: '50%',
  },
  'small-big': {
    className1: 'w-1/3 mr-3',
    className2: 'w-2/3',
    marginBottom1: '-33.33333%',
    paddingBottom1: '66.66666%',
    paddingBottom2: '33.33333%',
  },
};

export const ImagePair = ({
  className,
  breakpoint = 768,
  layout,
  image1,
  image2,
  video_id1,
  autoplay1,
  video_id2,
  autoplay2,
  shopTheLook1,
  shopTheLook2,
  image_1_parallax,
  image_2_parallax,
}: ImagePairProps) => {
  const width = useResponsive();
  const currentLayout = LAYOUTS[layout] || LAYOUTS['medium-medium'];

  return width > breakpoint ? (
    <div
      className={classNames('relative w-full pb-full -z-10', className)}
      style={{ marginBottom: currentLayout.marginBottom1 }}
    >
      <div className="absolute top-0 left-0 flex flex-row w-full h-full">
        <div
          className={currentLayout.className1}
          style={{ paddingBottom: currentLayout.paddingBottom1 }}
        >
          <div className="relative w-full h-full bg-radial-gradient-product-image">
            <StoryblokImageOrVideo
              shopTheLook={shopTheLook1}
              filename={image1?.filename}
              focus={image1?.focus}
              alt={image1?.alt}
              parallax={image_1_parallax}
              sizes="50vw"
              layout="fill"
              objectFit="cover"
              video_id={video_id1}
              autoplay={autoplay1}
            />
          </div>
        </div>

        <div
          className={currentLayout.className2}
          style={{ paddingBottom: currentLayout.paddingBottom2 }}
        >
          <div className="relative w-full h-full bg-radial-gradient-product-image">
            <StoryblokImageOrVideo
              shopTheLook={shopTheLook2}
              filename={image2?.filename}
              focus={image2?.focus}
              alt={image2?.alt}
              parallax={image_2_parallax}
              sizes="50vw"
              layout="fill"
              objectFit="cover"
              video_id={video_id2}
              autoplay={autoplay2}
            />
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className={classNames('relative w-full', className)}>
      <div className="relative w-full mt-4 bg-radial-gradient-product-image aspect-square">
        <StoryblokImageOrVideo
          shopTheLook={shopTheLook1}
          filename={image1?.filename}
          focus={image1?.focus}
          alt={image1?.alt}
          parallax={image_1_parallax}
          sizes="75vw"
          layout="fill"
          objectFit="cover"
          video_id={video_id1}
          autoplay={autoplay1}
        />
      </div>
      <div className="relative w-full mt-4 bg-radial-gradient-product-image aspect-square">
        <StoryblokImageOrVideo
          shopTheLook={shopTheLook2}
          filename={image2?.filename}
          focus={image2?.focus}
          alt={image2?.alt}
          parallax={image_2_parallax}
          sizes="75vw"
          layout="fill"
          objectFit="cover"
          video_id={video_id2}
          autoplay={autoplay2}
        />
      </div>
    </div>
  );
};
