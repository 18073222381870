import { useEffect, useState } from 'react';

enum CONSENT_ACTION {
  ACCEPT_ALL_SERVICES = 'onAcceptAllServices',
  DENY_ALL_SERVICES = 'onDenyAllServices',
  ESSENTIAL_CHANGE = 'onEssentialChange',
  INITIAL_PAGE_LOAD = 'onInitialPageLoad',
  MOBILE_SESSION_RESTORED = 'onMobileSessionRestore',
  NON_EU_REGION = 'onNonEURegion',
  SESSION_RESTORED = 'onSessionRestored',
  UPDATE_SERVICES = 'onUpdateServices',
}

enum CONSENT_TYPE {
  EXPLICIT = 'explicit',
  IMPLICIT = 'implicit',
}

interface ConsentHistory {
  action: CONSENT_ACTION;
  language: string;
  status: boolean;
  timestamp: number;
  type: CONSENT_TYPE;
  versions: { application: string; service: string; settings: string };
}

interface Consent {
  history: Array<ConsentHistory>;
  status: boolean;
}

interface Disclosure {
  cookieRefresh: null | boolean;
  description: null | string;
  domain: null | string;
  identifier: null | string;
  maxAgeSeconds: null | number;
  name: null | string;
  purposes: null | number[];
  type: string;
}

interface DeviceStorage {
  disclosures: Array<Disclosure>;
}

export interface BaseService {
  categorySlug: string;
  consent: Consent;
  description: string;
  deviceStorage: null | DeviceStorage;
  id: string;
  isEssential: boolean;
  isHidden: boolean;
  legalBasis: Array<string>;
  name: string;
  processorId: string;
  subServices: Array<any>;
  subServicesLength: number;
  version: string;
}

/**
 * Hook to return if userCentrics has been interacted with or not.
 */
const useUserCentricsInteracted = () => {
  const [hasInteracted, setHasInteracted] = useState<boolean>();

  const updateGTMConsent = () => {
    const baseServiceInfo = (window as any).UC_UI?.getServicesBaseInfo();
    // On initial page load without accepting or rejecting the services usercentric will still set
    // the consent status to false -> thus we have to check if the history is longer than 1 because
    // than the user has interacted with userCentrics.
    setHasInteracted(!!(baseServiceInfo?.[0].consent.history.length > 1));
  };
  if (typeof window !== 'undefined') {
    window.addEventListener('UC_UI_INITIALIZED', () => {
      updateGTMConsent();
    });
    window.addEventListener('UC_UI_CMP_EVENT', () => {
      updateGTMConsent();
    });
  }

  useEffect(() => {
    updateGTMConsent();
  }, []);

  return hasInteracted;
};

export default useUserCentricsInteracted;
