import { ImageWithFallback } from '@components/Image/WithFallback';
import { Image } from 'generated/graphql/types';
import { StoryblokImage } from '../../Image/StoryblokImage';

export type ProductCardImageProps = {
  image?: Pick<Image, 'src' | 'altText' | 'width' | 'height'> | null;
  placeholderImage?: Pick<Image, 'src' | 'altText' | 'width' | 'height'> | null;
  imageLoading?: 'lazy' | 'eager';
  layout?: 'intrinsic' | 'fill';
};

export const ProductCardImage = ({
  imageLoading,
  image,
  placeholderImage,
  layout,
}: ProductCardImageProps) => {
  if (image?.src?.includes('storyblok') || image?.src?.includes('thearc.dev')) {
    return (
      <StoryblokImage
        className="aspect-square object-contain"
        layout={layout ?? 'fill'}
        width={layout === 'intrinsic' ? image.width ?? 100 : undefined}
        height={layout === 'intrinsic' ? image.height ?? 100 : undefined}
        src={image.src}
        sizes={
          layout === 'intrinsic'
            ? undefined
            : `${Math.max(image.width ?? 100, image.height ?? 100)}px`
        }
        alt={image?.altText || ''}
        loading={imageLoading || 'lazy'}
      />
    );
  }
  return (
    <ImageWithFallback
      className="aspect-square object-contain"
      src={image?.src || ''}
      blurDataURL={placeholderImage?.src}
      placeholder={placeholderImage?.src && 'blur'}
      alt={image?.altText || ''}
      layout="responsive"
      width={image?.width || 100}
      height={image?.height || 100}
      loading={imageLoading || 'lazy'}
    />
  );
};
