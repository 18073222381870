import { ProductHandlesQuery } from '@generated/graphql/types';
import { getBrancheFromCollections, getVariantIdFromShopifyUri } from './utils';

const trimHandle = (handle: string) => {
  if (handle.endsWith('-sds')) {
    return handle.slice(0, -4);
  }
  return handle;
};

/**
 * Returns the path to the product. Can be used for the href prop in Nextjs' Link component.
 */
export const getProductPath = (
  handle: string,
  collections?: NonNullable<
    ArrayElementOf<ProductHandlesQuery['products']['edges']>
  >['node']['collections'],
  variantId?: string,
  variantSku?: string,
) => {
  let path = `/${['products', getBrancheFromCollections(collections), trimHandle(handle)]
    .filter((p) => p !== '')
    .join('/')}`;

  if (variantId) {
    path += `?variant=${
      variantId.includes('gid') ? getVariantIdFromShopifyUri(variantId) : variantId
    }`;
  }
  if (variantSku && !variantId) {
    path += `?variantSKU=${variantSku}`;
  }
  return path;
};
