import SignatureHermannSprengerDogsportsWhite from '@components/generated/logos/SignatureHermannSprengerDogsportsWhite';
import useBreakpoint, { Breakpoints } from '@hooks/useBreakpoint';
import { withBlur } from '../Image/StoryblokImage';
import { ContentBranch, ImageStoryblok, ContentBranchCore } from '@customTypes/storyblok-types';
import { primaryColorDict } from '@lib/color';
import SvgSignatureHermannSprengerGermanyWhite from '../generated/logos/SignatureHermannSprengerGermanyWhite';
import SvgSignatureHermannSprengerBoatWhite from '../generated/logos/SignatureHermannSprengerBoatWhite';
import SvgSignatureHermannSprengerEquestrianWhite from '../generated/logos/SignatureHermannSprengerEquestrianWhite';
import classNames from '../../utils/ClassNames';
import { StoryblokImageOrVideo } from '@components/Image/StoryblokImageOrVideo';
import { ShopTheLookProps } from '@components/storyblok/StoryblokImageWithAnnotations';

interface EditorialSectionEightProps {
  title: string;
  image?: ImageStoryblok;
  content_branch: ContentBranch;
  logo: ContentBranchCore;
  parallax?: boolean;
  layout: 'left' | 'right';
  shopTheLook?: ShopTheLookProps;
  split_headline?: boolean;
}

export const EditorialSectionEight = ({
  title,
  image,
  content_branch,
  logo,
  layout,
  parallax,
  shopTheLook,
  split_headline,
}: EditorialSectionEightProps) => {
  const isMobileViewport = useBreakpoint(Breakpoints.md);

  return (
    <div className="flex flex-row sm:flex-col items-center justify-center py-11 md:py-16">
      <div
        className={classNames(
          'flex col-end md:row-end sm:min-w-fit md:min-w-full bg-dark-black w-full sm:w-auto',
          layout === 'left' ? 'sm:flex-row flex-col' : 'sm:flex-row-reverse flex-col-reverse',
        )}
      >
        <div
          style={{ backgroundColor: primaryColorDict[content_branch] || primaryColorDict.dog }}
          className="flex flex-col justify-center items-center pl-1 pr-1 sm:pl-11 sm:pr-11 min-w-[100%] sm:min-w-[35%]"
        >
          <div className={classNames('text-h-56 sm:text-h-72 text-center text-white mt-9 sm:mt-20 w-[22rem] tracking-wide', split_headline ? 'hyphens-auto' : '')}>
            {title}
          </div>
          <div className="mt-10 md:mt-44 w-44 bottom-8 mb-12">
            {logo === 'dog' && <SignatureHermannSprengerDogsportsWhite />}
            {logo === 'horse' && <SvgSignatureHermannSprengerEquestrianWhite />}
            {logo === 'boat' && <SvgSignatureHermannSprengerBoatWhite />}
            {logo === 'sprenger' && <SvgSignatureHermannSprengerGermanyWhite />}
          </div>
        </div>
        {(image?.filename || shopTheLook) && (
          <div
            className={'relative md:float-right md:ml-auto w-full aspect-square sm:aspect-auto '}
          >
            <StoryblokImageOrVideo
              filename={image?.filename}
              placeholder="blur"
              blurDataURL={withBlur(image?.filename)}
              alt={image?.alt}
              focus={image?.focus}
              parallax={parallax}
              sizes={isMobileViewport ? '100vw' : '55vw'}
              shopTheLook={shopTheLook}
              objectFit="cover"
              layout="fill"
            />
          </div>
        )}
      </div>
    </div>
  );
};
