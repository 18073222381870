import { NavMenuDrawer } from './Drawer';
import { ReactNode } from 'react';

type NavMenuProps = {
  drawerShow: boolean;
  onClose: () => void;
  onAfterLeave?: () => void;
  children?: ReactNode;
};

export const NavMenu = ({ drawerShow, onClose, onAfterLeave, children }: NavMenuProps) => {

  return (
    <NavMenuDrawer show={drawerShow} onAfterLeave={onAfterLeave}>
      <div
        className='z-50 flex flex-row bg-white min-h-min max-h-[60rem] shadow-l2 border-b-[12px] border-accent-primary'
        onMouseLeave={onClose}
      >
        {children}
      </div>
    </NavMenuDrawer>
  );
};
