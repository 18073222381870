import classNames from 'classnames';
import { CollectionCardImage } from './Image';
import { CollectionCardDescription } from './Description';
import { CollectionCardProps } from '.';
import { CollectionCardLink } from './Link';

export const CollectionCardVertical = ({
  className,
  imageLoading,
  image,
  placeholderImage,
  handle,
  title,
  onClick,
}: CollectionCardProps) => (
  <CollectionCardLink onClick={onClick} handle={handle}>
    <div className={classNames('flex flex-col space-y-1.5', className)}>
      <div className="w-full bg-gray-100">
        <CollectionCardImage
          image={image}
          placeholderImage={placeholderImage}
          imageLoading={imageLoading}
        />
      </div>

      <div className="px-4">
        <CollectionCardDescription title={title} />
      </div>
    </div>
  </CollectionCardLink>
);
