import dynamic from 'next/dynamic';

const AlertCircle = dynamic(() => import('./AlertCircle'), { ssr: false });
const ArrowDown = dynamic(() => import('./ArrowDown'), { ssr: false });
const Bell = dynamic(() => import('./Bell'), { ssr: false });
const Book = dynamic(() => import('./Book'), { ssr: false });
const Calendar = dynamic(() => import('./Calendar'), { ssr: false });
const Check = dynamic(() => import('./Check'), { ssr: false });
const CheckCircle = dynamic(() => import('./CheckCircle'), { ssr: false });
const ChevronDown = dynamic(() => import('./ChevronDown'), { ssr: false });
const ChevronLeft = dynamic(() => import('./ChevronLeft'), { ssr: false });
const ChevronRight = dynamic(() => import('./ChevronRight'), { ssr: false });
const ChevronUp = dynamic(() => import('./ChevronUp'), { ssr: false });
const ChevronsDown = dynamic(() => import('./ChevronsDown'), { ssr: false });
const ChevronsLeft = dynamic(() => import('./ChevronsLeft'), { ssr: false });
const ChevronsRight = dynamic(() => import('./ChevronsRight'), { ssr: false });
const ChevronsUp = dynamic(() => import('./ChevronsUp'), { ssr: false });
const Download = dynamic(() => import('./Download'), { ssr: false });
const ExternalLink = dynamic(() => import('./ExternalLink'), { ssr: false });
const Eye = dynamic(() => import('./Eye'), { ssr: false });
const Facebook = dynamic(() => import('./Facebook'), { ssr: false });
const Home = dynamic(() => import('./Home'), { ssr: false });
const Image = dynamic(() => import('./Image'), { ssr: false });
const Info = dynamic(() => import('./Info'), { ssr: false });
const Link = dynamic(() => import('./Link'), { ssr: false });
const Lock = dynamic(() => import('./Lock'), { ssr: false });
const LogIn = dynamic(() => import('./LogIn'), { ssr: false });
const LogOut = dynamic(() => import('./LogOut'), { ssr: false });
const LogoBlackHorizontal = dynamic(() => import('./LogoBlackHorizontal'), { ssr: false });
const LogoBlackVertical = dynamic(() => import('./LogoBlackVertical'), { ssr: false });
const LogoSmall = dynamic(() => import('./LogoSmall'), { ssr: false });
const LogoWhiteHorizontal = dynamic(() => import('./LogoWhiteHorizontal'), { ssr: false });
const LogoWhiteVertical = dynamic(() => import('./LogoWhiteVertical'), { ssr: false });
const Mail = dynamic(() => import('./Mail'), { ssr: false });
const Maximize2 = dynamic(() => import('./Maximize2'), { ssr: false });
const Menu = dynamic(() => import('./Menu'), { ssr: false });
const MoreHorizontal = dynamic(() => import('./MoreHorizontal'), { ssr: false });
const NorthWestArrow = dynamic(() => import('./NorthWestArrow'), { ssr: false });
const Package = dynamic(() => import('./Package'), { ssr: false });
const Pen = dynamic(() => import('./Pen'), { ssr: false });
const Pinterest = dynamic(() => import('./Pinterest'), { ssr: false });
const Play = dynamic(() => import('./Play'), { ssr: false });
const Plus = dynamic(() => import('./Plus'), { ssr: false });
const PlusCircle = dynamic(() => import('./PlusCircle'), { ssr: false });
const Repeat = dynamic(() => import('./Repeat'), { ssr: false });
const RotateCcw = dynamic(() => import('./RotateCcw'), { ssr: false });
const RotateCw = dynamic(() => import('./RotateCw'), { ssr: false });
const Search = dynamic(() => import('./Search'), { ssr: false });
const Settings = dynamic(() => import('./Settings'), { ssr: false });
const Share = dynamic(() => import('./Share'), { ssr: false });
const ShoppingBag = dynamic(() => import('./ShoppingBag'), { ssr: false });
const ShoppingCart = dynamic(() => import('./ShoppingCart'), { ssr: false });
const Sliders = dynamic(() => import('./Sliders'), { ssr: false });
const ThreeDPreview = dynamic(() => import('./ThreeDPreview'), { ssr: false });
const Truck = dynamic(() => import('./Truck'), { ssr: false });
const User = dynamic(() => import('./User'), { ssr: false });
const UserLoggedIn = dynamic(() => import('./UserLoggedIn'), { ssr: false });
const X = dynamic(() => import('./X'), { ssr: false });
const XCircle = dynamic(() => import('./XCircle'), { ssr: false });
const Instagram = dynamic(() => import('./Instagram'), { ssr: false });
const YouTube = dynamic(() => import('./Youtube'), { ssr: false });
const Klarna = dynamic(() => import('./Klarna'), { ssr: false });
const Mastercard = dynamic(() => import('./Mastercard'), { ssr: false });
const Maestro = dynamic(() => import('./Maestro'), { ssr: false });
const Visa = dynamic(() => import('./Visa'), { ssr: false });
const Sofort = dynamic(() => import('./Sofort'), { ssr: false });
const Paypal = dynamic(() => import('./Paypal'), { ssr: false });
const Creditcard = dynamic(() => import('./Creditcard'), { ssr: false });

export {
  AlertCircle,
  ArrowDown,
  Bell,
  Book,
  Calendar,
  Check,
  ChevronUp,
  ChevronDown,
  ChevronLeft,
  ChevronRight,
  ChevronsUp,
  ChevronsDown,
  ChevronsLeft,
  ChevronsRight,
  Download,
  Eye,
  Home,
  Image,
  Info,
  Link,
  Lock,
  LogIn,
  LogOut,
  Mail,
  Maximize2,
  Menu,
  MoreHorizontal,
  NorthWestArrow,
  Package,
  Pen,
  Plus,
  PlusCircle,
  Repeat,
  RotateCcw,
  Search,
  Settings,
  Share,
  ShoppingBag,
  ShoppingCart,
  Sliders,
  ThreeDPreview,
  Truck,
  User,
  UserLoggedIn,
  X,
  XCircle,
  Play,
  LogoSmall,
  Facebook,
  Pinterest,
  YouTube,
  Instagram,
  Klarna,
  Mastercard,
  Maestro,
  Visa,
  Sofort,
  Paypal,
  Creditcard,
};
