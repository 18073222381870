import { Price } from '@components/Price';
import { formatProductTitle } from '@lib/formatProductTitle';
import { percentOf } from '@lib/sale';
import { getPriceAfterDiscount, moneyToQuantity, subtractMoney, sumMoney } from '@lib/utils';
import classNames from '@utils/ClassNames';
import {
  CartDiscountAllocation,
  MoneyV2,
  Product,
  ProductPriceRange,
  ProductVariant,
  ProductVariantDataFragment,
  SelectedOption,
} from 'generated/graphql/types';
import { useMemo } from 'react';
import UnitPrice from '../PageElements/UnitPrice';
import { ProductPrice } from '../Price';
import ProductSaleChip from '../ProductSaleChip';

export interface ProductCardVerticalDescriptionProps {
  title: Product['title'];
  variantTitle?: ProductVariant['title'];
  price: ProductPriceRange | MoneyV2;
  compareAtPrice?: ProductPriceRange | MoneyV2 | null;
  highlights?: string[] | null;
  selectedOptions?: SelectedOption[] | null;
  showSelectedOptions?: boolean;
  showUnitPrice?: boolean;
  wrapUnitPrice?: boolean;
  percentageOff?: number;
  quantity?: number;
  discountAllocations?: CartDiscountAllocation[];
  variant?: Pick<
    ProductVariantDataFragment,
    'priceV2' | 'unitPrice' | 'unitPriceMeasurement' | 'bundle_desc'
  > | null;
}

export const ProductCardVerticalDescription = ({
  title,
  price,
  compareAtPrice,
  variantTitle,
  highlights,
  selectedOptions,
  showSelectedOptions,
  showUnitPrice,
  variant,
  wrapUnitPrice,
  percentageOff,
  quantity,
  discountAllocations,
}: ProductCardVerticalDescriptionProps) => {
  const priceWithQuantity = useMemo(
    () =>
      quantity && price.__typename === 'MoneyV2'
        ? moneyToQuantity(price!, quantity)
        : (price! as MoneyV2),
    [quantity, price],
  );

  const displayedPrice = useMemo(() => {
    if (percentageOff && priceWithQuantity.__typename === 'MoneyV2') {
      return {
        ...priceWithQuantity,
        amount: getPriceAfterDiscount(priceWithQuantity.amount, percentageOff),
      };
    }
    if (
      discountAllocations &&
      discountAllocations.length > 0 &&
      priceWithQuantity.__typename === 'MoneyV2'
    ) {
      const removedFromPrice = discountAllocations.reduce(
        (last, curr) => sumMoney(last, curr.discountedAmount),
        {
          amount: 0,
          currencyCode: priceWithQuantity.currencyCode,
        } as MoneyV2,
      );
      return subtractMoney(priceWithQuantity, removedFromPrice);
    }
    return priceWithQuantity as MoneyV2;
  }, [percentageOff, priceWithQuantity]);

  const subtotalDiscount = useMemo(
    () => percentOf(displayedPrice, priceWithQuantity),
    [priceWithQuantity, displayedPrice],
  );
  return (
    <div className="w-full">
      <p
        style={{ textTransform: 'none' }}
        className="text-h-16 text-opacity-75 font-extrabold line-clamp-2 hover:text-dark-secondary"
        title={formatProductTitle(title, variantTitle)}
      >
        {formatProductTitle(title, variantTitle)}
      </p>
      {highlights && highlights?.length > 0 && (
        <p
          className={classNames(showSelectedOptions ? 'line-clamp-1' : 'line-clamp-2')}
          title={highlights.filter((h: string) => !h.includes('§')).join(', ')}
        >
          {highlights.filter((h: string) => !h.includes('§')).join(', ')}
        </p>
      )}
      {showSelectedOptions &&
        selectedOptions
          ?.filter((o) => o.name !== 'single')
          .map((o) => (
            <span className="mt-1 text-p-12">
              {o.name}: {o.value}
            </span>
          ))}

      {percentageOff ?? subtotalDiscount ? (
        <ProductSaleChip sale={percentageOff ?? subtotalDiscount} />
      ) : null}
      {price.__typename === 'ProductPriceRange' && (
        <ProductPrice
          className="text-accent-primary text-p-16 gap-x-2.5 mt-1 gap-y-1 !flex-row !items-start"
          priceRange={price}
          compareAtPriceV2={
            compareAtPrice?.__typename === 'ProductPriceRange' ? compareAtPrice : undefined
          }
        />
      )}

      {price.__typename === 'MoneyV2' && (
        <Price
          price={displayedPrice}
          compareAtPrice={priceWithQuantity}
          className={classNames(
            'text-accent-primary font-normal text-p-16 mt-1 gap-y-1 !items-start',
          )}
        />
      )}
      {showUnitPrice && variant && (
        <p>
          <UnitPrice
            variantPrice={variant.priceV2}
            measurement={variant.unitPriceMeasurement}
            price={variant.unitPrice}
            referenceUnit={variant.bundle_desc?.value}
          />
        </p>
      )}
    </div>
  );
};
