import { EditorialSectionTen } from '@components/EditorialSection/Ten';
import { ContentBlock10Props } from '@customTypes/storyblok-types';
import { storyblokEditable } from '@storyblok/react';

export const ContentBlock10 = ({ blok }: { blok: ContentBlock10Props }) => (
  <EditorialSectionTen
    /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
    /* @ts-ignore*/
    {...storyblokEditable(blok)}
    headline={blok.headline}
    title_1={blok.title_1}
    title_2={blok.title_2}
    title_3={blok.title_3}
    link_1={blok.link_1}
    link_2={blok.link_2}
    link_3={blok.link_3}
    image_1={blok.image_1}
    image_2={blok.image_2}
    image_3={blok.image_3}
  />
);
