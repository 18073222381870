import { useEffect, useState } from 'react';
import { LoginLandingPage } from './LandingPage';
import Login from './Login';
import Signup from './Signup';
import ForgotPassword from '@components/Customer/Session/ForgotPassword';
import { NOTIFICATION, useNotifications } from '@lib/NotificationContext';
import { useTranslate } from '@tolgee/react';

export type CustomerSessionType = null | 'register' | 'login' | 'reset';

interface CustomerSessionProps {
  initialSessionType: CustomerSessionType;
  onPasswordReset?: () => void;
  onActionDone?: () => void;
  onLinkClick: () => void;
}

export const CustomerSession = ({
  initialSessionType,
  onPasswordReset,
  onActionDone,
  onLinkClick,
}: CustomerSessionProps) => {
  const notification = useNotifications();
  const [newSessionType, setSessionType] = useState<CustomerSessionType>(initialSessionType);
  const { t } = useTranslate();

  const showSuccessfullyRegistered = () => {
    onActionDone?.();
    notification.setNotification({
      actions: [
        {
          href: '/customer/profile/edit',
          text: 'Profil bearbeiten',
        },
      ],
      text: t('modals.signUp.text'),
      title: t('modals.signUp.title'),
      variant: NOTIFICATION.SUCCESS,
    });
  };

  useEffect(() => {
    setSessionType(initialSessionType);
  }, [initialSessionType]);

  return (
    <div data-test="customer-session-drawer">
      {newSessionType === null ? (
        <LoginLandingPage onLinkClick={onLinkClick} />
      ) : newSessionType === 'register' ? (
        <Signup onSuccess={() => showSuccessfullyRegistered()} />
      ) : newSessionType === 'reset' ? (
        <ForgotPassword />
      ) : (
        <Login
          onLogin={() => onActionDone?.()}
          onPasswordReset={() => {
            setSessionType('reset');
            onPasswordReset?.();
          }}
        />
      )}
    </div>
  );
};
