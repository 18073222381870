import { ContentBlock5Props, ImageStoryblok } from '@customTypes/storyblok-types';
import { ReactNode } from 'react';
import classNames from '@utils/ClassNames';
import { StoryblokButtonProps, StoryblokButton } from '@components/Button/StoryblokButton';
import { withBlur } from '@components/Image/StoryblokImage';
import { StoryblokImageOrVideo } from '@components/Image/StoryblokImageOrVideo';
import { ShopTheLookProps } from '../StoryblokImageWithAnnotations';
import { ISbRichtext } from '@storyblok/react';
import { RichText } from '@components/RichText';
import { storyblokEditable } from '@storyblok/react';

export const ContentBlock5 = ({ blok }: { blok: ContentBlock5Props }) => {
  const button = blok.button_text
    ? { text: blok.button_text, url: blok.button_link.url, className: 'w-full mt-6' }
    : undefined;

  return (
    <div
      /* @ts-ignore*/
      {...storyblokEditable(blok)}
      className={classNames(blok.blue_background && "bg-accent-primary_005")}
    >
      <div className="md:space-y-0 box-x-wrapper w-full py-11 md:py-16">
        <div className="flex flex-row w-full md:space-x-3">
          {blok.image_emphasis === 'right' ? (
            <>
              {blok.image_right && (
                <ImageComponent
                  className="w-full lg:w-2/3"
                  {...blok.image_right}
                  parallax={blok.image_right_parallax}
                  shopTheLook={blok.shopTheLook1}
                  video_id={blok.video_id}
                  autoplay={blok.autoplay}
                />
              )}
              <FeaturedLayoutComponent>
                {blok.image_left && (
                  <ImageComponent
                    {...blok.image_left}
                    parallax={blok.image_left_parallax}
                    shopTheLook={blok.shopTheLook2}
                    className="hidden md:flex"
                  />
                )}
                <InformationComponent
                  title={blok.title}
                  subtitle={blok.subtitle}
                  description={blok.description}
                  className="hidden lg:block"
                  button={button}
                />
              </FeaturedLayoutComponent>
            </>
          ) : (
            <>
              <FeaturedLayoutComponent>
                {blok.image_left && (
                  <ImageComponent
                    {...blok.image_left}
                    parallax={blok.image_left_parallax}
                    shopTheLook={blok.shopTheLook2}
                    className="hidden md:flex"
                  />
                )}
                <InformationComponent
                  title={blok.title}
                  subtitle={blok.subtitle}
                  description={blok.description}
                  className="hidden lg:block"
                  button={button}
                />
              </FeaturedLayoutComponent>
              {blok.image_right && (
                <ImageComponent
                  className="w-full lg:w-2/3"
                  {...blok.image_right}
                  parallax={blok.image_right_parallax}
                  shopTheLook={blok.shopTheLook1}
                  video_id={blok.video_id}
                  autoplay={blok.autoplay}
                />
              )}
            </>
          )}
        </div>
        <InformationComponent
          title={blok.title}
          subtitle={blok.subtitle}
          description={blok.description}
          className="lg:hidden"
        />
      </div>
    </div>
  );
};

interface InformationComponentProps {
  subtitle: string;
  title: string;
  description: ISbRichtext;
  className?: string;
  button?: StoryblokButtonProps;
}

const InformationComponent = ({
  subtitle,
  title,
  description,
  className,
  button,
}: InformationComponentProps) => (
  <div className={classNames('space-y-4 lg:w-11/12', className)}>
    <div className="flex flex-col">
      <span className="text-h-16 mt-6">{subtitle}</span>
      <span className="text-h-32 hyphens-auto">{title}</span>
    </div>
    {typeof description === 'string' ? <p>{description}</p> : <RichText text={description} />}
    <div className="flex flex-col w-full items-stretch">
      {button && <StoryblokButton {...button} />}
    </div>
  </div>
);

const ImageComponent = ({
  filename,
  alt,
  className,
  video_id,
  autoplay,
  shopTheLook,
  focus,
  parallax,
}: ImageStoryblok & {
  video_id?: string;
  parallax?: boolean;
  autoplay?: boolean;
  className?: string;
  shopTheLook?: ShopTheLookProps;
}) => {
  if (!filename && !video_id && !shopTheLook) return <></>;
  return (
    <div className={classNames('relative aspect-[1/1] md:aspect-[9/10]', className)}>
      <StoryblokImageOrVideo
        filename={filename}
        placeholder="blur"
        blurDataURL={withBlur(filename)}
        parallax={parallax}
        alt={alt}
        focus={focus}
        sizes="50vw"
        layout="fill"
        objectFit="cover"
        shopTheLook={shopTheLook}
        video_id={video_id}
        autoplay={autoplay}
      />
    </div>
  );
};

const FeaturedLayoutComponent = ({ children }: { children: ReactNode }) => (
  <div className="md:w-1/2 lg:flex lg:flex-col lg:justify-between lg:space-y-6 lg:w-1/3">
    {children}
  </div>
);
