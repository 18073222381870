import { Fragment, ReactNode } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { LayoutNavbar } from '../Layout/Navbar';

interface MenuDrawerProps {
  show: boolean;
  onClose: () => void;
  children: ReactNode;
  onAfterLeave: () => void;
}

/**
 * @param args
 * @param args.show
 * @param args.onClose
 * @param args.onAfterLeave
 * @param args.children
 */

export const MenuDrawer = ({ show, onClose, children, onAfterLeave }: MenuDrawerProps) => (
  <Transition.Root show={show} as={Fragment} afterLeave={onAfterLeave}>
    <Dialog as="div" className="fixed inset-0 z-40 overflow-hidden" onClose={onClose}>
      <div className="absolute inset-0 overflow-hidden" onClick={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="absolute inset-0 transition-opacity bg-dark-quaternary" />
        </Transition.Child>

        <div className="fixed inset-x-0 bottom-0 w-full h-full overflow-hidden lg:w-auto lg:left-0 lg:inset-x-auto top-0">
          <LayoutNavbar />
          <Transition.Child
            as={Fragment}
            enter="transform transition ease-in-out duration-500 sm:duration-700"
            enterFrom="-translate-y-full lg:translate-y-0 lg:-translate-x-full"
            enterTo="translate-y-0 lg:translate-x-0"
            leave="transform transition ease-in-out duration-500 sm:duration-700"
            leaveFrom="translate-y-0 lg:translate-x-0"
            leaveTo="-translate-y-full lg:translate-y-0 lg:-translate-x-full"
          >
            {children}
          </Transition.Child>
        </div>
      </div>
    </Dialog>
  </Transition.Root>
);
