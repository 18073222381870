import { ContentBlock6Props } from '@customTypes/storyblok-types';
import { storyblokEditable } from '@storyblok/react';
import { useState, useEffect } from 'react';
import { useWindowSize } from 'react-use';
import useBreakpoint, { Breakpoints } from '@hooks/useBreakpoint';
import { primaryColorDict } from '@lib/color';
import classNames from '@utils/ClassNames';
import { StoryblokButton } from '@components/Button/StoryblokButton';
import { StoryblokFloatingImage } from '@components/Image/StoryblokFloatingImage';
import { withBlur } from '@components/Image/StoryblokImage';
import { StoryblokImageOrVideo } from '@components/Image/StoryblokImageOrVideo';
import { RichText } from '@components/RichText';
import resolveConfig from 'tailwindcss/resolveConfig';
import tailwindConfig from '../../../tailwind.config';

const tailwind = resolveConfig(tailwindConfig as any);
const maxWidth = tailwind.theme?.maxWidth as any;
const wrapperMaxWidth = maxWidth['7xl'].replace('rem', '') * 16;

interface Paragraph {
  content: Array<{
    text: string;
    type: 'text';
  }>;
  type: 'paragraph';
}
export interface RichTextContent {
  content: Array<Paragraph>;
}

export const ContentBlock6 = ({ blok }: { blok: ContentBlock6Props }) => {
  const { width: windowWidth } = useWindowSize();
  const smallerBreakpoint = Breakpoints['2xl'];
  const isSmallerViewport = useBreakpoint(smallerBreakpoint);
  const mobileBreakpoint = Breakpoints.md;
  const isMobileViewport = useBreakpoint(mobileBreakpoint);
  const blockTitleElementContent: Array<string | JSX.Element> = [];
  const [blockTitleElement, setBlockTitleElement] = useState(<></>);
  const [titleAsNormalString, setTitleAsNormalString] = useState('');

  useEffect(() => {
    if (blok.titleRich) {
      let titleAsNormalStringTemp = '';
      blok.titleRich.content.forEach((paragraph: Paragraph) => {
        const paragraphText = paragraph?.content?.[0]?.text ?? '';
        titleAsNormalStringTemp = titleAsNormalStringTemp += paragraph.content
          ? `${paragraphText} `
          : ' ';
        const paragraphTextCleaned = paragraphText.trimEnd();
        let paragraphTextNoSpaces: Array<string> = [];

        if (paragraphTextCleaned.match(/[.,]$/)) {
          if (paragraphTextCleaned.includes(' ')) {
            paragraphTextNoSpaces = paragraphTextCleaned.split(' ');
            const lastParagraphTextElementIndex = paragraphTextNoSpaces.length - 1;

            paragraphTextNoSpaces.forEach((paragraphTextPart, index) => {
              if (
                index === lastParagraphTextElementIndex &&
                paragraphTextNoSpaces[lastParagraphTextElementIndex].length <= 6
              ) {
                blockTitleElementContent.push(
                  <span style={{ whiteSpace: 'nowrap' }}>
                    {paragraphTextNoSpaces[lastParagraphTextElementIndex]}
                    &nbsp;
                    <br />
                  </span>,
                );
              } else {
                blockTitleElementContent.push(<>{`${paragraphTextPart}`}&nbsp;</>);
              }
            });
          } else {
            if (paragraphTextCleaned.length <= 6) {
              blockTitleElementContent.push(
                <span style={{ whiteSpace: 'nowrap' }}>
                  {paragraphTextCleaned}
                  &nbsp;
                  <br />
                </span>,
              );
            } else {
              const paragraphTextMain = paragraphTextCleaned.slice(
                0,
                paragraphTextCleaned.length - 2,
              );
              const paragraphTextEnding = paragraphTextCleaned.slice(
                paragraphTextCleaned.length - 2,
              );
              blockTitleElementContent.push(paragraphTextMain);
              blockTitleElementContent.push(
                <span style={{ whiteSpace: 'nowrap' }}>
                  {paragraphTextEnding}
                  <br />
                </span>,
              );
            }
          }
        } else if (paragraphTextCleaned.includes(' ')) {
          paragraphTextNoSpaces = paragraphTextCleaned.split(' ');

          paragraphTextNoSpaces.forEach((paragraphTextPart) => {
            if (paragraphTextPart.length <= 6) {
              blockTitleElementContent.push(
                <span style={{ whiteSpace: 'nowrap' }}>
                  {`${paragraphTextPart}`}
                  &nbsp;
                  <br />
                </span>,
              );
            } else {
              blockTitleElementContent.push(`${paragraphText} `);
            }
          });
        } else if (paragraphText.length <= 6) {
          blockTitleElementContent.push(
            !isSmallerViewport ? (
              <span style={{ whiteSpace: 'nowrap' }}>
                {paragraphText}
                &nbsp;
                <br />
              </span>
            ) : (
              <span style={{ wordBreak: 'keep-all' }}>{`${paragraphText}`}&nbsp;</span>
            ),
          );
        } else if (paragraphText.length > 10 && paragraphText.length < 13) {
          blockTitleElementContent.push(
            <span
              style={!isSmallerViewport ? { whiteSpace: 'nowrap' } : { wordBreak: 'break-word' }}
            >
              {paragraphText}
              <br />{' '}
            </span>,
          );
        } else {
          blockTitleElementContent.push(`${paragraphText} `);
        }
      });
      setBlockTitleElement(
        <>
          {blockTitleElementContent.map((element, index) => (
            <p className="title" key={index} style={{ display: 'inline-block' }}>
              {element}
            </p>
          ))}
        </>,
      );
      setTitleAsNormalString(titleAsNormalStringTemp);
    }
  }, [blok.titleRich, isSmallerViewport, blok.use_richtext]);

  return (
    <div
      /* @ts-ignore*/
      {...storyblokEditable(blok)}
      className={classNames(
        'sm:pb-36 lg:pb-48 mb-12 !mt-0',
        blok.add_padding ? 'pt-11 md:pt-16' : '',
        blok.blue_background && "bg-accent-primary_005"
      )}
    >
      <div className="relative flex flex-col items-center w-full">
        <div
          className={classNames(
            'flex flex-col w-full min-h-[46rem]',
            blok.image_emphasis === 'right' ? 'sm:flex-row' : 'sm:flex-row-reverse',
          )}
        >
          {(blok.bg_image.filename || blok.video_id || blok.shopTheLook) && (
            <div
              className="relative z-10 w-full aspect-square sm:w-1/2 sm:aspect-auto lg:w-8/12"
              style={{
                width:
                  isMobileViewport && windowWidth >= wrapperMaxWidth
                    ? `calc((100vw - ${wrapperMaxWidth}px)/2 + (7 / 12) *  ${wrapperMaxWidth}px)`
                    : undefined,
                perspective: '8px',
                perspectiveOrigin: '50%',
              }}
            >
              <StoryblokImageOrVideo
                filename={blok.bg_image.filename}
                placeholder="blur"
                blurDataURL={withBlur(blok.bg_image.filename)}
                alt={blok.bg_image.alt}
                sizes={isMobileViewport ? '100vw' : '66vw'}
                layout="fill"
                focus={blok.bg_image.focus}
                parallax={blok.image_parallax}
                objectFit="cover"
                video_id={blok.video_id}
                autoplay={blok.autoplay}
                shopTheLook={blok.shopTheLook}
              />
              {blok.floating_image.filename && (
                <div
                  className={classNames(
                    'absolute bottom-0 translate-y-1/2 sm:w-1/2 pointer-events-none aspect-square max-md:flex max-md:justify-center max-md:left-1/2 max-md:-translate-x-1/2',
                    blok.image_emphasis === 'right' && 'md:right-0',
                  )}
                  style={{ width: isMobileViewport ? `${blok.mobileProductImageSize?.value ?? 100}%` : undefined }}
                >
                  <StoryblokFloatingImage {...blok.floating_image} />
                </div>
              )}
            </div>
          )}

          <div
            className={`flex flex-col justify-center z-0 px-4 space-y-6 pt-32 sm:pt-0 ${blok.content_branch === 'noBranding' ? '' : 'border-t-24'
              } sm:w-1/2 sm:space-y-0 sm:px-0 lg:w-4/12`}
            style={{
              borderTopColor: primaryColorDict[blok.content_branch] || primaryColorDict.dog,
              width:
                isMobileViewport && windowWidth >= wrapperMaxWidth
                  ? `calc((100vw - ${wrapperMaxWidth}px)/2 + (5 / 12) *  ${wrapperMaxWidth}px)`
                  : undefined,
            }}
          >
            <div
              className="flex flex-col items-center space-y-8 sm:items-start sm:pr-wrapper sm:pl-10"
              style={{
                maxWidth:
                  isMobileViewport && windowWidth >= wrapperMaxWidth
                    ? `calc((5 / 12) * ${wrapperMaxWidth}px)`
                    : undefined,
              }}
            >
              <div className="space-y-6 max-w-full">
                <h4
                  className="text-h-56 lg:text-h-64 xl:text-h-72"
                  style={
                    blok.use_richtext
                      ? { hyphens: 'auto', wordBreak: 'break-word' }
                      : { hyphens: 'auto' }
                  }
                >
                  {!isMobileViewport && blok.use_richtext ? (
                    <div style={{ hyphens: 'auto' }}>{blockTitleElement}</div>
                  ) : blok.title ? (
                    blok.title
                  ) : (
                    titleAsNormalString
                  )}
                </h4>
                {blok.use_richtext
                  ? blok.descriptionRich && <RichText text={blok.descriptionRich}></RichText>
                  : blok.description && <p>{blok.description}</p>}
              </div>
              {blok.button_text && blok.button_link && (
                <StoryblokButton text={blok.button_text} url={blok.button_link.url} />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
