import { OrdersQuery } from '@generated/graphql/types';
import { create } from 'zustand';

type OrderList = Array<
  ArrayElementOf<NonNullable<OrdersQuery['customer']>['orders']['edges']>['node']
> | null;

export const useOrdersZustand = create<{
  orders: OrderList;
  setOrders: (orders: OrderList) => void;
}>((set) => ({
  orders: null,
  setOrders: (orders: OrderList) => set((state) => ({ ...state, orders })),
}));
