import { ButtonPrimary } from '@components/Button/Primary';
import { useCustomerAccessToken } from '@hooks/useCustomerAccessToken';
import { useRouter } from 'next/router';
import { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { CustomerUserError } from '@generated/graphql/types';
import { useTranslate } from '@tolgee/react';
import { FormFieldTextInput } from '@components/Form/Field/TextInput';

type Inputs = {
  customerEmail: string;
  password: string;
};

type LoginProps = {
  onPasswordReset: () => void;
  onLogin: () => void;
};

const Login = ({ onPasswordReset, onLogin }: LoginProps) => {
  const [, , { login }] = useCustomerAccessToken();
  const router = useRouter();
  const [fetchErrors, setFetchErrors] = useState<Array<CustomerUserError>>([]);
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslate();

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<Inputs>();

  const onSubmit: SubmitHandler<Inputs> = async (formData) => {
    setIsLoading(true);
    const res = await login(formData.customerEmail, formData.password);

    if (Array.isArray(res)) {
      setFetchErrors(res);
    } else {
      setFetchErrors([]);
      onLogin();
    }
    setIsLoading(false);

    if (res && 'accessToken' in res) {
      if (router.pathname !== '/cart') {
        router.push('/customer');
      }
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="flex flex-col gap-6">
        <FormFieldTextInput
          label={t('customer.email')}
          type="email"
          name="customerEmail"
          formRegister={register}
          formWatch={watch}
          errors={errors}
          required
        />

        <FormFieldTextInput
          label={t('changeEmail.form.password')}
          type="password"
          name="password"
          formRegister={register}
          formWatch={watch}
          errors={errors}
          required
        />
      </div>
      <div className="flex flex-col gap-6 pt-6">
        {fetchErrors.length ? (
          <div className="text-special-danger">
            {fetchErrors
              .filter((e) => !!e.code)
              .map((err, index) => (
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                <div key={index}>{t(`customerErrorCodeMapping.${err.code!}`)}</div>
              ))}
          </div>
        ) : (
          <></>
        )}
        <a
          role="none"
          onClick={() => onPasswordReset()}
          className="cursor-pointer text-accent-primary text-p-16"
        >
          {t('signIn.forgotPassword')}
        </a>
        <div className="flex justify-end">
          <ButtonPrimary
            className="w-full"
            type="submit"
            loading={isLoading}
            text={t('signIn.submitButton')}
          />
        </div>
      </div>
    </form>
  );
};

export default Login;
