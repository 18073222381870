import { useEffect, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import { Transition } from '@headlessui/react';
import { useWindowSize } from 'react-use';
import { useLocale } from '@zustand/useLocale';

interface StoryDataContent {
  data: {
    stories: {
      content: {
        parts: Array<{
          icon: { filename: string };
          text: string;
          link: { url: string; target?: string };
        }>;
      };
    };
  };
}
type StoryDataContentItem = ArrayElementOf<StoryDataContent['data']['stories']['content']['parts']>;

export const InfoBanner = ({ isScrolledToTop }: { isScrolledToTop: boolean }) => {
  const { language, locale } = useLocale();
  const [storyData, setStoryData] = useState<StoryDataContent | null>(null);
  const container = useRef<HTMLDivElement>(null);
  const windowSize = useWindowSize();
  const [index, setIndex] = useState<number>(0);
  const [activeElement, setActiveElement] = useState<StoryDataContentItem | null>();
  let timerInterval: NodeJS.Timeout | null = null;

  const isOverflowing = useMemo(() => {
    if (container.current) {
      const overflowX = container.current.offsetWidth < container.current.scrollWidth;
      return overflowX;
    }
    return false;
  }, [container.current, windowSize]);

  const startTimerInterval = () => {
    if (timerInterval) {
      clearInterval(timerInterval);
    }

    setActiveElement(storyData?.data?.stories?.content?.parts?.[0]);
    setTimeout(() => {
      setActiveElement(null);
    }, 5600);
    timerInterval = setInterval(() => {
      setIndex(prevIndex => (prevIndex + 1) % (storyData?.data?.stories?.content?.parts?.length ?? 1));
    }, 5600);
  }

  useEffect(() => {
    setActiveElement(storyData?.data?.stories?.content?.parts?.[index] ?? null);
    setTimeout(() => {
      setActiveElement(null);
    }, 5000);
  }, [index]);

  useEffect(() => {
    if (isOverflowing) {
      startTimerInterval();
    }
  }, [isOverflowing]);

  useEffect(() => {
    if (!locale && !language) {
      return;
    }
    fetch('/api/storyblok/retrieve', {
      body: JSON.stringify({
        lang: language,
        path: `static/${locale.toLowerCase()}/infobar`,
        slugs: undefined,
      }),
      method: 'POST',
    })
      .then((res) => res.json() as unknown as StoryDataContent)
      .then((res) => {
        if (res.data.stories.content) {
          setStoryData(res);
        }
      });
  }, [locale, language]);

  if (!storyData?.data?.stories?.content?.parts?.length) {
    return <></>;
  }

  return (
    <div>
      <div
        ref={container}
        className={classNames(
          'absolute opacity-0 flex w-full py-3 justify-center items-center gap-3 lg:gap-12 text-h-12 whitespace-nowrap',
        )}
      >
        {storyData?.data?.stories?.content?.parts.map((p, index) => (
          <BannerItem item={p} key={index} />
        ))}
      </div>
      {isOverflowing ? (
        <div
          className={classNames(
            'relative flex overflow-x-hidden',
            isScrolledToTop ? 'bg-accent-primary text-white' : 'bg-white text-black',
          )}
        >
          <div className="relative w-full py-3 flex justify-center h-10 items-center gap-3 lg:gap-12 text-h-12 transition-colors whitespace-nowrap">
            <Transition
              show={activeElement != null}
              appear={true}
              enter="transition-all duration-500"
              enterFrom="opacity-0 -translate-x-20"
              enterTo="opacity-100 translate-x-0"
              leave="transition-all duration-300"
              leaveFrom="opacity-100 translate-x-0"
              leaveTo="opacity-0 translate-x-20"
            >
              {storyData?.data?.stories?.content?.parts[index] && (
                <BannerItem item={storyData.data.stories.content.parts[index]} />
              )}
            </Transition>
          </div>
        </div>
      ) : (
        <div
          className={classNames(
            'relative w-full py-3 flex justify-center items-center text-h-12 transition-colors whitespace-nowrap',
            isScrolledToTop ? 'bg-accent-primary text-white' : 'bg-white text-black',
          )}
        >
          <div className="max-w-5xl w-full flex justify-center sm:gap-6 lg:gap-24 xl:gap-36">
            {storyData?.data?.stories?.content?.parts.map((p, index) => (
              <div className="flex justify-center" key={index}>
                <BannerItem item={p} />
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

const BannerItem = ({ item }: { item: StoryDataContentItem }) =>
  item.link?.url ? (
    <a
      href={item.link.url}
      target={item.link.target}
      className="relative flex gap-2 items-center hover:underline"
      key={item.text}
    >
      {item.text}
    </a>
  ) : (
    <div className="relative flex gap-2 items-center" key={item.text}>
      {item.text}
    </div>
  );
