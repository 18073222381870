import { useEffect, useState } from 'react';
import ChevronUp from '@components/icons/ChevronUp';
import classNames from 'classnames';

type BackToTopProps = {
  /**
   * Scroll threshold in decimal 0 <= x <= 1
   */
  scrollThreshold?: number;
};

export const BackToTop = ({ scrollThreshold = 1 }: BackToTopProps) => {
  const [shouldShow, setShouldShow] = useState(false);

  useEffect(() => {
    if (typeof window === 'undefined') {
      return;
    }
    window.onscroll = () => evaluateScroll();
    evaluateScroll();

    return () => {
      window.onscroll = null;
    };
  }, [scrollThreshold, shouldShow]);

  function evaluateScroll() {
    const percentage = window.scrollY / window.outerHeight;
    if (percentage > scrollThreshold && !shouldShow) {
      setShouldShow(true);
      return;
    }

    if (percentage < scrollThreshold && shouldShow) {
      setShouldShow(false);
      return;
    }
  }

  function scrollToTop() {
    window.scrollTo({
      behavior: 'smooth',
      top: 0,
    });
  }

  return (
    <button
      onClick={scrollToTop}
      data-test="back-to-top"
      className={classNames(
        'bg-accent-primary rounded-full back-to-top h-12 w-12 fixed md:bottom-24 bottom-[1.2rem] md:right-10 right-[5.5rem] p-1 z-30 transform transition duration-500',
        shouldShow ? 'scale-100' : 'scale-0',
      )}
    >
      <ChevronUp className="text-white" />
    </button>
  );
};
