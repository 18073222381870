import SlickSlider, { Settings } from 'react-slick';
import { ReactNode } from 'react';
import { SliderArrowNext } from './Arrow/Next';
import { SliderArrowPrev } from './Arrow/Prev';

type SliderProps = { children: ReactNode } & Omit<Settings, 'nextArrow' | 'prevArrow'>;

export const Slider = ({ children, ...sliderProps }: SliderProps) => (
  <SlickSlider {...sliderProps} nextArrow={<SliderArrowNext />} prevArrow={<SliderArrowPrev />}>
    {children}
  </SlickSlider>
);
