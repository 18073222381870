import { useCustomerLazyQuery } from '@generated/graphql/apollo';
import { useCustomerZustand } from '@zustand';
import { useEffect, useState } from 'react';
import { useCustomerAccessToken } from './useCustomerAccessToken';
import { useDidMount } from './useDidMount';
import { CustomerQuery } from '@generated/graphql/types';
import { useLocale } from '@zustand/useLocale';
import { ensureLanguage } from '@lib/utils';

export const useCustomer: () => [
  CustomerQuery['customer'] | null,
  { wasFetched: boolean; refresh: () => Promise<void> },
] = () => {
  // State if the customer has been fetched or at least has tried to been fetched.
  const [wasFetched, setWasFetched] = useState(false);
  const { language, locale } = useLocale();
  const didMount = useDidMount();
  const [customerAccessToken, , { reset }] = useCustomerAccessToken();
  const [customerQuery, { data, error, refetch, loading }] = useCustomerLazyQuery({
    variables: {
      customerAccessToken: customerAccessToken?.accessToken as string,
      country: ensureLanguage(locale),
      language: ensureLanguage(language),
    },
  });

  const { customer, setCustomer } = useCustomerZustand();

  useEffect(() => {
    if (!didMount) {
      return;
    }
    if (customerAccessToken?.accessToken && !loading) {
      customerQuery({
        variables: {
          customerAccessToken: customerAccessToken?.accessToken,
          country: ensureLanguage(locale),
          language: ensureLanguage(language),
        },
      });
    } else {
      setCustomer(null);
    }
  }, [customerAccessToken, didMount]);

  useEffect(() => {
    if (!didMount) {
      return;
    }
    // On first load, wether we have a customer or not, we set wasFetched to true.
    setWasFetched(true);
    setCustomer(data?.customer);
    if (error) {
      console.log('error while fetching customer', error);
    }
    if (data && data.customer === null) {
      setCustomer(null);
      reset();
    }
    // Route to login if no customer is found. // @Dome soll das immer aufgeklappt werden, wenn man nicht eingeloggt ist? :o
    // if (!data?.customer && didMount && !loading) {
    //   router.push({
    //     pathname: router.pathname,
    //     query: { ...router.query, login: 'true' },
    //   });
    // }
  }, [data, error]);

  const refresh = async () => {
    setWasFetched(false);
    await refetch();
    setWasFetched(true);
  };

  return [customer, { refresh, setCustomer, wasFetched }];
};
