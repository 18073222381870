import { LocalStorage } from '@customTypes/LocalStorage';
import { useCartCreateMutation } from '@generated/graphql/apollo';
import { useCartZustand } from '@zustand/cart';
import { useCustomerAccessToken } from './useCustomerAccessToken';
import { useLocale } from '@zustand/useLocale';

export const useCreateCart = () => {
  const setId = useCartZustand((store) => store.setId);
  const { locale } = useLocale();
  const [customerAccessToken] = useCustomerAccessToken();

  return useCartCreateMutation({
    onCompleted: (data) => {
      if (!data.cartCreate?.cart?.id) {
        return;
      }

      const sessionCartId = data.cartCreate.cart.id;
      setId(sessionCartId);
      localStorage.setItem(LocalStorage.CartId, sessionCartId);
    },
    onError: (error) => {
      console.error('Remote cart creation failed', error.message);
    },
    variables: {
      input: {
        buyerIdentity: {
          customerAccessToken: customerAccessToken?.accessToken,
          countryCode: locale,
        },
      },
    },
  });
};
