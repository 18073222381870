import { ImageWithFallback } from '@components/Image/WithFallback';
import { Image } from 'generated/graphql/types';

export type CollectionCardImageProps = {
  image?: Pick<Image, 'src' | 'altText' | 'width' | 'height'> | null;
  placeholderImage?: Pick<Image, 'src' | 'altText' | 'width' | 'height'> | null;
  imageLoading?: 'lazy' | 'eager';
};

export const CollectionCardImage = ({
  imageLoading,
  image,
  placeholderImage,
}: CollectionCardImageProps) => (
  <ImageWithFallback
    src={image?.src || ''}
    blurDataURL={placeholderImage?.src}
    placeholder="blur"
    alt={image?.altText || ''}
    layout="responsive"
    width={image?.width || 100}
    height={image?.height || 100}
    loading={imageLoading || 'lazy'}
  />
);
