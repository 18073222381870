import { Box } from '@components/storyblok/Layout/Box';
import { ContentBlock1 } from '@components/storyblok/ContentBlocks/ContentBlock1';
import { ContentBlock2 } from '@components/storyblok/ContentBlocks/ContentBlock2';
import { ContentBlock4 } from '@components/storyblok/ContentBlocks/ContentBlock4';
import { ContentBlock5 } from '@components/storyblok/ContentBlocks/ContentBlock5';
import { ContentBlock6, RichTextContent } from '@components/storyblok/ContentBlocks/ContentBlock6';
import { ContentBlock7 } from '@components/storyblok/ContentBlocks/ContentBlock7';
import { ContentBlock8 } from '@components/storyblok/ContentBlocks/ContentBlock8';
import { FaqItem } from '@components/storyblok/Faq/FaqItem';
import { FaqList } from '@components/storyblok/Faq/FaqList';
import { HeroBlock } from '@components/storyblok/HeroBlock';
import { HeroBlock2, HeroBlock2Props } from '@components/storyblok/HeroBlock2';
import { QuestionAnswer } from '@components/storyblok/QuestionAnswer';
import { RichTextBlock } from '@components/storyblok/RichTextBlock';
import { Section } from '@components/storyblok/Layout/Section';
import { Slider } from '@components/storyblok/Product/Slider';
import { ContentBlock9 } from '@components/storyblok/ContentBlocks/ContentBlock9';
import { MaterialAndCareBaseBlock } from '@components/storyblok/MaterialAndCareBaseBlock';
import { ContentBlock10 } from '@components/storyblok/ContentBlocks/ContentBlock10';
import { HeroSliderBlock } from '@components/storyblok/HeroSlider';
import { ContentBlock11 } from '@components/storyblok/ContentBlocks/ContentBlock11';
import { ContentBlock12 } from '@components/storyblok/ContentBlocks/ContentBlock12';
import { ContentBlock13 } from '@components/storyblok/ContentBlocks/ContentBlock13';
import { ContentBlock14 } from '@components/storyblok/ContentBlocks/ContentBlock14';
import { YoutubeBlock } from '@components/storyblok/ContentBlocks/YoutubeBlock';
import { ShopTheLook, ShopTheLookProps } from '@components/storyblok/StoryblokImageWithAnnotations';
import { ISbRichtext, StoryblokComponentType } from '@storyblok/react';
import { FAQSection } from '../components/storyblok/Faq/FaqSection';
import { ContentBlock3 } from '../components/storyblok/ContentBlocks/ContentBlock3';
import { MagazinTitle } from '../components/storyblok/ContentBlocks/MagazinTitle';
import { MagazinShare } from '../components/storyblok/ContentBlocks/MagazinShare';
import NewsletterBlock from '@components/storyblok/NewsletterBlock';

/*
 *  ADD ALL STORYBLOK COMPONENTS HERE:
 */
export const StoryBlokComponents = {
  box: Box,
  care: MaterialAndCareBaseBlock,
  contentBlock1: ContentBlock1,
  contentBlock10: ContentBlock10,
  contentBlock11: ContentBlock11,
  contentBlock12: ContentBlock12,
  contentBlock13: ContentBlock13,
  contentBlock14: ContentBlock14,
  contentBlock2: ContentBlock2,
  contentBlock3: ContentBlock3,
  contentBlock4: ContentBlock4,
  contentBlock5: ContentBlock5,
  contentBlock6: ContentBlock6,
  contentBlock7: ContentBlock7,
  contentBlock8: ContentBlock8,
  contentBlock9: ContentBlock9,
  faqItem: FaqItem,
  faqList: FaqList,
  hero: HeroBlock,
  hero2: HeroBlock2,
  heroSlider: HeroSliderBlock,
  material: MaterialAndCareBaseBlock,
  questionAnswer: QuestionAnswer,
  richTextBlock: RichTextBlock,
  section: Section,
  slider: Slider,
  youtubeBlock: YoutubeBlock,
  magazinTitle: MagazinTitle,
  magazinShare: MagazinShare,
  faqSection: FAQSection,
  shopTheLook: ShopTheLook,
  newsletterBlock: NewsletterBlock,
};

/** Not needed because we don't use type-sense for this anyway */
export type DynamicComponentBlok = any;

// The content branches including the noBranding flag that indicates no branding should be shown.
export type ContentBranch = 'dog' | 'boat' | 'horse' | 'sprenger' | 'noBranding';

// The core content branch type - without noBranding flag.
export type ContentBranchCore = Exclude<ContentBranch, 'noBranding'>;

export type StoryBlokComponentSlug = keyof typeof StoryBlokComponents;
// BLOK DEFINITIONS
// interface GenericBlok {
//   _editable: string;
//   _uid: string;
//   component: StoryBlokComponentSlug;
// }

export interface HeroBlockProps extends StoryblokComponentType<string> {
  headline: string;
  title: string;
  description: ISbRichtext;
  bg_image: ImageStoryblok;
  floating_image: ImageStoryblok;
  button_text: string;
  button_link: LinkStoryblok;
  content_branch: ContentBranch;
  signature: ContentBranchCore;
}

export interface ImageStoryblok {
  alt: string | undefined;
  copyright: any;
  fieldtype: 'asset';
  filename: string | null;
  focus?: string;
  id: string | null;
  name: string;
  title: string | null;
}

export interface LinkStoryblok {
  cached_url: string;
  fieldtype: 'multilink';
  id: string;
  linktype: 'url' | 'story';
  url: string;
}

export interface SizeBlok extends StoryblokComponentType<'size'> {
  image: ImageStoryblok;
  title: string;
  description: ISbRichtext;
  /** Is true by default -> storyblok sends as undefined or false */
  showPimDetails: boolean;
}

export interface ContentBlock1Props extends StoryblokComponentType<'imagePair'> {
  headline: string;
  text: ISbRichtext;
  image1: ImageStoryblok;
  button_text: string;
  button_link: LinkStoryblok;
  image_emphasis: 'left' | 'right';
  image2?: ImageStoryblok;
  video_id?: string;
  autoplay?: boolean;
  shopTheLook1?: ShopTheLookProps;
  shopTheLook2?: ShopTheLookProps;
  image_1_parallax: boolean;
  image_2_parallax: boolean;
  blue_background: boolean;
}

export interface ContentBlock2Props extends StoryblokComponentType<string> {
  title: string;
  subtitle: string;
  description: ISbRichtext;
  bg_image: ImageStoryblok;
  image_parallax: boolean;
  shopTheLook?: ShopTheLookProps;
  floating_image: ImageStoryblok;
  button_text: string;
  button_link: LinkStoryblok;
  image_emphasis: 'left' | 'right';
  video_id?: string;
  autoplay?: boolean;
  desktopImageSize?: { value: number };
  blue_background: boolean;
}

export interface ContentBlock3Props extends StoryblokComponentType<string> {
  headline: string;
  text: ISbRichtext;
  subtitle: string;
  button_text: string;
  button_link: LinkStoryblok;
  text_emphasis: 'left' | 'right' | 'center';
  blue_background: boolean;
}

export interface ContentBlock4Props extends StoryblokComponentType<string> {
  title: string;
  subtitle: string;
  description: ISbRichtext;
  image: ImageStoryblok;
  image_parallax: boolean;
  content_branch: ContentBranch;
  video_id?: string;
  autoplay?: boolean;
  shopTheLook?: ShopTheLookProps;
  is_text_wide?: boolean;
  blue_background: boolean;
}

export interface ContentBlock5Props extends StoryblokComponentType<string> {
  image_emphasis: 'left' | 'right';
  subtitle: string;
  title: string;
  description: ISbRichtext;
  image_left?: ImageStoryblok;
  image_left_parallax: boolean;
  image_right: ImageStoryblok;
  image_right_parallax: boolean;
  button_text: string;
  button_link: LinkStoryblok;
  video_id?: string;
  autoplay?: boolean;
  shopTheLook1?: ShopTheLookProps;
  shopTheLook2?: ShopTheLookProps;
  blue_background: boolean;
}

export interface ContentBlock6Props extends StoryblokComponentType<string> {
  title: string;
  titleRich: RichTextContent;
  description: string;
  descriptionRich: ISbRichtext;
  use_richtext: boolean;
  bg_image: ImageStoryblok;
  image_parallax: boolean;
  floating_image: ImageStoryblok;
  button_text: string;
  button_link: LinkStoryblok;
  image_emphasis: 'left' | 'right';
  content_branch: ContentBranch;
  video_id?: string;
  autoplay?: boolean;
  shopTheLook?: ShopTheLookProps;
  add_padding?: boolean;
  mobileProductImageSize?: { value: number };
  blue_background: boolean;
}

export interface ContentBlock7Props extends StoryblokComponentType<string> {
  headline: string;
  text: ISbRichtext;
  image: ImageStoryblok;
  image_parallax: boolean;
  product_1: string;
  product_2: string;
  id: string;
  image_emphasis: 'left' | 'right';
  video_id?: string;
  autoplay?: boolean;
  shopTheLook?: ShopTheLookProps;
  fill_image?: boolean;
  blue_background: boolean;
  split_headline?: boolean;
}

export interface ContentBlock8Props extends StoryblokComponentType<string> {
  title: string;
  image: ImageStoryblok;
  image_parallax: boolean;
  logo: ContentBranchCore;
  content_branch: ContentBranch;
  image_emphasis: 'left' | 'right';
  shopTheLook?: ShopTheLookProps;
  split_headline?: boolean;
}

export interface ContentBlock9Props extends StoryblokComponentType<string> {
  image: ImageStoryblok;
  mobile_image: ImageStoryblok;
  button_text: string;
  no_mobile_padding: boolean;
  show: boolean;
  button_link: LinkStoryblok;
}

export interface ContentBlock10Props extends StoryblokComponentType<string> {
  headline: string;
  title_1: string;
  title_2: string;
  title_3: string;
  link_1: LinkStoryblok;
  link_2: LinkStoryblok;
  link_3: LinkStoryblok;
  image_1: ImageStoryblok;
  image_2: ImageStoryblok;
  image_3: ImageStoryblok;
}

export interface ContentBlock11Props extends StoryblokComponentType<string> {
  title: string;
  description: ISbRichtext;
  image_1: ImageStoryblok;
  image_2: ImageStoryblok;
  image_left: boolean;
  content_branch: ContentBranch;
  video_id?: string;
  autoplay?: boolean;
  shopTheLook?: ShopTheLookProps;
  blue_background: boolean;
}

export interface ContentBlock12Props extends StoryblokComponentType<string> {
  title: string;
  description: ISbRichtext;
  image_1: ImageStoryblok;
  image_2: ImageStoryblok;
  size: 'big' | 'small';
  text_emphasis: 'top' | 'bottom';
  video_id?: string;
  autoplay?: boolean;
  shopTheLook?: ShopTheLookProps;
  button_text?: string;
  button_link?: LinkStoryblok;
  blue_background: boolean;
}

export interface ContentBlock13Props extends StoryblokComponentType<string> {
  title: string;
  textContent: ISbRichtext;
  imageLeft?: boolean;
  image?: ImageStoryblok;
  image_parallax: boolean;
  content_branch: ContentBranch;
  video_id?: string;
  autoplay?: boolean;
  shopTheLook?: ShopTheLookProps;
  split_headline?: boolean;
}

export interface ContentBlock14Props extends StoryblokComponentType<string> {
  headline: ISbRichtext;
  title_1: string;
  text_content_1: ISbRichtext;
  image_1: ImageStoryblok;
  link_1: LinkStoryblok;
  content_branch_1: ContentBranch;
  title_2: string;
  text_content_2: ISbRichtext;
  image_2: ImageStoryblok;
  link_2: LinkStoryblok;
  content_branch_2: ContentBranch;
  title_3: string;
  text_content_3: ISbRichtext;
  image_3: ImageStoryblok;
  link_3: LinkStoryblok;
  content_branch_3: ContentBranch;
}

export interface BoxProps extends StoryblokComponentType<string> {
  items: DynamicComponentBlok[];
}

export interface MaterialAndCareBlockProps extends StoryblokComponentType<string> {
  title: string;
  description: string;
}

export interface SectionProps extends StoryblokComponentType<string> {
  items: DynamicComponentBlok[];
}

export interface SliderProps extends StoryblokComponentType<string> {
  title?: string;
  collection_slug?: string;
  products?: { productOrVariant: string }[];
  size: 'small' | 'big';
}

export interface CollectionProps extends StoryblokComponentType<string> {
  hero: HeroBlock2Props[];
}

export interface FaqItemProps extends StoryblokComponentType<string> {
  content_link: LinkStoryblok;
}

export interface YoutubeBlockProps extends StoryblokComponentType<string> {
  video_id: string;
  autoplay: boolean;
}

export interface MagazinTitleProps extends StoryblokComponentType<string> {
  title: string;
  author: string;
  date: Date;
  preview_text: string;
  preview_image: ImageStoryblok;
  category: ContentBranchCore;
}

export interface HeroSliderBlockProps extends StoryblokComponentType<string> {
  slides: HeroSlide[];
}

export interface HeroSlide {
  title: string;
  subtitle: string;
  call_to_action: string;
  bg_image: ImageStoryblok;
  foreground_image: ImageStoryblok;
  href: LinkStoryblok;
  content_branch: ContentBranch;
  signature: ContentBranchCore;
  split_headline?: boolean;
}

type MetaComponentType = 'title' | 'description' | 'meta_keywords' | 'should_not_index';

export type MetaBlockProps = {
  _uid: string;
  component: MetaComponentType;
} & (
  | {
      title: any;
      component: 'title';
    }
  | {
      description: any;
      component: 'description';
    }
  | {
      meta_keywords: any;
      component: 'meta_keywords';
    }
  | {
      should_not_index: any;
      component: 'should_not_index';
    }
);

export interface NewsletterBlockProps extends StoryblokComponentType<string> {
  title: string;
  description: ISbRichtext;
  button_cta: string;
  image?: ImageStoryblok;
  image_emphasis: 'left' | 'right';
}
