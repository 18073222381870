import { create } from 'zustand';

export enum Branch {
  DogSports = 'dog-sport',
  EquineSports = 'equine-sport',
  BoatSports = 'boat-sport',
}

type CartLoadingOverlay = {
  branch: Branch;
  shown: boolean;
  opacity: boolean;
  adhsMode: boolean;
  updateBranch: (branch: Branch) => void;
  setShown: (shown: boolean) => void;
  enableADHS: () => void;
};

export const useCartLoadingOverlayZustand = create<CartLoadingOverlay>((set) => ({
  adhsMode: false,
  branch: Branch.DogSports,
  enableADHS: () => set({ adhsMode: true }),
  opacity: false,
  setShown: (shown) => {
    if (shown) {
      set({ adhsMode: false, shown });
      setTimeout(() => {
        set({ opacity: shown });
      }, 200);
    } else {
      set({ opacity: shown });
      setTimeout(() => {
        set({ shown });
      }, 200);
    }
  },
  shown: false,
  updateBranch: (branch) => set({ branch }),
}));
