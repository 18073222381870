import classNames from 'classnames';
import { ImageProps } from 'next/image';
import { Image } from '@components/generated/icons';

interface ImageFallbackProps extends Pick<ImageProps, 'width' | 'height'> {
  className?: string;
}

export const ImageFallback = ({ className, width, height }: ImageFallbackProps) => {
  if (width && height) {
    const ratio = Number(height) / Number(width);

    return (
      <div
        className={classNames('w-full h-full bg-dark-background relative aspect-square', className)}
      >
        <div className="w-full" style={{ paddingTop: `${ratio * 100}%` }}></div>

        <div className="absolute inset-0 flex items-center justify-center h-full aspect-square">
          <InnerIcon />
        </div>
      </div>
    );
  }

  return (
    <div
      className={classNames(
        'w-full h-full flex items-center justify-center absolute inset-0 bg-dark-background',
        className,
      )}
    >
      <InnerIcon />
    </div>
  );
};

interface InnerIconProps {
  className?: string;
}

const InnerIcon = ({ className }: InnerIconProps) => (
  // eslint-disable-next-line jsx-a11y/alt-text
  <Image className={classNames('w-6 h-6 text-dark-quaternary m-4', className)} />
);
