import { Notification } from '@components/Notification';
import { AlertCircle, Check } from '@components/generated/icons';
import { createContext, ReactNode, useCallback, useContext, useRef, useState } from 'react';

interface NotificationsWrapperProps {
  children?: ReactNode;
}

export interface Action {
  text: string;
  href?: string;
  onClick?: () => void;
}

export function useNotifications() {
  return useContext(NotificationsContext);
}

export enum NOTIFICATION {
  ALERT,
  ERROR,
  SUCCESS,
}

export enum NOTIFICATION_DURATION {
  SHORT = 2000,
  MEDIUM = 3500,
  LONG = 5000,
}

interface AddNotification {
  title: string;
  text: string;
  variant?: NOTIFICATION;
  actions?: Action[];
  hideAfter?: number;
  onClose?: () => void;
}

interface INotification {
  id: number;
  text: string;
  title: string;
  variant: NOTIFICATION;
  actions?: Action[];
  onClose?: () => void;
}

const defaultApi: {
  /** Clears the notification with the given id */
  clearNotification?: (id: number) => void;

  /** List of all currently visible notifications */
  notifications: Array<INotification>;

  /** Adds a notification as the last one to the notification stack */
  setNotification: (notification: AddNotification) => void;
} = {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars,  @typescript-eslint/no-empty-function
  clearNotification: (_id: number) => {},
  notifications: [],
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function
  setNotification: (_notification: AddNotification) => {},
};

export type NotificationsContextValue = typeof defaultApi;

/**
 * Create Context
 */
export const NotificationsContext = createContext<NotificationsContextValue>(defaultApi);

/** Wrapper for the notification handling. */
const NotificationsWrapper = ({ children }: NotificationsWrapperProps) => {
  // Notifications queue is managed in local useState
  const [notifications, setNotifications] = useState<INotification[]>(defaultApi.notifications);
  const notificationsRef = useRef(notifications);
  notificationsRef.current = notifications;

  const clearNotificationAfterTime = useCallback(
    (hideAfter: number, id: number) => {
      setTimeout(() => {
        setNotifications(notificationsRef.current.filter((n) => n.id !== id));
      }, hideAfter);
    },
    [notifications, setNotifications],
  );

  // Method to push a new notification
  const setNotification = useCallback(
    (notification: AddNotification) => {
      const not: INotification = {
        id: new Date().getTime(),
        variant: NOTIFICATION.ERROR,
        ...notification,
      };
      const nextNotifications = [...notifications, not];
      setNotifications(nextNotifications);
      if (notification.hideAfter && notification.hideAfter >= 0) {
        clearNotificationAfterTime(notification.hideAfter, not.id);
      }
    },
    [notifications, setNotifications],
  );

  // Method to clear a notification
  const clearNotification = useCallback(
    (id: number) => {
      const nextNotifications = notifications.filter((n) => n.id !== id);
      setNotifications(nextNotifications);
    },
    [notifications, setNotifications],
  );

  // Return Provider with full API
  const api = { clearNotification, notifications, setNotification };

  return (
    <NotificationsContext.Provider value={api}>
      <>
        {children}
        {notifications.length > 0 && (
          <div className="fixed top-0 right-0 z-50 flex flex-col w-full max-w-md">
            {notifications.map((notification) => (
              <Notification
                className="relative p-2"
                key={notification.id}
                mainIcon={
                  notification.variant === NOTIFICATION.ALERT ? (
                    <AlertCircle className="text-special-warn" />
                  ) : notification.variant === NOTIFICATION.ERROR ? (
                    <AlertCircle className="text-special-danger" />
                  ) : (
                    <Check className="text-special-success" />
                  )
                }
                title={notification.title}
                text={notification.text}
                actions={notification.actions}
                onClose={() => {
                  clearNotification(notification.id);
                  notification.onClose?.();
                }}
              />
            ))}
          </div>
        )}
      </>
    </NotificationsContext.Provider>
  );
};

export default NotificationsWrapper;
