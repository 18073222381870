import { gql } from '@apollo/client';

export const PRODUCT_DRAWER_STORIES = gql`
  fragment ProductDrawerStories on HasMetafields {
    measure_d2c: metafield(key: "measure_d2c", namespace: "sprenger") {
      key
      value
    }
  }
`;

const PRODUCT_VARIANT_DATA = gql`
  fragment ProductVariantData on ProductVariant {
    id
    selectedOptions {
      name
      value
    }
    sku
    priceV2 {
      amount
      currencyCode
    }
    compareAtPriceV2 {
      amount
      currencyCode
    }
    availableForSale
    quantityAvailable
    currentlyNotInStock
    storeAvailability(first: 10) {
      edges {
        node {
          available
        }
      }
    }
    title
    product {
      title
      handle
      collections(first: 10) {
        edges {
          node {
            title
            handle
            description
          }
        }
      }
    }
    isArchived: metafield(key: "is_archived", namespace: "sprenger") {
      key
      value
    }
    justSku: metafield(key: "just_sku", namespace: "sprenger") {
      key
      value
    }
    bundle_desc: metafield(key: "bundle_desc", namespace: "sprenger") {
      key
      value
    }
    care_d2c: metafield(key: "care_d2c", namespace: "sprenger") {
      key
      value
    }
    content_d2c: metafield(key: "content_d2c", namespace: "sprenger") {
      key
      value
    }
    measure_d2c: metafield(key: "measure_d2c", namespace: "sprenger") {
      key
      value
    }
    material_d2c: metafield(key: "material_d2c", namespace: "sprenger") {
      key
      value
    }
    faq_d2c: metafield(key: "faq_d2c", namespace: "sprenger") {
      key
      value
    }
    colorcode: metafield(key: "colorcode", namespace: "sprenger") {
      key
      value
    }
    technical_data: metafield(key: "technical_data", namespace: "sprenger") {
      key
      value
    }
    image_urls: metafield(key: "image_urls", namespace: "sprenger") {
      key
      value
    }
    bulletPoints: metafield(key: "bullets", namespace: "sprenger") {
      key
      value
    }
    dreid: metafield(key: "dreid", namespace: "sprenger") {
      key
      value
    }
    video: metafield(key: "video", namespace: "sprenger") {
      key
      value
    }
    hazard_data: metafield(key: "hazard_data", namespace: "sprenger") {
      key
      value
    }
    crossSellReferences: metafield(key: "crosssell_references", namespace: "sprenger") {
      key
      value
    }
    highlights: metafield(key: "highlights", namespace: "sprenger") {
      key
      value
    }
    sds_qty: metafield(key: "sds_qty", namespace: "sprenger") {
      key
      value
    }
    unitPrice {
      amount
      currencyCode
    }
    unitPriceMeasurement {
      referenceValue
      referenceUnit
    }
  }
`;

const WITH_CROSS_SELL_PRODUCTS = gql`
  fragment WithCrossSellProducts on HasMetafields {
    crossSellReferences: metafield(key: "crosssell_references", namespace: "sprenger") {
      key
      value
    }
  }
`;

const VARIANT_TECHNICAL_DETAILS = gql`
  query VariantTechnicalDetails($variantId: ID!) {
    variant: node(id: $variantId) {
      ... on ProductVariant {
        id
        technical_data: metafield(key: "technical_data", namespace: "sprenger") {
          key
          value
        }
      }
    }
  }
`;

const VARIANT_HAZARD_DATA = gql`
  query VariantHazardData($variantId: ID!) {
    variant: node(id: $variantId) {
      ... on ProductVariant {
        id
        hazard_data: metafield(key: "hazard_data", namespace: "sprenger") {
          key
          value
        }
      }
    }
  }
`;

export const STORYBLOK_MATERIAL_AND_CARE_URLS = gql`
  query StoryblokMaterialAndCareMetafieldUrls($productId: ID!, $variantId: ID!) {
    product(id: $productId) {
      id
      care_d2c: metafield(key: "care_d2c", namespace: "sprenger") {
        key
        value
      }
      material_d2c: metafield(key: "material_d2c", namespace: "sprenger") {
        key
        value
      }
    }
    variant: node(id: $variantId) {
      ... on ProductVariant {
        id
        care_d2c: metafield(key: "care_d2c", namespace: "sprenger") {
          key
          value
        }
        material_d2c: metafield(key: "material_d2c", namespace: "sprenger") {
          key
          value
        }
      }
    }
  }
`;

export const STORYBLOK_FAQ_URLS = gql`
  query StoryblokFaqMetafieldUrls($productId: ID!, $variantId: ID!) {
    product(id: $productId) {
      id
      faq_d2c: metafield(key: "faq_d2c", namespace: "sprenger") {
        key
        value
      }
    }
    variant: node(id: $variantId) {
      ... on ProductVariant {
        id
        faq_d2c: metafield(key: "faq_d2c", namespace: "sprenger") {
          key
          value
        }
      }
    }
  }
`;

export const STORYBLOK_CONTENT_URLS = gql`
  query StoryblokContentMetafieldUrls($productId: ID!, $variantId: ID!) {
    product(id: $productId) {
      id
      content_d2c: metafield(key: "content_d2c", namespace: "sprenger") {
        key
        value
      }
    }
    variant: node(id: $variantId) {
      ... on ProductVariant {
        id
        content_d2c: metafield(key: "content_d2c", namespace: "sprenger") {
          key
          value
        }
      }
    }
  }
`;

export const CROSS_SELL_PRODUCTS_FOR_ALL = gql`
  query CrossSellProductForAll($id: ID, $handle: String, $variant: ID!) {
    product(id: $id, handle: $handle) {
      crossSellReferences: metafield(key: "crosssell_references", namespace: "sprenger") {
        key
        value
      }
    }
    variant: node(id: $variant) {
      ... on ProductVariant {
        crossSellReferences: metafield(key: "crosssell_references", namespace: "sprenger") {
          key
          value
        }
      }
    }
  }
`;

export const IMAGE_SKU_MINIMAl_DATA = gql`
  query ImageSKUMinimalData($handle: String, $country: CountryCode!, $language: LanguageCode!)
  @inContext(country: $country, language: $language) {
    product(handle: $handle) {
      id
      title
      description
      collections(first: 100) {
        edges {
          node {
            title
            handle
            description
          }
        }
      }
      variants(first: 100) {
        edges {
          node {
            id
            title
            sku: metafield(key: "just_sku", namespace: "sprenger") {
              key
              value
            }
            image_urls: metafield(key: "image_urls", namespace: "sprenger") {
              key
              value
            }
            isArchived: metafield(key: "is_archived", namespace: "sprenger") {
              key
              value
            }
            quantityAvailable
            priceV2 {
              amount
              currencyCode
            }
            compareAtPriceV2 {
              amount
              currencyCode
            }
            unitPrice {
              amount
              currencyCode
            }
            unitPriceMeasurement {
              referenceValue
              referenceUnit
            }
            bundle_desc: metafield(key: "bundle_desc", namespace: "sprenger") {
              key
              value
            }
          }
        }
      }
      priceRange {
        maxVariantPrice {
          amount
          currencyCode
        }
        minVariantPrice {
          amount
          currencyCode
        }
      }
      compareAtPriceRange {
        maxVariantPrice {
          amount
          currencyCode
        }
        minVariantPrice {
          amount
          currencyCode
        }
      }
    }
  }
`;

export const TECHNICAL_DETAILS_VARIANTS = gql`
  query TechnicalDetailsVariants($productId: ID!) {
    product(id: $productId) {
      variants(first: 100) {
        edges {
          node {
            id
            technical_data: metafield(key: "technical_data", namespace: "sprenger") {
              key
              value
            }
          }
        }
      }
    }
  }
`;

export const PRODUCT = gql`
  query Product(
    $id: ID
    $handle: String
    $sdsHandle: String!
    $country: CountryCode!
    $language: LanguageCode!
  ) @inContext(country: $country, language: $language) {
    product(id: $id, handle: $handle) {
      id
      title
      tags
      totalInventory
      handle
      description
      descriptionHtml
      availableForSale
      priceRange {
        maxVariantPrice {
          amount
          currencyCode
        }
        minVariantPrice {
          amount
          currencyCode
        }
      }
      compareAtPriceRange {
        maxVariantPrice {
          amount
          currencyCode
        }
        minVariantPrice {
          amount
          currencyCode
        }
      }
      options(first: 10) {
        id
        name
        values
      }
      dreid: metafield(key: "dreid", namespace: "sprenger") {
        key
        value
      }
      video: metafield(key: "video", namespace: "sprenger") {
        key
        value
      }
      measure_d2c: metafield(key: "measure_d2c", namespace: "sprenger") {
        key
        value
      }
      upSellSkus: metafield(key: "upsell_skus", namespace: "sprenger") {
        key
        value
      }
      sku: metafield(key: "sku", namespace: "sprenger") {
        key
        value
      }
      is_single: metafield(key: "is_single", namespace: "sprenger") {
        key
        value
      }
      colormap: metafield(key: "colormap", namespace: "sprenger") {
        key
        value
      }
      highlights: metafield(key: "highlights", namespace: "sprenger") {
        key
        value
      }
      image_urls: metafield(key: "image_urls", namespace: "sprenger") {
        key
        value
      }
      bulletPoints: metafield(key: "bullets", namespace: "sprenger") {
        key
        value
      }
      measurement_table: metafield(key: "measurement_table", namespace: "sprenger") {
        key
        value
      }
      placeholderImages: images(first: 10) {
        edges {
          node {
            src
          }
        }
      }
      images(first: 10) {
        edges {
          node {
            src
            altText
            id
          }
        }
      }
      collections(first: 100) {
        edges {
          node {
            title
            handle
            description
          }
        }
      }
      variants(first: 100) {
        edges {
          node {
            id
            sku
            selectedOptions {
              name
              value
            }
            priceV2 {
              amount
              currencyCode
            }
            justSku: metafield(key: "just_sku", namespace: "sprenger") {
              key
              value
            }
            availableForSale
          }
        }
      }
    }
    sdsProduct: product(handle: $sdsHandle) {
      variants(first: 100) {
        edges {
          node {
            id
            justSku: metafield(key: "just_sku", namespace: "sprenger") {
              key
              value
            }
            selectedOptions {
              name
              value
            }
            quantityAvailable
            availableForSale
          }
        }
      }
    }
  }
`;

export const PRODUCT_COLLECTIONS = gql`
  query ProductCollections($id: ID, $handle: String, $sdsHandle: String!) {
    product(id: $id, handle: $handle) {
      id
      sku: metafield(key: "sku", namespace: "sprenger") {
        key
        value
      }
      collections(first: 100) {
        edges {
          node {
            title
            handle
            description
          }
        }
      }
    }
  }
`;

export const PRODUCT_CARD = gql`
  query ProductCard($id: ID, $handle: String, $country: CountryCode, $language: LanguageCode)
  @inContext(country: $country, language: $language) {
    product(id: $id, handle: $handle) {
      id
      title
      tags
      totalInventory
      handle
      description
      descriptionHtml
      availableForSale
      priceRange {
        maxVariantPrice {
          amount
          currencyCode
        }
        minVariantPrice {
          amount
          currencyCode
        }
      }
      compareAtPriceRange {
        maxVariantPrice {
          amount
          currencyCode
        }
        minVariantPrice {
          amount
          currencyCode
        }
      }
      options(first: 10) {
        id
        name
        values
      }
      dreid: metafield(key: "dreid", namespace: "sprenger") {
        key
        value
      }
      video: metafield(key: "video", namespace: "sprenger") {
        key
        value
      }
      measure_d2c: metafield(key: "measure_d2c", namespace: "sprenger") {
        key
        value
      }
      upSellSkus: metafield(key: "upsell_skus", namespace: "sprenger") {
        key
        value
      }
      sku: metafield(key: "sku", namespace: "sprenger") {
        key
        value
      }
      is_single: metafield(key: "is_single", namespace: "sprenger") {
        key
        value
      }
      colormap: metafield(key: "colormap", namespace: "sprenger") {
        key
        value
      }
      highlights: metafield(key: "highlights", namespace: "sprenger") {
        key
        value
      }
      image_urls: metafield(key: "image_urls", namespace: "sprenger") {
        key
        value
      }
      bulletPoints: metafield(key: "bullets", namespace: "sprenger") {
        key
        value
      }
      measurement_table: metafield(key: "measurement_table", namespace: "sprenger") {
        key
        value
      }
      placeholderImages: images(first: 10) {
        edges {
          node {
            src
          }
        }
      }
      images(first: 10) {
        edges {
          node {
            src
            altText
            id
          }
        }
      }
      collections(first: 100) {
        edges {
          node {
            title
            handle
            description
          }
        }
      }
      variants(first: 100) {
        edges {
          node {
            id
            sku
            selectedOptions {
              name
              value
            }
            priceV2 {
              amount
              currencyCode
            }
            compareAtPriceV2 {
              amount
              currencyCode
            }
            justSku: metafield(key: "just_sku", namespace: "sprenger") {
              key
              value
            }
          }
        }
      }
    }
  }
`;

export const PRODUCT_VARIANT = gql`
  ${PRODUCT_VARIANT_DATA}
  query ProductVariant($variantId: ID!, $country: CountryCode, $language: LanguageCode)
  @inContext(country: $country, language: $language) {
    variant: node(id: $variantId) {
      ... on ProductVariant {
        ...ProductVariantData
      }
    }
  }
`;

export const PRODUCT_SAFETY_INSTRUCTIONS = gql`
  query ProductSafetyInstructionData($handle: String!, $selected: [SelectedOptionInput!]!) {
    product(handle: $handle) {
      variantBySelectedOptions(selectedOptions: $selected) {
        hazard_data: metafield(key: "hazard_data", namespace: "sprenger") {
          key
          value
        }
        justSku: metafield(key: "just_sku", namespace: "sprenger") {
          key
          value
        }
      }
    }
  }
`;

export const CROSS_SELL_PRODUCT = gql`
  query CrossSellProduct($id: ID, $handle: String, $country: CountryCode, $language: LanguageCode)
  @inContext(country: $country, language: $language) {
    product(id: $id, handle: $handle) {
      id
      handle
      title
      totalInventory
      sku: metafield(key: "sku", namespace: "sprenger") {
        key
        value
      }
      justSku: metafield(key: "just_sku", namespace: "sprenger") {
        key
        value
      }
      image_urls: metafield(key: "image_urls", namespace: "sprenger") {
        key
        value
      }
      highlights: metafield(key: "highlights", namespace: "sprenger") {
        key
        value
      }
      compareAtPriceRange {
        maxVariantPrice {
          amount
          currencyCode
        }
        minVariantPrice {
          amount
          currencyCode
        }
      }
      variants(first: 10) {
        edges {
          node {
            id
            quantityAvailable
            isArchived: metafield(key: "is_archived", namespace: "sprenger") {
              key
              value
            }
          }
        }
      }
      collections(first: 100) {
        edges {
          node {
            title
            description
          }
        }
      }
      priceRange {
        maxVariantPrice {
          amount
          currencyCode
        }
        minVariantPrice {
          amount
          currencyCode
        }
      }
    }
  }
`;

export const VARIANTS_BY_PARENT = gql`
  query VariantsByParent(
    $handle: String!
    $handleSDS: String!
    $country: CountryCode
    $language: LanguageCode
  ) @inContext(country: $country, language: $language) {
    product(handle: $handle) {
      id
      description
      colormap: metafield(key: "colormap", namespace: "sprenger") {
        key
        value
      }
      highlights: metafield(key: "highlights", namespace: "sprenger") {
        key
        value
      }
      dreid: metafield(key: "dreid", namespace: "sprenger") {
        key
        value
      }
      image_urls: metafield(key: "image_urls", namespace: "sprenger") {
        key
        value
      }
      is_single: metafield(key: "is_single", namespace: "sprenger") {
        key
        value
      }
      collections(first: 100) {
        edges {
          node {
            title
            handle
            description
          }
        }
      }
      title
      priceRange {
        maxVariantPrice {
          amount
          currencyCode
        }
        minVariantPrice {
          amount
          currencyCode
        }
      }
      compareAtPriceRange {
        maxVariantPrice {
          amount
          currencyCode
        }
        minVariantPrice {
          amount
          currencyCode
        }
      }
      options(first: 100) {
        name
        values
      }
      totalInventory
      variants(first: 100) {
        edges {
          node {
            product {
              handle
              title
              collections(first: 100) {
                edges {
                  node {
                    title
                    handle
                  }
                }
              }
            }
            id
            title
            justSku: metafield(key: "just_sku", namespace: "sprenger") {
              key
              value
            }
            sku: metafield(key: "just_sku", namespace: "sprenger") {
              key
              value
            }
            image_urls: metafield(key: "image_urls", namespace: "sprenger") {
              key
              value
            }
            isArchived: metafield(key: "is_archived", namespace: "sprenger") {
              key
              value
            }
            sds_qty: metafield(key: "sds_qty", namespace: "sprenger") {
              key
              value
            }
            selectedOptions {
              name
              value
            }
            quantityAvailable
            priceV2 {
              amount
              currencyCode
            }
            compareAtPriceV2 {
              amount
              currencyCode
            }
          }
        }
      }
    }
    sdsProduct: product(handle: $handleSDS) {
      totalInventory
      variants(first: 100) {
        edges {
          node {
            id
            sku: metafield(key: "just_sku", namespace: "sprenger") {
              key
              value
            }
            isArchived: metafield(key: "is_archived", namespace: "sprenger") {
              key
              value
            }
            quantityAvailable
          }
        }
      }
    }
  }
`;

export const CROSS_SELL_PRODUCT_VARIANT = gql`
  query CrossSellProductVariant(
    $id: ID
    $handle: String
    $selectedOptions: [SelectedOptionInput!]!
    $country: CountryCode
    $language: LanguageCode
  ) @inContext(country: $country, language: $language) {
    product(id: $id, handle: $handle) {
      id
      handle
      totalInventory
      highlights: metafield(key: "highlights", namespace: "sprenger") {
        key
        value
      }
      variantBySelectedOptions(selectedOptions: $selectedOptions) {
        quantityAvailable
        id
        sku
        justSku: metafield(key: "just_sku", namespace: "sprenger") {
          key
          value
        }
        title
        compareAtPriceV2 {
          amount
          currencyCode
        }
        price: priceV2 {
          amount
          currencyCode
        }
        image_urls: metafield(key: "image_urls", namespace: "sprenger") {
          key
          value
        }
        highlights: metafield(key: "highlights", namespace: "sprenger") {
          key
          value
        }
        isArchived: metafield(key: "is_archived", namespace: "sprenger") {
          key
          value
        }
        product {
          id
          handle
          totalInventory
          title
          collections(first: 100) {
            edges {
              node {
                title
                description
              }
            }
          }
        }
        selectedOptions {
          name
          value
        }
      }
    }
  }
`;

export const DATA_LOADED_AVAILABILITY = gql`
  query DataLoadedAvailability($first: Int, $query: String) {
    products(first: $first, query: $query) {
      edges {
        node {
          handle
          variants(first: 20) {
            edges {
              node {
                quantityAvailable
              }
            }
          }
        }
      }
    }
  }
`;

export const PRODUCT_VARIANT_SKU_BY_ID = gql`
  query getProductVariantSKUById($id: ID!) {
    variant: node(id: $id) {
      ... on ProductVariant {
        selectedOptions {
          name
          value
        }
        product {
          sku: metafield(namespace: "sprenger", key: "sku") {
            id
            value
          }
          justSku: metafield(key: "just_sku", namespace: "sprenger") {
            key
            value
          }
          collections(first: 100) {
            edges {
              node {
                title
                description
              }
            }
          }
        }
      }
    }
  }
`;
