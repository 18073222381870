import { CollectionTree } from '@zustand/shopData/collectionTree';

export const faqNavMenuList: CollectionTree[] = [
  {
    items: [
      {
        link: '/faq',
        title: 'nav.faq.general',
      },
      {
        link: '/faq/equine',
        title: 'nav.faq.horse',
      },
      {
        link: '/faq/dog',
        title: 'nav.faq.dog',
      },
      {
        link: '/faq/boat',
        title: 'nav.faq.boat',
      },
    ],
    link: '/faq',
    title: 'nav.faq.title',
  },
];

export const menuHelpLinkList = [
  {
    link: '/about',
    title: 'links.aboutUs',
  },
  {
    link: '/magazin',
    title: 'links.magazine',
  },
  {
    link: '/zahlung-versand',
    title: 'links.paymentAndDelivery',
  },
];

export const menuCustomerLinkList = [
  {
    link: '/customer/orders',
    title: 'links.myOrders',
  },
  {
    link: '/customer/profile',
    title: 'links.myProfile',
  },
  {
    link: 'https://sprenger.de',
    title: 'links.b2b',
  },
];
