type HeroSliderSecondaryLineProps = {
  color: string;
  children: React.ReactNode;
};

const HeroSliderSecondaryLine = ({ color, children }: HeroSliderSecondaryLineProps) => (
  <div
    className="w-full transition duration-300 h-48 grid grid-cols-2"
    style={{
      backgroundColor: color,
    }}
  >
    {children}
  </div>
);

export default HeroSliderSecondaryLine;
