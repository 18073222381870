import { SVGProps } from 'react';

const SvgChevronDown = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M10.749 14.787 5 9.213 6.251 8 12 13.573 17.749 8 19 9.213l-5.749 5.574L12 16l-1.251-1.213Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgChevronDown;
