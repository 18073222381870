import SearchAutoSuggestionItem from '@components/SearchBar/SearchAutoSuggestionItem';

type SearchResultListProps = {
  suggestions: string[];
  onResultSelect: (result: string) => void;
};

const SearchAutoSuggestionItemList = ({ suggestions, onResultSelect }: SearchResultListProps) => {
  if (suggestions.length === 0) {
    return <></>;
  }
  return (
    <>
      {suggestions.map((e, i) => (
        <SearchAutoSuggestionItem key={i} title={e} onClick={() => onResultSelect(e)} />
      ))}
    </>
  );
};

export default SearchAutoSuggestionItemList;
