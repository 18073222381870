import classNames from 'classnames';

type ChipProps = {
  disabled?: boolean;
  selected?: boolean;
  color?: string;
  secondaryColor?: string;
  label: string;
  onClick?: () => void;
  className?: string;
};

const Chip = ({
  disabled,
  selected,
  color,
  secondaryColor,
  label,
  onClick,
  className,
}: ChipProps) => (
  <div
    onClick={onClick}
    onKeyDown={onClick}
    role="button"
    tabIndex={-1}
    data-test="color-filter-chip"
    className={classNames(
      'p-2 border rounded-md text-p-12 select-none text-center flex items-center',
      selected
        ? 'border-accent-primary'
        : 'border border-dark-quaternary hover:border-dark-terteriary',
      disabled ? 'opacity-50 bg-dark-background cursor-default ' : 'cursor-pointer',
      color ? 'capitalize' : 'uppercase',
      className,
    )}
  >
    {color && secondaryColor ? (
      <div className="flex">
        <div
          className={classNames('rounded-l-full h-5 w-2.5')}
          style={{ backgroundColor: color }}
          data-test="primary-color"
        />
        <div
          className={classNames('rounded-r-full h-5 w-2.5 mr-1')}
          style={{ backgroundColor: secondaryColor }}
          data-test="secondary-color"
        />
      </div>
    ) : color ? (
      <div
        data-test="primary-color"
        className={classNames('rounded-full h-5 w-5 mr-1')}
        style={{ backgroundColor: color }}
      />
    ) : null}
    <div>{label}</div>
  </div>
);

export default Chip;
