import { create } from 'zustand';

type NavMenuCollectionState = {
  openNavMenuCollection: boolean;
  setOpenNavMenuCollection: (openNavMenuCollection: boolean) => void;
};

export const useNavMenuCollectionZustand = create<NavMenuCollectionState>((set) => ({
  openNavMenuCollection: false,
  setOpenNavMenuCollection: (openNavMenuCollection: boolean) =>
    set((state) => ({ ...state, openNavMenuCollection })),
}));
