import { create } from 'zustand';

type UseSoldProductStore = {
  skus: Set<string>;
  insertId(id: string): void;
  compare(id1: string, id2: string): number;
};

export const useSoldProductZustand = create<UseSoldProductStore>((set, get) => ({
  compare: (first, second) => {
    const ids = Array.from(get().skus);
    if (ids.includes(first) && !ids.includes(second)) {
      return 1;
    }
    if (!ids.includes(first) && ids.includes(second)) {
      return -1;
    }
    return 0;
  },
  insertId: (id) => set((before) => ({ ...before, skus: before.skus.add(id) })),
  skus: new Set<string>(),
}));
