import { gql } from '@apollo/client';

export const REQUIRED_CART_DATA_FRAGMENT = gql`
  fragment RequiredCardData on Cart {
    id
    buyerIdentity {
      customer {
        id
      }
    }
    lines(first: 10) {
      edges {
        node {
          id
          discountAllocations {
            discountedAmount {
              amount
              currencyCode
            }
          }
          merchandise {
            ... on ProductVariant {
              id
              quantityAvailable
              product {
                id
                title
                handle
                sku: metafield(key: "sku", namespace: "sprenger") {
                  key
                  value
                }
                justSku: metafield(key: "just_sku", namespace: "sprenger") {
                  key
                  value
                }
                crossSellReferences: metafield(key: "crosssell_references", namespace: "sprenger") {
                  key
                  value
                }
                highlights: metafield(key: "highlights", namespace: "sprenger") {
                  key
                  value
                }
                collections(first: 100) {
                  edges {
                    node {
                      title
                      handle
                      description
                    }
                  }
                }
              }
              priceV2 {
                amount
                currencyCode
              }
              compareAtPriceV2 {
                amount
                currencyCode
              }
              justSku: metafield(key: "just_sku", namespace: "sprenger") {
                key
                value
              }
              crossSellReferences: metafield(key: "crosssell_references", namespace: "sprenger") {
                key
                value
              }
              image_urls: metafield(key: "image_urls", namespace: "sprenger") {
                key
                value
              }
              sds_qty: metafield(key: "sds_qty", namespace: "sprenger") {
                key
                value
              }
              selectedOptions {
                name
                value
              }
              sku
              bundle_desc: metafield(key: "bundle_desc", namespace: "sprenger") {
                key
                value
              }
              unitPrice {
                amount
                currencyCode
              }
              unitPriceMeasurement {
                referenceUnit
                referenceValue
              }
            }
          }
          quantity
          cost {
            subtotalAmount {
              amount
              currencyCode
            }
            totalAmount {
              amount
              currencyCode
            }
          }
        }
        cursor
      }
    }
    discountCodes {
      applicable
      code
    }
    cost {
      subtotalAmount {
        amount
        currencyCode
      }
      totalAmount {
        amount
        currencyCode
      }
      totalDutyAmount {
        amount
        currencyCode
      }
      totalTaxAmount {
        amount
        currencyCode
      }
    }
  }
`;
export const CART = gql`
  ${REQUIRED_CART_DATA_FRAGMENT}
  query Cart($id: ID!, $country: CountryCode, $language: LanguageCode)
  @inContext(country: $country, language: $language) {
    cart(id: $id) {
      ...RequiredCardData
    }
  }
`;

export const RETRIEVE_SDS_INFO = gql`
  query RetrieveSDSInfo($handle: String!) {
    product(handle: $handle) {
      variants(first: 50) {
        edges {
          node {
            id
            quantityAvailable
            justSku: metafield(key: "just_sku", namespace: "sprenger") {
              key
              value
            }
            sds_qty: metafield(key: "sds_qty", namespace: "sprenger") {
              key
              value
            }
          }
        }
      }
    }
  }
`;

export const RETRIEVE_BUY_X_GET_Y_PRODUCT = gql`
  query RetrieveBuyXGetYProduct(
    $id: ID!
    $handle: String!
    $country: CountryCode!
    $language: LanguageCode!
  ) @inContext(country: $country, language: $language) {
    variant: node(id: $id) {
      ... on ProductVariant {
        id
        title
        image_urls: metafield(key: "image_urls", namespace: "sprenger") {
          key
          value
        }
        highlights: metafield(key: "highlights", namespace: "sprenger") {
          key
          value
        }
        isArchived: metafield(key: "is_archived", namespace: "sprenger") {
          key
          value
        }
        justSku: metafield(key: "just_sku", namespace: "sprenger") {
          key
          value
        }
        selectedOptions {
          value
          name
        }
        price {
          currencyCode
          amount
        }
        compareAtPrice {
          currencyCode
          amount
        }
        bundle_desc: metafield(key: "bundle_desc", namespace: "sprenger") {
          key
          value
        }
        unitPrice {
          amount
          currencyCode
        }
        unitPriceMeasurement {
          referenceUnit
          referenceValue
        }
        quantityAvailable
        product {
          collections(first: 100) {
            edges {
              node {
                title
                description
              }
            }
          }
          handle
          title
        }
      }
    }
    sdsProduct: product(handle: $handle) {
      variants(first: 100) {
        edges {
          node {
            id
            justSku: metafield(key: "just_sku", namespace: "sprenger") {
              key
              value
            }
            sds_qty: metafield(key: "sds_qty", namespace: "sprenger") {
              key
              value
            }
            quantityAvailable
          }
        }
      }
    }
  }
`;
