import { ContentBlock13Props } from '@customTypes/storyblok-types';
import { storyblokEditable } from '@storyblok/react';
import classNames from '@utils/ClassNames';
import { withBlur } from '@components/Image/StoryblokImage';
import { StoryblokImageOrVideo } from '@components/Image/StoryblokImageOrVideo';
import { RichText } from '@components/RichText';

export const ContentBlock13 = ({ blok }: { blok: ContentBlock13Props }) => {
  return (
    <div
      /* @ts-ignore*/
      {...storyblokEditable(blok)}
      className={classNames(
        'flex relative flex-col col-end md:row-end sm:min-w-fit md:min-w-full md:max-h-[775px] py-11 md:py-16',
        blok.imageLeft ? 'md:flex-row-reverse' : 'md:flex-row',
      )}
    >
      <div
        style={{
          backgroundColor: `var(${
            blok.content_branch ? `--special-${blok.content_branch}` : '--accent-primary'
          })`,
        }}
        className="flex-1 flex flex-col justify-center pl-3 pr-3 pb-12 sm:pl-5 sm:pr-20"
      >
        <div className={classNames('min-w-fit text-h-40 md:text-h-56 xl:text-h-72 text-white mt-14 sm:mt-20 tracking-wide', blok.split_headline ? 'hyphens-auto' : '')}>
          {blok.title}
        </div>
        <div className="mt-10 text-white text-p-16 !font-extralight !md:leading-9 md:text-p-24 md:mt-6 md:max-w-[412px]">
          {typeof blok.textContent === 'string' ? (
            blok.textContent
          ) : (
            <RichText text={blok.textContent} />
          )}
        </div>
      </div>
      {(blok.image?.filename || blok.video_id || blok.shopTheLook) && (
        <StoryblokImageOrVideo
          className="w-full max-w-[1400px] aspect-video"
          filename={blok.image?.filename}
          placeholder="blur"
          focus={blok.image?.focus}
          blurDataURL={withBlur(blok.image?.filename)}
          alt={blok.image?.alt}
          parallax={blok.image_parallax}
          layout="intrinsic"
          width="1400"
          height="1400"
          objectFit="cover"
          video_id={blok.video_id}
          autoplay={blok.autoplay}
          shopTheLook={blok.shopTheLook}
        />
      )}
    </div>
  );
};
