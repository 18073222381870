import { getStoryblokApi, ISbResult, ISbStoriesParams } from '@storyblok/react';
import { sleep } from '@lib/utils';

/**
 * ! Only use in server side !
 * Get the story from storyblok.
 * @param slug of the story content
 * @returns data with story
 */
export const getStory = async (
  slug: string,
  language = 'de-de',
  more?: Partial<ISbStoriesParams>,
): Promise<ISbResult | { data: null }> => {
  const preview = process.env.STORYBLOK_PREVIEW_MODE === 'true';
  // the storyblok params
  const params: ISbStoriesParams = {
    language: language.split("-")[0],
    version: 'published',
    fallback_lang: 'de',
    ...slug
      .split('?')[1]
      ?.split('&')
      .map((param) => param.split('='))
      .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {}),
    ...more,
  };

  // checks if Next.js is in preview mode
  if (preview) {
    // loads the draft version
    params.version = 'draft';
    // appends the cache version to get the latest content
    params.cv = Date.now();
  }

  // return the story from Storyblok and whether preview mode is active
  try {
    return await getStoryblokApi().get(`cdn/stories/${slug}`, params);
  } catch (error: any) {
    if (error.status === 404) {
      return { data: null };
    }
    console.warn(
      `Error while fetching story from storyblok: slug: ${slug}, params: ${params.cv}, ${params.version}`,
    );

    if (error.status === 429) {
      console.warn(`Seems like a timeout error. Waiting 10 second and try again.`);
      await sleep(10010);
      return await getStory(slug, language);
    }
    console.error(error);
    return { data: null };
  }
};
