import classNames from '../utils/ClassNames';

/**
 * Shows a simple spinner to indicate a loading status. Use this component in place of the component that's awaiting data, until it's ready.
 */
export const LoadingIndicator = ({ className }: { className?: string }) => (
  <div className={classNames('flex items-center justify-center p-10', className)}>
    <div className="relative w-6 h-6 animate-spin">
      <div className="w-full h-full border-[3px] rounded-full border-dark-background"></div>
      <div className="absolute inset-0 w-full h-full border-[3px] rounded-full border-r-dark-primary"></div>
    </div>
  </div>
);
