import React from 'react';
import { ImageStoryblok, YoutubeBlockProps } from '@customTypes/storyblok-types';
import { StoryblokImage } from './StoryblokImage';
import { StoryblokVideo } from './StoryblokVideo';
import { ImageProps } from 'next/image';
import { ShopTheLook, ShopTheLookProps } from '../storyblok/StoryblokImageWithAnnotations';

export const StoryblokImageOrVideo: React.FC<
  Partial<YoutubeBlockProps> &
  Partial<ImageStoryblok> & {
    shopTheLook?: ShopTheLookProps;
    className?: string;
    parallax?: boolean;
  } & Partial<ImageProps>
> = ({ video_id, autoplay, filename, className, shopTheLook, parallax, ...imageProps }) => {
  const shopTheLookItem = shopTheLook?.asset ? shopTheLook : undefined;

  return (
    <>
      {video_id && (
        <StoryblokVideo video_id={video_id} autoplay={!!autoplay} className={className} />
      )}
      {!!shopTheLookItem && !video_id && (
        <ShopTheLook {...shopTheLookItem} {...imageProps} className={className} />
      )}
      {filename && !video_id && !shopTheLookItem && (
        <StoryblokImage parallax={parallax} src={filename} {...imageProps} />
      )}
    </>
  );
};
