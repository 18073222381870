import classNames from 'classnames';
import Link, { LinkProps } from 'next/link';
import {
  AnchorHTMLAttributes,
  ButtonHTMLAttributes,
  ComponentType,
  DetailedHTMLProps,
  ReactNode,
  Ref,
  SVGProps,
} from 'react';

type LayoutNavbarButtonProps = {
  Icon?: ComponentType<any>;
  IconProps?: Record<string, any>;
  text?: string;
  counter?: number;
  className?: string;
  iconClass?: string;
  isActive?: boolean;
  isScrolled?: boolean;
} & Omit<ContainerProps, 'children' | 'className'>;

export const LayoutNavbarButton = ({
  Icon,
  className,
  text,
  isActive,
  counter,
  isScrolled,
  iconClass,
  IconProps,
  ...buttonProps
}: LayoutNavbarButtonProps) => {
  return (
    <Container
      className={classNames(
        'flex items-center justify-center min-w-fit flex-1 h-full cursor-pointer',
        className,
        !isActive && 'font-light',
      )}
      {...buttonProps}
    >
      {Icon && (
        <div
          className={classNames(
            'relative border-b-2 pb-[0.125rem] pt-[2px] -mb-[0.125rem] border-transparent hover:border-b-current transition-colors',
            isScrolled ? 'text-white' : 'hover:text-accent-primary',
            isActive &&
              `pt-[2px] border-b-2 font-extrabold !border-current ${
                !isScrolled ? 'text-accent-primary' : 'text-white border-white'
              }`,
          )}
          aria-hidden="true"
        >
          {Icon && <Icon className={classNames('w-5 h-5', iconClass)} {...IconProps} />}

          {counter && (
            <div
              className={classNames(
                'absolute top-0 right-0 inline-flex items-center justify-center px-2 py-1 font-semibold transition-colors duration-300 leading-none transform translate-x-1/2 -translate-y-1/2 rounded-full text-h-12',
                isScrolled ? 'bg-white text-accent-primary' : 'bg-accent-primary text-white',
              )}
            >
              <span>{counter < 100 ? counter.toString() : '99+'}</span>
            </div>
          )}
        </div>
      )}
      {text && (
        <div className="flex flex-col items-center justify-between">
          <span>{text}</span>
          <span className="px-[0.0125rem] font-normal h-0 overflow-hidden cursor-none pointer-events-none invisible select-none">
            {text}
          </span>
        </div>
      )}
    </Container>
  );
};
type ContainerProps = { children: ReactNode } & DetailedHTMLProps<
  ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
> &
  DetailedHTMLProps<AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement> &
  Partial<LinkProps>;

const Container = (props: ContainerProps) => {
  const { children, href, className, onClick, onMouseEnter } = props;

  if (href) {
    return (
      <Link
        href={href}
        passHref
        onClick={onClick}
        onMouseEnter={onMouseEnter}
        tabIndex={0}
        role="button"
        className={className}
        aria-label="navBarButton"
        {...props}
        legacyBehavior={false}
        ref={props.ref as Ref<HTMLAnchorElement> | undefined}
      >
        {children}
      </Link>
    );
  }

  if (!props.onClick) {
    return (
      <div className={className} onMouseEnter={onMouseEnter}>
        {children}
      </div>
    );
  }

  return (
    <button className={className} aria-label="navBarButton" {...props}>
      {children}
    </button>
  );
};
