import { Fragment, ReactNode } from 'react';
import { Transition } from '@headlessui/react';

interface NavMenuDrawerProps {
  show: boolean;
  children: ReactNode;
  onAfterLeave?: () => void;
}

export const NavMenuDrawer = ({ show, children, onAfterLeave }: NavMenuDrawerProps) => (
  <Transition.Root show={show} as={Fragment} {...(onAfterLeave && { afterLeave: onAfterLeave })}>
    <div className="relative inset-0 overflow-hidden">
      <div className="fixed w-full h-auto overflow-hidden">
        <Transition.Child
          as={Fragment}
          enter="transform transition ease-in-out duration-500 sm:duration-700"
          enterFrom="-translate-y-full"
          enterTo="translate-y-0"
          leave="transform transition ease-in-out duration-500 sm:duration-700"
          leaveFrom="translate-y-0"
          leaveTo="-translate-y-full"
        >
          {children}
        </Transition.Child>
      </div>
    </div>
  </Transition.Root>
);
