import {
  useCustomerAccessTokenCreateMutation,
  useCustomerAccessTokenDeleteMutation,
} from '@generated/graphql/apollo';
import {
  CustomerAccessToken,
  CustomerAccessTokenCreateMutation,
  CustomerUserError,
} from '@generated/graphql/types';
import { useEffect } from 'react';
import { MutationResult } from '@apollo/client';
import { useCustomerAccessTokenZustand } from '@zustand';

export const useCustomerAccessToken: () => [
  CustomerAccessToken | null,
  MutationResult<CustomerAccessTokenCreateMutation>,
  {
    login: (
      email: string,
      password: string,
      onlyValidate?: boolean,
    ) => Promise<CustomerAccessToken | Array<CustomerUserError> | null | undefined>;
    logout: () => Promise<void>;
    reset: () => void;
  },
] = () => {
  const { customerAccessToken: token, setCustomerAccessToken: setToken } =
    useCustomerAccessTokenZustand();

  const [createAccessToken, mutationResult] = useCustomerAccessTokenCreateMutation();
  const [deleteAccessToken] = useCustomerAccessTokenDeleteMutation();

  useEffect(() => {
    // on first render check if we have a token in local storage
    const tok = localStorage.getItem('customerAccessToken');
    if (token == null && tok != null) {
      setToken(JSON.parse(tok) as CustomerAccessToken);
    }
  }, []);

  const reset = () => {
    setToken(null);
    localStorage.removeItem('customerAccessToken');
  };

  const login = async (email: string, password: string, onlyValidate = false) => {
    try {
      const { data } = await createAccessToken({ variables: { input: { email, password } } });
      const accessToken = data?.customerAccessTokenCreate?.customerAccessToken || null;
      if (accessToken == null && onlyValidate) {
        return null;
      }
      if (accessToken != null) {
        localStorage.setItem('customerAccessToken', JSON.stringify(accessToken));
      } else {
        localStorage.removeItem('customerAccessToken');
      }
      setToken(accessToken);
      return accessToken || data?.customerAccessTokenCreate?.customerUserErrors;
    } catch (e) {
      console.log('ERROR HIER:', e);
      return null;
    }
  };

  const logout = async () => {
    try {
      if (token != null) {
        await deleteAccessToken({
          variables: { customerAccessTokenDeleteInput: token.accessToken },
        });
      }
    } catch (e) {
      console.log(e);
    } finally {
      setToken(null);
      localStorage.removeItem('customerAccessToken');
    }
  };

  return [token, mutationResult, { login, logout, reset, setToken }];
};
