import { ChevronLeft } from '@components/generated/icons';
import { SliderArrow, SliderArrowProps } from '.';
import classNames from '@utils/ClassNames';

type SliderArrowPrevProps = { className?: string } & Pick<SliderArrowProps, 'onClick'>;

export const SliderArrowPrev = ({ onClick, className }: SliderArrowPrevProps) => (
  <SliderArrow
    onClick={onClick}
    className={classNames(className, 'mr-2')}
    ArrowIcon={ChevronLeft}
  />
);
