import { ImageStoryblok } from '@customTypes/storyblok-types';
import { getStory } from 'storyblok/storyblok';
import { ISbStory, ISbStoryData } from '@storyblok/react';
import { ISbComponentType } from 'storyblok-js-client';

interface PromiseFulfilledResult<T> {
  status: 'fulfilled';
  value: T;
}

/**
 * Returns a Storyblok image's width and height in px, based on the filename.
 * @param image The Storyblok image to analyse
 * @returns The image's width and height
 */
export const getImageDimensions = (image: ImageStoryblok) => {
  if (!image?.filename) {
    return { height: 0, width: 0 };
  }

  const url = image.filename;

  return {
    // https://www.storyblok.com/faq/image-dimensions-assets-js
    height: Number(url.split('/')[5]?.split('x')[1]) || 0,
    width: Number(url.split('/')[5]?.split('x')[0]) || 0,
  };
};

export function getContentFromStory<T>(
  storyblokResult:
    | ISbStory
    | {
        data: null;
      },
): T | undefined {
  return storyblokResult.data?.story?.content as unknown as T | undefined;
}

export async function resolveStories(
  slugs: string[] | undefined,
  path: string,
  locale = 'de',
): Promise<ISbStoryData<ISbComponentType<string> & { [index: string]: any }>[]> {
  let stories: ISbStoryData<ISbComponentType<string> & { [index: string]: any }>[] = [];
  if (slugs?.length) {
    try {
      // puuh.. 🥵
      // Get all the stories you can get. Just don't crash.
      const storyblokResults = await Promise.allSettled(
        slugs.map((slug) => getStory(`${path}/${slug}`.replace(/\/\//g, '/'), locale)),
      );
      stories = storyblokResults
        .filter((r) => r.status === 'fulfilled' && !!r.value)
        .map((r) => (r as PromiseFulfilledResult<ISbStory>).value?.data?.story)
        .filter((s) => !!s);

      // if didn't get all stories we wanted to, simply log here 🗒
      if (slugs.length > stories.length) {
        console.error(
          "did't fetch all storyblok data,° only:",
          storyblokResults?.length,
          'for all contentBlockSlugs in the product',
          slugs,
          '\n',
        );
      }
    } catch (e) {
      console.error('Error while fetching storyblok content in products/[handle].tsx');
    }
  } else {
    try {
      stories = (await getStory(`${path}`.replace(/\/\//g, '/'), locale))?.data?.story ?? [];
    } catch (e) {
      console.error('Error while fetching storyblok content');
    }
  }

  return stories;
}
