import React from 'react';

import HeroSlider from '@components/HeroSlider';
import { HeroSliderBlockProps } from '@customTypes/storyblok-types';
import { storyblokEditable } from '@storyblok/react';

export const HeroSliderBlock: React.FC<{ blok: HeroSliderBlockProps }> = ({ blok }) => (
  /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
  /* @ts-ignore*/
  <HeroSlider {...storyblokEditable(blok)} slides={blok.slides} />
);
