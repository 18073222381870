import { CustomerQuery, DiscountApplication } from '@generated/graphql/types';
import { getGtmCustomerDetails, GtmCustomerDetails } from './customer';
import { GtmEventType } from './types';
import { OrderConfirmationData } from '../../pages/api/order-confirmation/[id]';
import { APIOrderPaymentMethod } from '../../pages/api/order';
import { sha256 } from '@lib/utils';

interface CheckoutCompletedBasicParams {
  orderData: OrderConfirmationData;
  customer: CustomerQuery['customer'] | null;
  cartId: string;
  fbData?: any;
}

type CheckoutCompletedBasicPayload = {
  event: GtmEventType;
  customer: GtmCustomerDetails;
  checkout: GtmCheckoutDetails;
  cartId: string;
  fbData?: any;
};

export const getGtmPayloadCheckoutCompletedDisplayed = ({
  customer,
  orderData,
  cartId,
  fbData,
}: CheckoutCompletedBasicParams): CheckoutCompletedBasicPayload => ({
  cartId,
  checkout: getGtmCheckoutDetails(orderData),
  customer: getGtmCustomerDetails(customer),
  event: GtmEventType.CheckoutCompleted,
  fbData,
});

export const getGtmPayloadCheckoutCompletedShopifyDisplayed = ({
  customer,
  orderData,
  cartId,
  fbData,
}: CheckoutCompletedBasicParams): CheckoutCompletedBasicPayload => ({
  cartId,
  checkout: getGtmCheckoutDetails(orderData),
  customer: getGtmCustomerDetails(customer),
  event: GtmEventType.CheckoutCompletedShopify,
  fbData,
});

interface GtmCheckoutDetails {
  discountApplications: Array<DiscountApplication>;
  productVariantsCount: number;
  productSkus?: string[];
  productVariantSkus?: string[];
  totalValue: number;
  paymentMethod?: APIOrderPaymentMethod | null;
}

export type CustomerDataToHash = {
  email?: string | null;
  phone?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  gender?: { value: string | null } | null;
  defaultAddress?: {
    country?: string | null;
    city?: string | null;
    zip?: string | null;
  } | null;
};

export const hashCustomerData = async (data: CustomerDataToHash) => {
  return Promise.all([
    data?.email ? sha256(data.email) : Promise.resolve(null),
    data?.phone ? sha256(data.phone) : Promise.resolve(null),
    data?.firstName ? sha256(data.firstName) : Promise.resolve(null),
    data?.lastName ? sha256(data.lastName) : Promise.resolve(null),
    data?.gender?.value ? sha256(data.gender.value) : Promise.resolve(null),
    data?.defaultAddress?.country ? sha256(data.defaultAddress.country) : Promise.resolve(null),
    data?.defaultAddress?.city ? sha256(data.defaultAddress.city) : Promise.resolve(null),
    data?.defaultAddress?.zip ? sha256(data.defaultAddress.zip) : Promise.resolve(null),
  ]).then(([email, phone, firstName, lastName, gender, country, city, zip]) => {
    return {
      email,
      phone,
      firstName,
      lastName,
      gender,
      country,
      city,
      zip,
    };
  });
};

const getGtmCheckoutDetails = (orderData: OrderConfirmationData): GtmCheckoutDetails => {
  const getCheckoutQuantity = () => {
    const edges = orderData.lineItems.edges;
    let total = 0;

    if (!edges || edges.length === 0) {
      return total;
    }

    for (const element of edges) {
      total += element.node.quantity;
    }

    return total;
  };

  const productsCount = getCheckoutQuantity();
  const allVariants = orderData.lineItems.edges.map(({ node }) => node.variant);
  return {
    discountApplications: orderData.discountApplications.edges.map(({ node }) => node),
    paymentMethod: orderData.transactions[orderData.transactions.length - 1].paymentMethod, // @Pololinger should we collect all methods here?
    productSkus: allVariants
      .map((variant) => variant?.product.sku?.value.replace('Sprenger_', '').replace('_SDS', ''))
      .filter((sku): sku is NonNullable<typeof sku> => !!sku),
    productVariantSkus: allVariants
      .map((variant) => variant?.sku.replace('Sprenger_', '').replace('_SDS', ''))
      .filter((sku): sku is NonNullable<typeof sku> => !!sku),
    productVariantsCount: productsCount,
    totalValue: parseFloat(orderData.totalPriceSet.presentmentMoney.amount),
  };
};
