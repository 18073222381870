import { create } from 'zustand';

type ConsentState = {
  consentGiven: boolean;
  setConsentGiven: (consentGiven: boolean) => void;
};

export const useConsentZustand = create<ConsentState>((set) => ({
  consentGiven: false,
  setConsentGiven: (consentGiven) => set((state) => ({ ...state, consentGiven })),
}));
