import { LayoutNavbar } from './Navbar';
import { LayoutFooter } from './Footer';
import { CustomerSessionDrawer } from '@components/Customer/Session/Drawer';
import { Menu } from '@components/Menu';
import { useNavMenuProductZustand } from '@zustand/navMenuProduct';
import { useProductDetailZustand } from '@zustand/productDetail';
import { useNavMenuCollectionZustand } from '@zustand/navMenuCollection';
import { useShopData } from '@hooks/useShopData';
import { BackToTop } from '@components/Layout/BackToTop';
import ShippingCountryPopup from '@components/Language/ShippingCountryPopup';
import { useEffect } from 'react';
import { useCart } from '@hooks/useCart';
import { useTranslate } from '@tolgee/react';

type LayoutProps = {
  children?: React.ReactNode;
};
const branchToIconMapping = {
  Hundesport: { icon: '🐶', text: 'tabtitle_dog_only' },
  Pferdesport: { icon: '🐴', text: 'tabtitle_equestrian_only' },
  Bootsport: { icon: '⛵️', text: 'tabtitle_boat_only' },
} as const;

const Layout = ({ children }: LayoutProps): JSX.Element => {
  useShopData();
  const openNavMenuProduct = useNavMenuProductZustand((store) => store.openNavMenuBranch);
  const openNavMenuCollection = useNavMenuCollectionZustand((store) => store.openNavMenuCollection);
  const openProductDetail = useProductDetailZustand((store) => store.openProductDetail);
  const cart = useCart();
  const { t } = useTranslate();

  const resetHTMLStyle = () => {
    if (!openNavMenuProduct && !openNavMenuCollection && !openProductDetail) {
      const element = document.getElementsByTagName('html')[0];
      if (
        (element && element.style.overflow === 'hidden') ||
        element.style.paddingRight === '15px'
      ) {
        element.removeAttribute('style');
      }
    }
  };

  useEffect(() => {
    let currentWindowTitle = document.title;
    function setTabTitle() {
      currentWindowTitle = document.title;
      const matches: (keyof typeof branchToIconMapping)[] = [];
      if (cart.data?.lines.edges.length) {
        Object.entries(branchToIconMapping).forEach(([key]) => {
          if (
            cart.data?.lines.edges.some((e) =>
              e.node.merchandise.product.collections.edges.some((e) => e.node.title === key),
            )
          ) {
            matches.push(key as unknown as any);
          }
        });
      }

      if (matches.length === 1) {
        document.title = `${branchToIconMapping[matches[0]].icon} ${t(
          branchToIconMapping[matches[0]].text,
        )}`;
      } else if (matches.length > 1) {
        document.title = `${matches.reduce(
          (prev, curr) => (prev += branchToIconMapping[curr].icon),
          '',
        )} ${t('tabtitle_diverse_branches')}`;
      } else {
        document.title = `${t('tabtitle_miss_you')}`;
      }
    }
    function resetTabTitle() {
      document.title = currentWindowTitle;
    }
    window.addEventListener('blur', setTabTitle);
    window.addEventListener('focus', resetTabTitle);

    return () => {
      window.removeEventListener('blur', setTabTitle);
      window.removeEventListener('focus', resetTabTitle);
    };
  }, [cart.data]);

  return (
    <>
      <LayoutNavbar />
      <Menu />
      <div
        className="flex flex-col items-center w-full h-full min-h-[75vh] pb-40 -mb-24"
        onWheel={() => resetHTMLStyle()}
        onTouchStart={() => resetHTMLStyle()}
      >
        {children}
      </div>
      <LayoutFooter />
      <CustomerSessionDrawer />
      <BackToTop />
      <ShippingCountryPopup />
    </>
  );
};

export default Layout;
