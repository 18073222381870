/* eslint-disable jsx-a11y/click-events-have-key-events */
import Link from 'next/link';
import { getProductPath } from '@lib/getProductPath';
import { Product, ProductHandlesQuery, ProductVariant } from '@generated/graphql/types';
import { ReactNode } from 'react';
import { useRouter } from 'next/router';
import { useLastCollectionFilterOption } from '@zustand/lastCollectionFilterOption';

export interface ProductCardLinkProps {
  handle: Product['handle'];
  children: ReactNode;
  onClick?: (sku: string) => void;
  className?: string;
  collections?: NonNullable<
    ArrayElementOf<ProductHandlesQuery['products']['edges']>
  >['node']['collections'];
  variantId?: string;
  variantSku?: string;
}

export const ProductCardLink = ({
  handle,
  collections,
  variantId,
  variantSku,
  children,
  onClick,
  className,
}: ProductCardLinkProps) => {
  const router = useRouter();
  const { lastCollectionFilterOption: lastPath, clearLastCollectionFilterOption} = useLastCollectionFilterOption();

  return (
    <Link href={getProductPath(handle, collections, variantId, variantSku)} passHref>
      <a role="button" tabIndex={0} onClick={async (e) => {
        e.preventDefault();
        if (lastPath) {
          clearLastCollectionFilterOption();
          await router.push(`/collections/${lastPath}`);
        }
        router.push(getProductPath(handle, collections, variantId, variantSku));
        onClick?.(handle);
      }} className={className}>
        {children}
      </a>
    </Link>
  );
};
