import SearchAutoSuggestion from '@components/SearchBar/QuickSearchItem';
import { Product } from '@hooks/useSearch';

type QuickSearchResultListProps = {
  products: Product[];
  onProductSelected?: (id: string) => void;
};

const QuickSearchItemList = ({ products, onProductSelected }: QuickSearchResultListProps) => (
  <>
    {products.map((product, i) => (
      <SearchAutoSuggestion
        key={`${product.parentHandle}-${i}`}
        product={product}
        onClick={() => onProductSelected?.(product.parentHandle)}
      />
    ))}
  </>
);

export default QuickSearchItemList;
