import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook';
import { GtmEventType } from '@lib/gtm/types';
import { isProd, isStg } from '@lib/utils';
import { useConsentZustand } from '@zustand/consent';

/**
 * Hook that can be used in order to send data to Gtm.
 */
const useGtm = () => {
  const { consentGiven } = useConsentZustand();
  const sendDataToGTM = useGTMDispatch();

  return (
    data:
      | { event: GtmEventType; [key: string]: any }
      | PromiseLike<{ event: GtmEventType; [key: string]: any }>,
  ) => {
    const asyncSend = async () => {
      const newData = JSON.parse(JSON.stringify(await data).replace(/:"Sprenger_/g, ':"'));

      if (consentGiven) {
        if (isStg()) {
          console.log(`is consent given ${consentGiven}`);
        }
        // Only send to gtm in production and staging
        if (isStg() || isProd()) {
          //eslint-disable-line
          sendDataToGTM(newData);
        }
        // Only log payloads in dev and staging
        if (isStg()) {
          console.log('GtmDispatch: ', newData);
        }
      } else if (isStg()) {
        console.log('No Collect for you GTM', newData);
      }
    };
    asyncSend();
  };
};

export default useGtm;
