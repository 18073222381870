import { Facets, FacetValue, SearchClient, SearchResponse } from '@lib/search-client';
import { useEffect, useMemo, useState } from 'react';
import { combineFilters, debounce, deduplicate, isColorProp } from '@lib/utils';
import { useAvailableColors, useCurrentFilterQuery, useFiltersZustand } from '@zustand/filters';
import {
  CountedOption,
  Filter,
  FilterGroupOption,
  FilterGroupType,
  SearchAbleData,
  SearchAbleDataTypes,
  Size,
} from '@lib/search';
import { useCurrentSortQuery } from '@zustand/sort';
import { useRouter } from 'next/router';
import { useSearchConfig } from '@hooks/useSearchConfig';
import { SupportedLanguages } from '@lib/constants';
// import * as Sentry from '@sentry/nextjs';
import { useLocale } from '@zustand/useLocale';

const FORBIDDEN_FIELDS = ['EAN', 'Länge Inch', 'Maße', 'Ringe'];

interface ProductCollectionTree {
  name: string;
  sub: ProductCollectionTree[];
}

export type Product = {
  parentHandle: string;
  parentTitle: string;
  sku: string;
  imageUrls: string[];
  collections: ProductCollectionTree[];
  selectedOption: {
    name: string;
    value: string | number;
  }[];
};

export type ExhaustiveProduct = Product & {
  description: string;
  sku: string;
  searchableData: SearchAbleData;
  dataTypes: SearchAbleDataTypes;
  price: number;
  compareAtPrice: number;
  colorcode: string | null;
  selectedOption: {
    name: string;
    value: string | number;
  }[];
  colormap: Record<string, string[]>;
  availableForSale: boolean;
  priceRange: {
    minVariantPrice: number;
    maxVariantPrice: number;
  };
  compareAtPriceRange: {
    minVariantPrice: number;
    maxVariantPrice: number;
  };
};

type UseQuickSearch = (
  query: string,
  limit?: number,
) => { searchResults: Product[]; searchError: Error | null; autoSuggestions: string[] };

type UseExhaustiveSearch = (
  query: string,
  limit: number,
  forceQuery?: boolean,
  preservedFilters?: Filter[],
) => {
  searchResults: ExhaustiveProduct[];
  facetData: FilterGroupOption[];
  searchError: Error | null;
  totalHits: number;
  loading: boolean;
};

export const useQuickSearch: UseQuickSearch = (query, limit = 10) => {
  const [searchResults, setSearchResults] = useState<Product[]>([]);
  const [autoSuggestions, setAutoSuggestions] = useState<string[]>([]);
  const [searchError, setSearchError] = useState<Error | null>(null);
  const { language } = useLocale();
  const [client] = useState(new SearchClient({ locale: language }));

  useEffect(() => {
    client.changeLocale((language ?? 'de') as SupportedLanguages);
  }, [language]);

  function extractAutoSuggestions(response: SearchResponse<Product>) {
    return response.hits
      .filter((s) => !!s.collections)
      .map((hit) => {
        const { _matchesPosition } = hit;
        if (!_matchesPosition || !_matchesPosition.parentTitle) {
          return '';
        }
        const singleStrings = _matchesPosition.parentTitle.map((match) => {
          const substring = hit.parentTitle.slice(match.start);
          let firstSpace = substring.indexOf(' ');
          if (firstSpace === -1) {
            firstSpace = substring.length;
          }
          const charBeforeSpace = substring.charAt(firstSpace - 1);
          if (charBeforeSpace === ',' || charBeforeSpace === '.') {
            firstSpace--;
          }
          return substring.slice(0, firstSpace);
        });
        return singleStrings.join(' ');
      })
      .filter((suggestion) => suggestion.length > 0);
  }

  const { func, cleanup } = debounce(() => {
    if (query.length === 0) {
      setSearchResults([]);
      setAutoSuggestions([]);
      return;
    }
    client
      .search<Product>(query, {
        attributesToRetrieve: [
          'parentTitle',
          'parentHandle',
          'imageUrls',
          'collections',
          'selectedOption',
          'sku',
        ],
        limit,
        showMatchesPosition: true,
      })
      .then((response) => {
        setSearchResults(response.hits.filter((s) => !!s.collections));
        const newSuggestions = deduplicate(extractAutoSuggestions(response));
        setAutoSuggestions(newSuggestions.slice(0, 4));
      })
      .catch((error) => {
        setSearchError(error);
      });
  });

  useEffect(() => {
    func();
    return () => cleanup();
  }, [query, language]);

  return { autoSuggestions, searchError, searchResults };
};

function sameNameOf(facet: FilterGroupOption) {
  return (newFacet: FilterGroupOption) => newFacet.name === facet.name;
}

function toZero(option: CountedOption) {
  return { ...option, count: 0 };
}

export const useExhaustiveSearch: UseExhaustiveSearch = (
  query,
  pages,
  forceQuery = false,
  preservedFilters = [],
) => {
  const [searchResults, setSearchResults] = useState<ExhaustiveProduct[][]>([]);
  const [rawFacetData, setRawFacetData] = useState<null | SearchResponse<ExhaustiveProduct>>(null);
  const [searchError, setSearchError] = useState<Error | null>(null);
  const [isLoading, setLoading] = useState(false);
  const [totalHits, setTotalHits] = useState<number>(0);
  const [lastPath, setLastPath] = useState('');
  const currentlyAppliedFilters = useCurrentFilterQuery();
  const currentSortBy = useCurrentSortQuery();
  const config = useSearchConfig();
  const { language } = useLocale();
  const [client] = useState(new SearchClient({ locale: language as SupportedLanguages }));
  const [lastFetchedWith, setLastFetchedWith] = useState([
    query,
    currentlyAppliedFilters,
    currentSortBy,
    config,
    preservedFilters.map((e) => e.getQueryPart()).join('.'),
  ]);
  const { asPath } = useRouter();
  const { hasFilterGroupWithId, clearFilters } = useFiltersZustand((state) => ({
    clearFilters: state.clearFilters,
    hasFilterGroupWithId: state.hasFilterGroupWithId,
  }));
  const clearColors = useAvailableColors((store) => store.clear);
  const [iHateReactFacetData, setIHateReactFacetData] = useState<FilterGroupOption[]>([]);

  useEffect(() => {
    if (lastPath === asPath || asPath.includes('collection') || asPath.includes('virtualHandle')) {
      return;
    }
    setLastPath(asPath);
    clearFilters();
    clearColors();
  }, [asPath]);

  function shouldRefetch() {
    return [
      query,
      currentlyAppliedFilters,
      currentSortBy,
      config,
      preservedFilters.map((e) => e.getQueryPart()).join('.'),
    ]
      .map((e, i) => e !== lastFetchedWith[i])
      .some((e) => e);
  }

  function getUpdatedFacetData(usedData: FilterGroupOption[], groupOptions: FilterGroupOption[]) {
    return usedData.map((facet) => {
      const newData = groupOptions.find(sameNameOf(facet));
      if (!newData) {
        return {
          ...facet,
          options: facet.options.map(toZero),
          type: tryDescribeFacet(facet.name),
        };
      }
      return {
        ...facet,
        options: [
          ...newData.options,
          ...facet.options
            .filter((s) => !newData.options.some((r) => r.value === s.value))
            .map(toZero),
        ],
        type: tryDescribeFacet(newData.name),
      };
    });
  }

  async function fetchFacets() {
    const preservedQuery = !preservedFilters
      ? ''
      : preservedFilters.map((e) => e.getQueryPart()).join(' AND ');
    const hasPreservedFilters = preservedFilters.length > 0;
    await client
      .search<ExhaustiveProduct>(query, {
        facets: ['searchableData', 'price', 'collections'],
        filter: hasPreservedFilters
          ? combineFilters(`(${preservedQuery})`, currentlyAppliedFilters)
          : currentlyAppliedFilters,
        hitsPerPage: 50,
        page: 0,
        sort: currentSortBy ? [currentSortBy] : [],
      })
      .then((response) => {
        setRawFacetData(response);
      })
      .catch((error) => {
        if (error.name === 'TypeError' && error.message === 'Failed to fetch') {
          return [] as ExhaustiveProduct[];
        }
        // Sentry.captureException(error);
        setSearchError(error);
        return [] as ExhaustiveProduct[];
      });
  }

  function fetchSearchResults(page: number) {
    const preservedQuery = !preservedFilters
      ? ''
      : preservedFilters.map((e) => e.getQueryPart()).join(' AND ');
    const hasPreservedFilters = preservedFilters.length > 0;
    return client
      .search<ExhaustiveProduct>(query, {
        facets: ['searchableData', 'price', 'collections'],
        filter: hasPreservedFilters
          ? combineFilters(`(${preservedQuery})`, currentlyAppliedFilters)
          : currentlyAppliedFilters,
        hitsPerPage: 50,
        page: page,
        sort: currentSortBy ? [currentSortBy] : [],
      })
      .then((response) => {
        setTotalHits(response.totalHits);
        return response.hits.filter((s) => !!s.collections);
      })
      .catch((error) => {
        if (error.name === 'TypeError' && error.message === 'Failed to fetch') {
          return [] as ExhaustiveProduct[];
        }
        // Sentry.captureException(error);
        setSearchError(error);
        return [] as ExhaustiveProduct[];
      });
  }

  async function refetchPages() {
    setLoading(true);
    const results: ExhaustiveProduct[][] = Array.from({ length: pages }, () => []);
    for (let i = 0; i < pages; i++) {
      results[i] = await fetchSearchResults(i + 1);
      setSearchResults(results);
      if (i === 0) {
        setLoading(false);
      }
    }
  }

  useEffect(() => {
    if (query.length < 1 && !forceQuery) {
      return;
    }
    if (searchResults.length < pages && !shouldRefetch()) {
      fetchSearchResults(pages).then((results) => {
        setSearchResults((prev) => {
          if (prev.length < pages) {
            return [...prev, results];
          }
          prev[pages - 1] = results;
          return prev;
        });
      });
      fetchFacets().then(() => {
        console.log('fetched facets');
      });
    } else if (shouldRefetch()) {
      refetchPages();
      fetchFacets().then(() => {
        console.log('fetched facets');
      });
      setLastFetchedWith([
        query,
        currentlyAppliedFilters,
        currentSortBy,
        config,
        preservedFilters.map((e) => e.getQueryPart()).join('.'),
      ]);
    }
  }, [
    query,
    currentlyAppliedFilters,
    currentSortBy,
    pages,
    JSON.stringify(config),
    preservedFilters.map((e) => e.getQueryPart()).join('.'),
  ]);

  function getLastNestedName(fields: string[]): string | null {
    const lastNestedName = fields.pop();
    if (
      !lastNestedName ||
      FORBIDDEN_FIELDS.includes(lastNestedName) ||
      lastNestedName.startsWith('collection')
    ) {
      return null;
    }
    if (lastNestedName.includes('.')) {
      let result = '';
      const remainder = lastNestedName.split('.');
      while (remainder.length > 0) {
        const current = remainder.pop()!;
        if (current.length > 1) {
          result = `${current}${result.length > 0 ? '.' : ''}${result}`;
        } else {
          return null;
        }
      }
      return result;
    }

    if (isColorProp(lastNestedName)) {
      return null;
    }
    return lastNestedName;
  }

  function getCollectionFacetData(rawFacets: [string, FacetValue][]) {
    return rawFacets
      .map(([, facetValue]) =>
        Object.entries(facetValue).map<CountedOption>(([value, count]) => ({
          count,
          value,
        })),
      )
      .flat();
  }

  const facetData = useMemo(
    () => (rawFacetData ? extractFacetData(rawFacetData) : []) ?? [],
    [rawFacetData, config],
  );

  useEffect(() => {
    setIHateReactFacetData((prev) => {
      return facetData ?? prev;
    });
  }, [facetData]);

  function getDefaultFacetData(facets: Facets) {
    const defaultData = Object.entries(facets)
      .map<FilterGroupOption | null>(([key, value]) => {
        if (Object.values(value).length === 0) {
          return null;
        }

        // Nested fields are packed seperated by a dot.
        // We need to unpack them to get the actual field name.
        const fields = key.trim().split('searchableData.');
        const lastNestedName = getLastNestedName(fields);
        if (!lastNestedName) {
          return null;
        }

        const pairs = Object.entries(value).map<CountedOption>(([option, count]) => ({
          count,
          value: option,
        }));

        let remainingOptions: CountedOption[] = [];
        if (hasFilterGroupWithId(lastNestedName)) {
          const predecessor = iHateReactFacetData.find((facet) => facet.name === lastNestedName);
          remainingOptions =
            predecessor?.options
              .filter((option) => !pairs.some((pair) => pair.value === option.value))
              .map((option) => ({ ...option })) ?? [];
        }

        const options = [...pairs, ...remainingOptions];
        return {
          name: lastNestedName,
          options: trySortSizes(options),
          type: tryDescribeFacet(lastNestedName),
        };
      })
      .filter((option) => option !== null) as FilterGroupOption[];
    return defaultData;
  }

  function extractFacetData(response: SearchResponse<ExhaustiveProduct>) {
    const facets = response.facetDistribution;
    const collectionData = Object.entries(facets).filter(([key]) => key.startsWith('collection'));
    const processedCollectionData = getCollectionFacetData(collectionData);
    const processedDefaultData = getDefaultFacetData(facets);
    return [
      {
        name: 'collection',
        options: deduplicate(processedCollectionData, (a, b) => a.value === b.value),
        type: 'collection',
      },
      ...processedDefaultData,
    ] as FilterGroupOption[];
  }

  function trySortSizes(options: CountedOption[]) {
    if (!options.every((s) => typeof s.value === 'string')) {
      return options;
    }
    const sizes = Object.keys(Size);
    const hasSizes =
      options.filter((s) => sizes.includes((s.value as string).toUpperCase())).length > 2;
    if (!hasSizes) {
      return options;
    }
    return options.sort((a, b) => {
      const firstSizeIndex = sizes.indexOf((a.value as string).toUpperCase());
      const secondSizeIndex = sizes.indexOf((b.value as string).toUpperCase());
      return firstSizeIndex - secondSizeIndex;
    });
  }

  function tryDescribeFacet(name: string): FilterGroupType {
    if (name === 'collection') {
      return 'collection';
    }
    if (name === 'price') {
      return 'price';
    }
    if (!config) {
      return 'string';
    }
    const type = config.data[name];
    if (!type) {
      console.warn(`Unknown type ${type}`);
      return 'string';
    }
    return type;
  }

  return {
    facetData: facetData
      .filter((s) => s.options.some((option) => option.count > 0) && s.options.length > 1)
      .sort((a, b) =>
        a.name === 'collection' ? -1 : b.name === 'collection' ? 1 : a.name.localeCompare(b.name),
      ),
    loading: isLoading,
    searchError,
    searchResults: searchResults.flat(),
    totalHits,
  };
};
