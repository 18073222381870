import React from 'react';
import { ImageStoryblok } from '@customTypes/storyblok-types';
import { getImageDimensions } from '@lib/storyblokUtil';
import useResponsive from '@hooks/useResponsive';
import { StoryblokImage } from '../Image/StoryblokImage';
import { storyblokEditable } from '@storyblok/react';

export interface HeroBlock2Props {
  image_big: ImageStoryblok;
  image_small: ImageStoryblok;
}

/**
 * @deprecated don't use this component anymore
 * @param blok - storyblok blok
 * @constructor - React component
 */
export const HeroBlock2: React.FC<{ blok: HeroBlock2Props }> = ({ blok }) => {
  const width = useResponsive();
  const imageDimensions_big = blok?.image_big && getImageDimensions(blok.image_big);
  const imageDimensions_small = blok?.image_small && getImageDimensions(blok.image_small);

  return (
    /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
    /* @ts-ignore*/
    <div {...storyblokEditable(blok)} className="w-full">
      {width > 800 || !blok.image_small.filename ? (
        <StoryblokImage
          src={blok.image_big?.filename ?? ''}
          alt={blok.image_big?.alt ?? ''}
          layout="responsive"
          sizes="100vw"
          width={imageDimensions_big?.width || 600}
          height={imageDimensions_big?.height || 400}
        />
      ) : (
        <StoryblokImage
          src={blok.image_small.filename}
          alt={blok.image_small?.alt ?? ''}
          layout="intrinsic"
          width={imageDimensions_small?.width || 600}
          height={imageDimensions_small?.height || 400}
        />
      )}
    </div>
  );
};
