import { useTranslate } from '@tolgee/react';
import classNames from 'classnames';
import { HTMLInputTypeAttribute, useEffect, useState } from 'react';
import { FormFieldConfig, FormFieldRequiredProps } from '.';
import { AlertCircle, ChevronRight, Search, X } from '@components/icons';

/**
 * Validation rules & error messages for all types of ConfigTypes.
 * TODO: translate validations patterns
 */
const defaultConfigs: Record<string, Config> = {
  address1: {
    htmlType: 'text',
    requiredMessage: 'requiredMessage',
    validation: {
      pattern: {
        message: 'validators.error.address1',
        value: /(.){3,}(\s|\.)?\d+\w*/,
      },
    },
  },
  date: { htmlType: 'date', requiredMessage: 'requiredMessage' },
  default: { htmlType: 'text', requiredMessage: 'requiredMessage' },
  email: {
    htmlType: 'email',
    requiredMessage: 'requiredMessage',
    validation: {
      pattern: {
        message: 'validators.error.email',
        value: /\S+@\S+\.\S+/,
      },
    },
  },
  firstName: { htmlType: 'text', requiredMessage: 'requiredMessage' },
  germanZipCode: {
    htmlType: 'text',
    requiredMessage: 'requiredMessage',
    validation: {
      pattern: {
        message: 'validators.error.zip',
        value: /^([0]{1}[1-9]{1}|[1-9]{1}[\d]{1})[\d]{3}$/,
      },
    },
  },
  lastName: { htmlType: 'text', requiredMessage: 'textInput.default.requiredMessage' },
  orderNumber: { htmlType: 'text', requiredMessage: 'validators.error.orderNumber' },
  password: {
    htmlType: 'password',
    requiredMessage: 'requiredMessage',
    validation: {
      minLength: { message: 'validators.error.password', value: 8 },
    },
  },
  phone: {
    htmlType: 'tel',
    validation: {
      pattern: {
        message: 'validators.error.tel',
        value: /^[+][\d]{5,15}$/,
      },
    },
  },
};

type ConfigType = keyof typeof defaultConfigs;

type Config = {
  htmlType: HTMLInputTypeAttribute;
  placeholder?: string;
  defaultValue?: string;
} & FormFieldConfig;

type FormFieldTextInputProps = {
  label?: string;
  type: ConfigType;
  helperText?: string;
  className?: string;
  showClearButton?: boolean;
  showChevron?: boolean;
  fullColorIcon?: boolean;
  showSearch?: boolean | 'nosubmit';
  onClear?: () => void;
  onBlur?: () => void;
  customInputClass?: string;
} & FormFieldRequiredProps &
  Partial<Config>;

export const FormFieldTextInput = ({
  label,
  helperText,
  type,
  formRegister,
  name,
  errors,
  required,
  className,
  htmlType,
  placeholder,
  defaultValue,
  validation,
  requiredMessage,
  formWatch,
  showClearButton,
  onClear,
  onBlur,
  showChevron,
  showSearch,
  customInputClass,
  fullColorIcon,
}: FormFieldTextInputProps) => {
  const defaultConfig: Config = defaultConfigs[type];
  const error = errors[name];
  const value = formWatch(name);
  const { t } = useTranslate();
  const [focus, setFocus] = useState(false);
  const isDateField = [htmlType, defaultConfig.htmlType].includes('date');
  const [hasValue, setHasValue] = useState(false);
  const isNewsletterInput = name === 'newsLetterEmail';
  const inputStylingError = `text-special-danger placeholder-special-danger border-special-danger focus:ring-special-danger focus:border-special-danger ${isNewsletterInput ? 'newsletter-input-footer' : 'pt-5 pb-1'
    }`;
  const inputStyling = `${isNewsletterInput && (focus || hasValue)
      ? 'border-opacity-50 focus:border-white'
      : isNewsletterInput
        ? 'border-opacity-100 focus:border-accent-primary'
        : focus || hasValue
          ? '!border-accent-primary pt-5 pb-1'
          : 'border-accent-primary/35 !bg-opacity-5 py-3'
    } ${isNewsletterInput ? 'border-light-secondary newsletter-input-footer' : ''
    } ring-0 bg-opacity-10`;

  useEffect(() => {
    if (isDateField) {
      setHasValue(true);
      return;
    }

    if (!value) {
      setHasValue(false);
      return;
    }

    setHasValue(true);
  }, [value]);

  return (
    <div className={classNames('flex flex-col space-y-2', className)}>
      <div className="relative overflow-hidden rounded bg-accent-primary_005">
        <label
          htmlFor={name}
          className={classNames(
            'absolute px-3 py-1 transition-all !normal-case',
            error
              ? 'text-special-danger'
              : isNewsletterInput
                ? 'text-white'
                : 'text-accent-primary',
            focus || hasValue
              ? 'text-opacity-100 top-0 translate-y-0 text-p-12'
              : `text-opacity-35 top-1/2 -translate-y-1/2 ${isNewsletterInput ? 'text-light-primary' : ''
              }`,
            showSearch && 'pl-8',
            isNewsletterInput ? 'text-p-12' : 'text-p-16',
          )}
        >
          {label}
        </label>

        <input
          className={classNames(
            'autofill-input-global bg-gray-100 border-b-[0.15rem] border-t-0 border-x-0 outline-none text-h-16 text-accent-primary !normal-case placeholder-dark-tertiary w-full',
            error ? inputStylingError : inputStyling,
            showSearch ? 'pl-8' : 'pl-3',
            customInputClass,
          )}
          style={
            isNewsletterInput
              ? focus || hasValue
                ? {
                  backgroundColor: 'rgba(255, 255, 255, .1)',
                  color: 'white',
                  height: '50px',
                  paddingTop: '20px',
                  transition: 'height 0.3s linear',
                }
                : {
                  backgroundColor: 'rgba(255, 255, 255, .1)',
                  color: 'white',
                  transition: 'height 0.3s linear',
                }
              : {}
          }
          type={htmlType || defaultConfig.htmlType}
          id={name}
          placeholder={
            placeholder ||
            (defaultConfig.placeholder
              ? t(`textInput.${type}.placeholder.${defaultConfig.placeholder}`)
              : '')
          }
          defaultValue={
            defaultValue ||
            (defaultConfig.defaultValue
              ? t(`textInput.${type}.defaultValue.${defaultConfig.defaultValue}`)
              : '')
          }
          onFocus={() => setFocus(true)}
          aria-invalid={!!error}
          aria-describedby={`${name}-error`}
          {...formRegister(name, {
            ...(validation || defaultConfig.validation),
            onBlur: () => {
              setFocus(false);
              onBlur?.();
            },
            required: {
              message:
                requiredMessage ||
                (defaultConfig.requiredMessage
                  ? t(`textInput.${type}.${defaultConfig.requiredMessage}`)
                  : t('formField.mustNotBeEmpty')),
              value: required || false,
            },
          })}
        />

        <div className="absolute left-2 inset-y-0 grid place-items-center">
          {showSearch &&
            (typeof showSearch === 'string' && showSearch === 'nosubmit' ? (
              <Search
                className={classNames(
                  'h-5 transition duration-500',
                  focus || hasValue || fullColorIcon
                    ? 'text-accent-primary'
                    : 'text-accent-primary/50',
                )}
              />
            ) : (
              <button type="submit" aria-label="submit">
                <Search
                  className={classNames(
                    'h-5 cursor-pointer transition duration-500',
                    focus || hasValue || fullColorIcon
                      ? 'text-accent-primary'
                      : 'text-accent-primary/50',
                  )}
                />
              </button>
            ))}
        </div>

        <div className="absolute inset-y-0 right-0 flex items-center justify-center pl-1 pr-2 m-1 space-x-1 pointer-events-none">
          {showClearButton && (
            <button onClick={onClear} className="pointer-events-auto">
              <X className="w-5 h-5 text-accent-primary" />
            </button>
          )}

          {error && <AlertCircle className="w-5 h-5 text-special-danger" aria-hidden="true" />}
        </div>
        <div className="flex flex-col justify-center absolute right-0 top-0 lg:-mr-3 h-full">
          {showChevron && !error && (
            <button type="submit" aria-label="submit">
              <ChevronRight
                className={`h-5 cursor-pointer ${isNewsletterInput ? 'text-white' : 'text-accent-primary pr-2'
                  } pr-1 lg:pr-3`}
              />
            </button>
          )}
        </div>
      </div>

      {error?.message && (
        <p
          className={classNames(
            isNewsletterInput
              ? 'text-special-danger text-p-12 !mt-0'
              : 'text-special-danger text-p-12',
          )}
          id={`${name}-error`}
        >
          {t(error.message as string)}
        </p>
      )}

      {helperText && <p className="text-p-12 text-dark-tertiary">{helperText}</p>}
    </div>
  );
};
