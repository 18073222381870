import { NorthWestArrow, Search } from '@components/icons';
import Link from 'next/link';
import { useRef } from 'react';

export type SearchResultDataProps = {
  title: string;
};
type OnClickProps = { onClick: () => void };

const SearchAutoSuggestionItem = ({ title, onClick }: SearchResultDataProps & OnClickProps) => {
  const anchor = useRef<HTMLAnchorElement>(null);

  function handle() {
    anchor.current?.blur();
    onClick();
  }

  return (
    <div className="p-2 w-full hover:bg-gray-100 transition duration-200 flex flex-row items-center px-5 text-gray-900">
      <Link href={`/search?q=${title}`}>
        {/* eslint-disable-next-line jsx-a11y/interactive-supports-focus */}
        <a
          role="link"
          ref={anchor}
          onClick={handle}
          onKeyPress={(e) => e.key === 'Enter' && handle()}
          className="flex flex-row items-center flex-1"
        >
          <Search height={15} width={15} className="mr-1" />
          <div className="text-sm leading-5 font-medium line-clamp-1 flex-1">{title}</div>
        </a>
      </Link>
      <button className="z-10" onClick={onClick}>
        <NorthWestArrow width={15} height={15} />
      </button>
    </div>
  );
};

export default SearchAutoSuggestionItem;
