import Link from 'next/link';
import { ButtonPrimary } from './Primary';

export interface StoryblokButtonProps {
  text: string;
  url?: string;
  className?: string;
}

export const StoryblokButton = ({ text, url, className: classname }: StoryblokButtonProps) => {
  if (url) {
    return (
      <Link href={url} passHref>
        <a>
          <ButtonPrimary text={text} className={classname} />
        </a>
      </Link>
    );
  }

  return <ButtonPrimary text={text} className={classname} />;
};
