import React from 'react';
import { ContentBlock3Props } from '@customTypes/storyblok-types';
import { StoryblokButton } from '@components/Button/StoryblokButton';
import { storyblokEditable } from '@storyblok/react';
import classNames from '@utils/ClassNames';
import { RichText } from '@components/RichText';

export const ContentBlock3: React.FC<{ blok: ContentBlock3Props }> = ({ blok }) => (
  <div
    /* @ts-ignore*/
    {...storyblokEditable(blok)}
    className={classNames(blok.blue_background && "bg-accent-primary_005")}
  >
    <div
      className="flex flex-col-reverse w-full space-y-6 space-y-reverse md:space-y-8 md:flex-col box-x-wrapper py-11 md:py-16"
    >
      {blok.text_emphasis === 'right' && (
        <div className="flex flex-row justify-end w-full h-full z-0">
          <InformationComponent {...blok} />
        </div>
      )}
      {blok.text_emphasis === 'left' && (
        <div className="flex flex-row justify-start w-full h-full z-0">
          <InformationComponent {...blok} />
        </div>
      )}
      {blok.text_emphasis === 'center' && (
        <div className="flex flex-row justify-center w-full h-full z-0">
          <InformationComponent {...blok} />
        </div>
      )}
    </div>
  </div>
);

const InformationComponent = ({
  text,
  headline,
  button_link,
  button_text,
  subtitle,
  text_emphasis,
}: ContentBlock3Props) => (
  <div
    className={classNames(
      'md:flex md:flex-col md:items-start w-full md:pl-1.5',
      text_emphasis === 'center' ? 'md:w-2/3' : 'md:w-1/2 ',
    )}
  >
    <span className="text-h-16">{subtitle}</span>
    <h3 className="text-h-24 lg:text-h-32 mb-6 hyphens-auto">{headline}</h3>
    {typeof text === 'string' ? <p>{text}</p> : <RichText text={text} />}
    <div className="flex flex-col items-start">
      {button_link && button_text && (
        <StoryblokButton text={button_text} url={button_link.url} className="w-full my-6" />
      )}
    </div>
  </div >
);
