import Link from 'next/link';
import { Action } from '@lib/NotificationContext';
import { ReactNode } from 'react';
import classNames from 'classnames';
import { X } from './generated/icons';

export interface NotificationProps {
  className?: string;
  mainIcon: ReactNode;
  title: string;
  text: string;
  actions?: Action[];
  onClose: () => void;
}

export const Notification = ({
  className,
  mainIcon,
  title,
  text,
  actions,
  onClose,
}: NotificationProps) => (
  <div className={classNames('fixed top-0 z-20 w-full max-w-md p-3 mx-auto', className)}>
    <div className="flex p-4 space-x-2 rounded-lg bg-white shadow-l1">
      <div className="flex-shrink-0 w-6 h-6">{mainIcon}</div>

      <div className="flex-1 space-y-2 flex flex-col">
        <div className="space-y-1">
          <div className="text-h-16 text-accent-primary">{title}</div>
          <div className="text-p-14 text-dark-secondary/75">{text}</div>
        </div>

        {actions && actions.length && (
          <div className="flex space-x-3 flex-1">
            {actions.map((action, index) => (
              <ActionItem
                {...action}
                onClick={() => {
                  action.onClick?.();
                  onClose?.();
                }}
                key={index}
              />
            ))}
          </div>
        )}
      </div>

      <button className="flex-shrink-0 w-4 h-4 text-accent-primary" onClick={onClose}>
        <X />
      </button>
    </div>
  </div>
);

type ActionItemProps = Action;

const ActionItem = ({ text, href, onClick }: ActionItemProps) => {
  const classes = 'text-h-14 text-accent-primary';

  if (href) {
    return (
      <Link href={href}>
        <a className={classes}>{text}</a>
      </Link>
    );
  }

  return (
    <button className={classes} onClick={onClick}>
      {text}
    </button>
  );
};
