import { NewsletterBlockProps } from '@customTypes/storyblok-types';
import { RichText } from '@components/RichText';
import { FormFieldTextInput } from '@components/Form/Field/TextInput';
import { SubmitHandler, useForm } from 'react-hook-form';
import { ButtonPrimary } from '@components/Button/Primary';
import { StoryblokImageOrVideo } from '@components/Image/StoryblokImageOrVideo';
import classNames from 'classnames';
import { subscribe, SubscriptionList } from '@lib/newsletter';
import { NOTIFICATION, NOTIFICATION_DURATION, useNotifications } from '@lib/NotificationContext';
import { useTranslate } from '@tolgee/react';
import { useRouter } from 'next/router';
import { ArrowDown } from '@components/icons';
import SignatureHermannSprengerGermanyBlack from '@components/generated/logos/SignatureHermannSprengerGermanyBlack';
import { FormFieldCheckbox } from '@components/Form/Field/Checkbox';

type FormData = {
  email: string;
  listEquine: boolean;
  listDog: boolean;
  listBoat: boolean;
};

const NewsletterBlock = ({ blok }: { blok: NewsletterBlockProps }) => {
  const router = useRouter();
  const notifications = useNotifications();

  const {
    register,
    handleSubmit: validateForm,
    watch,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      listDog: true,
      listEquine: true,
      listBoat: true,
    },
  });

  const { t } = useTranslate();

  const showVerifyEmail = () =>
    notifications.setNotification({
      hideAfter: NOTIFICATION_DURATION.LONG,
      text: t('modals.newsletterSettings.confirm.content'),
      title: t('modals.newsletterSettings.confirm.title'),
      variant: NOTIFICATION.ALERT,
    });

  const handleSubmit: SubmitHandler<FormData> = async (data) => {
    if (/\S+@\S+\.\S+/.test(data.email)) {
      const isEverythingSelected = !data.listDog && !data.listBoat && !data.listEquine;
      await subscribe(data.email, 'newsletter', {
        listDog: isEverythingSelected || data.listDog,
        listBoat: isEverythingSelected || data.listBoat,
        listEquine: isEverythingSelected || data.listEquine,
      });
      await showVerifyEmail();

      // await router.push(`/customer/newsletter-settings?email=${data.email}`);
    }
  };

  const hasImage = !!blok.image?.filename;

  return (
    <div
      className={classNames(
        'flex bg-accent-off-white gap-6 md:min-h-[35.5rem] max-w-[1920px] mx-auto',
        hasImage
          ? blok.image_emphasis === 'right'
            ? 'flex-col-reverse md:flex-row md:items-stretch'
            : 'flex-col md:flex-row-reverse'
          : 'flex-col justify-evenly',
      )}
    >
      <div
        className={classNames(
          'p-3 flex gap-6 items-center mt-10 md:mt-14 md:px-10',
          hasImage ? 'md:w-1/3 flex-col' : 'justify-center flex-col md:flex-row',
        )}
      >
        <div className={classNames(!hasImage ? 'max-w-md' : 'space-y-6')}>
          <h1 className={classNames(hasImage ? 'text-center' : 'md:text-left text-center')}>
            {blok.title}
          </h1>
          <h5 className={classNames(hasImage ? 'text-center' : 'md:text-left text-center')}>
            <RichText text={blok.description} />
          </h5>
        </div>
        <form
          className={classNames('flex flex-col gap-4 flex-wrap w-full', hasImage ? '' : 'max-w-md')}
          onSubmit={validateForm(handleSubmit)}
        >
          <div className="space-y-2">
            <div className="text-accent-primary text-p-12">{t('newsletter.list.title')}</div>
            <div className="flex gap-8">
              <FormFieldCheckbox
                formRegister={register}
                errors={errors}
                name="listEquine"
                formWatch={watch}
              >
                {t('newsletter.list.equine')}
              </FormFieldCheckbox>
              <FormFieldCheckbox
                formRegister={register}
                errors={errors}
                name="listDog"
                formWatch={watch}
              >
                {t('newsletter.list.dog')}
              </FormFieldCheckbox>
              <FormFieldCheckbox
                formRegister={register}
                errors={errors}
                name="listBoat"
                formWatch={watch}
              >
                {t('newsletter.list.boat')}
              </FormFieldCheckbox>
            </div>
          </div>
          <FormFieldTextInput
            type="email"
            label="E-Mail-Adresse"
            formRegister={register}
            errors={errors}
            name="email"
            formWatch={watch}
          />
          <ButtonPrimary
            text={blok.button_cta}
            className="md:w-auto w-full"
            type="submit"
            iconPosition="right"
            Icon={ArrowDown}
            iconClass="transform rotate-[270deg]"
          />
        </form>
        {hasImage ? <SignatureHermannSprengerGermanyBlack className="w-48" /> : null}
      </div>
      {!hasImage ? <SignatureHermannSprengerGermanyBlack className="w-48 mx-auto mb-10" /> : null}
      {hasImage ? (
        <div className="relative md:w-2/3 w-full aspect-[23.43/18.18] md:aspect-auto">
          {/* @ts-ignore */}
          <StoryblokImageOrVideo {...blok.image} objectFit="cover" layout="fill" />
        </div>
      ) : null}
    </div>
  );
};

export default NewsletterBlock;
