import { ContentBlock9Props } from '@customTypes/storyblok-types';
import { EditorialSectionNine } from '@components/EditorialSection/Nine';
import { getImageDimensions } from '@lib/storyblokUtil';
import { storyblokEditable } from '@storyblok/react';

export const ContentBlock9 = ({ blok }: { blok: ContentBlock9Props }) => {
  if (blok.show) {
    return <EditorialSectionNine
      /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
      /* @ts-ignore*/
      {...storyblokEditable(blok)}
      image={
        blok.image.filename
          ? {
            dimensions: getImageDimensions(blok.image),
            ...blok.image,
          }
          : undefined
      }
      mobile_image={
        blok.mobile_image?.filename
          ? {
            dimensions: getImageDimensions(blok.mobile_image),
            ...blok.mobile_image,
          }
          : undefined
      }
      button={blok.button_text ? { text: blok.button_text, url: blok.button_link.url } : undefined}
      no_mobile_padding={blok.no_mobile_padding}
    />
  } else {
    return <></>
  }
};
