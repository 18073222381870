import { useLocale } from '@zustand/useLocale';
import { T, useTranslate } from '@tolgee/react';
import { CountryFlag } from '@components/Layout/Navbar';
import { ButtonPrimary } from '@components/Button/Primary';
import { X } from '@components/icons';
import { useEffect, useMemo, useState } from 'react';
import { shippingMarkets } from '@lib/shippingCountries';
import { currencyCodeToSymbol } from '@lib/utils';

type InfoPopupProps = {
  showAdvancedOptions: boolean;
  onClose: () => void;
  onChange: () => void;
};

export const InfoPopup = ({ showAdvancedOptions = true, onChange, onClose }: InfoPopupProps) => {
  const { locale, hasSavedLanguage, language } = useLocale();
  const [savedLanguage, setSavedLanguage] = useState(hasSavedLanguage());
  const currentCurrency = useMemo(() => {
    return shippingMarkets.find((market) => market.regions.nodes.find((n) => n.code === locale))
      ?.currencySettings.baseCurrency;
  }, [locale]);

  const { t } = useTranslate();
  const [localeName, setLocaleName] = useState<string>("Deutschland");

  useEffect(() => {
    setLocaleName(new Intl.DisplayNames(language, { type: "region" }).of(locale) ?? locale);
  }, [language, locale]);
  useEffect(() => {
    setSavedLanguage(hasSavedLanguage());
  }, [typeof window === 'undefined']);

  if (savedLanguage) {
    return null;
  }

  return (
    <div className="fixed md:top-40 top-64 md:right-12 md:left-auto max-w-[95%] left-1/2 transform -translate-x-1/2 md:translate-x-0 bg-white rounded-md p-4 shadow-l3 z-40 flex">
      <div className="w-10 h-7 mr-2">
        <CountryFlag country={locale.toLowerCase()} />
      </div>
      <div className="flex-1 w-80">
        <div className="text-h-16">
          {t('shipping.country.destination.prefix')} {localeName}
        </div>
        <div className="text-p-14">
          <T
            keyName="shipping.country.description"
            params={{ b: <b />, country: localeName }}
          />
          <ul className="list-disc my-2 ml-4">
            <T
              keyName="shipping.country.bullets"
              params={{
                li: <li />,
                country: t(`country.${locale}`),
                currency: `${currentCurrency?.currencyCode} ${currencyCodeToSymbol(
                  currentCurrency?.currencyCode ?? 'EUR',
                )}`,
              }}
            />
          </ul>
          <button onClick={() => onChange()} className="text-left">
            <T keyName="shipping.country.change-option-here" params={{ u: <u /> }} />
          </button>
        </div>
        {showAdvancedOptions ? (
          <>
            <ButtonPrimary
              onClick={onClose}
              text={t('shipping.country.continue')}
              className="w-full mt-5 mb-3"
            />
            <button
              onClick={() => onChange()}
              className="underline text-accent-primary w-full text-center"
            >
              {t('shipping.country.change')}
            </button>
          </>
        ) : null}
      </div>
      <button onClick={() => onClose()} className="w-4 h-4">
        <X />
      </button>
    </div>
  );
};
