import { create } from 'zustand';

type NavMenuFAQState = {
  openNavMenuFAQ: boolean;
  setOpenNavMenuFAQ: (openNavMenuFAQ: boolean) => void;
};

export const useNavMenuFAQZustand = create<NavMenuFAQState>((set) => ({
  openNavMenuFAQ: false,
  setOpenNavMenuFAQ: (openNavMenuFAQ) => set((state) => ({ ...state, openNavMenuFAQ })),
}));
