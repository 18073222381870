import { T, useTranslate } from '@tolgee/react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { FormFieldTextInput } from '@components/Form/Field/TextInput';
import { Facebook, Instagram, YouTube } from '@components/generated/icons';
import Link from 'next/link';
import { FormFieldCheckbox } from '@components/Form/Field/Checkbox';

type NewsletterSignUpProps = {
  onSignUp: (formData: NewsLetterSignUpInput) => void;
};

export type NewsLetterSignUpInput = {
  newsLetterEmail: string;
  listEquine: boolean;
  listDog: boolean;
  listBoat: boolean;
};

type SocialMediaListProps = {
  title: string;
  textClassName: string;
  instagramLink?: string;
  facebookLink?: string;
  youtubeLink?: string;
};

/* DOGSPORTS:

IG: https://www.instagram.com/sprenger_dogsports/?hl=de

FB: https://www.facebook.com/sprengerdogsports

YT: https://www.youtube.com/channel/UCxqqFUAtie51LIJSojw1yRg

EQUITATION:

IG: https://www.instagram.com/sprenger_equestrian/?hl=de

FB:

YT: https://www.youtube.com/channel/UCxqqFUAtie51LIJSojw1yRg

BOATSPORTS:

IG: https://www.instagram.com/sprenger_boatsports/?hl=de

FB:

YT: https://www.youtube.com/channel/UCxqqFUAtie51LIJSojw1yRg

*/

const SocialMediaList = ({
  title,
  textClassName,
  instagramLink,
  facebookLink,
  youtubeLink,
}: SocialMediaListProps) => (
  <div className={textClassName}>
    <span>{title}</span>
    <div className="flex gap-2 items-center mt-1">
      {instagramLink && (
        <Link href={instagramLink} passHref>
          <a>
            <Instagram height={24} width={24} className="hover:text-white hover:cursor-pointer" />
          </a>
        </Link>
      )}
      {facebookLink && (
        <Link href={facebookLink} passHref>
          <a>
            <Facebook height={18} width={18} className="hover:text-white hover:cursor-pointer" />
          </a>
        </Link>
      )}
      {youtubeLink && (
        <Link href={youtubeLink} passHref>
          <a>
            <YouTube height={24} width={24} className="hover:text-white hover:cursor-pointer" />
          </a>
        </Link>
      )}
    </div>
  </div>
);

const NewsletterSignUp = ({ onSignUp }: NewsletterSignUpProps) => {
  const { t } = useTranslate();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<NewsLetterSignUpInput>({
    defaultValues: {
      listDog: true,
      listEquine: true,
      listBoat: true,
    }
  });
  const onSubmit: SubmitHandler<NewsLetterSignUpInput> = onSignUp;

  return (
    <div className="grid md:grid-cols-3 mt-1 md:mt-0 gap-y-5 lg:-mb-4 relative lg:min-w-md min-w-sm">
      <label htmlFor="inputNewsletterEmail" className="flex flex-col w-full md:flex-row">
        <div className="text-h-16 mr-6 self-auto flex-none md:self-center">
          <T keyName="newsletter.subscribe" />
        </div>
      </label>
      <form className="w-72 -mt-6 md:mt-0 pr-2 col-span-2 md:grow" onSubmit={handleSubmit(onSubmit)}>
        <div className="space-y-2 mb-5">
          <div className="text-p-12">{t('newsletter.list.title')}</div>
          <div className="flex gap-8">
            <FormFieldCheckbox
              formRegister={register}
              errors={errors}
              name="listEquine"
              formWatch={watch}
            >
             <span className="text-white"> {t('newsletter.list.equine')}</span>
            </FormFieldCheckbox>
            <FormFieldCheckbox
              formRegister={register}
              errors={errors}
              name="listDog"
              formWatch={watch}
            >
             <span className="text-white"> {t('newsletter.list.dog')}</span>
            </FormFieldCheckbox>
            <FormFieldCheckbox
              formRegister={register}
              errors={errors}
              name="listBoat"
              formWatch={watch}
            >
             <span className="text-white"> {t('newsletter.list.boat')}</span>
            </FormFieldCheckbox>
          </div>
        </div>
        <div id="inputNewsletterEmail" className="relative">
          <FormFieldTextInput
            className="mt-6 md:mt-0"
            label={t('customer.email')}
            type="email"
            name="newsLetterEmail"
            formRegister={register}
            showChevron={true}
            formWatch={watch}
            errors={errors}
            required
          />
        </div>
      </form>
      <div className="text-h-16 mt-6">
        <span>
          <T keyName="newsletter.followUs" />
        </span>
      </div>
      <div className="flex md:flex-row flex-wrap flex-col text-h-16 gap-5 col-span-2">
        <SocialMediaList
          facebookLink="https://www.facebook.com/sprengerdogsports"
          instagramLink="https://www.instagram.com/sprenger_dogsports/?hl=de"
          youtubeLink="https://www.youtube.com/channel/UCxqqFUAtie51LIJSojw1yRg"
          title="Dogsports"
          textClassName="text-special-dog"
        />
        <SocialMediaList
          instagramLink="https://www.instagram.com/sprenger_equestrian/?hl=de"
          youtubeLink="https://www.youtube.com/channel/UCxqqFUAtie51LIJSojw1yRg"
          title="Equestrian"
          textClassName="text-special-horse"
        />
        <SocialMediaList
          instagramLink="https://www.instagram.com/sprenger_boatsports/?hl=de"
          youtubeLink="https://www.youtube.com/channel/UCxqqFUAtie51LIJSojw1yRg"
          title="Boatsports"
          textClassName="text-special-boat"
        />
      </div>
    </div>
  );
};

export default NewsletterSignUp;
