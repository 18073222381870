import { storyblokEditable } from '@storyblok/react';

export interface FaqListBlok {
  faqs: Array<{ question: string; answer: string }>;
}

export const FaqList: React.FC<{ blok: FaqListBlok }> = ({ blok }) => (
  <div
    /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
    /* @ts-ignore*/
    {...storyblokEditable(blok)}
    className="mt-12 lg:mt-0 lg:col-span-2"
  >
    <dl className="space-y-12">
      {blok?.faqs?.map((faq) => (
        <div key={faq.question}>
          <dt className="text-lg font-medium leading-6 text-gray-900">{faq.question}</dt>
          <dd className="mt-2 text-base text-gray-500">{faq.answer}</dd>
        </div>
      ))}
    </dl>
  </div>
);
