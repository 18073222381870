import { LoadingIndicator } from '@components/LoadingIndicator';
import { NavMenuCollectionScroller } from './Scroller';
import { NavMenu } from '..';
import { useNavMenuCollectionZustand } from '@zustand/navMenuCollection';
import { CollectionItemQuery, CollectionItemQueryVariables } from '@generated/graphql/types';
import { CollectionTree, useCollectionTreeZustand } from '@zustand/shopData/collectionTree';
import { useEffect, useState } from 'react';
import { initializeApollo } from '@graphql/apollo-client';
import { COLLECTION_ITEM } from '@graphql/queries/collections';
import { useLocale } from '@zustand/useLocale';
import { ensureLanguage } from '@lib/utils';

/**
 * Sort Collections by the position metafield.
 * Ascending. Lowest position value will be shown first.
 */
const sortCollectionByPositionAsc = (
  a: CollectionItemQuery['collection'],
  b: CollectionItemQuery['collection'],
): number => {
  const posA = parseInt(a?.position?.value || '', 10) || 0;
  const posB = parseInt(b?.position?.value || '', 10) || 0;

  return posA - posB;
};

const usePrefetchCollection = (collections?: CollectionTree) => {
  const [results, setResults] = useState<Array<null> | Array<CollectionItemQuery['collection']>>(
    Array.from({ length: collections?.items?.length ?? 0 }).map(() => null),
  );
  const { language, locale } = useLocale();

  const client = initializeApollo(locale);

  useEffect(() => {
    if (!collections?.items) {
      if (collections?.link) {
        client
          .query<CollectionItemQuery, CollectionItemQueryVariables>({
            query: COLLECTION_ITEM,
            variables: {
              handle: collections.link,
              language: ensureLanguage(language),
              country: ensureLanguage(locale),
            },
          })
          .then(({ data, errors, error }) => {
            if (!data) {
              throw errors || error;
            }
            setResults([
              { ...data.collection, position: { ...data.collection?.position, value: '0' } },
            ] as typeof results);
          })
          .catch((err) => console.error('Failed to fetch collection', err));
      }
      return;
    }
    for (let i = 0; i < collections.items.length; i++) {
      const collection = collections.items[i];
      client
        .query<CollectionItemQuery, CollectionItemQueryVariables>({
          query: COLLECTION_ITEM,
          variables: {
            handle: collection.link,
            language: ensureLanguage(language),
            country: ensureLanguage(locale),
          },
        })
        .then(({ data, errors, error }) => {
          if (!data) {
            throw errors || error;
          }
          setResults((last) => {
            last[i] = data.collection;
            return last;
          });
        })
        .catch((err) => console.error('Failed to fetch collection', err));
    }
  }, [JSON.stringify(collections)]);

  return results;
};

export const NavMenuCollection = () => {
  const tree = useCollectionTreeZustand();
  const neuheitenCollection = tree.items?.find(
    (collection) => collection.link === 'neuheiten-root',
  );
  const saleCollection = tree.items?.find((collection) => collection.link === 'sale-root');
  const specialCollections = tree.items?.find(
    (collection) => collection.link === 'kollektionen-root',
  );
  const dogsportsCollection = tree.items?.find(
    (collection) => collection.link === 'hundesport-root',
  );
  const boatsportsCollection = tree.items?.find(
    (collection) => collection.link === 'bootsport-root',
  );
  const equestrianCollection = tree.items?.find(
    (collection) => collection.link === 'pferdesport-root',
  );

  const collections = usePrefetchCollection(specialCollections);
  const dogCollections = usePrefetchCollection(dogsportsCollection);
  const neuheitenCollections = usePrefetchCollection(neuheitenCollection);
  const saleCollections = usePrefetchCollection(saleCollection);
  const boatCollections = usePrefetchCollection(boatsportsCollection);
  const equestrianCollections = usePrefetchCollection(equestrianCollection);

  const { openNavMenuCollection, setOpenNavMenuCollection } = useNavMenuCollectionZustand();

  const closeMenu = () => {
    setOpenNavMenuCollection(false);
  };

  return (
    <NavMenu onClose={() => setOpenNavMenuCollection(false)} drawerShow={openNavMenuCollection}>
      <div className="flex items-center justify-center w-full">
        {[
          ...neuheitenCollections,
          ...saleCollections,
          ...collections,
          ...equestrianCollections,
          ...dogCollections,
          ...boatCollections,
        ]?.length ? (
          <NavMenuCollectionScroller
            items={(
              [
                ...neuheitenCollections,
                ...saleCollections,
                ...collections,
                ...equestrianCollections,
                ...dogCollections,
                ...boatCollections,
              ].filter(
                (e) =>
                  ((e && e.includeInMenu?.value === '1') ||
                    e?.handle === 'neuheiten-root' ||
                    e?.handle === 'sale-root') &&
                  !(
                    (e?.handle.startsWith('hundesport') ||
                      e?.handle.startsWith('pferdesport') ||
                      e?.handle.startsWith('bootsport')) &&
                    (e?.handle.endsWith('-neuheiten') || e?.handle.endsWith('-sale'))
                  ),
              ) as Array<NonNullable<CollectionItemQuery['collection']>>
            )
              .sort((a, b) => sortCollectionByPositionAsc(a, b))
              .map(({ title, handle, image, placeholderImages, id }) => ({
                handle,
                id,
                image:
                  handle === 'neuheiten-root'
                    ? dogCollections.find((c) => c?.handle === 'hundesport-neuheiten')?.image
                    : handle === 'sale-root'
                    ? dogCollections.find((c) => c?.handle === 'hundesport-sale')?.image
                    : image
                    ? image
                    : undefined,
                onClick: () => closeMenu(),
                placeholderImage:
                  handle === 'neuheiten-root'
                    ? dogCollections.find((c) => c?.handle === 'hundesport-neuheiten')
                        ?.placeholderImages
                    : handle === 'sale-root'
                    ? dogCollections.find((c) => c?.handle === 'hundesport-sale')?.placeholderImages
                    : placeholderImages
                    ? placeholderImages
                    : undefined,
                title,
              }))}
          />
        ) : (
          <LoadingIndicator />
        )}
      </div>
    </NavMenu>
  );
};
