import { useEffect, useMemo, useState } from 'react';
import { ISbRichtext, renderRichText, storyblokEditable } from '@storyblok/react';
import classNames from 'classnames';
import { X } from '@components/icons';
import { useLocale } from '@zustand/useLocale';
import { useDiscountBarDismissZustand } from '@zustand/discountBar';

interface StoryDataContent {
  data: {
    stories: {
      published_at: string;
      content: {
        _uid: string;
        content: ISbRichtext;
      };
    };
  };
}

const LAST_DISCOUNT_UID = 'lastDiscountUID';

export const DiscountBar = () => {
  const [storyData, setStoryData] = useState<StoryDataContent | null>(null);
  const { isDismissed, setDismissed } = useDiscountBarDismissZustand();
  const richTextData = useMemo(
    () => (storyData ? renderRichText(storyData?.data.stories.content.content) : null),
    [storyData],
  );
  const { locale, language } = useLocale();

  useEffect(() => {
    try {
      fetch('/api/storyblok/retrieve', {
        body: JSON.stringify({
          lang: language.toLowerCase(),
          path: `static/${locale.toLowerCase()}/discount-bar`,
          slugs: undefined,
        }),
        method: 'POST',
      })
        .then((res) => res.json() as unknown as StoryDataContent)
        .then((res) => {
          if (
            res.data.stories.content &&
            `${res?.data.stories.content._uid}-${res?.data.stories.published_at}` !==
              localStorage.getItem(LAST_DISCOUNT_UID)
          ) {
            setStoryData(res);
          } else {
            setStoryData(null);
          }
        })
        .catch(() => {});
    } catch (e) {
      console.log('Error fetching discount bar', e);
    }
  }, [locale, language]);

  // Empty discount bar field equals <p></p>
  if (!storyData || isDismissed || richTextData?.replaceAll(/[\n\r\s\t]*/g, '') === '<p></p>') {
    return null;
  }

  return (
    <div
      // @ts-ignore
      {...storyblokEditable(storyData)}
      className="w-full min-h-[42px] bg-special-dog text-white relative flex px-4 gap-1 items-center justify-between"
    >
      <div className="w-4 h-4" />
      <div className="grid place-items-center h-full">
        {richTextData ? (
          <div
            dangerouslySetInnerHTML={{ __html: richTextData }}
            className={classNames('rich-text text-center')}
          />
        ) : null}
      </div>
      <X
        data-test="staging-banner-dismiss"
        onClick={() => {
          setDismissed(true);
          localStorage.setItem(
            LAST_DISCOUNT_UID,
            `${storyData?.data.stories.content._uid}-${storyData?.data.stories.published_at}`,
          );
        }}
        className="flex-0 w-4 h-4 text-white"
      />
    </div>
  );
};
