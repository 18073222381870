import { CollectionTree } from '@zustand/shopData/collectionTree';
import { ExhaustiveProduct } from '@hooks/useSearch';
import { StringFilter } from '@lib/search';
import {
  CartDiscountAllocation,
  CollectionsQuery,
  CountryCode,
  CurrencyCode,
  LanguageCode,
  MoneyV2,
  ProductHandlesQuery,
} from '@generated/graphql/types';
import { ProductDetails } from './gtm/types';
import { base64Decode } from '@lib/base64';

type MatchResult = {
  number: string;
  unit?: string;
};

type EdgeNodes<E> = {
  edges: Array<{ node: E }>;
};

export function getLevelAbove(
  topTree: CollectionTree,
  subTree: CollectionTree,
  topLevelHandle: string,
): CollectionTree | null | undefined {
  if (subTree.title === '' || subTree.link === topLevelHandle) {
    return null;
  }
  const possible = topTree.items?.find((sub) => sub.link === subTree.link);
  if (possible) {
    return topTree;
  }
  return topTree.items?.map((sub) => getLevelAbove(sub, subTree, topLevelHandle)).find((s) => !!s);
}

export function getNestedTree(tree: CollectionTree, link: string): CollectionTree | undefined {
  if (tree.link === link) {
    return tree;
  }
  const tryFind = tree.items?.map((sub) => getNestedTree(sub, link));
  return tryFind?.find((s) => !!s);
}

export function getCollectionNesting(tree: CollectionTree, link: string): number {
  if (tree.link === link) {
    return 0;
  }
  if (!tree.items) {
    return -1;
  }
  const possibleResults = tree.items.map((nestedTree) => {
    const nesting = getCollectionNesting(nestedTree, link);
    if (nesting === -1) {
      return nesting;
    }
    return nesting + 1;
  });
  const result = possibleResults.find((s) => s > 0);
  if (!result) {
    return -1;
  }
  return result;
}

export function getFiltersFromTreeNesting(
  nesting: number,
  currentTree: CollectionTree,
  maxTree: CollectionTree,
) {
  if (nesting === -1) {
    return [];
  }

  let lastTree = currentTree;
  const subCollections = Array.from({ length: nesting })
    .map((_, i) => {
      if (i === nesting) {
        return null;
      }
      const nester = Array.from({ length: nesting - i - 1 })
        .map(() => '.sub')
        .join('');
      const levelUp = i === 0 ? lastTree : getLevelAbove(maxTree, lastTree, '');
      if (!levelUp) {
        throw new Error('Couldnt find level above');
      }
      const filter = new StringFilter(
        'collection',
        `collections${nester}.name`,
        levelUp.title,
        true,
      );
      lastTree = levelUp;
      return filter;
    })
    .filter((s) => !!s) as StringFilter[];
  return subCollections;
}

export async function sleep(ms = 0) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function retry<E>(fn: Promise<E>, retries = 3, timeout = 1000): Promise<E> {
  return fn.catch((e) => {
    if (retries > 0) {
      return sleep(timeout).then(() => retry(fn, retries - 1));
    }
    throw e;
  });
}

export function mapToNode<E>(nodes: EdgeNodes<E> | null | undefined): E[] {
  if (!nodes?.edges) {
    return [];
  }
  return nodes.edges.map(({ node }) => node);
}

export const combineFilters = (...filters: (string | null | undefined)[]) =>
  filters.filter((item) => (item?.length ?? 0) > 1).join(' AND ');

type IsEqualFn<T> = (a: T, b: T) => boolean;

export type SingleProductOption = {
  name: string;
  value: string;
};

export const matchesProductOptions = (
  first: SingleProductOption[],
  second: SingleProductOption[],
) => {
  for (const { name, value } of first) {
    const fromSecond = second.find((item) => item.name === name && item.value === value);
    if (!fromSecond) {
      return false;
    }
  }
  return true;
};

/**
 * Whether the app runs in stg mode.
 */
export function isStg(): boolean {
  return (
    process.env.NEXT_PUBLIC_ENVIRONMENT === 'b2c_staging' ||
    process.env.NEXT_PUBLIC_ENVIRONMENT === 'b2b_staging'
  );
}

/**
 * Whether the app runs in prod mode.
 */
export function isProd(): boolean {
  return (
    process.env.NEXT_PUBLIC_ENVIRONMENT === 'b2c' || process.env.NEXT_PUBLIC_ENVIRONMENT === 'b2b'
  );
}

/**
 * Revalidation time for static pages.
 * 30 min for prod, 5 sec for stg.
 */
export function getValidationTime(): number {
  return (process.env.NEXT_PUBLIC_ENVIRONMENT !== 'b2c' &&
    process.env.NEXT_PUBLIC_ENVIRONMENT !== 'b2b') ||
    process.env.STORYBLOK_PREVIEW_MODE === 'true'
    ? 5
    : 60 * 30;
}

/**
 * Whether the app is in B2B mode.
 */
export function isB2B(): boolean {
  return (
    process.env.NEXT_PUBLIC_PLATFORM === 'B2B' ||
    (typeof window !== 'undefined' && localStorage.getItem('b2b') === 'true')
  );
}

export function removeZeroWidthSpace(str: string): string {
  if (!str) {
    return '';
  }
  return str.replace(/[\u200B-\u200D\uFEFF]/g, '');
}

/**
 * Removes duplicate elements from an array.
 *
 * If no comparator is provided, the default comparator is used which checks
 * for reference equality.
 * @param items The array to remove duplicates from.
 * @param comparator A comparator function.
 */
export function deduplicate<E>(items: E[], comparator: IsEqualFn<E> = (i1, i2) => i1 === i2): E[] {
  return items.reduce((acc, curr) => {
    const item = acc.findIndex((e) => comparator(e, curr));
    if (item === -1) {
      acc.push(curr);
    }
    return acc;
  }, [] as E[]);
}

/**
 * Returns the shopify id of the base64 encoded id
 * @param base The id of the product.
 */
export function getProductIdFromBase64Id(base: string): string {
  return (base).replace('gid://shopify/Product/', '');
}

/**
 * Returns the shopify id of the base64 encoded id
 * @param base The id of the line.
 * @deprecated
 */
export function getLineIdFromBase64Id(base: string): string {
  return base64Decode(base)
    .replace('gid://shopify/CartLine/', '')
    .replace(/\?cart=(.)*/, '');
}

/**
 * Returns the shopify id of the base64 encoded id
 * @param base The id of the variant.
 */
export function getVariantIdFromShopifyUri(base: string): string {
  return base.replace('gid://shopify/ProductVariant/', '');
}

/**
 * Returns the shopify id of the base64 encoded id
 * @param base The id of the cart.
 */
export function getCartIdFromShopifyUri(base: string): string {
  return base.replace('gid://shopify/Cart/', '');
}

/**
 * Returns the shopify id of the base64 encoded id
 * @param base The id of the order.
 */
export function getOrderIdFromShopifyUri(base: string): string {
  return base.replace('gid://shopify/Order/', '').split('?')[0];
}

/**
 * Returns if the element is currently in viewport. Only checks y axis.
 * @param el The HTMLElement to check.
 * @returns true if in viewport, false otherwise.
 */
export function isElementInViewport(el: HTMLElement) {
  const rect = el.getBoundingClientRect();
  const windowHeight = window.innerHeight || document.documentElement.clientHeight;

  return rect.top <= windowHeight && rect.top + rect.height >= 0;
}

/**
 * Triggers the callback when the element is in viewport.
 * @param target The HTMLElement to watch.
 * @param callback The callback to trigger.
 */
export const observeInViewport = (
  target: HTMLElement,
  callback: (isInViewport: boolean) => void,
  threshold = 0,
) =>
  new IntersectionObserver(
    (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          callback(true);
        } else if (!entry.isIntersecting) {
          callback(false);
        }
      });
    },
    { threshold },
  ).observe(target);

/**
 * Triggers the callback when the element is in viewport.
 * @param targets The HTML elements to watch.
 * @param callback The callback to trigger.
 */
export const getNumberOfItemsViewed = (
  targets: Array<HTMLElement>,
  callback: (numberOfElementsInViewport: number) => void,
  threshold = 0.8,
) => {
  let numberOfElementsInViewport = 0;
  targets.forEach((target) => {
    let wasInViewport = false;
    new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting && !wasInViewport) {
            wasInViewport = true;
            numberOfElementsInViewport++;
            callback(numberOfElementsInViewport);
          }
        });
      },
      { threshold },
    ).observe(target);
  });
};

export const image_urlsAsArray = (image_urls?: string | null) =>
  image_urls?.startsWith('https://')
    ? [image_urls]
    : (JSON.parse(image_urls ?? '[]') as Array<string>);

export const matchNumberAndUnit = (stringToMatch: string) => {
  const numberMatcher =
    /[+-]?(?<number>\d+(?:[.,]\d+)?(\s[x]\s)?(\d+(?:[.,]\d+)?)?)\s*(?<unit>\w+)?/;
  return stringToMatch.match(numberMatcher)?.groups as MatchResult;
};

/** Clamps the value to min and max. */
export function clamp(value: number, min: number, max: number) {
  return Math.min(Math.max(value, min), max);
}

/**
 * Returns a function, that, as long as it continues to be invoked, will not be triggered.
 * @param func The function to debounce.
 * @param delay The time to wait before calling the function.
 */
// eslint-disable-next-line space-before-function-paren
export function debounce<T extends (...args: any) => any>(
  func: (...args: Parameters<T>) => unknown,
  delay = 200,
): { func: typeof func; cleanup: () => void } {
  let timeout: number | NodeJS.Timeout;
  return {
    cleanup: () => clearTimeout(timeout as number),
    func: (...args: Parameters<T>) => {
      clearTimeout(timeout as number);
      timeout = setTimeout(() => func(...args), delay);
    },
  };
}

export function isEqual(obj1: unknown, obj2: unknown): boolean {
  if (obj1 === obj2) {
    return true;
  }

  if (obj1 == null || obj2 == null) {
    return false;
  }

  if (typeof obj1 !== 'object' || typeof obj2 !== 'object') {
    return false;
  }

  const obj1Keys = Object.keys(obj1);
  const obj2Keys = Object.keys(obj2);

  if (obj1Keys.length !== obj2Keys.length) {
    return false;
  }

  for (const key of obj1Keys) {
    if (!obj2Keys.includes(key) || !isEqual((obj1 as any)[key], (obj2 as any)[key])) {
      return false;
    }
  }

  return true;
}

export function transformExhaustiveToShopify(node: ExhaustiveProduct) {
  const hasNewestPriceData = node.compareAtPriceRange && node.priceRange;

  return {
    node: {
      ...node,
      collections: { edges: node.collections.map(({ name }) => ({ node: { title: name } })) },
      compareAtPriceRange: {
        __typename: 'ProductPriceRange',
        maxVariantPrice: {
          amount:
            (hasNewestPriceData ? node.compareAtPriceRange.maxVariantPrice : node.compareAtPrice) /
            100,
          currencyCode: 'EUR',
        },
        minVariantPrice: {
          amount:
            (hasNewestPriceData ? node.compareAtPriceRange.minVariantPrice : node.compareAtPrice) /
            100,
          currencyCode: 'EUR',
        },
      },
      // variantId: node.sku,
      handle: node.parentHandle,
      image_urls: {
        value: JSON.stringify(node.imageUrls),
      },
      priceRange: {
        __typename: 'ProductPriceRange',
        maxVariantPrice: {
          amount: (hasNewestPriceData ? node.priceRange.maxVariantPrice : node.price) / 100,
          currencyCode: 'EUR',
        },
        minVariantPrice: {
          amount: (hasNewestPriceData ? node.priceRange.minVariantPrice : node.price) / 100,
          currencyCode: 'EUR',
        },
      },
      title: node.parentTitle,
    },
  };
}

export const sumMoney: (...money: (MoneyV2 | undefined | null)[]) => MoneyV2 = (
  ...money: (MoneyV2 | undefined | null)[]
) => {
  if (money.length === 0) {
    throw new Error('No money to sum');
  }
  let sum;
  for (let i = 0; i < money.length; i++) {
    const element = money[i];

    if (!element) {
      continue;
    }

    if (!sum) {
      sum = element;
      continue;
    }

    if (element.currencyCode !== sum.currencyCode) {
      throw new Error('Cannot sum different currencies');
    }
    sum = {
      amount: Number(sum.amount) + Number(element.amount),
      currencyCode: sum.currencyCode,
    };
  }

  return (
    sum ?? {
      amount: 0,
      currencyCode: CurrencyCode.Eur,
      __typename: 'MoneyV2',
    }
  );
};

export const firstLevelEquals = <E>(first: E[], second: E[]) => {
  if (first.length !== second.length) {
    return false;
  }
  for (let i = 0; i < first.length; i++) {
    if (first[i] !== second[i]) {
      return false;
    }
  }
  return true;
};

export const isEmptyDiscount = (discount: CartDiscountAllocation) =>
  Number(discount.discountedAmount.amount) === 0;

export const subtractMoney = (...money: MoneyV2[]) => {
  if (money.length === 0) {
    throw new Error('No money to sum');
  }
  let sum = money[0];
  for (let i = 1; i < money.length; i++) {
    const element = money[i];
    if (element.currencyCode !== sum.currencyCode) {
      throw new Error('Cannot sum different currencies');
    }
    sum = {
      amount: Number(sum.amount) - Number(element.amount),
      currencyCode: sum.currencyCode,
    };
  }
  return sum;
};

export const moneyToQuantity = (money: MoneyV2, quantity: number) => {
  if (quantity === 0 || Math.ceil(quantity) !== quantity) {
    throw new Error(`We need a number >0 and a full value. Got \`${quantity}\``);
  }
  return {
    ...money,
    amount: roundPriceToShopify(Number(money.amount) * quantity),
  };
};

/**
 * Checks if the prop is a color prop using regex
 * @param name the name of the prop to check against
 */
export const isColorProp = (name: string) => /\b[Ff]arbe|[Ff]arbe|[Cc]olor\b/gm.test(name);

const roundPriceToShopify = (input: number) => Math.ceil(input * 100) / 100;
export const roundMoneyV2 = (input: MoneyV2) => {
  const priceRounded = roundPriceToShopify(Number(input.amount));
  return {
    ...input,
    amount: priceRounded,
  };
};

export function ensureLanguage(code: LanguageCode): LanguageCode;
export function ensureLanguage(code: CountryCode): CountryCode;
export function ensureLanguage<E = LanguageCode | CountryCode>(code: E): E {
  return (code as string).toUpperCase() as E;
}

/**
 * Calculates the new price after applying the discount
 *
 * This function rounds the last digit up like shopify eg.
 * 15.121 -> 15.13
 * @param price the original price
 * @param percentageOff the discount amount in %
 */
export const getPriceAfterDiscount = (price: number, percentageOff: number) => {
  const calculatedPrice = price * (1 - percentageOff / 100);
  // Shopify Rounds the cent price up, so we do it too :)
  return roundPriceToShopify(calculatedPrice);
};

/**
 * Sort Collections by the position metafield.
 * Descending. Highest position value will be shown first.
 */
export const sortCollectionByPosition = (
  a: ArrayElementOf<CollectionsQuery['collections']['edges']>['node'],
  b: ArrayElementOf<CollectionsQuery['collections']['edges']>['node'],
): number => {
  const posA = parseInt(a.position?.value || '', 10) || 0;
  const posB = parseInt(b.position?.value || '', 10) || 0;
  return posB - posA;
};

export function getBrancheFromCollections(
  collections?: NonNullable<
    ArrayElementOf<ProductHandlesQuery['products']['edges']>
  >['node']['collections'],
): ProductDetails['branche'] {
  return collections?.edges.some((e) => e.node.title === 'Pferdesport')
    ? 'equine-sport'
    : collections?.edges.some((e) => e.node.title === 'Bootsport')
    ? 'boat-sport'
    : collections?.edges.some((e) => e.node.title === 'Hundesport')
    ? 'dog-sport'
    : '';
}
export const stringToHashedNumber = (s: string) =>
  s.split('').reduce((a, b) => ((a << 5) - a + b.charCodeAt(0)) | 0, 0);

export async function sha256(text: string) {
  const textAsBuffer = new TextEncoder().encode(text);
  const hashBuffer = await window.crypto.subtle.digest('SHA-256', textAsBuffer);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const digest = hashArray.map((b) => b.toString(16).padStart(2, '0')).join('');
  return digest;
}

export function languageCodeToCountryCode(language: string): string {
  switch (language.toLowerCase()) {
    case 'de':
      return 'de';
    case 'en':
      return 'gb';
    default:
      return CountryCode.De;
  }
}

export function currencyCodeToSymbol(currency: string) {
  return new Intl.NumberFormat('en-UK', { style: 'currency', currency })
    .format(0)
    .replace(/(\d|\.|\,)*/g, '');
}

export function getCookie(cname: string): string | null {
  if (typeof document === 'undefined') return null;
  let name = cname + '=';
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
}

export function setCookie(cname: string, cvalue: string, exdays = 365) {
  const d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  let expires = 'expires=' + d.toUTCString();
  document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
}
