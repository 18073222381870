import { ChevronRight } from '@components/generated/icons';
import { SliderArrow, SliderArrowProps } from '.';
import classNames from '@utils/ClassNames';

type SliderArrowNextProps = { className?: string } & Pick<SliderArrowProps, 'onClick'>;

export const SliderArrowNext = ({ onClick, className }: SliderArrowNextProps) => (
  <SliderArrow
    onClick={onClick}
    className={classNames(className, 'ml-2')}
    ArrowIcon={ChevronRight}
  />
);
