/* eslint-disable jsx-a11y/click-events-have-key-events */
import { ChevronRight } from '@components/icons';
import classNames from 'classnames';
import Link, { LinkProps } from 'next/link';
import {
  AnchorHTMLAttributes,
  ButtonHTMLAttributes,
  DetailedHTMLProps,
  ReactNode,
  Ref,
} from 'react';
import { B2BLinkLocation, PostHogEvent, track } from '@lib/posthog';

export type NavListItemProps = {
  text: string;
  directory?: boolean;
  className?: string;
} & Omit<ContainerProps, 'children' | 'className'>;

export const NavListItem = ({ className, directory, text, ...buttonProps }: NavListItemProps) => (
  <Container
    onMouseUp={() => {
      (document.activeElement as HTMLElement).blur();
    }}
    className={classNames(
      'flex space-x-2 items-center justify-between py-3 -ml-3 px-3 outline-none hover:underline focus:outline focus:outline-accent-primary active:outline-accent-primary_02',
      className,
    )}
    {...buttonProps}
  >
    <span>{text}</span>
    {directory && <ChevronRight className="w-6 h-6" />}
  </Container>
);

type ContainerProps = { children: ReactNode } & DetailedHTMLProps<
  ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
> &
  DetailedHTMLProps<AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement> &
  Partial<LinkProps>;

const Container = (props: ContainerProps) => {
  const { children, href, className, onClick } = props;

  if (href) {
    return (
      <Link
        href={href}
        {...props}
        onClick={undefined}
        ref={props.ref as Ref<HTMLAnchorElement> | undefined}
      >
        <a
          role="button"
          tabIndex={0}
          className={className}
          target={props.href?.includes('https://sprenger.de') ? '_blank' : undefined}
          onClick={
            props.href?.includes('https://sprenger.de')
              ? () =>
                  track(PostHogEvent.B2BLinkClicked, {
                    location: B2BLinkLocation.CollectionMenuDrawer,
                  })
              : onClick
          }
        >
          {children}
        </a>
      </Link>
    );
  }

  return (
    <button
      className={className}
      area-hidden="true"
      {...props}
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        props.onClick?.(e);
      }}
    >
      {children}
    </button>
  );
};
