import { useState } from 'react';
import Image, { ImageProps } from 'next/image';
import { ImageFallback } from './Fallback';

export const ImageWithFallback = (imageProps: ImageProps) => {
  const { src } = imageProps;
  const [fallback, setFallback] = useState<boolean>(!src);

  if (fallback || !src) {
    return <ImageFallback width={imageProps.width} height={imageProps.height} />;
  }
  return <Image {...imageProps} alt={imageProps.alt ?? ''} onError={() => setFallback(true)} />;
};
