import { LANGUAGE_AND_REGION_COOKIE_NAME } from '@lib/constants';
import { create } from 'zustand';
import { getCookie, setCookie } from '@lib/utils';
import { CountryCode, LanguageCode } from '../generated/graphql/types';
import { CountryTax } from '../pages/api/tax/[code]';

type LocaleZustand = {
  language: LanguageCode;
  locale: CountryCode;
  tax: CountryTax;
  setLang(lang: LanguageCode): void;
  setLocale(loc: CountryCode): void;
  hasSavedLanguage: () => boolean;
  saveCurrentLanguage: () => void;
};

/**
 * Hook that contains the language and locale.
 * Use this to update and use the applications language and locale.
 * Will trigger tolgee to use another language.
 * Will change apollo router to use another locale and language.
 */
export const useLocale = create<LocaleZustand>((set, get) => ({
  language: (typeof document !== undefined
    ? getCookie(LANGUAGE_AND_REGION_COOKIE_NAME)?.split('-')[0] ?? 'de'
    : 'de') as LanguageCode,
  locale: (typeof document !== undefined
    ? getCookie(LANGUAGE_AND_REGION_COOKIE_NAME)?.split('-')[1] ?? 'DE'
    : 'DE') as CountryCode,
  // Take german as default
  tax: {
    id: 430542913773,
    name: 'Germany',
    code: 'DE',
    tax_name: 'DE MwSt',
    tax: 0.19,
    provinces: [],
  } as CountryTax,
  hasSavedLanguage: () =>
    typeof window !== 'undefined' ? !!localStorage.getItem(LANGUAGE_AND_REGION_COOKIE_NAME) : false,
  saveCurrentLanguage: () =>
    localStorage.setItem(LANGUAGE_AND_REGION_COOKIE_NAME, `${get().language}-${get().locale}`),
  setLang: (lang: LanguageCode) => {
    const locale = getCookie(LANGUAGE_AND_REGION_COOKIE_NAME)?.split('-')[1] ?? 'DE';
    setCookie(LANGUAGE_AND_REGION_COOKIE_NAME, `${lang ?? 'de'}-${locale}`, 365);
    set({ language: lang });
  },
  setLocale: (loc: CountryCode) => {
    const lang = getCookie(LANGUAGE_AND_REGION_COOKIE_NAME)?.split('-')[0] ?? 'de';
    setCookie(LANGUAGE_AND_REGION_COOKIE_NAME, `${lang}-${loc ?? 'DE'}`, 365);
    fetch(`/api/tax/${loc}`)
      .then((res) => res.json())
      .then((res) => set({ tax: res as CountryTax }));
    set({ locale: loc });
  },
}));
