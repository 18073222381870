import { useEffect, useMemo } from 'react';
import { useCartZustand } from '@zustand/cart';
import {
  useCartBuyerIdentityUpdateMutation,
  useCartDiscountCodesUpdateMutation,
  useCartLinesAddMutation,
  useCartLinesRemoveMutation,
  useCartLinesUpdateMutation,
  useCartQuery,
} from '@generated/graphql/apollo';
import { LocalStorage } from '@customTypes/LocalStorage';
import {
  CartLine,
  CartQuery,
  Product,
  ProductVariant,
  RetrieveSdsInfoQuery,
  RetrieveSdsInfoQueryVariables,
} from '@generated/graphql/types';
import { useCustomerAccessToken } from './useCustomerAccessToken';
import { useCustomer } from './useCustomer';
import { useCreateCart } from './useCreateCart';
import { firstWithElements, useCrossSellProducts } from './useCrossSellProducts';
import useGtm from './useGTM';
import {
  getGtmPayloadCartProductRemoved,
  getGtmPayloadCartVoucherCodeSubmitted,
} from '@lib/gtm/cart';
import { ensureLanguage, firstLevelEquals, isEmptyDiscount, mapToNode, sumMoney } from '@lib/utils';
import { useCartLoadingOverlayZustand } from '@zustand/cartLoadingOverlay';
import { initializeApollo } from '@graphql/apollo-client';
import { RETRIEVE_SDS_INFO } from '@graphql/queries/cart';
import { useLocale } from '@zustand/useLocale';

/**
 * A hook to keep the cart up-to-date locally and remotely. Use it anywhere you want to interact with the cart.
 *
 * To check the cart's status, use `loading`. It will return `false` when the cart is initialized.
 * @returns An object containing all functions necessary to interact with the cart.
 */
export const useCart = () => {
  const {
    id,
    data: rawData,
    crossSellProducts,
    setData,
    setId,
    setCrossSellProducts,
    ignoreUpdateCount,
    increaseIgnoreUpdateCount,
    reFetchId,
    updateReFetchId,
    resetReFetchId, // I didn't get it working with useState
  } = useCartZustand();
  const { setShown } = useCartLoadingOverlayZustand();
  const { language, locale } = useLocale();

  const updateCartData = (cart: typeof data) => {
    if (cart) {
      setData(cart);
    }
  };

  const data = useMemo(() => {
    const _rawData = { ...rawData };
    // The end result
    const updatedLines: NonNullable<CartQuery['cart']>['lines']['edges'] = [];
    const cartLines = mapToNode(_rawData?.lines);
    let currentIndex = 0;
    for (const line of cartLines) {
      if (!line) {
        continue;
      }
      // eslint-disable-next-line no-loop-func
      const duplicateLineIndex = cartLines.findIndex((currentLine, i) => {
        const inRange = i > currentIndex; // Ignore the already processed items
        const isSameLine = currentLine.id === line.id; // Ignore if this is the current line
        const hasDifferentAllocations = !firstLevelEquals(
          currentLine.discountAllocations,
          line.discountAllocations,
        ); // Check if the discount data is the same
        const discountEmpty =
          !currentLine.discountAllocations.every(isEmptyDiscount) ||
          !line.discountAllocations.every(isEmptyDiscount); // Check if one of the products has Discounts
        const isSameProduct = currentLine.merchandise.id === line.merchandise.id; // Match variantId
        const isSameCost =
          currentLine.merchandise.priceV2.amount === line.merchandise.priceV2.amount; // Match price
        return (
          inRange &&
          !isSameLine &&
          isSameProduct &&
          isSameCost &&
          hasDifferentAllocations &&
          !discountEmpty
        );
      });
      // remove the line and add up the quantities
      if (duplicateLineIndex >= 0) {
        const [duplicateLine] = cartLines.splice(duplicateLineIndex, 1);
        updatedLines.push({
          cursor: '',
          node: {
            ...line,
            cost: {
              subtotalAmount: sumMoney(line.cost.subtotalAmount, duplicateLine.cost.subtotalAmount),
              totalAmount: sumMoney(line.cost.totalAmount, duplicateLine.cost.totalAmount),
            },
            quantity: line.quantity + duplicateLine.quantity,
          },
        });
      } else {
        // Do nothing...
        updatedLines.push({
          cursor: '',
          node: line,
        });
      }
      currentIndex++;
    }
    return {
      ..._rawData,
      lines: {
        edges: updatedLines,
      },
    } as CartQuery['cart'];
  }, [rawData]);
  const loading = data === undefined;
  const [createCart] = useCreateCart();
  const sendGTMEvent = useGtm();

  const [addLinesToCart] = useCartLinesAddMutation({
    onError: (error) => console.error('Could not add item to the remote cart', error.message),
  });

  const [removeLinesFromCart] = useCartLinesRemoveMutation({
    onError: (error) => console.error('Could not remove item from the remote cart', error.message),
  });

  const [updateCartLines] = useCartLinesUpdateMutation({
    onError: (error) => console.error('Could not update item(s) in the remote cart', error.message),
  });

  const [updateCartDiscountCodes] = useCartDiscountCodesUpdateMutation({
    onError: (error) => console.error('Could not update item(s) in the remote cart', error.message),
  });

  const sdsProducts = useMemo(() => {
    return mapToNode(data?.lines).filter((line) =>
      line.merchandise.product.handle.endsWith('-sds'),
    );
  }, [JSON.stringify(data)]);

  const normalProducts = useMemo(() => {
    return mapToNode(data?.lines).filter(
      (line) => !line.merchandise.product.handle.endsWith('-sds'),
    );
  }, [JSON.stringify(data)]);

  const hasOneSDSProduct = useMemo(() => sdsProducts.length > 0, [sdsProducts.length]);

  const hasOneNormalProduct = useMemo(() => normalProducts.length > 0, [normalProducts.length]);

  const replaceAllSDSProducts = async (...ignoreIDs: string[]) => {
    const apollo = initializeApollo(language);
    const changeset = await Promise.all(
      sdsProducts
        .filter((item) => !ignoreIDs.includes(item.id))
        .map(async (product) => {
          const avtProductData = await apollo.query<
            RetrieveSdsInfoQuery,
            RetrieveSdsInfoQueryVariables
          >({
            query: RETRIEVE_SDS_INFO,
            variables: {
              handle: product.merchandise.product.handle.slice(0, -4),
            },
          });
          const avtVariant = mapToNode(avtProductData.data?.product?.variants).find(
            (item) => item.justSku?.value === product.merchandise.justSku?.value,
          );
          if (!avtVariant || (avtVariant.quantityAvailable ?? 0) < product.quantity) {
            console.warn(`Could not find replacement for ${product.merchandise.product.handle}`);
            return null;
          }
          return {
            remove: product.id,
            add: {
              id: avtVariant.id,
              quantity: product.quantity,
            },
          };
        }),
    ).then((d) => d.filter((s) => !!s));
    const removeSet = Array.from(changeset.map((e) => e!.remove));
    const addSet = changeset.map((e) => ({
      merchandiseId: e!.add.id,
      quantity: e!.add.quantity,
    }));
    await removeLinesFromCart({
      variables: {
        cartId: data!.id,
        lineIds: removeSet,
        language: ensureLanguage(language),
        country: ensureLanguage(locale),
      },
    });
    await addLinesToCart({
      variables: {
        cartId: data!.id,
        lines: addSet,
        language: ensureLanguage(language),
        country: ensureLanguage(locale),
      },
    });
  };

  const tryReplaceAvantradoProducts = async (...ignoreIDs: string[]) => {
    const apollo = initializeApollo(language);
    const changelist = await Promise.all(
      normalProducts
        .filter((item) => !ignoreIDs.includes(item.id))
        .map(async (product) => {
          const sdsProductData = await apollo.query<
            RetrieveSdsInfoQuery,
            RetrieveSdsInfoQueryVariables
          >({
            query: RETRIEVE_SDS_INFO,
            variables: {
              handle: `${product.merchandise.product.handle}-sds`,
            },
          });
          const sdsVariant = mapToNode(sdsProductData.data?.product?.variants).find(
            (item) => item.justSku?.value === product.merchandise.justSku?.value,
          );
          if (!sdsVariant || (sdsVariant.quantityAvailable ?? 0) < product.quantity) {
            console.warn(`Could not find replacement for ${product.merchandise.product.handle}`);
            return null;
          }

          return {
            remove: product.id,
            add: {
              id: sdsVariant.id,
              quantity: product.quantity,
            },
          };
        }),
    ).then((d) => d.filter((s) => !!s));
    const removeSet = changelist.map((e) => e!.remove);
    const addSet = changelist.map((e) => ({
      merchandiseId: e!.add.id,
      quantity: e!.add.quantity,
    }));
    await removeLinesFromCart({
      variables: {
        cartId: data!.id,
        lineIds: removeSet,
        language: ensureLanguage(language),
        country: ensureLanguage(locale),
      },
    });
    await addLinesToCart({
      variables: {
        cartId: data!.id,
        lines: addSet,
        language: ensureLanguage(language),
        country: ensureLanguage(locale),
      },
    });
  };

  const canUseAvantradoForEveryProduct = async (...ignoreIDs: string[]) => {
    const hasProductWithThreshold = sdsProducts
      .filter((item) => !ignoreIDs.includes(item.id))
      .some((product) => product.quantity > (Number(product.merchandise?.sds_qty?.value) || 10));
    if (hasProductWithThreshold) {
      return false;
    }

    const apollo = initializeApollo(language);
    for (const product of sdsProducts) {
      const sdsProductData = await apollo.query<
        RetrieveSdsInfoQuery,
        RetrieveSdsInfoQueryVariables
      >({
        query: RETRIEVE_SDS_INFO,
        variables: {
          handle: product.merchandise.product.handle.slice(0, 4),
        },
      });
      const avantradoVariant = mapToNode(sdsProductData.data?.product?.variants).find(
        (item) => item.justSku?.value === product.merchandise.justSku?.value,
      );
      if (!avantradoVariant || (avantradoVariant.quantityAvailable ?? 0) < product.quantity) {
        return false;
      }
    }
    return true;
  };

  /**
   * Adds a new item to the remote cart and updates the local state. If no cart is found, a new remote cart is created.
   * @param variantId The id of the product variant.
   * @param quantity The quantity to add.
   */
  const addItem = async (
    variantId: Product['id'],
    quantity: number,
    isSDSProduct = false,
    ...ignoreIDsForward: string[]
  ) => {
    let cartId = id;

    if (!id) {
      const res = await createCart({
        variables: {
          language: ensureLanguage(language),
          country: ensureLanguage(locale),
        },
      });
      cartId = res.data?.cartCreate?.cart?.id;
    }

    if (!cartId) {
      console.error(
        'Cannot add item to cart because the cart id is undefined. This is probably due to the failure of the creation of a remote cart.',
      );

      return;
    }

    if (isSDSProduct && hasOneNormalProduct) {
      increaseIgnoreUpdateCount(2);
      await tryReplaceAvantradoProducts(...ignoreIDsForward);
    }

    const linesData = await addLinesToCart({
      variables: {
        cartId,
        lines: [{ merchandiseId: variantId, quantity }],
        language: ensureLanguage(language),
        country: ensureLanguage(locale),
      },
    });
    // Recreate cart and retry to add the lineItem, if for some reason the current cart is broken
    if (
      (linesData.errors && linesData.errors.length > 0) ||
      (linesData.data?.cartLinesAdd?.userErrors &&
        linesData.data.cartLinesAdd.userErrors.length > 0)
    ) {
      const res = await createCart({
        variables: {
          language: ensureLanguage(language),
          country: ensureLanguage(locale),
        },
      });
      cartId = res.data?.cartCreate?.cart?.id;
      if (cartId) {
        const response = await addLinesToCart({
          variables: {
            cartId,
            lines: [{ merchandiseId: variantId, quantity }],
            language: ensureLanguage(language),
            country: ensureLanguage(locale),
          },
        });
        const cart = response.data?.cartLinesAdd?.cart;
        if (cart) {
          updateCartData(cart);
        }
      }
    } else {
      const cart = linesData.data?.cartLinesAdd?.cart;
      updateCartData(cart);
    }
  };

  /**
   * Removes the specified item from the remote cart and updates the local state.
   * @param lineId The id of the cart line item.
   * @param preserveSiblings keep variants with different lined ids
   */
  const removeItem = async (
    lineId: CartLine['id'],
    preserveSiblings = true,
    triggerReplace = true,
  ) => {
    if (!id) {
      console.error(
        'Cannot remove item from the cart because the cart id is undefined. This is probably due to the failure of the creation of a remote cart.',
      );

      return;
    }

    const remainingLines = [];
    if (data && rawData) {
      const removedCartLine = data.lines.edges.find(({ node }) => node.id === lineId);

      if (removedCartLine) {
        const merchandise = removedCartLine.node.merchandise;
        if (!preserveSiblings) {
          // collect items with same variant but different line
          const linesWithSameProduct = mapToNode(rawData.lines).filter(
            (item) => item.id !== removedCartLine.node.id && item.merchandise.id === merchandise.id,
          );
          remainingLines.push(...linesWithSameProduct);
        }

        const removedProductSku = removedCartLine.node.merchandise.product.sku?.value;

        sendGTMEvent(
          getGtmPayloadCartProductRemoved({
            cartData: data,
            removedProductSku,
          }),
        );
      }
    }

    const linesToRemove = [...remainingLines.map((line) => line.id), lineId];

    const response = await removeLinesFromCart({
      variables: {
        cartId: id,
        lineIds: linesToRemove,
        language: ensureLanguage(language),
        country: ensureLanguage(locale),
      },
    });

    const cart = response.data?.cartLinesRemove?.cart;
    updateCartData(cart);
    if (triggerReplace) {
      increaseIgnoreUpdateCount(1);
      updateReFetchId();
    }
  };

  useEffect(() => {
    if (reFetchId === -1) {
      localStorage.removeItem('replacing');
      return;
    }
    if (localStorage.getItem('replacing')) {
      // useRef not working either
      return;
    }
    localStorage.setItem('replacing', '1');

    canUseAvantradoForEveryProduct()
      .then((can) => {
        if (can) {
          console.log('Replace all SDS');
          return replaceAllSDSProducts();
        }
        return Promise.resolve();
      })
      .then(() => {
        resetReFetchId();
        localStorage.removeItem('replacing');
      });
  }, [reFetchId === -1]);

  /**
   * Returns the sum of all the items in the cart. Returns 0 if no cart was initialized.
   */
  const getQuantity = () => {
    const edges = data?.lines.edges;
    let total = 0;

    if (!edges || edges.length === 0) {
      return total;
    }

    for (const element of edges) {
      const edge = element;
      total += edge.node.quantity;
    }

    return total;
  };

  /**
   * Returns the quantity in the cart of the requested variant.
   */
  const getQuantityForVariant = (variant?: Pick<ProductVariant, 'id'> | null) => {
    if (variant && data) {
      return mapToNode(data.lines).reduce((count, { merchandise: { id }, quantity }) => {
        if (id !== variant.id) {
          return count;
        }

        return count + quantity;
      }, 0);
    }
    return 0;
  };

  const getLineIDsForVariant = (variantId: string) => {
    return mapToNode(data?.lines).reduce(
      (count: string[], { merchandise: { id }, quantity, id: lineId }) => {
        if (id !== variantId) {
          return count;
        }

        return [...count, lineId];
      },
      [],
    );
  };

  /**
   * Updates the quantity of a specified item in the remote cart and updates the local state.
   * @param lineId The id of the cart line item.
   * @param quantity The new quantity of the cart line item.
   */
  const updateItemQuantity = async (
    lineId: CartLine['id'],
    quantity: number,
    isSDS: boolean,
    sds_qty: number,
    otherAvailable: number,
    otherVariantId: string,
  ) => {
    setShown(true);
    try {
      if (!id) {
        console.error(
          'Cannot update item of cart because the cart id is undefined. This is probably due to the failure of the creation of a remote cart.',
        );

        return;
      }
      const line = mapToNode(data?.lines).find((line) => line.id === lineId);
      if (!line) {
        return;
      }

      // Get the change amount
      let quantityDelta = (line.quantity - quantity) * -1;

      if (quantityDelta === 0) {
        return;
      }

      if (quantityDelta > 0) {
        if (
          !isSDS &&
          (quantity >= sds_qty || quantity > (line.merchandise.quantityAvailable ?? 0))
        ) {
          increaseIgnoreUpdateCount(4);
          await removeLinesFromCart({
            variables: {
              cartId: data!.id,
              lineIds: [lineId],

              language: ensureLanguage(language),
              country: ensureLanguage(locale),
            },
          });
          await addItem(otherVariantId, quantity, true, lineId);
          return;
        }
      } else if (isSDS && quantity < sds_qty && otherAvailable >= quantity) {
        const canReplaceAll = await canUseAvantradoForEveryProduct(lineId);
        if (canReplaceAll) {
          increaseIgnoreUpdateCount(4);
          await removeLinesFromCart({
            variables: {
              cartId: data!.id,
              lineIds: [lineId],
              language: ensureLanguage(language),
              country: ensureLanguage(locale),
            },
          });
          await addItem(otherVariantId, quantity, false);
          await replaceAllSDSProducts(lineId);
          return;
        }
      }

      const affectedLines = mapToNode(rawData?.lines)
        .filter((l) => l.merchandise.id === line.merchandise.id)
        .map((e) => ({ node: { ...e }, wasChanged: false }))
        .sort((e1) => (e1.node.id === lineId ? -1 : 0));

      let index = 0;
      let affectedLine = affectedLines[index];

      while (quantityDelta !== 0 && affectedLine != null) {
        if (quantityDelta > 0) {
          affectedLine.node.quantity += quantityDelta;
          affectedLine.wasChanged = true;
          quantityDelta = 0;
        } else {
          affectedLine.node.quantity = Math.max(0, affectedLine.node.quantity + quantityDelta);
          affectedLine.wasChanged = true;
          quantityDelta = Math.min(0, affectedLine.node.quantity + quantityDelta);
        }
        index++;
        affectedLine = affectedLines[index];
      }

      const response = await updateCartLines({
        variables: {
          cartId: id,
          lines: affectedLines
            .filter((line) => line.wasChanged)
            .map(({ node: { id, quantity } }) => ({ id, quantity })),
          language: ensureLanguage(language),
          country: ensureLanguage(locale),
        },
      });
      updateCartData(response.data?.cartLinesUpdate?.cart);
    } finally {
      setShown(false);
    }
  };

  /**
   * Updates the cart's applied discount code.
   * @param code The code associated with the discount
   */
  const updateDiscountCode = async (code: string) => {
    if (!id) {
      console.error(
        "Cannot update the cart's discount codes because the cart id is undefined. This is probably due to the failure of the creation of a remote cart.",
      );

      return;
    }

    const response = await updateCartDiscountCodes({
      variables: {
        cartId: id,
        discountCodes: code.length > 0 ? [code] : [],
        language: ensureLanguage(language),
        country: ensureLanguage(locale),
      },
    });
    const cart = response.data?.cartDiscountCodesUpdate?.cart;
    if (cart) {
      sendGTMEvent(
        getGtmPayloadCartVoucherCodeSubmitted({
          cartData: cart,
          enteredValue: code,
        }),
      );
      updateCartData(cart);
    }
  };

  /**
   * Clears the cart
   */
  const clear = () => {
    localStorage.removeItem(LocalStorage.CartId);
    setCrossSellProducts({ data: undefined, error: false, loading: false });
    setId(undefined);
    updateCartData(null);
  };

  return {
    addItem,
    clear,
    crossSellProducts,
    data,
    getQuantity,
    getQuantityForVariant,
    loading,
    removeItem,
    updateDiscountCode,
    updateItemQuantity,
    getLineIDsForVariant,
    shouldUseSDS: hasOneSDSProduct,
  };
};

/**
 * Initializes the cart by fetching the cart's content based on the `cartId` stored in the client's local storage. If no `cartId` is found, `cart.data` is set to `null`.
 * It also contains a hook that updates the buyer identity associated to the cart when a customer logs in.
 */
export const useInitializeCart = () => {
  const { id, data, setId, setData, setCrossSellProducts } = useCartZustand();
  const { language, locale } = useLocale();

  const [customerAccessToken] = useCustomerAccessToken();
  const [customer] = useCustomer();
  const [createCart] = useCreateCart();

  const crossSellProducts = useCrossSellProducts(
    data?.lines.edges?.map(({ node }) =>
      node
        ? firstWithElements(
            node.merchandise.crossSellReferences,
            node.merchandise.product.crossSellReferences,
          )
        : null,
    ),
  );

  const [updateCartBuyerIdentity] = useCartBuyerIdentityUpdateMutation({
    onError: (error) =>
      console.error('Could not update buyer identity in the remote cart', error.message),
  });

  useCartQuery(
    id
      ? {
          notifyOnNetworkStatusChange: true,
          onCompleted: (d) => setData(d.cart),
          onError: (error) => {
            console.error("Error fetching cart's contents", error.message);
            createCart({
              variables: {
                language: ensureLanguage(language),
                country: ensureLanguage(locale),
              },
            });
          },
          variables: {
            id,
            language: ensureLanguage(language),
            country: ensureLanguage(locale),
          },
        }
      : { skip: true },
  );

  useEffect(() => {
    const sessionCartId = localStorage.getItem(LocalStorage.CartId);

    if (sessionCartId) {
      setId(sessionCartId);
    } else {
      setData(null);
    }
  }, []);

  useEffect(() => {
    if (!id || customerAccessToken) {
      return;
    }
    updateCartBuyerIdentity({
      variables: {
        buyerIdentity: {
          countryCode: ensureLanguage(locale),
        },
        cartId: id,
        language: ensureLanguage(language),
        country: ensureLanguage(locale),
      },
    }).then((r) => {
      const cart = r.data?.cartBuyerIdentityUpdate?.cart;
      if (cart) {
        setData(cart);
      }
    });
  }, [locale, id, customerAccessToken]);

  useEffect(() => {
    if (!id || !data || !customerAccessToken || !customer) {
      return;
    }
    updateCartBuyerIdentity({
      variables: {
        buyerIdentity: {
          customerAccessToken: customerAccessToken.accessToken,
          countryCode: ensureLanguage(locale),
        },
        cartId: id,
        language: ensureLanguage(language),
        country: ensureLanguage(locale),
      },
    }).then((r) => {
      const cart = r.data?.cartBuyerIdentityUpdate?.cart;
      if (cart) {
        setData(cart);
      }
    });
  }, [id, customerAccessToken, customer, language, locale]);

  useEffect(() => {
    setCrossSellProducts(crossSellProducts);
  }, [JSON.stringify(crossSellProducts)]);
};
