import { FaqItemProps } from '@customTypes/storyblok-types';
import { RichText } from '@components/RichText';
import { ISbRichtext, storyblokEditable } from '@storyblok/react';

interface FaqBlokPopulated {
  question: string; // populated
  answer: ISbRichtext; // populated
}

export const FaqItem = ({ blok }: { blok: FaqItemProps }) => {
  // these props are populated on server side - check the related page component. -> getStaticProps
  const blokPopulated = blok as FaqItemProps & FaqBlokPopulated;
  return (
    <div
      /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
      /* @ts-ignore*/
      {...storyblokEditable(blok)}
      key={blokPopulated.question}
    >
      <dt className="text-lg font-medium leading-6 text-gray-900">{blokPopulated.question}</dt>
      <dd className="mt-2 text-base text-gray-500">
        <RichText text={blokPopulated.answer} className="" />
      </dd>
    </div>
  );
};
