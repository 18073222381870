import Link from 'next/link';
import { KeyboardEvent, MouseEvent, useRef, useState } from 'react';
import { ensureLanguage, getBrancheFromCollections, isB2B, isEqual } from '@lib/utils';
import { StoryblokImage } from '../Image/StoryblokImage';
import { useMenuZustand } from '@zustand/menu';
import { Product } from '@hooks/useSearch';
import { ShoppingBag } from '../generated/icons';
import { useCart } from '@hooks/useCart';
import { useProductLazyQuery } from '@generated/graphql/apollo';
import classNames from '@utils/ClassNames';
import { useLocale } from '@zustand/useLocale';

type SearchResultDataProps = {
  product: Product;
  onClick: () => void;
};

const SearchAutoSuggestion = ({ product, onClick }: SearchResultDataProps) => {
  const anchor = useRef<HTMLAnchorElement>(null);
  const { setOpenMenu: setState } = useMenuZustand();
  const { locale, language } = useLocale();
  const cart = useCart();
  const [itemAddLoading, setItemAddLoading] = useState(false);

  const [productQuery] = useProductLazyQuery({
    variables: {
      handle: product.parentHandle,
      sdsHandle: `${product.parentHandle}-sds`,
      language: ensureLanguage(language),
      country: ensureLanguage(locale),
    },
  });
  const attributeOnClick = () => {
    setState(false);
    onClick();
    return anchor.current && anchor.current.blur();
  };

  const productSearchParams = `variantSKU=${product.sku}`;

  /** Trigger a quick add of the selected item and prevent events from further bubbling */
  const handleQuickAdd = (ev: MouseEvent) => {
    ev.stopPropagation();
    ev.preventDefault();
    quickBuy();
  };

  /** Send query to quick add the item to cart */
  const quickBuy = async () => {
    setItemAddLoading(true);
    const prod = (await productQuery()).data;
    if (!prod) {
      setItemAddLoading(false);
      return;
    }
    const variant = prod.product?.variants.edges.find(({ node }) =>
      isEqual(
        node.selectedOptions.map((o) => ({ name: o.name, value: o.value })),
        product.selectedOption,
      ),
    );

    if (variant) {
      await cart.addItem(variant.node.id, 1);
      setState(false);
    }
    setItemAddLoading(false);
  };

  /** React on keyboard events on the link -> either quick add or blur - based on b2b or not */
  const handleLinkKeyboardEvent = (e: KeyboardEvent) => {
    if (e.key === 'Enter' && ((isB2B() && !e.metaKey) || (!isB2B() && e.metaKey))) {
      quickBuy();
      e.preventDefault();
      e.stopPropagation();
    } else if (
      (e.key === 'Enter' || e.key === 'Space') &&
      !document.activeElement?.classList.contains('quickOrderButton')
    ) {
      setTimeout(() => {
        onClick();
        anchor.current?.blur();
      });
    }
  };

  return (
    <Link
      href={`/${[
        [
          'products',
          getBrancheFromCollections({
            edges: product.collections.map(({ name }) => ({ node: { title: name } })),
          }),
          product.parentHandle,
        ]
          .filter((v) => !!v)
          .join('/'),
        productSearchParams.toString(),
      ].join('?')}`}
    >
      {/* eslint-disable-next-line jsx-a11y/interactive-supports-focus */}
      <a
        role="link"
        ref={anchor}
        onClick={attributeOnClick}
        onKeyDown={handleLinkKeyboardEvent}
        className={classNames(
          'quickSearchItem flex items-center px-4 py-2 cursor-pointer hover:bg-gray-100 focus:bg-gray-100 focus-within:bg-gray-100',
        )}
      >
        <StoryblokImage
          width={48}
          height={48}
          src={product.imageUrls[0]}
          alt={product.parentTitle}
          className="rounded-full"
        />
        <div className="flex-1 ml-4">
          <div className="text-sm font-medium text-gray-900 line-clamp-2">
            {product.parentTitle}
          </div>
        </div>
        {isB2B() && (
          <button
            type="button"
            onClick={handleQuickAdd}
            className="flex-0 quickOrderButton"
            tabIndex={-1}
          >
            {itemAddLoading ? (
              <svg
                className="animate-spin h-5 w-5 text-gray-900"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
            ) : (
              <ShoppingBag className="w-5 h-5 text-gray-900" />
            )}
          </button>
        )}
      </a>
    </Link>
  );
};

export default SearchAutoSuggestion;
