import { Filter, FilterGroup } from '@lib/search';
import { create } from 'zustand';

type FiltersState = {
  filters: FilterGroup[];
  collectionHandle: string;
  addFilter: (filter: Filter) => void;
  removeFilter: (filter: Filter) => void;
  hasFilter: (filter: Filter) => boolean;
  clearFilters: () => void;
  hasFilterGroupWithId: (id: string) => boolean;
  replaceFilter: (filter: Filter) => void;
  removeWhere: (fn: (filter: Filter) => boolean) => void;
  hasSome: (fn: (filter: Filter) => boolean) => boolean;
  setCollectionHandle: (handle: string) => boolean;
};

export const useFiltersZustand = create<FiltersState>((set, get) => ({
  addFilter: (filter) => {
    let group = get().filters.find((s) => s.field === filter.field);
    if (!group) {
      group = new FilterGroup(filter.field);
    }
    group.addFilter(filter);
    set((state) => ({
      ...state,
      filters: [...state.filters.filter((s) => s.id !== group!.id), group as FilterGroup],
    }));
  },
  clearFilters: () => {
    set((state) => ({ ...state, filters: [] }));
  },
  filters: [],
  collectionHandle: '',
  setCollectionHandle: (handle) => {
    if (get().collectionHandle !== handle) {
      set({ collectionHandle: handle, filters: [] });
      return true;
    }
    return false;
  },
  hasFilter: (filter: Filter) => get().filters.some((s) => s.hasFilter(filter)),
  hasFilterGroupWithId: (id: string) => get().filters.some((s) => s.id === id),
  hasSome: (filter) => get().filters.some(filter),
  removeFilter: (filter) => {
    const group = get().filters.find((s) => s.field === filter.field);
    if (!group) {
      return;
    }
    group.removeFilter(filter);
    const newFilters = get().filters.filter((s) => s.id !== group.id);
    if (group.hasFilters) {
      newFilters.push(group);
    }
    set((state) => ({
      ...state,
      filters: newFilters,
    }));
  },
  removeWhere: (filter) => {
    set((state) => ({
      ...state,
      filters: state.filters.filter((item) => !filter(item)),
    }));
  },
  replaceFilter: (filter) => {
    const group = new FilterGroup(filter.field);
    group.addFilter(filter);
    set((state) => ({
      ...state,
      filters: [...state.filters.filter((s) => s.id !== group.id), group],
    }));
  },
}));

export const useCurrentFilterQuery = () =>
  useFiltersZustand((store) => store.filters.map((f) => f.getQueryPart()).join(' AND '));

type RangeItem = {
  min: number;
  max: number;
  name: string;
};

type RangeZustand = {
  insert: (range: RangeItem) => RangeItem;
  clear: () => void;
  ranges: RangeItem[];
};

export const useRangeZustand = create<RangeZustand>((set, get) => ({
  clear: () => {
    set((state) => ({
      ...state,
      ranges: [],
    }));
  },
  insert: (range: RangeItem) => {
    const rangeItem = get().ranges.find((r) => r.name === range.name);
    if (!rangeItem) {
      set((state) => ({
        ...state,
        ranges: [...state.ranges, range],
      }));
    }
    return rangeItem ?? range;
  },
  ranges: [],
}));

type OneOrMoreColors = [string, string] | [string];

type AvailableColorsZustand = {
  colors: Map<string, OneOrMoreColors>;
  clear: () => void;
  insertColor: (colorName: string, ...hexValues: OneOrMoreColors) => void;
};

export const useAvailableColors = create<AvailableColorsZustand>((set, get) => ({
  colors: new Map(),
  clear: () => set({ colors: new Map() }),
  insertColor: (colorName, ...hexValues) => {
    if (!hexValues || hexValues.length > 2 || hexValues.length < 1) {
      throw new Error();
    }
    set({
      colors: get().colors.set(colorName, hexValues),
    });
  },
}));
