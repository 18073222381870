export type SubscribeOptions = {
  newCollection: boolean;
  polls: boolean;
  productUpdates: boolean;
  recommendations: boolean;
  sale: boolean;
  sizeAvailable: boolean;
  sport: boolean;
  // sportsPreferences: boolean
};

type DefaultLists = keyof SubscribeOptions;
export type SubscriptionList = DefaultLists | 'newsletter' | 'usa-newsletter';

// TODO: Add error response handling - also let
// the user know if something and what went wrong.
export function subscribe(email: string, list: SubscriptionList, props: object) {
  return fetch(`/api/subscribe/${list}`, {
    body: JSON.stringify({ email, ...props }),
    method: 'POST',
  });
}

export function unsubscribe(email: string, list: SubscriptionList) {
  return fetch(`/api/unsubscribe/${list}`, {
    body: JSON.stringify({ email }),
    method: 'POST',
  });
}

export function getSubscriptions(email: string) {
  return fetch('/api/subscribe', {
    body: JSON.stringify({ email }),
    method: 'POST',
  }).then(async (res) => (await res.json()) as Array<DefaultLists>);
}
