import { CustomerSessionType } from '@components/Customer/Session';
import { create } from 'zustand';

export type CustomerSessionDrawerState = {
  open: boolean;
  sessionType: CustomerSessionType;
  setOpen: (open: boolean) => void;
  setSessionType: (sessionType: CustomerSessionType) => void;
  openWithSessionType: (sessionType: CustomerSessionType) => void;
};

export const useCustomerSessionDrawerZustand = create<CustomerSessionDrawerState>((set) => ({
  open: false,
  openWithSessionType: (sessionType) => set((state) => ({ ...state, open: true, sessionType })),
  sessionType: null,
  setOpen: (open) => set((state) => ({ ...state, open })),
  setSessionType: (sessionType) => set((state) => ({ ...state, sessionType })),
}));
