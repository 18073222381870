import classNames from 'classnames';
import { ProductCardImage } from './ProductCardImage';
import { ProductCardVerticalDescription } from './ProductCardVerticalDescription';
import type { ProductCardProps } from './shared';
import { ProductCardLink } from './ProductCardLink';
import {
  ensureLanguage,
  getVariantIdFromShopifyUri,
  image_urlsAsArray,
  isColorProp,
  mapToNode,
} from '@lib/utils';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Transition } from '@headlessui/react';
import { T, useTranslate } from '@tolgee/react';
import ProductSaleChip from '@components/Product/ProductSaleChip';
import { highestVariantSale, percentOf } from '@lib/sale';
import { useVariantsByParentQuery } from '@generated/graphql/apollo';
import ProductCardColorPicker from '@components/Product/Card/ProductCardColorPicker';
import { useSoldProductZustand } from '@zustand/useSoldProduct';
import { Chevron } from '@components/Button/Chevron';
import { useCart } from '@hooks/useCart';
import { ProductAddedToCart } from '../AddedToCart';
import { ButtonPrimary } from '@components/Button/Primary';
import { ProductCardSelection } from './ProductCardSelection';
import useIsTouch from '@hooks/useIsTouch';
import { ChevronDown } from '../../generated/icons';
import { useLocale } from '@zustand/useLocale';
import useGtm from '@hooks/useGTM';
import { getGtmPayloadProductAddedToCart } from '@lib/gtm/productDetails';
import { hashCustomerData } from '@lib/gtm/order';
import { useCustomer } from '@hooks/useCustomer';

export const ProductCardVertical = ({
  className,
  imageLoading,
  handle,
  variantSku,
  variantId,
  onClick,
}: ProductCardProps) => {
  const { t } = useTranslate();
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isHovering, setIsHovering] = useState(false);
  const [mobileFilterOpen, setMobileFilterOpen] = useState(false);
  const [hasNonColorOptions, setHasNonColoOptions] = useState(false);
  const [hasColorMap, setHasColorMap] = useState(false);
  const [persistIndex, setPersistIndex] = useState(false);
  const [currentSelectedColor, setCurrentSelectedColor] = useState<
    { name: string; value: string }[] | undefined
  >([]);
  const insertProductId = useSoldProductZustand((store) => store.insertId);
  const [optionImageUrls, setOptionImageUrls] = useState<string[] | null>(null);
  const cart = useCart();
  const [showAddedToCart, setShowAddedToCart] = useState(false);
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const additionalContainer = useRef<HTMLDivElement | null>(null);
  const isTouchDevice = useIsTouch();
  const [currentSelectedOptions, setCurrentSelectedOptions] = useState<
    | {
        name: string;
        value: string;
      }[]
    | undefined
  >([]);
  const sendDataToGTM = useGtm();
  const [customer] = useCustomer();

  const { locale, language } = useLocale();
  const { data } = useVariantsByParentQuery({
    variables: {
      handle,
      handleSDS: `${handle}-sds`,
      country: ensureLanguage(locale),
      language: ensureLanguage(language),
    },
  });

  const [selectedVariantSku, setSelectedVariantSku] = useState(variantSku?.trim());

  const filteredData = useMemo(
    () =>
      ({
        product: {
          ...data?.product,
          variants: {
            edges: data?.product?.variants.edges.filter((p) => p.node.isArchived?.value !== 'true'),
          },
        },
        sdsProduct: {
          variants: {
            edges: data?.sdsProduct?.variants.edges.filter(
              (p) => p.node.isArchived?.value !== 'true',
            ),
          },
        },
      } as typeof data),
    [data],
  );

  let highestSale = highestVariantSale(filteredData?.product?.variants);
  if (
    filteredData?.product?.compareAtPriceRange &&
    (filteredData?.product?.priceRange.minVariantPrice.amount !==
      filteredData?.product?.compareAtPriceRange.minVariantPrice.amount ||
      filteredData?.product?.priceRange.maxVariantPrice.amount !==
        filteredData?.product?.compareAtPriceRange.maxVariantPrice.amount)
  ) {
    const minSale = percentOf(
      filteredData.product.priceRange.minVariantPrice,
      filteredData?.product?.compareAtPriceRange.minVariantPrice,
    );
    const maxSale = percentOf(
      filteredData.product.priceRange.maxVariantPrice,
      filteredData?.product?.compareAtPriceRange.maxVariantPrice,
    );
    highestSale = Math.max(highestSale, minSale, maxSale);
  }

  const selectedVariant = useMemo(
    () =>
      filteredData?.product?.variants?.edges?.find(
        (v) => v.node.sku?.value === selectedVariantSku,
      ) ?? filteredData?.product?.variants.edges?.[0],
    [filteredData, selectedVariantSku],
  );
  const selectedSdsVariant = useMemo(
    () =>
      filteredData?.sdsProduct?.variants?.edges?.find(
        (v) => v.node.sku?.value === selectedVariantSku,
      ) ?? filteredData?.sdsProduct?.variants.edges?.[0],
    [filteredData, selectedVariantSku],
  );

  useEffect(() => {
    if (!variantSku && variantId) {
      const variant = filteredData?.product?.variants?.edges?.find(
        (item) => getVariantIdFromShopifyUri(item.node.id) === variantId,
      );
      setSelectedVariantSku(variant?.node.sku?.value);
    }
    setHasColorMap(
      !!(
        filteredData?.product?.colormap?.value &&
        Object.entries(JSON.parse(filteredData.product.colormap.value)).length > 1
      ),
    );
    setHasNonColoOptions(
      (filteredData?.product?.options?.filter((o) => !isColorProp(o.name)).length ?? 0) >
        (!!filteredData?.product?.options?.some((o) => isColorProp(o.name)) ? 0 : 1),
    );
  }, [filteredData, variantSku, variantId]);

  useEffect(() => {
    if (selectedVariantSku) {
      setCurrentSelectedColor(
        selectedVariant?.node.selectedOptions?.filter((o) => isColorProp(o.name)) ?? [],
      );
    }

    if (!optionImageUrls && selectedVariantSku && filteredData?.product?.variants?.edges) {
      setOptionImageUrls(
        image_urlsAsArray(
          filteredData?.product?.variants?.edges?.find(
            (item) => item.node.sku?.value === selectedVariantSku,
          )?.node?.image_urls?.value,
        ),
      );
    }
  }, [filteredData, selectedVariantSku]);

  const hasStock = useMemo(() => {
    return (
      (filteredData?.product?.totalInventory ?? 0) +
        (filteredData?.sdsProduct?.variants.edges?.reduce(
          (acc, curr) => acc + (curr.node?.quantityAvailable ?? 0),
          0,
        ) ?? 0) >
      0
    );
  }, [filteredData]);

  useEffect(() => {
    const variants = [
      ...mapToNode(filteredData?.product?.variants ?? { edges: [] }),
      ...mapToNode(filteredData?.sdsProduct?.variants ?? { edges: [] }),
    ];
    const everythingOutOfStock = variants.every((item) => (item.quantityAvailable ?? 0) <= 0);
    if (everythingOutOfStock) {
      (variants.map((v) => v.sku?.value).filter((v) => !!v) as string[]).forEach(insertProductId);
    }
  }, [JSON.stringify(filteredData?.product?.variants)]);

  const imageUrls = useMemo(
    () =>
      optionImageUrls && optionImageUrls.length > 0
        ? optionImageUrls
        : image_urlsAsArray(filteredData?.product?.image_urls?.value),
    [filteredData, optionImageUrls],
  );

  const increase = () => changeIndex(1);
  const decrease = () => changeIndex(-1);

  const changeIndex = (val: number) => {
    setCurrentImageIndex((currentImageIndex + val + imageUrls.length) % imageUrls.length);
    setPersistIndex(true);
  };

  const updateColorOption = (name: string, value: string | number) => {
    setCurrentSelectedColor((last) => {
      if (!last) {
        return undefined;
      }
      return [
        ...last.filter((item) => item.name !== name),
        {
          name,
          value: value as string,
        },
      ];
    });
  };

  const handleAddToCart = async () => {
    const quantityThreshold = Number(selectedVariant?.node.sds_qty?.value) || 10;
    const quantity = 1;
    const sdsAvailable = selectedSdsVariant?.node.quantityAvailable ?? 0;
    const normalAvailable = selectedVariant?.node.quantityAvailable ?? 0;

    const sdsHasEnoughStock = sdsAvailable >= quantity;
    const cartHasSDSProduct = cart.shouldUseSDS;
    const quantityExceedsThreshold = quantity >= quantityThreshold;
    const normalHasNotEnoughStock = normalAvailable < quantity;

    let fbDataAppend: {} | { fbData: any } = {};
    if (customer) {
      fbDataAppend = { fbData: await hashCustomerData(customer) };
    }

    if (
      selectedSdsVariant?.node.id &&
      (cartHasSDSProduct || quantityExceedsThreshold || normalHasNotEnoughStock) &&
      sdsHasEnoughStock
    ) {
      await cart.addItem(selectedSdsVariant.node.id, quantity, true);
      setShowAddedToCart(true);
      sendDataToGTM(
        getGtmPayloadProductAddedToCart({
          productVariantDetails: {
            price: selectedVariant?.node.priceV2.amount,
            product_category: filteredData?.product?.collections.edges[0]?.node.title ?? '',
            variant_id: selectedVariant?.node.sku?.value ?? '',
          },
          ...fbDataAppend,
        }),
      );
    } else if (selectedVariant?.node.id) {
      await cart.addItem(selectedVariant.node.id, quantity);
      setShowAddedToCart(true);
      sendDataToGTM(
        getGtmPayloadProductAddedToCart({
          productVariantDetails: {
            price: selectedVariant?.node.priceV2.amount,
            product_category: filteredData?.product?.collections.edges[0]?.node.title ?? '',
            variant_id: selectedVariant?.node.sku?.value ?? '',
          },
          ...fbDataAppend,
        }),
      );
    }
  };

  return (
    filteredData?.product?.variants?.edges && (
      <div
        ref={wrapperRef}
        className={classNames(
          'relative flex flex-col min-h-[16rem] self-start space-y-1.5 product-card mx-3 group hoverCard shadow-l1 md:shadow-none before:absolute before:bg-white before:content-[""]',
          className,
          isHovering && 'before:shadow-l3 before:-inset-5',
        )}
        onMouseEnter={(e) => {
          if (isTouchDevice) return;
          setIsHovering(true);
          if (!persistIndex && imageUrls.length > 1) {
            setCurrentImageIndex(1);
          }
          const onMouseMove = (e: MouseEvent) => {
            if (!wrapperRef.current) return;
            const { clientX, clientY } = e;
            const { left, top, width, height } = wrapperRef.current.getBoundingClientRect();
            const additional = additionalContainer.current?.getBoundingClientRect();
            const x = clientX - left;
            const y = clientY - top;
            const isInside = x > 0 && x < width && y > 0 && y < height + (additional?.height ?? 0);
            if (!isInside) {
              setIsHovering(false);
              document.removeEventListener('mousemove', onMouseMove);
              if (!persistIndex && imageUrls.length > 1) {
                setCurrentImageIndex(0);
              }
            }
          };
          document.addEventListener('mousemove', onMouseMove);
        }}
        onTouchStart={() => {
          if (!persistIndex && imageUrls.length > 1) {
            setCurrentImageIndex((currentImageIndex + 1) % 2);
          }
        }}
      >
        {filteredData?.product?.collections && (
          <ProductCardLink
            collections={filteredData.product.collections}
            onClick={onClick}
            handle={handle}
            variantId={getVariantIdFromShopifyUri(selectedVariant?.node.id || variantId || '')}
          >
            <div
              className="relative w-full pb-full bg-radial-gradient-product-image aspect-square"
              style={{
                background: 'radial-gradient(#FFFFFF 0%, #F2F2F8 100%)',
              }}
            >
              <Transition
                appear={true}
                show={isHovering && imageUrls.length > 1}
                enter="transition-all ease-in-out duration-300"
                enterFrom="-translate-x-4 opacity-0"
                enterTo="translate-x-0 opacity-100"
                leave="transition-all ease-in-out duration-300"
                leaveFrom="translate-x-0 opacity-100"
                leaveTo="-translate-x-4 opacity-0"
                className="absolute top-0 z-10 flex items-center h-full pointer-events-none left-2"
              >
                <Chevron direction="left" onClick={decrease} />
              </Transition>
              <div className="absolute top-0 bottom-0 left-0 right-0 z-0 flex items-center justify-center">
                {(!filteredData.product.dreid ||
                  currentImageIndex !== (imageUrls.length > 2 ? 3 : imageUrls.length)) && (
                  <ProductCardImage
                    image={{
                      altText: filteredData.product.title,
                      height: 384,
                      src: imageUrls[
                        currentImageIndex > 2 && filteredData.product.dreid
                          ? currentImageIndex - 1
                          : currentImageIndex
                      ],
                      width: 384,
                    }}
                    imageLoading={imageLoading}
                  />
                )}
                {filteredData.product.dreid && persistIndex && (
                  <iframe
                    allowTransparency={true}
                    title="3D preview"
                    src={filteredData.product.dreid.value}
                    name="dreidpreview"
                    scrolling="no"
                    frameBorder="0"
                    className={classNames(
                      'absolute top-0 left-0 w-full h-full',
                      currentImageIndex === (imageUrls.length > 2 ? 3 : imageUrls.length)
                        ? 'block'
                        : 'hidden',
                    )}
                  >
                    <p>
                      Ihr Browser kann leider keine eingebetteten Frames anzeigen: Sie können die
                      eingebettete Seite über den folgenden Verweis aufrufen:
                      <a href="https://wiki.selfhtml.org/wiki/Startseite">SELFHTML</a>
                    </p>
                  </iframe>
                )}
              </div>
              <Transition
                appear={true}
                show={isHovering && imageUrls.length > 1}
                enter="transition-all ease-in-out duration-300"
                enterFrom="translate-x-4 opacity-0"
                enterTo="translate-x-0 opacity-100"
                leave="transition-all ease-in-out duration-300"
                leaveFrom="translate-x-0 opacity-100"
                leaveTo="translate-x-4 opacity-0"
                className="absolute top-0 z-10 flex items-center h-full right-2"
              >
                <Chevron direction="right" onClick={increase} />
              </Transition>
            </div>

            <div className="pt-3 pb-4 px-2 md:px-0 relative">
              <ProductCardVerticalDescription
                title={filteredData.product.title}
                variantTitle={
                  (currentSelectedColor?.length
                    ? currentSelectedColor.reduce(
                        (acc, curr) => (acc.length > 0 ? `${acc} / ${curr.value}` : curr.value),
                        '',
                      ) + (currentSelectedOptions?.length ? ' / ' : '')
                    : '') +
                  (currentSelectedOptions?.reduce(
                    (acc, curr) => (acc.length > 0 ? `${acc} / ${curr.value}` : curr.value),
                    '',
                  ) ?? '')
                }
                highlights={JSON.parse(filteredData.product.highlights?.value ?? '[]')}
                price={filteredData.product.priceRange}
                compareAtPrice={filteredData.product.compareAtPriceRange}
              />
            </div>
          </ProductCardLink>
        )}

        {hasColorMap && filteredData?.product?.options ? (
          <div className="pb-4 relative">
            <ProductCardColorPicker
              options={filteredData!.product!.options}
              onColorChange={(colorProp, colorName) => {
                updateColorOption(colorProp, colorName);
              }}
              onVariantSelected={(id) =>
                setSelectedVariantSku(
                  filteredData!.product?.variants.edges.find((variant) => variant.node.id === id)
                    ?.node.sku?.value,
                )
              }
              onImageChange={(imageUrls) => {
                setOptionImageUrls(imageUrls);
                setCurrentImageIndex(0);
              }}
              variantData={filteredData}
              variantSku={selectedVariantSku}
            />
          </div>
        ) : null}
        {isHovering && !isTouchDevice && (!hasColorMap || currentSelectedColor?.length) ? (
          <div className="absolute -bottom-0 -right-5 -left-5">
            <div
              ref={additionalContainer}
              className="absolute w-full flex flex-col gap-2 bg-white shadow-l3bottom px-2 pb-5 z-50"
            >
              {filteredData?.product?.variants?.edges &&
              filteredData.product.variants.edges.length > 1 &&
              (filteredData?.product?.options?.filter((o) => !isColorProp(o.name)).length ?? 0) >
                0 ? (
                <>
                  <p
                    style={{ textTransform: 'uppercase' }}
                    className="text-h-12 text-opacity-75 font-extrabold line-clamp-2"
                    title={t('quick_to_cart')}
                  >
                    {t('quick_to_cart')}
                  </p>
                  <ProductCardSelection
                    setCurrentSelectedOptions={setCurrentSelectedOptions}
                    currentSelectedColor={currentSelectedColor}
                    filteredData={filteredData}
                    setSelectedVariantSku={setSelectedVariantSku}
                    setShowAddedToCart={setShowAddedToCart}
                  />
                </>
              ) : (
                <>
                  <ButtonPrimary
                    className="mt-4 !py-2 !h-auto"
                    disabled={
                      (!selectedVariant || (selectedVariant.node.quantityAvailable ?? 0) <= 0) &&
                      (!selectedSdsVariant || (selectedSdsVariant.node.quantityAvailable ?? 0) <= 0)
                    }
                    text={t('product.intoCart')}
                    onClick={handleAddToCart}
                  />
                </>
              )}
            </div>
          </div>
        ) : (
          isTouchDevice && (
            <div className="flex flex-col">
              <button
                disabled={!selectedVariant || (!selectedVariantSku && !variantId)}
                className="disabled:text-dark-tertiary text-left py-3 text-h-12 uppercase border-t border-t-dark-quaternary px-2 flex justify-between items-center"
                onClick={() => {
                  if (hasNonColorOptions) {
                    setMobileFilterOpen(!mobileFilterOpen);
                  } else if (selectedVariant) {
                    handleAddToCart();
                    setShowAddedToCart(true);
                  }
                }}
              >
                <span>
                  <T keyName="quick_to_cart" />
                </span>
                {hasNonColorOptions &&
                  (!hasColorMap || (currentSelectedColor && currentSelectedColor.length > 0)) && (
                    <ChevronDown className="h-4 fill-current" />
                  )}
              </button>
              {mobileFilterOpen &&
              hasNonColorOptions &&
              (!hasColorMap || currentSelectedColor?.length) ? (
                <div className="w-full pb-5">
                  <ProductCardSelection
                    className="px-2 max-w-full overflow-auto"
                    currentSelectedColor={currentSelectedColor}
                    filteredData={filteredData}
                    setSelectedVariantSku={setSelectedVariantSku}
                    setShowAddedToCart={setShowAddedToCart}
                    setCurrentSelectedOptions={setCurrentSelectedOptions}
                  />
                </div>
              ) : (
                <></>
              )}
            </div>
          )
        )}
        {!!selectedVariant && showAddedToCart && (
          <ProductAddedToCart
            show={showAddedToCart}
            onClose={() => {
              setShowAddedToCart(false);
              setIsHovering(false);
            }}
            variant={
              {
                ...selectedVariant.node,
                sku: selectedVariant.node.sku?.value,
                product: { ...filteredData!.product, handle, title: filteredData!.product!.title! },
              } as any
            }
          />
        )}

        <div className="absolute z-10 flex items-center left-2 top-1 gap-2">
          {!hasStock && (
            <div className="bg-gray-200 border rounded text-p-12 text-dark-tertiary border-dark-05 px-2 py-1 h-[1.625rem]">
              <T keyName="outOfStock" />
            </div>
          )}
          {highestSale > 0 && <ProductSaleChip sale={highestSale} />}
        </div>
      </div>
    )
  );
};
