import { useEffect, useState } from 'react';

/**
 * Use this hook in order to track if the component has mounted.
 * @returns a reference to the component's mounted state.
 *
 * @example
 * const didMount = useDidMount();
 *
 * useEffect(() => {
 *   if (didMount) {
 *     // component has mounted
 *     return;
 *   }
 *   // do things that should not be done on first render cycle.
 * })
 */
export const useDidMount = () => {
  const [didMount, setDidMount] = useState(false);
  useEffect(() => {
    setDidMount(true);
  }, []);

  return didMount;
};
