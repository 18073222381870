import { useCollectionQuery } from '@generated/graphql/apollo';
import { NavMenu } from '..';
import { useTranslate } from '@tolgee/react';
import classNames from 'classnames';
import { NavList } from '@components/NavList';
import { NavListItemPrimary } from '@components/NavList/Item/Primary';
import { NavListSection } from '@components/NavList/Section';
import { CollectionTree } from '@zustand/shopData/collectionTree';
import { useNavMenuFAQZustand } from '@zustand/navMenuFAQ';
import { LoadingIndicator } from '../../LoadingIndicator';
import { NavMenuProductSlider } from '../Product/Scroller';
import { NavMenuProductList } from '../Product/List';
import { faqNavMenuList } from '../../Menu/links';
import { useLocale } from '@zustand/useLocale';
import { ensureLanguage } from '@lib/utils';

export const NavMenuFaq = () => {
  const { t } = useTranslate();
  const { language, locale } = useLocale();
  const { data } = useCollectionQuery({
    variables: {
      handle: 'neuheiten-root',
      language: ensureLanguage(language),
      country: ensureLanguage(locale),
    },
  });
  const { openNavMenuFAQ, setOpenNavMenuFAQ } = useNavMenuFAQZustand();

  const closeMenu = () => {
    setOpenNavMenuFAQ(false);
  };
  return (
    <NavMenu onClose={closeMenu} drawerShow={openNavMenuFAQ}>
      <>
        <div className="flex flex-row justify-start w-1/2">
          {faqNavMenuList.map((list: CollectionTree, layer) => (
            <NavList
              back={layer > 0}
              key={layer}
              title={t(list.title)}
              className={classNames(
                'flex-1 pt-10 scrollbar-hide text-accent-primary',
                layer === 0 && 'bg-[#C6C6E1] lg:px-8 xl:px-20 max-h-max',
                layer === 1 && 'bg-[#DBDBE9] lg:px-8 xl:px-20 overflow-scroll'
              )}
              style={{
                zIndex: faqNavMenuList.length - layer,
              }}
            >
              <NavListSection className="w-full max-w-[18rem]">
                {list.items?.map((item: CollectionTree, index) => (
                  <NavListItemPrimary
                    text={t(item.title)}
                    key={index}
                    directory={!!item.items}
                    {...(item?.link && { href: item.link })}
                    onClick={() => closeMenu()}
                  />
                ))}
              </NavListSection>
            </NavList>
          ))}
        </div>
        <NavMenuProductList title={t('nav.product.chosen')} className="w-1/2">
          {data?.collection?.products ? (
            <NavMenuProductSlider
              onProductSelected={() => closeMenu()}
              items={data.collection.products.edges.map(
                ({ node: { sku, image_urls, priceRange, ...rest } }, index) => ({
                  ...rest,
                  colormap: undefined,
                  key: sku?.value ?? index,
                  highlights: undefined,
                  image_urls: image_urls?.value ?? '',
                  onClick: () => closeMenu(),
                  price: priceRange,
                  sku: sku?.value,
                  totalInventory: rest.totalInventory,
                }),
              )}
            />
          ) : (
            <LoadingIndicator />
          )}
        </NavMenuProductList>
      </>
    </NavMenu>
  );
};
