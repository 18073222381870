import { MoneyV2 } from '@generated/graphql/types';

type Money = MoneyV2 | { amount?: number | null } | null;

export const percentOf = (current?: Money, compareAt?: Money) => {
  const compareAtAmount = compareAt?.amount ?? 0;
  const currentAtAmount = current?.amount ?? 0;
  if (compareAtAmount === 0) {
    return 0;
  }
  const diff = currentAtAmount / compareAtAmount;
  return Math.round((1 - diff) * 100);
};

type VarSale = {
  edges: {
    node: {
      quantityAvailable?: number | null;
      compareAtPriceV2?: Money;
      priceV2?: Money;
    };
  }[];
};

export const highestVariantSale = (product?: VarSale) =>
  product
    ? product.edges?.reduce((acc, curr) => {
        if (!curr.node.compareAtPriceV2) {
          return acc;
        }
        const sale = percentOf(curr?.node?.priceV2, curr?.node?.compareAtPriceV2);
        return sale > acc ? sale : acc;
      }, 0)
    : 0;

export interface SkuWithAmount {
  handle: string;
  amount: number;
  collections: string[];
}

interface WithDiscountPercentage {
  discountPercentage: number;
}

export interface EligibleProductsResponse extends WithDiscountPercentage {
  handles: string[];
  /*{
    !!!! DO NOT REMOVE THIS COMMENT !!!!
    ---------------------------------------------
    This code contains the old variant join logic which is not needed anymore
    but might be needed in the future again. So please do not remove it.
    ---------------------------------------------
    !!!! DO NOT REMOVE THIS COMMENT !!!!
    id: string;
    handle: string;
  }[]*/
}

export interface EligibleProduct extends WithDiscountPercentage {
  handle: string;
}
