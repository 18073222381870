import useResponsive from '@hooks/useResponsive';
import { Slider } from '@components/Slider';
import { ProductCardVertical } from '@components/Product/Card/ProductCardVertical';
import type { ProductCardProps } from '@components/Product/Card/shared';
import { LoadingIndicator } from '@components/LoadingIndicator';
import { getProductListCarouselClickedGtmPayload, GtmProductListType } from '@lib/gtm/productList';
import { useEffect, useMemo, useRef, useState } from 'react';
import useGtm from '@hooks/useGTM';
import { useTranslate } from '@tolgee/react';
import classNames from 'classnames';
import { Product } from '@generated/graphql/types';

interface ProductSliderProps {
  items?: (Item | string | null)[];
  title?: string;
  loading?: boolean;
  error?: boolean;
  errorText?: string;
  emptyText?: string;
  gtmProductListType?: GtmProductListType;
}

type Item = ProductCardProps & {
  id: string;
  image_urls?: string;
  sku?: string;
  justSku?: string;
  compareAtPriceRange?: Product['compareAtPriceRange'];
  variants: { edges: { node: { quantityAvailable?: number | null; id?: string } }[] };
};
export const ProductSlider = ({
  items: _items,
  title,
  loading,
  error,
  errorText,
  emptyText,
  gtmProductListType,
}: ProductSliderProps) => {
  const width = useResponsive();
  const sendDataToGTM = useGtm();
  const items = useMemo<(Item | string)[]>(
    () => (_items?.filter((i) => !!i) ?? []) as Item[],
    [_items],
  );
  const steps = () => (width <= 1024 ? (width <= 800 ? 1.05 : 2) : 4);
  const [slidesToShow, setSlidesToShow] = useState<number>(steps());

  const minIndex = useRef(0);
  const maxIndex = useRef(steps());
  const totalItemsViewedCounter = useRef(width <= 1024 ? 2 : 3);
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const { t } = useTranslate();

  useEffect(() => {
    setSlidesToShow(steps());
  }, [width]);

  const handleProductClicked = (sku?: string) => {
    if (!items) {
      return;
    }
    if (!gtmProductListType) {
      return;
    }
    const list_of_products: Array<string> = items
      .map((e) => (typeof e === 'string' ? e : e.justSku))
      .filter((s) => !s) as string[];
    sendDataToGTM(
      getProductListCarouselClickedGtmPayload({
        listDetails: {
          numberOfClicks: totalItemsViewedCounter.current,
          products: list_of_products,
          routedToProduct: sku,
          totalUniqueProductsCount: list_of_products.length,
        },
        listType: gtmProductListType,
      }),
    );
    return true;
  };

  const handleIndexChange = (index: number) => {
    if (!items) {
      return;
    }

    totalItemsViewedCounter.current++;
    maxIndex.current = Math.max(maxIndex.current, steps());
    if (index + steps() === maxIndex.current + 1) {
      maxIndex.current++;
    } else if (index === minIndex.current - 1 || index - items.length === minIndex.current - 1) {
      minIndex.current--;
    }
  };

  return (
    <div className="mx-auto space-y-3 lg:space-y-6" role="none">
      {title && <h2 className="text-h-24 lg:text-h-32">{title}</h2>}

      {loading ? (
        <LoadingIndicator />
      ) : error ? (
        <div>{errorText || t('productSlider.error')}</div>
      ) : items?.length ? (
        <Slider
          className={classNames('product-list-related-slider pb-10', !title ? 'mt-16' : '!mt-4')}
          dots={false}
          infinite={false}
          speed={400}
          slidesToShow={slidesToShow}
          slidesToScroll={1}
          afterChange={(i) => {
            handleIndexChange(i % items.length);
            setSelectedIndex(i);
          }}
        >
          {[...Array(items.length > 3 ? items.length : 3)].map((_, index) => {
            if (index > items.length - 1) {
              return <div key={index} />;
            }

            let currentItem = items[index];
            if (typeof currentItem === 'string') {
              return (
                <ProductCardVertical
                  handle={currentItem.split('/')[0]}
                  variantSku={currentItem.split('/')[1]}
                  className={index === selectedIndex && width < 768 ? '!ml-0' : undefined}
                  imageLoading="lazy"
                  onClick={handleProductClicked}
                  key={index}
                />
              );
            } else {
              const { id, variants, ...item } = currentItem;

              return (
                <ProductCardVertical
                  {...item}
                  imageLoading="lazy"
                  className={
                    index === Math.round(selectedIndex) && width < 768
                      ? '!ml-0 transition-all duration-300'
                      : undefined
                  }
                  onClick={handleProductClicked}
                  key={index}
                />
              );
            }
          })}
        </Slider>
      ) : (
        <div>{emptyText || t('productSlider.empty')} </div>
      )}
    </div>
  );
};
