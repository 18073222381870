import Link from 'next/link';
import { T } from '@tolgee/react';

type LoginLandingPageProps = {
  onLinkClick: () => void;
};

export const LoginLandingPage = ({ onLinkClick }: LoginLandingPageProps) => (
  <div className="flex flex-col gap-3 font-bold uppercase">
    <Link href="/customer/orders" passHref>
      <a
        className="hover:underline"
        tabIndex={0}
        role="button"
        onClick={onLinkClick}
        onKeyDown={onLinkClick}
      >
        <T keyName="orders.headline" />
      </a>
    </Link>
    <Link href="/customer" passHref>
      <a
        className="hover:underline"
        tabIndex={-1}
        role="button"
        onClick={onLinkClick}
        onKeyDown={onLinkClick}
      >
        <T keyName="customer.profile.title" />
      </a>
    </Link>
  </div>
);
