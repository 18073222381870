import { RichText } from '@components/RichText';
import { DisclosureContent } from '@components/disclosure/DisclosureContent';
import { YoutubeBlock } from '../ContentBlocks/YoutubeBlock';
import { FAQContent } from './FaqPage';

export type FAQSectionProps = {
  section: ArrayElementOf<FAQContent['body']>;
  blok?: ArrayElementOf<FAQContent['body']>;
};

export const FAQSection: React.FC<FAQSectionProps> = ({ section: s, blok }) => {
  const section = blok || s;

  return (
    <div className={blok ? 'flex flex-row justify-center ' : ''} key={section.title}>
      <div className="h-6 relative w-full mb-6">
        <div
          style={{
            background:
              section.section === 'sprenger'
                ? 'var(--accent-primary)'
                : `var(--special-${section.section})`,
          }}
          className={blok ? 'absolute right-0 h-6 w-full' : 'absolute right-0 h-6 w-screen'}
        />
      </div>
      <h2
        className="mb-10 uppercase font-extrabold text-h-32"
        style={{
          color:
            section.section === 'sprenger'
              ? 'var(--accent-primary)'
              : `var(--special-${section.section})`,
        }}
      >
        {section.title}
      </h2>
      <div className="space-y-20 mb-20">
        {section.entries.map((entry) => (
          <div key={entry.title} id={section.title + entry.title} className="mb-8 mt-8">
            {!(section.entries.length === 1 && entry.title === section.title) && (
              <h3 className="text-h-24 scroll-mt-36 lg:scroll-mt-24 mb-4">{entry.title}</h3>
            )}
            {entry.entries?.map((e) => (
              <DisclosureContent seo_answer={e.seo_answer} title={e.title} key={e.title}>
                <RichText className="mb-8" text={e.text} />
                {e.youtube &&
                  e.youtube.map((youtube, i) => <YoutubeBlock blok={youtube} key={i} />)}
              </DisclosureContent>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};
