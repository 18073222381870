import { Menu, Transition } from '@headlessui/react';
import classNames from 'classnames';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { forwardRef, ReactNode } from 'react';
import { ChevronUp } from '../generated/icons';

export interface DropdownArgs {
  className?: string;
  menuClassName?: string;
  menuButtonClassName?: string;
  items: Array<MenuItemProps>;
  ActiveItem: JSX.Element;
  iconOnly?: boolean;
}

const Dropdown = ({
  className,
  menuClassName,
  items,
  ActiveItem,
  menuButtonClassName,
  iconOnly,
}: DropdownArgs) => {
  const router = useRouter();
  const path = router.pathname;
  return (
    <Menu as="div" className={classNames('relative z-10 w-full', className)}>
      {({ open }) => (
        <>
          <Menu.Button
            className={classNames(
              'flex items-center justify-between w-full px-4 rounded-lg outline-none bg-accent-primary_005 text-accent-primary',
              menuButtonClassName,
            )}
          >
            {ActiveItem
              ? ActiveItem
              : items.map((props: MenuItemProps, index) => {
                  const { href } = props;
                  const hrefPathPart = href?.split('/')[2];
                  const otherPathPart = path.split('/')[2];

                  return hrefPathPart === otherPathPart && <MenuItem key={index} {...props} />;
                })}
            {
              <ChevronUp
                className={classNames('w-6 h-6 transition-all', open ? '' : 'rotate-180')}
              />
            }
          </Menu.Button>
          <Transition
            show={open}
            enter="transition duration-200 ease-out"
            enterFrom="transform scale-90 opacity-0"
            enterTo="transform scale-100 opacity-100"
            leave="transition duration-100 ease-out"
            leaveFrom="transform scale-100 opacity-100"
            leaveTo="transform scale-90 opacity-0"
          >
            <Menu.Items
              className={classNames(
                'absolute z-10 text-black flex flex-col p-2 mt-4 bg-white rounded-lg outline-none top-full shadow-l2 w-full min-w-fit right-0',
                iconOnly ? 'mr-4' : 'max-w-full',
                menuClassName,
              )}
            >
              {items.map((item, index) => (
                <MenuItem {...item} key={index} />
              ))}
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  );
};

export type MenuItemProps = {
  text?: string | JSX.Element;
  Icon?: JSX.Element;
  textRight?: string;
  active?: boolean;
} & MenuItemWrapperProps;

const MenuItem = ({
  href,
  className,
  Icon: IconComp,
  text,
  textRight,
  onClick,
  active,
}: MenuItemProps) => (
  <Menu.Item>
    {({}) => (
      <MenuItemWrapper
        href={href}
        onClick={() => {
          // close(); FIXME: When @headlessui/react is updated
          onClick?.();
        }}
        className={classNames(
          'group flex items-center pl-3 text-sm font-medium duration-200 transition-colors rounded-md justify-between gap-10',
          href || onClick
            ? 'hover:bg-accent-primary_005 hover:text-accent-primary'
            : 'cursor-default',
          text ? 'pr-3' : 'pr-2',
          IconComp ? 'py-1' : 'py-3',
          className,
          active ? 'bg-accent-primary_005 text-accent-primary' : '',
        )}
      >
        <div className="flex items-center">
          {IconComp != null && IconComp}
          {text &&
            (typeof text === 'string' ? <span className="truncate text-p-16">{text}</span> : text)}
        </div>
        {textRight &&
          (typeof textRight === 'string' ? (
            <span className="text-right whitespace-nowrap text-p-16">{textRight}</span>
          ) : (
            textRight
          ))}
      </MenuItemWrapper>
    )}
  </Menu.Item>
);

type MenuItemWrapperProps = {
  href?: string;
  className?: string;
  children?: ReactNode;
  onClick?: () => void;
};

const MenuItemWrapper = forwardRef(
  ({ href, onClick, className, children }: MenuItemWrapperProps, ref) =>
    href ? (
      // @ts-ignore
      <Link ref={ref} href={href}>
        <a onClick={onClick} className={className}>
          {children}
        </a>
      </Link>
    ) : (
      // @ts-ignore
      <button ref={ref} className={className} aria-hidden="true" onClick={onClick}>
        {children}
      </button>
    ),
);
export default Dropdown;
