import { ContentBlock12Props } from '@customTypes/storyblok-types';
import { storyblokEditable } from '@storyblok/react';
import classNames from '@utils/ClassNames';
import { ImageHover } from '@components/Image/ImageHover';
import { StoryblokImageOrVideo } from '@components/Image/StoryblokImageOrVideo';
import { RichText } from '@components/RichText';
import { StoryblokButton } from '@components/Button/StoryblokButton';
import React from 'react';

export const ContentBlock12 = ({ blok }: { blok: ContentBlock12Props }) => {
  const button = blok.button_text
    ? { text: blok.button_text, url: blok.button_link!.url, className: 'w-full my-6' }
    : undefined;

  return (
    <div
      /* @ts-ignore*/
      {...storyblokEditable(blok)}
      className={classNames(blok.blue_background && "bg-accent-primary_005")}
    >
      <div className="box-x-wrapper flex justify-center py-11 md:py-16">
        <div
          className={classNames(
            'flex content-center h-full',
            blok.size === 'small' ? 'lg:w-2/3' : 'w-full',
            blok.text_emphasis === 'top' ? 'flex-col' : 'flex-col-reverse gap-4',
          )}
        >
          <div className="center-block">
            <div className="text-h-32 pb-4 hyphens-auto">{blok.title}</div>
            <RichText className="text-p-16 pb-4" text={blok.description} />
            <div className="flex flex-col md:items-start">
              {button && <StoryblokButton {...button} />}
            </div>
          </div>
          {blok.image_1.filename && blok.image_2.filename && !blok.video_id && (
            <ImageHover
              className="pt-4"
              image_1={blok.image_1}
              image_2={blok.image_2}
              objectFit="cover"
            />
          )}
          {blok.image_1.filename && !blok.image_2.filename && (blok.video_id || blok.shopTheLook) && (
            <StoryblokImageOrVideo
              filename={blok.image_1.filename}
              alt={blok.image_1?.alt ?? undefined}
              focus={blok.image_1.focus}
              layout="responsive"
              width="2560"
              height="1440"
              sizes="75vw"
              objectFit="cover"
              video_id={blok.video_id}
              autoplay={blok.autoplay}
              shopTheLook={blok.shopTheLook}
              className="aspect-video w-full"
            />
          )}
        </div>
      </div>
    </div>
  );
};
