import { LoadingIndicator } from '@components/LoadingIndicator';
import { useCollectionQuery } from '@generated/graphql/apollo';
import { NavMenu } from '..';
import { NavMenuProductList } from './List';
import { NavMenuProductSlider } from './Scroller';
import { getCollectionPath } from '@lib/getCollectionPath';
import { useTranslate } from '@tolgee/react';
import classNames from 'classnames';
import { NavList } from '@components/NavList';
import { NavListItemPrimary } from '@components/NavList/Item/Primary';
import { NavListSection } from '@components/NavList/Section';
import { CollectionTree, useCollectionTreeZustand } from '@zustand/shopData/collectionTree';
import { useEffect, useState } from 'react';
import { useNavMenuProductZustand } from '@zustand/navMenuProduct';
import { useRouter } from 'next/router';
import { ButtonPrimary } from '../../Button/Primary';
import { useLocale } from '@zustand/useLocale';
import { ensureLanguage } from '@lib/utils';

export const NavMenuProduct = () => {
  const { t } = useTranslate();
  const { openNavMenuBranch, setOpenNavMenuProduct } = useNavMenuProductZustand();
  const router = useRouter();
  const { language, locale } = useLocale();

  const { data } = useCollectionQuery({
    variables: {
      handle: `${openNavMenuBranch}-selection`,
      language: ensureLanguage(language),
      country: ensureLanguage(locale),
    },
  });
  const rootElement = useCollectionTreeZustand();
  const [navMenuList, setNavMenuList] = useState<CollectionTree[]>([rootElement]);

  useEffect(() => {
    if (!openNavMenuBranch) {
      return;
    }

    // get the list of either dog or horse collection tree (or boat).
    const rootBranchName = `${openNavMenuBranch}-root`;
    const possibleBranch = rootElement.items?.find((s) => s.link === rootBranchName);
    setNavMenuList(possibleBranch ? [possibleBranch] : []);
  }, [openNavMenuBranch]);

  const closeMenu = () => {
    setOpenNavMenuProduct(null);
  };

  useEffect(() => {
    router.events.on('routeChangeStart', closeMenu);
    return () => router.events.off('routeChangeStart', closeMenu);
  }, []);

  return (
    <NavMenu
      onAfterLeave={() => setNavMenuList([rootElement])}
      onClose={closeMenu}
      drawerShow={!!openNavMenuBranch}
    >
      <>
        <div className="flex flex-row justify-start w-1/2">
          {navMenuList.map((list: CollectionTree, layer) => {
            const sliceMenuToTargetLayer = navMenuList.slice(0, layer);
            return list ? (
              <NavList
                back={layer > 0}
                key={layer}
                href={`/collections/${list.link}`}
                title={list.title}
                className={classNames(
                  'flex-1 scrollbar-hide text-accent-primary',
                  layer === 0 && 'bg-[#C6C6E1] lg:px-8 xl:px-20 max-h-max',
                  layer === 1 && 'bg-[#DBDBE9] lg:px-8 xl:px-20 overflow-scroll'
                )}
                onClick={() => {
                  setNavMenuList(sliceMenuToTargetLayer);
                }}
                style={{
                  zIndex: navMenuList.length - layer,
                }}
              >
                <NavListSection className="w-full max-w-[18rem]">
                  {list.items?.map((item: CollectionTree, index) => (
                    <NavListItemPrimary
                      text={item.title}
                      key={index}
                      directory={!!item.items}
                      {...(!item.items && { href: getCollectionPath(item.link) })}
                      onClick={
                        !item.items
                          ? () => closeMenu()
                          : () => setNavMenuList([...navMenuList.slice(0, layer + 1), item])
                      }
                    />
                  ))}
                </NavListSection>
                {layer === 0 && (
                  <ButtonPrimary
                    href={`/collections/${openNavMenuBranch}-root`}
                    className="self-start mt-6 hover:bg-transparent"
                    text={t('nav.product.all')}
                  />
                )}
              </NavList>
            ) : (
              <></>
            );
          })}
        </div>
        <NavMenuProductList title={t('nav.product.chosen')} className="w-1/2">
          {data?.collection?.products ? (
            <NavMenuProductSlider
              onProductSelected={() => closeMenu()}
              items={data.collection.products.edges.map(
                ({
                  node: {
                    variants,
                    title,
                    handle,
                    sku,
                    image_urls,
                    priceRange,
                    id,
                    compareAtPriceRange,
                    collections,
                    totalInventory,
                  },
                }) => ({
                  collections,
                  compareAtPriceRange,
                  handle,
                  id,
                  key: id,
                  image_urls: image_urls?.value ?? '',
                  onClick: () => closeMenu(),
                  price: priceRange,
                  sku: sku?.value,
                  title,
                  totalInventory,
                  variants: {
                    ...variants,
                    edges: variants.edges.filter(({ node }) => node.isArchived?.value !== 'true'),
                  },
                }),
              )}
            />
          ) : (
            <LoadingIndicator />
          )}
        </NavMenuProductList>
      </>
    </NavMenu>
  );
};
