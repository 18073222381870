import classNames from '@utils/ClassNames';
import { ChevronRight, ChevronLeft } from '@components/icons';

/**
 * Default height: 50px, adjust with classNames.
 */
export const Chevron = ({
  direction,
  className,
  onClick,
}: {
  direction: 'left' | 'right';
  className?: string;
  onClick: () => void;
}) => (
  <button
    className={classNames(
      'p-2 my-auto rounded-full pointer-events-auto text-dark-secondary bg-light-secondary border-2 transition-colors border-transparent hover:border-accent-primary hover:text-accent-primary active:border-accent-primary active:text-accent-primary',
      className ?? 'w-[3.125rem] h-[3.125rem]',
    )}
    onClick={(e) => {
      e.preventDefault();
      onClick();
      e.stopPropagation();
    }}
  >
    {direction === 'left' ? <ChevronLeft /> : <ChevronRight />}
  </button>
);
