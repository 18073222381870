import { ProductCardImage } from './ProductCardImage';
import { ProductCardHorizontalDescription } from './ProductCardHorizontalDescription';
import type { ProductCardHorizontalProps } from './shared';
import { ProductCardLink } from './ProductCardLink';
import { image_urlsAsArray } from '@lib/utils';

export const ProductCardHorizontal = ({
  className,
  imageLoading,
  image_urls,
  handle,
  title,
  variantTitle,
  selectedOptions,
  price,
  variantId,
  highlights,
  image,
  collections,
  onClick,
  compareAtPriceRange,
  compareAtPrice,
  showSelectedOptions,
  showUnitPrice,
  variant,
  wrapUnitPrice,
  discountAllocations,
  quantity,
  percentageOff,
  taxIncluded,
}: ProductCardHorizontalProps) => (
  <ProductCardLink
    collections={collections}
    handle={handle}
    variantId={variantId}
    onClick={onClick}
    className={className}
  >
    <div className="flex gap-x-4 w-full flex-1 items-start">
      <div className="flex-shrink-0 relative bg-accent-primary_005 w-20 h-20 aspect-square p-1">
        <ProductCardImage
          layout="intrinsic"
          image={{
            altText: title,
            height: 768,
            src: image_urls
              ? image_urlsAsArray(
                typeof image_urls === 'object' ? image_urls?.value : image_urls,
              )[0]
              : image?.src,
            width: 768,
          }}
          imageLoading={imageLoading}
        />
      </div>
      <ProductCardHorizontalDescription
        title={title!}
        variantTitle={variantTitle}
        price={price!}
        highlights={highlights}
        selectedOptions={selectedOptions}
        showSelectedOptions={showSelectedOptions}
        variant={variant}
        wrapUnitPrice={wrapUnitPrice}
        showUnitPrice={showUnitPrice}
        compareAtPrice={compareAtPriceRange || compareAtPrice}
        percentageOff={percentageOff}
        quantity={quantity}
        discountAllocations={discountAllocations}
        taxIncluded={taxIncluded}
      />
    </div>
  </ProductCardLink>
);
