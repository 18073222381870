import { CustomerQuery } from '@generated/graphql/types';

export interface GtmCustomerDetails {
  hasAccount: boolean;
  ordersCount: number;
}

export const getGtmCustomerDetails = (customer: CustomerQuery['customer'] | null) =>
  customer
    ? { hasAccount: true, ordersCount: customer.orders.edges.length }
    : { hasAccount: false, ordersCount: 0 };
