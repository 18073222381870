const plugin = require('tailwindcss/plugin');
const svgToDataUri = require('mini-svg-data-uri');

module.exports = {
  content: ['./components/**/*.{js,ts,jsx,tsx}', './pages/**/*.{js,ts,jsx,tsx}'],
  future: {
    hoverOnlyWhenSupported: true,
  },
  plugins: [
    require('@tailwindcss/forms'),
    // https://github.com/reslear/tailwind-scrollbar-hide/blob/main/src/index.js
    plugin(({ addUtilities }) => {
      addUtilities(
        {
          '.scrollbar-hide': {
            /* IE and Edge */
            '-ms-overflow-style': 'none',

            /* Firefox */
            'scrollbar-width': 'none',

            /* Safari and Chrome */
            '&::-webkit-scrollbar': {
              display: 'none',
            },
          },

          '.scrollbar-default': {
            /* IE and Edge */
            '-ms-overflow-style': 'auto',

            /* Firefox */
            'scrollbar-width': 'auto',

            /* Safari and Chrome */
            '&::-webkit-scrollbar': {
              display: 'block',
            },
          },
        },
        ['responsive'],
      );
    }),
    // https://github.com/tailwindlabs/tailwindcss-forms/issues/17
    plugin(function ({ addComponents }) {
      addComponents({
        select: {
          'background-image': `url("${svgToDataUri(
            `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20"><path stroke="#1D1EA04B" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M6 8l4 4 4-4"/></svg>`,
          )}")`,
        },
      });
    }),
  ],
  theme: {
    boxShadow: {
      l1: '0px 2px 8px rgba(27, 32, 50, 0.08)',
      l2: '0px 6px 24px rgba(27, 32, 50, 0.1)',
      l3: '0px 10px 40px rgba(27, 32, 50, 0.12)',
      l3bottom: '0px 30px 40px rgba(27, 32, 50, 0.12)',
      l4: '0px 24px 128px rgba(27, 32, 50, 0.14)',
      none: 'none',
    },
    extend: {
      transitionTimingFunction: {
        nice: 'cubic-bezier(0.5, -0.300, 0.5, 1.30)',
      },
      screens: {
        'lg-after': '1025px',
      },
      transitionDelay: {
        2000: '2000ms',
      },
      opacity: {
        35: '0.35',
      },
      borderWidth: {
        16: '16px',
        24: '24px',
      },
      gridTemplateColumns: {
        technicalDataGrid: 'repeat(1, 0.1fr 0.7fr)',
        pdpButtonGrid: 'repeat(1, 1fr auto)',
      },
      animation: {
        marquee: 'marquee 25s linear infinite',
        marquee2: 'marquee2 25s linear infinite',
      },
      keyframes: {
        marquee: {
          '0%': { transform: 'translateX(0%)' },
          '100%': { transform: 'translateX(-100%)' },
        },
        marquee2: {
          '0%': { transform: 'translateX(100%)' },
          '100%': { transform: 'translateX(0%)' },
        },
      },
      colors: {
        accent: {
          primary: '#1D1EA0',
          pressed: '#2728B2',
          hover: '#2425C8',
          primary_005: '#1D1EA00D',
          primary_01: '#e8e9f6',
          primary_02: '#d2d2ec',
          'off-white': '#F4F4FA',
        },
        dark: {
          background: 'rgba(39, 41, 55, 0.05)',
          black: '#000',
          primary: '#272937',
          quaternary: 'rgba(39, 41, 55, 0.12)',
          secondary: 'rgba(39, 41, 55, 0.75)',
          tertiary: 'rgba(39, 41, 55, 0.35)',
          '05': 'rgba(39, 41, 55, 0.05)',
        },
        light: {
          white: '#fff',
          primary: 'rgba(255, 255, 255, 0.93)',
          secondary: 'rgba(255, 255, 255, 0.65)',
          tertiary: 'rgba(255, 255, 255, 0.35)',
          quaternary: 'rgba(255, 255, 255, 0.2)',
          background: 'rgba(255, 255, 255, 0.1)',
        },
        special: {
          danger: '#FF3A30',
          success: '#48B527',
          warn: '#FFB649',
          info: '#3385FF',
          boat: '#00C8FF',
          dog: '#FF6A5B',
          horse: '#00DEB4',
        },
      },
      flex: {
        0: '0 0 auto',
        2: '2',
        3: '3',
      },
      maxWidth: {
        '1/2': '50%',
      },
      spacing: {
        86: '86px',
        76: '19rem',
      },
      padding: {
        full: '100%',
        '13p': '13%',
      },
      translate: {
        '7/32': 'calc(21.8%)',
        '1/6': '16.66%',
        '5/6': '83.33%',
      },
      height: {
        38: '9.5rem',
      },
      aspectRatio: {
        '18/9': '18 / 9',
      },
    },
    fontSize: {
      0: '0',
      'h-152': [
        '9.5rem',
        {
          lineHeight: '9.5rem',
        },
      ],
      'h-96': [
        '6rem',
        {
          lineHeight: '5.625rem',
        },
      ],
      'h-72': [
        '4.5rem',
        {
          lineHeight: '4.5rem',
        },
      ],
      'h-56': [
        '3.5rem',
        {
          lineHeight: '3.75rem',
        },
      ],
      'h-40': [
        '2.5rem',
        {
          letterSpacing: '-.09375rem',
          lineHeight: '2.75rem',
        },
      ],
      'h-32': [
        '2rem',
        {
          letterSpacing: '-.0625rem',
          lineHeight: '2.25rem',
        },
      ],
      'h-24': [
        '1.5rem',
        {
          letterSpacing: '-.0625rem',
          lineHeight: '2rem',
        },
      ],
      'h-20': [
        '1.25rem',
        {
          letterSpacing: '-.05rem',
          lineHeight: '1.75rem',
        },
      ],
      'h-16': [
        '1rem',
        {
          lineHeight: '1.5rem',
        },
      ],
      'h-14': [
        '.875rem',
        {
          letterSpacing: '-.00625rem',
          lineHeight: '1.25rem',
        },
      ],
      'h-12': [
        '.75rem',
        {
          letterSpacing: '.01875rem',
          lineHeight: '1rem',
        },
      ],
      'p-24': [
        '1.5rem',
        {
          letterSpacing: '-.0625rem',
          lineHeight: '2rem',
        },
      ],
      'p-20': [
        '1.25rem',
        {
          letterSpacing: '-.05rem',
          lineHeight: '1.75rem',
        },
      ],
      'p-16': [
        '1rem',
        {
          letterSpacing: '-.0375rem',
          lineHeight: '1.5rem',
        },
      ],
      'p-14': [
        '.875rem',
        {
          letterSpacing: '-.00625rem',
          lineHeight: '1.25rem',
        },
      ],
      'p-12': [
        '.75rem',
        {
          letterSpacing: '.01875rem',
          lineHeight: '1rem',
        },
      ],
      16: '1rem',
    },
  },
};
