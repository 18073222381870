import { useCollectionLazyQuery } from '@generated/graphql/apollo';
import { SliderProps } from '@customTypes/storyblok-types';
import { LoadingIndicator } from '@components/LoadingIndicator';
import { ProductSlider } from '@components/Slider/ProductSlider';
import { GtmProductListType } from '@lib/gtm/productList';
import { storyblokEditable } from '@storyblok/react';
import { useLocale } from '@zustand/useLocale';
import { ensureLanguage } from '@lib/utils';
import { useEffect } from 'react';

export const Slider = ({ blok }: { blok: SliderProps }) => {
  const { language, locale } = useLocale();

  const [query, { data, loading, error }] = useCollectionLazyQuery();

  useEffect(() => {
    if (blok.collection_slug) {
      query({
        variables: {
          handle: blok.collection_slug,
          language: ensureLanguage(language),
          country: ensureLanguage(locale),
        },
        fetchPolicy: "cache-and-network"
      })
    }
  }, [blok, locale, language])

  return (
    <div
      /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
      /* @ts-ignore*/
      {...storyblokEditable(blok)}
      className="w-full overflow-x-hidden !-mb-40 !pb-40"
    >
      <div className="space-y-wrapper box-x-wrapper">
        {blok.collection_slug && data?.collection?.products ? (
          <ProductSlider
            items={data.collection?.products.edges
              .map(
                ({
                  node: {
                    title,
                    handle,
                    highlights,
                    image_urls,
                    priceRange,
                    id,
                    dreid,
                    video,
                    sku,
                    variants,
                    compareAtPriceRange,
                    collections,
                    totalInventory,
                  },
                }) => ({
                  collections,
                  compareAtPriceRange,
                  dreid,
                  handle,
                  highlights: highlights?.value?.length ? JSON.parse(highlights.value) : [],
                  id,
                  image_urls: image_urls?.value,
                  price: priceRange,
                  priceRange,
                  sku: sku?.value,
                  title,
                  totalInventory,
                  variants,
                  video,
                }),
              )
              .filter((e) => e.variants.edges.every((e) => e.node.isArchived?.value !== 'true'))}
            title={blok.title}
            gtmProductListType={GtmProductListType.CarouselMostLovedProducts}
            loading={loading}
            error={!!error}
          />
        ) : blok.products ? (
          <ProductSlider
            items={blok.products.map((e) => e.productOrVariant)}
            title={blok.title}
            gtmProductListType={GtmProductListType.CarouselMostLovedProducts}
            loading={loading}
            error={!!error}
          />
        ) : (
          <LoadingIndicator />
        )}
      </div>
    </div>
  );
};
