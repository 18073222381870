import { GtmEvent, GtmEventType } from './types';

export enum GtmProductListType {
  Overview = 'Overview',
  Collection = 'Collection',
  Featured = 'Featured Products / Ausgewaehlte Produkte',
  CarouselYouMightAlsoLike = 'Carousel: You might also like',
  CarouselMostLovedProducts = 'Carousel: Most loved',
  Other = 'Other',
  Order = 'Order',
}

interface ListDetails {
  totalProductsCount: number;
  totalUniqueProductsCount?: number;
  products?: Array<string>;
  percentProductsViewed: number;
  routedToProduct?: string | null;
}
interface CarouselListDetails {
  numberOfClicks: number;
  totalUniqueProductsCount: number;
  products?: Array<string>;
  routedToProduct?: string;
}

interface SearchResultsViewedPayload {
  query: string;
  listDetails: Omit<ListDetails, 'totalUniqueProductsCount'>;
}

export const getGtmPayloadProductListSearchResultViewed = ({
  query,
  listDetails,
}: SearchResultsViewedPayload): SearchResultsViewedPayload & GtmEvent => ({
  event: GtmEventType.SearchResultViewed,
  listDetails,
  query,
});

interface SearchUsedViewedPayload {
  query: string;
}

export const getGtmPayloadSearchUsed = ({
  query,
}: SearchUsedViewedPayload): SearchUsedViewedPayload & GtmEvent => ({
  event: GtmEventType.SearchUsed,
  query,
});

interface SearchResultClickedPayload {
  query: string;
  handle: string;
}

export const getGtmPayloadSearchResultClicked = ({
  query,
  handle,
}: SearchResultClickedPayload): SearchResultClickedPayload & GtmEvent => ({
  event: GtmEventType.SearchResultViewed,
  handle,
  query,
});

interface ProductListClickedPayload {
  listType: GtmProductListType | `Collection: ${string}`; // /en/products
  listDetails: ListDetails;
}

export const getProductListClickedGtmPayload = ({
  listDetails,
  listType,
}: ProductListClickedPayload): ProductListClickedPayload & GtmEvent => ({
  event: GtmEventType.ProductListClicked,
  listDetails,
  listType,
});

interface CarouselClickedPayload {
  listType: GtmProductListType; // you might also like, unsere beliebtesten produkte, cart carousel
  listDetails: CarouselListDetails;
}

export const getProductListCarouselClickedGtmPayload = ({
  listDetails,
  listType,
}: CarouselClickedPayload): CarouselClickedPayload & GtmEvent => ({
  event: GtmEventType.CarouselClicked,
  listDetails,
  listType,
});
