import { T } from '@tolgee/react';
import { MagazinTitleProps } from '@customTypes/storyblok-types';
import SvgMagazintitleline from '../generated/logos/Magazintitleline';
import { useEffect, useState } from 'react';
import { useLocale } from '@zustand/useLocale';

export const EditorialSectionMagazinTitle = ({ title, author, date }: MagazinTitleProps) => {
  const [dateString, setDateString] = useState('');
  const { language } = useLocale();

  useEffect(() => {
    if (language && date) {
      setDateString(
        new Date(date).toLocaleDateString(language, {
          day: '2-digit',
          month: 'long',
          year: 'numeric',
        }),
      );
    }
  }, [language, date]);

  return (
    <div className="flex flex-col-reverse w-full space-y-reverse md:flex-col box-x-wrapper">
      <div className="flex flex-row justify-center w-full h-full z-0">
        <div className="md:flex md:flex-col w-full md:w-2/3 md:pl-1.5 text-center">
          <h3 className="text-h-32 md:text-h-72 mb-2 md:mb-4 pt-11 md:pt-24">{title}</h3>
          <span className="text-p-12">
            <T keyName="magazinTitleByAuthor" /> {author}, {dateString}
          </span>
          <SvgMagazintitleline className="pt-4 md:pt-8" />
        </div>
      </div>
    </div>
  );
};
