import { XCircle } from '@components/icons';
import { shippingMarkets } from '@lib/shippingCountries';
import { CountryCode, LanguageCode } from '@generated/graphql/types';
import { currencyCodeToSymbol, languageCodeToCountryCode } from '@lib/utils';
import { useLocale } from '@zustand/useLocale';
import { useRouter } from 'next/router';
import { useTranslate } from '@tolgee/react';
import { SUPPORTED_LANGUAGES } from '@lib/constants';
import classNames from 'classnames';

type CountryChooserProp = {
  onClose: () => void;
  onSelected: () => void;
};

export const CountryChooser = ({ onClose, onSelected }: CountryChooserProp) => {
  const { setLocale, language, locale, setLang } = useLocale();
  const router = useRouter();

  const { t } = useTranslate();

  return (
    <>
      <div className="fixed z-[60] left-1/2 top-1/2 transform bg-white -translate-x-1/2 -translate-y-1/2 flex flex-col w-full h-full md:h-auto md:w-[413px] sm:max-w-[95%]">
        <button onClick={() => onClose()} className="ml-auto">
          <XCircle className="w-10 h-10 text-accent-primary mr-4 mt-6" />
        </button>
        <div className="px-4 mt-6">
          <div className="text-p-24">{t('languagePicker.title')}</div>
          <div className="flex flex-col gap-4 my-8">
            {SUPPORTED_LANGUAGES.map((e) => {
              const iconFromCode = languageCodeToCountryCode(e);
              return (
                <button
                  onClick={() => {
                    setLang(e as LanguageCode);
                    router.replace(
                      { pathname: router.pathname, query: router.query },
                      router.asPath,
                      {
                        locale: `${e}-${locale}`,
                      },
                    );
                  }}
                  className={classNames(
                    'flex justify-between hover:bg-accent-primary_005 py-2 px-1',
                    e === language ? 'bg-accent-primary_005' : '',
                  )}
                >
                  <div className="flex items-center gap-2">
                    <img
                      src={`/static/flags/${iconFromCode}.svg`}
                      width={28}
                      height={20}
                      className="rounded-sm"
                    />
                    {t(`languagePicker.languages.${e}`)}
                  </div>
                </button>
              );
            })}
          </div>

          <div className="text-p-24">{t('country.select.title')}</div>
          <div className="text-p-16 text-dark-tertiary">{t('country.select.subtitle')}</div>
          <div className="flex flex-col gap-4 my-8">
            {shippingMarkets.map((e) => {
              const iconFromCode = e.regions.nodes[0].code.toLowerCase();

              return (
                <button
                  onClick={() => {
                    setLocale(iconFromCode.toUpperCase() as CountryCode);
                    router.replace(
                      { pathname: router.pathname, query: router.query },
                      router.asPath,
                      {
                        locale: `${language}-${iconFromCode.toUpperCase()}`,
                      },
                    );
                    onSelected();
                  }}
                  className="flex justify-between hover:bg-dark-quaternary py-2 px-1"
                >
                  <div className="flex items-center gap-2">
                    <img
                      src={`/static/flags/${iconFromCode}.svg`}
                      width={28}
                      height={20}
                      className="rounded-sm"
                    />
                    {t(`country.${e.regions.nodes[0].code}`)}
                  </div>

                  <div>
                    {currencyCodeToSymbol(e.currencySettings.baseCurrency.currencyCode)}{' '}
                    {e.currencySettings.baseCurrency.currencyCode}
                  </div>
                </button>
              );
            })}
          </div>
        </div>
      </div>
      <div
        onClick={() => onClose()}
        className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-25 z-50"
      ></div>
    </>
  );
};
