import { ContentBlock14Props } from '@customTypes/storyblok-types';
import { EditorialSection14 } from '@components/EditorialSection/Fourteen';
import { storyblokEditable } from '@storyblok/react';

export const ContentBlock14 = ({ blok }: { blok: ContentBlock14Props }) => (
  <EditorialSection14
    /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
    /* @ts-ignore*/
    {...storyblokEditable(blok)}
    headline={blok.headline}
    title_1={blok.title_1}
    text_content_1={blok.text_content_1}
    image_1={blok.image_1}
    link_1={blok.link_1}
    content_branch_1={blok.content_branch_1}
    title_2={blok.title_2}
    text_content_2={blok.text_content_2}
    image_2={blok.image_2}
    link_2={blok.link_2}
    content_branch_2={blok.content_branch_2}
    title_3={blok.title_3}
    text_content_3={blok.text_content_3}
    image_3={blok.image_3}
    link_3={blok.link_3}
    content_branch_3={blok.content_branch_3}
  />
);
