import { ApolloClient, createHttpLink, InMemoryCache, NormalizedCacheObject } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { relayStylePagination } from '@apollo/client/utilities';
import { useMemo } from 'react';
import merge from 'deepmerge';
import { isEqual } from '@lib/utils';
import { GetStaticPropsResult } from 'next';
import { useRouter } from 'next/router';

const APOLLO_STATE_PROP_NAME = '__APOLLO_STATE__';
let apolloClient: ApolloClient<NormalizedCacheObject> | undefined;
let previousLocale: string | undefined;

const httpLink = createHttpLink({
  fetchOptions: {
    timeout: 30000,
  },
  uri: `https://${process.env.NEXT_PUBLIC_SHOPIFY_STORE_DOMAIN}/api/2022-10/graphql.json`,
});
const authLink = (locale?: string) => (
  setContext((_, { headers }) => ({
    headers: Object.assign(headers || {}, {
      Accept: 'application/graphql',
      'Accept-Language': locale
        ? `${locale};q=1,de-DE;q=0.7`
        : 'de-DE;q=1,de;q=0.7;',
      'X-Shopify-Storefront-Access-Token': process.env.NEXT_PUBLIC_SHOPIFY_STOREFRONT_API_TOKEN,
    }),
  })));

const createApolloClient = (locale?: string) =>
  new ApolloClient({
    cache: new InMemoryCache({
      typePolicies: {
        Query: {
          fields: {
            products: relayStylePagination(),
          },
        },
      },
    }),
    link: authLink(locale).concat(httpLink),
    ssrMode: typeof window === 'undefined',
  });

export const initializeApollo = (
  locale?: string,
  initialState?: NormalizedCacheObject,
): ApolloClient<NormalizedCacheObject> => {
  const _apolloClient =
    (!previousLocale || previousLocale !== locale ? createApolloClient(locale) : apolloClient) ??
    createApolloClient(locale);

  previousLocale = locale;

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state gets hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = _apolloClient.extract();

    // Merge the existing cache into data passed from getStaticProps/getServerSideProps
    const data = merge(initialState, existingCache, {
      // combine arrays using object equality (like in sets)
      arrayMerge: (destinationArray, sourceArray) => [
        ...sourceArray,
        ...destinationArray.filter((d) => sourceArray.every((s) => !isEqual(d, s))),
      ],
    });

    // Restore the cache with the merged data
    _apolloClient.cache.restore(data);
  }

  // For SSG and SSR always create a new Apollo Client
  if (typeof window === 'undefined') {
    return _apolloClient;
  }
  // Create the Apollo Client once in the client
  if (!apolloClient) {
    apolloClient = _apolloClient;
  }

  return _apolloClient;
};

type ApolloProps = Partial<Record<typeof APOLLO_STATE_PROP_NAME, NormalizedCacheObject>>;

export const withApolloState = <P extends Record<string, unknown>>(
  client: ApolloClient<NormalizedCacheObject>,
  getStaticPropsResult: GetStaticPropsResult<P & ApolloProps>,
): GetStaticPropsResult<P & ApolloProps> => {
  if ('props' in getStaticPropsResult && getStaticPropsResult.props) {
    getStaticPropsResult.props[APOLLO_STATE_PROP_NAME] = client.cache.extract();
  }

  return getStaticPropsResult;
};

export function useApollo(pageProps: ApolloProps & Record<string, unknown>) {
  const { locale } = useRouter();
  const state = pageProps[APOLLO_STATE_PROP_NAME];
  return useMemo(() => initializeApollo(locale, state), [state, locale]);
}
