import { useEffect, useState } from 'react';

export const useAutoEnableAfter = (duration = 500) => {
  const [isActive, setActive] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setActive(true);
    }, duration);
  }, []);

  return isActive;
};
