import React from 'react';
import { RichText } from '@components/RichText';
import { storyblokEditable } from '@storyblok/react';

export interface QuestionAnswerBlok {
  question: string;
  answer: string;
}

export const QuestionAnswer: React.FC<{ blok: QuestionAnswerBlok }> = ({ blok }) => (
  /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
  /* @ts-ignore*/
  <div {...storyblokEditable(blok)} key={blok.question}>
    <dt className="text-lg font-medium leading-6 text-gray-900">{blok.question}</dt>
    {typeof blok.answer === 'object' ? (
      <dd className="mt-2 text-base text-gray-500">
        <RichText text={blok.answer} />
      </dd>
    ) : (
      <dd className="mt-2 text-base text-gray-500">{blok.answer}</dd>
    )}
  </div>
);
