/* eslint-disable jsx-a11y/click-events-have-key-events */
import Link from 'next/link';
import { getCollectionPath } from '@lib/getCollectionPath';
import { Collection } from '@generated/graphql/types';
import { ReactNode } from 'react';

export interface CollectionCardLinkProps {
  handle: Collection['handle'];
  onClick?: () => void;
  children: ReactNode;
}

export const CollectionCardLink = ({ handle, children, onClick }: CollectionCardLinkProps) => (
  <Link
    href={{
      pathname: getCollectionPath(handle),
    }}
  >
    <a role="button" tabIndex={0} onClick={onClick}>
      {children}
    </a>
  </Link>
);
