import { ContentBlock4Props } from '@customTypes/storyblok-types';
import { storyblokEditable } from '@storyblok/react';
import { primaryColorDict } from '@lib/color';
import { StoryblokImageOrVideo } from '@components/Image/StoryblokImageOrVideo';
import { withBlur } from '@components/Image/StoryblokImage';
import classNames from 'classnames';
import { RichText } from '@components/RichText';

export const ContentBlock4 = ({ blok }: { blok: ContentBlock4Props }) => (
  <div
    /* @ts-ignore*/
    {...storyblokEditable(blok)}
    className={classNames("w-full overflow-visible py-11 md:py-16", blok.blue_background && "bg-accent-primary_005")}
  >
    <div className="space-y-6 lg:space-y-8 pl-wrapper">
      {(blok.image?.filename || blok.video_id || blok.shopTheLook) && (
        <>
          <div
            style={
              blok.content_branch === 'noBranding'
                ? {}
                : {
                  borderTopColor:
                    primaryColorDict[blok.content_branch] || primaryColorDict.sprenger,
                }
            }
            className={`relative w-full aspect-video ${blok.content_branch === 'noBranding' ? '' : 'border-t-[16px]'} pl-wrapper`}
          >
            <StoryblokImageOrVideo
              shopTheLook={blok.shopTheLook}
              filename={blok.image.filename}
              focus={blok.image.focus}
              placeholder="blur"
              blurDataURL={withBlur(blok.image?.filename)}
              alt={blok.image.alt ?? ''}
              parallax={blok.image_parallax}
              sizes="75vw"
              layout="fill"
              objectFit="cover"
              video_id={blok.video_id}
              autoplay={blok.autoplay}
            />
          </div>
        </>
      )}
      <div
        data-test="__storyblok-content-block-4"
        className={classNames(
          'mx-auto space-y-4 md:pl-1.5 pr-wrapper',
          blok.is_text_wide ? 'w-full' : 'w-2/3',
        )}
      >
        <div className="flex flex-col">
          <span className="text-h-16">{blok.subtitle}</span>
          <span className="text-h-32 hyphens-auto">{blok.title}</span>
        </div>
        {typeof blok.description === 'string' ? (
          <p>{blok.description}</p>
        ) : (
          <RichText text={blok.description} />
        )}
      </div>
    </div>
  </div>
);
