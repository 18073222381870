import { useState } from 'react';
import useBreakpoint, { Breakpoints } from '@hooks/useBreakpoint';
import classNames from 'classnames';

type SliderDotsProps = {
  currentIndex: number;
  totalSlides: number;
  onIndexChange: (current: number) => void;
  onFirstSlideChange: (isFirstSlide: boolean) => void;
  className?: string;
};

const SliderDots = ({
  currentIndex,
  onIndexChange,
  totalSlides,
  className,
  onFirstSlideChange,
}: SliderDotsProps) => {
  if (currentIndex < 0) {
    throw new Error('"currentIndex" < 0 is not allowed');
  }
  if (totalSlides <= 0) {
    throw new Error('"totalSlides" must be bigger than 0');
  }

  const isMobile = useBreakpoint(Breakpoints.lg);
  const [isHovering, setIsHovering] = useState(false);

  return (
    <div
      className={classNames(
        'mx-auto flex gap-2',
        !isMobile ? 'col-start-2 relative z-10 mb-8 mt-auto' : 'mt-14 mb-8 md:mb-16',
        className,
      )}
    >
      {Array.from({ length: totalSlides }).map((_, i) => (
        <div
          role="button"
          aria-label={`slide ${i}`}
          tabIndex={0}
          onFocus={() => setIsHovering(true)}
          onBlur={() => setIsHovering(false)}
          onKeyDown={() => onIndexChange(i)}
          onClick={() => {
            onFirstSlideChange(true);
            onIndexChange(i);
          }}
          onMouseOver={() => {
            if (!isMobile) {
              setIsHovering(true);
            }
          }}
          data-test={`dot-${i}`}
          onMouseLeave={() => setIsHovering(false)}
          key={i}
          className={`rounded-full h-2 w-2 transition duration-1000 hover:bg-transparent hover:cursor-pointer ${
            !isMobile && 'hover:ring-accent-primary hover:ring-2 '
          } ${
            currentIndex === i && !isHovering
              ? 'bg-transparent ring-accent-primary ring-2'
              : 'bg-accent-primary'
          }`}
        />
      ))}
    </div>
  );
};

export default SliderDots;
