type HeroSliderPrimaryLineProps = {
  color: string;
};

const HeroSliderPrimaryLine = ({ color }: HeroSliderPrimaryLineProps) => (
  <div
    style={{
      backgroundColor: color,
    }}
    className="w-full h-4 lg-after:h-6 relative lg:z-20 transition duration-1000"
  />
);

export default HeroSliderPrimaryLine;
