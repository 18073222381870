import { create } from 'zustand';

type NavBrancheType = null | 'pferdesport' | 'hundesport' | 'bootsport';

type NavMenuProductState = {
  openNavMenuBranch: NavBrancheType;
  setOpenNavMenuProduct: (openNavMenuBranch: NavBrancheType) => void;
};

/**
 * Determines which of the NavBar content is displayed.
 */
export const useNavMenuProductZustand = create<NavMenuProductState>((set) => ({
  openNavMenuBranch: null,
  setOpenNavMenuProduct: (openNavMenuBranch) => set((state) => ({ ...state, openNavMenuBranch })),
}));
