import { create } from 'zustand';
import { CartQuery } from 'generated/graphql/types';
import { UseCrossSellProductsPayload } from '@hooks/useCrossSellProducts';
import { ApolloQueryResult } from '@apollo/client';

export type CartState = {
  id?: string;
  data?: Mutable<CartQuery['cart']>;
  ignoreUpdateCount: number;
  increaseIgnoreUpdateCount: (amount?: number) => void;
  manualRefetch?: () => Promise<ApolloQueryResult<CartQuery>>;
  crossSellProducts: UseCrossSellProductsPayload;
  setData: (data: Mutable<CartQuery['cart']>) => void;
  setManualRefetch: (manualRefetch: () => Promise<ApolloQueryResult<CartQuery>>) => void;
  setCrossSellProducts: (crossSellProducts: UseCrossSellProductsPayload) => void;
  setId: (id?: string) => void;
  resetReFetchId: () => void;
  updateReFetchId: () => void;
  reFetchId: number;
};

export const useCartZustand = create<CartState>((set, get) => ({
  crossSellProducts: { error: false, loading: false },
  reFetchId: -1,
  setCrossSellProducts: (crossSellProducts: UseCrossSellProductsPayload) =>
    set((state) => ({ ...state, crossSellProducts })),
  setData: (data: CartQuery['cart']) => {
    const ignoreCount = get().ignoreUpdateCount;
    if (ignoreCount > 0) {
      set({ ignoreUpdateCount: ignoreCount - 1 });
      return;
    }
    set({ data });
  },
  setId: (id?: string) => set((state) => ({ ...state, id })),
  setManualRefetch: (manualRefetch: () => Promise<ApolloQueryResult<CartQuery>>) =>
    set((state) => ({ ...state, manualRefetch })),
  resetReFetchId: () => set({ reFetchId: -1 }),
  updateReFetchId: () => set({ reFetchId: Date.now() }),
  ignoreUpdateCount: 0,
  increaseIgnoreUpdateCount: (count = 1) => set({ ignoreUpdateCount: count }),
}));
