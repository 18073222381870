import { MoneyV2 } from '@generated/graphql/types';
import { useMemo } from 'react';
import { useLocale } from '@zustand/useLocale';

export const usePriceFormat = (price: MoneyV2, compareAtPrice?: MoneyV2, taxIncluded?: boolean) => {
  const { tax, language } = useLocale();

  const withTax = (amount: number) => amount; // * (1 + (!taxIncluded ? tax?.tax ?? 0 : 0));
  const getFormattedPrice = (p: number, currency: string, loc: string) =>
    new Intl.NumberFormat(loc, { currency: currency, style: 'currency' }).format(p);

  const formattedPrice = useMemo(() => {
    if (!language) {
      return;
    }

    return getFormattedPrice(withTax(price.amount), price.currencyCode, language);
  }, [price, language]);

  const formattedComparePrice = useMemo(() => {
    if (!language || !compareAtPrice) {
      return null;
    }
    if (compareAtPrice.amount === price.amount) {
      return null;
    }

    return getFormattedPrice(withTax(compareAtPrice.amount), compareAtPrice.currencyCode, language);
  }, [compareAtPrice, language]);

  return { formattedComparePrice, formattedPrice };
};
