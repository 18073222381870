import { create } from 'zustand';
import { SortBy, SortOption } from '@lib/search';

export const useSortByZustand = create<{
  sortBy: SortOption;
  setSortBy: (sortBy: SortOption) => void;
}>((set) => ({
  setSortBy: (sortBy: SortOption) => set((state) => ({ ...state, sortBy })),
  sortBy: SortOption.create(SortBy.RELEVANCE),
}));

export const useCurrentSortQuery = () =>
  useSortByZustand((store) => (store.sortBy.shouldBeIncluded ? store.sortBy.query : ''));
