import classNames from 'classnames';
import { ReactNode } from 'react';

type NavListSectionProps = {
  className?: string;
  children: ReactNode;
};

export const NavListSection = ({ className, children }: NavListSectionProps) => (
  <div className={classNames('flex flex-col items-stretch pb-5 pt-3', className)}>{children}</div>
);
