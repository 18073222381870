import { Transition } from '@headlessui/react';
import { useEffect, useState } from 'react';
import { ContentBranch, ImageStoryblok, LinkStoryblok } from '@customTypes/storyblok-types';
import useBreakpoint, { Breakpoints } from '@hooks/useBreakpoint';
import classNames from '@utils/ClassNames';
import { StoryblokImage, withBlur } from '../Image/StoryblokImage';
import { RichText } from '../RichText';
import { ButtonPrimary } from '../Button/Primary';
import { ISbRichtext } from '@storyblok/react';

interface EditorialSection14Props {
  headline: ISbRichtext;
  image_1?: ImageStoryblok;
  title_1: string;
  text_content_1: ISbRichtext;
  link_1: LinkStoryblok;
  content_branch_1: ContentBranch;
  image_2?: ImageStoryblok;
  title_2: string;
  text_content_2: ISbRichtext;
  link_2: LinkStoryblok;
  content_branch_2: ContentBranch;
  image_3?: ImageStoryblok;
  title_3: string;
  text_content_3: ISbRichtext;
  link_3: LinkStoryblok;
  content_branch_3: ContentBranch;
  className?: string;
}

export const EditorialSection14 = ({
  headline,
  image_1,
  title_1,
  text_content_1,
  link_1,
  content_branch_1,
  image_2,
  title_2,
  text_content_2,
  link_2,
  content_branch_2,
  image_3,
  title_3,
  text_content_3,
  link_3,
  content_branch_3,
  className,
}: EditorialSection14Props) => {
  const [data, setData] = useState<
    Array<{
      image: ImageStoryblok;
      content_branch: ContentBranch;
      title: string;
      text_content: ISbRichtext;
      link: LinkStoryblok;
    }>
  >([]);
  const isMobile = useBreakpoint(Breakpoints.md);

  const [selectedItem, setSelectedItem] = useState<number | undefined>();

  useEffect(() => {
    const newData: typeof data = [];

    if (image_1) {
      newData.push({
        content_branch: content_branch_1,
        image: image_1,
        link: link_1,
        text_content: text_content_1,
        title: title_1,
      });
    }
    if (image_2) {
      newData.push({
        content_branch: content_branch_2,
        image: image_2,
        link: link_2,
        text_content: text_content_2,
        title: title_2,
      });
    }
    if (image_3) {
      newData.push({
        content_branch: content_branch_3,
        image: image_3,
        link: link_3,
        text_content: text_content_3,
        title: title_3,
      });
    }
    setData(newData);
  }, [image_1, image_2, image_3]);

  return (
    <div className="flex flex-col py-11 md:py-16">
      <RichText className="pl-4 md:pl-20 mb-8 text-h-56 md:text-h-72 hyphens-auto" text={headline} />
      <div className={classNames('flex flex-row', className)}>
        {data.map((item, index) => (
          <div
            className={classNames(
              'relative overflow-hidden flex h-96 md:h-[40rem] transition-all duration-700 ease-in-out cursor-pointer',
              selectedItem === index ? 'flex-3' : 'flex-1',
            )}
            onClick={() =>
              selectedItem === index ? setSelectedItem(undefined) : setSelectedItem(index)
            }
            key={index}
            role="none"
          >
            <div
              className="absolute top-0 w-full h-4 lg:h-[23px] z-20"
              style={{ backgroundColor: `var(--special-${item.content_branch})` }}
            />
            {selectedItem == null && !isMobile && (
              <div
                className="absolute select-none top-0 right-0 left-0 bottom-0 flex items-center justify-center opacity-0 transition-opacity ease-in-out duration-700 hover:opacity-100 z-20 text-h-40 lg:text-h-56 xl:text-h-72"
                style={{ color: `var(--special-${item.content_branch})` }}
              >
                {item.title}
              </div>
            )}

            {!!item.image?.filename && (
              <div className="flex-1 select-none overflow-hidden">
                <StoryblokImage
                  src={item.image.filename}
                  placeholder="blur"
                  blurDataURL={withBlur(item.image.filename)}
                  alt={item.image.alt}
                  layout="fixed"
                  focus={item.image.focus}
                  width={isMobile ? '384px' : '640px'}
                  height={isMobile ? '384px' : '640px'}
                  objectFit="cover"
                  object-position="center"
                />
              </div>
            )}
            {item.text_content && !isMobile && (
              <div
                className={classNames(
                  'z-10 h-full transition-all bg-white duration-700',
                  selectedItem === index ? 'opacity-100 md:w-[25vw]' : 'w-0 opacity-0',
                )}
              >
                <div className="md:w-[25vw] px-8 py-20 h-full flex flex-col">
                  <RichText className="text-p-16 flex flex-col gap-4" text={item.text_content} />
                  {item.link && (
                    <ButtonPrimary href={item.link.url} text="Zu den Produkten" className="mt-16" />
                  )}
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
      <Transition
        show={(selectedItem != null && data[selectedItem].text_content && isMobile) || false}
        enter="transition duration-200 ease-in-out"
        enterFrom="transform scale-0 opacity-0"
        enterTo="transform scale-100 opacity-100"
        leave="transition duration-500 ease-in-out"
        leaveFrom="transform scale-100 opacity-100"
        leaveTo="transform scale-0 opacity-0"
      >
        <div className="flex-1 z-30 h-full transition-all">
          <div className="w-full px-8">
            {selectedItem != null && (
              <RichText
                className="text-p-16 flex flex-col gap-4 pt-6 pb-10"
                text={data[selectedItem].text_content}
              />
            )}
            {selectedItem != null && data[selectedItem].link && (
              <ButtonPrimary href={data[selectedItem].link.url} text="Zu den Produkten" />
            )}
          </div>
        </div>
      </Transition>
    </div>
  );
};
