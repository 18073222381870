import { create } from 'zustand';

type LastCollectionFilterOptionZustand = {
  lastCollectionFilterOption?: string;
  setLastCollectionFilterOption: (option: string) => void;
  clearLastCollectionFilterOption: () => void;
}


export const useLastCollectionFilterOption = create<LastCollectionFilterOptionZustand>((set) => ({
  lastCollectionFilterOption: undefined,
  setLastCollectionFilterOption: (option: string) => set({ lastCollectionFilterOption: option }),
  clearLastCollectionFilterOption: () => set({ lastCollectionFilterOption: undefined }),
}));