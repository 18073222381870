import { gql } from '@apollo/client';

export const COLLECTION_ITEM = gql`
  query CollectionItem($handle: String!, $country: CountryCode, $language: LanguageCode)
  @inContext(country: $country, language: $language) {
    collection(handle: $handle) {
      id
      handle
      description
      descriptionHtml
      title
      placeholderImages: image {
        src
      }
      image {
        src
        altText
        id
      }
      includeInMenu: metafield(key: "include_in_menu", namespace: "sprenger") {
        key
        value
        id
      }
      position: metafield(key: "position", namespace: "sprenger") {
        key
        value
        id
      }
      products(first: 10) {
        edges {
          node {
            id
            handle
            description
            availableForSale
            priceRange {
              maxVariantPrice {
                amount
                currencyCode
              }
              minVariantPrice {
                amount
                currencyCode
              }
            }
            compareAtPriceRange {
              maxVariantPrice {
                amount
                currencyCode
              }
              minVariantPrice {
                amount
                currencyCode
              }
            }
            sku: metafield(key: "sku", namespace: "sprenger") {
              key
              value
            }
            image_urls: metafield(key: "image_urls", namespace: "sprenger") {
              key
              value
            }
            dreid: metafield(key: "dreid", namespace: "sprenger") {
              key
              value
            }
            video: metafield(key: "video", namespace: "sprenger") {
              key
              value
            }
            placeholderImages: images(first: 10) {
              edges {
                node {
                  src
                }
              }
            }
            images(first: 10) {
              edges {
                node {
                  src
                  altText
                  id
                  width
                  height
                }
              }
            }
            collections(first: 100) {
              edges {
                node {
                  title
                  description
                }
              }
            }
            variants(first: 10) {
              edges {
                node {
                  id
                  isArchived: metafield(key: "is_archived", namespace: "sprenger") {
                    key
                    value
                  }
                }
              }
            }
            title
            tags
            totalInventory
          }
        }
      }
    }
  }
`;

export const COLLECTIONS = gql`
  query Collections($first: Int!) {
    collections(first: $first) {
      edges {
        node {
          id
          handle
          description
          descriptionHtml
          title
          placeholderImages: image {
            src
          }
          image {
            src
            altText
            id
          }
          includeInMenu: metafield(key: "include_in_menu", namespace: "sprenger") {
            key
            value
            id
          }
          position: metafield(key: "position", namespace: "sprenger") {
            key
            value
            id
          }
          products(first: 10) {
            edges {
              node {
                id
                handle
                description
                availableForSale
                priceRange {
                  maxVariantPrice {
                    amount
                    currencyCode
                  }
                  minVariantPrice {
                    amount
                    currencyCode
                  }
                }
                compareAtPriceRange {
                  maxVariantPrice {
                    amount
                    currencyCode
                  }
                  minVariantPrice {
                    amount
                    currencyCode
                  }
                }
                sku: metafield(key: "sku", namespace: "sprenger") {
                  key
                  value
                }
                image_urls: metafield(key: "image_urls", namespace: "sprenger") {
                  key
                  value
                }
                dreid: metafield(key: "dreid", namespace: "sprenger") {
                  key
                  value
                }
                video: metafield(key: "video", namespace: "sprenger") {
                  key
                  value
                }
                placeholderImages: images(first: 10) {
                  edges {
                    node {
                      src
                    }
                  }
                }
                images(first: 10) {
                  edges {
                    node {
                      src
                      altText
                      id
                      width
                      height
                    }
                  }
                }
                collections(first: 100) {
                  edges {
                    node {
                      title
                      description
                    }
                  }
                }
                variants(first: 10) {
                  edges {
                    node {
                      id
                    }
                  }
                }
                title
                tags
                totalInventory
              }
              cursor
            }
          }
        }
      }
    }
  }
`;

export const COLLECTION_HANDLES = gql`
  query CollectionHandles($first: Int!) {
    collections(first: $first) {
      edges {
        node {
          handle
          includeInMenu: metafield(key: "include_in_menu", namespace: "sprenger") {
            key
            value
            id
          }
        }
      }
    }
  }
`;
