import type { ProductCardProps } from '@components/Product/Card/shared';
import { ProductCardVertical } from '@components/Product/Card/ProductCardVertical';
import { getNumberOfItemsViewed, image_urlsAsArray } from '@lib/utils';
import { useEffect, useRef } from 'react';
import useGtm from '@hooks/useGTM';
import { getProductListClickedGtmPayload, GtmProductListType } from '@lib/gtm/productList';
import { ProductsQuery } from '@generated/graphql/types';

interface NavMenuProductSliderProps {
  items: Item[];
  onProductSelected?: () => void;
}

type Item = {
  id: string;
  image_urls: string;
  variants: ArrayElementOf<ProductsQuery['products']['edges']>['node']['variants'];
} & ProductCardProps;

export const NavMenuProductSlider = ({ items, onProductSelected }: NavMenuProductSliderProps) => {
  const sendDataToGTM = useGtm();
  const scrolling_progress = useRef(0);
  const _progressObserved = useRef(false);

  useEffect(() => {
    setTimeout(() => {
      const elementsToWatch = document.getElementsByClassName('product-card');
      if (elementsToWatch && !_progressObserved.current) {
        _progressObserved.current = true;
        getNumberOfItemsViewed(Array.from(elementsToWatch ?? []) as HTMLElement[], (num) => {
          scrolling_progress.current = +(num / elementsToWatch.length).toPrecision(3);
        });
      }
    }, 100);
  }, [items]);

  const sendData = (sku: string) => {
    if (!sku) {
      return;
    }
    sendDataToGTM(
      getProductListClickedGtmPayload({
        listDetails: {
          percentProductsViewed: scrolling_progress.current,
          products: items.map(({ sku: itemSku, id }) => itemSku ?? id),
          routedToProduct: sku,
          totalProductsCount: items.length ?? 0,
          totalUniqueProductsCount: items.length ?? 0,
        },
        listType: GtmProductListType.Featured,
      }),
    );
  };

  return (
    <div className="px-20 overflow-x-auto -mb-40 pb-40 scrollbar-hide">
      <div className="flex flex-row">
        {items.map((_, index) => {
          const { id, variants, collections, ...item } = items[index];
          return (
            <ProductCardVertical
              collections={collections}
              key={id}
              {...item}
              onClick={(sku) => {
                sendData(sku);
                onProductSelected?.();
              }}
              image={{
                altText: item.title,
                height: 500,
                src: image_urlsAsArray(item.image_urls)[0],
                width: 500,
              }}
              className={'w-48 flex-none'}
              imageLoading="eager"
            />
          );
        })}
        <div className="w-px pr-14" />
      </div>
    </div>
  );
};
