import { Price } from '@components/Price';
import { ProductPriceRange } from '@generated/graphql/types';
import { PriceWithSale } from '@components/PriceWithSale';

interface ProductPriceProps {
  priceRange: ProductPriceRange;
  compareAtPriceV2?: ProductPriceRange;
  className?: string;
  variantAmount?: number;
  shouldWrap?: boolean;
  taxIncluded?: boolean;
}

export const ProductPrice = ({
  priceRange,
  compareAtPriceV2,
  className,
  variantAmount,
  shouldWrap,
  taxIncluded,
}: ProductPriceProps) => {
  if (priceRange.maxVariantPrice.amount !== priceRange.minVariantPrice.amount) {
    return (
      <PriceWithSale
        className={className}
        shouldWrap={shouldWrap}
        priceRange={priceRange}
        compareAtPriceV2={compareAtPriceV2}
      />
    );
  }

  return (
    <Price
      price={priceRange.minVariantPrice}
      compareAtPrice={compareAtPriceV2?.minVariantPrice}
      variantAmount={variantAmount}
      className={className}
      shouldWrap={shouldWrap}
      taxIncluded={taxIncluded}
    />
  );
};
