import { Facebook, Link, Mail, Pinterest, Share } from '@components/icons';
import { Image, ProductQuery } from '@generated/graphql/types';
import { Transition } from '@headlessui/react';
import useGtm from '@hooks/useGTM';
import { useTranslate } from '@tolgee/react';
import { getGtmPayloadProductShared } from '@lib/gtm/productDetails';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { getBrancheFromCollections } from '@lib/utils';

interface ProductShareButtonProps {
  title: string;
  image?:
    | {
        src: Image['src'];
      }
    | undefined;
  product?: NonNullable<ProductQuery['product']>;
  showShareIcons?: boolean;
}

export const ProductShareButton = ({
  title,
  image,
  product,
  showShareIcons: showIcons,
}: ProductShareButtonProps) => {
  const { t } = useTranslate();
  const [showShareIcons, setShowShareIcons] = useState<boolean>(!!showIcons);
  const [copied, setCopied] = useState<boolean>(false);
  const sendDataToGTM = useGtm();
  const [url, setUrl] = useState<string>('');

  const productGtmPayload = getGtmPayloadProductShared({
    productDetails: {
      availability: product?.availableForSale ? 'in_stock' : 'out_of_stock',
      branche: getBrancheFromCollections(product?.collections),
      price_max: product?.priceRange.maxVariantPrice.amount ?? 0,
      price_min: product?.priceRange.minVariantPrice.amount ?? 0,
      product_category: product?.collections.edges[0]?.node.title ?? '',
      product_id: product?.sku?.value ?? '',
    },
    productSharedTo: 'mail',
  });

  const startShare = async () => {
    if ('share' in navigator) {
      try {
        await navigator.share({
          text: t('share.text'),
          title: t('share.title'),
          url: location.href,
        });
        sendDataToGTM({ ...productGtmPayload, productSharedTo: 'mobile_na' });
      } catch (e) {
        return;
      }
    }
    if (!showIcons) {
      setShowShareIcons(true);
    }
  };

  useEffect(() => {
    setUrl(location.href);
  }, []);

  return (
    <div className="my-auto">
      <div
        className={classNames(
          'border-2 border-accent-primary text-accent-primary transition-all inline-flex flex-shrink-0 cursor-pointer rounded-full items-center justify-center h-12',
          showShareIcons ? 'px-2' : 'w-12',
        )}
      >
        {!showShareIcons && (
          <Share
            onClick={() => startShare()}
            className="flex-shrink-0 w-6 h-6 mx-7"
            aria-hidden="true"
          />
        )}

        {showShareIcons && (
          <>
            <div className="inline-flex items-center justify-center flex-shrink-0 h-12 rounded-full">
              <Link
                onClick={(ev) => {
                  navigator.clipboard.writeText(url);
                  setCopied(true);
                  setTimeout(() => {
                    setCopied(false);
                  }, 1000);
                  ev.stopPropagation();
                  sendDataToGTM({ ...productGtmPayload, productSharedTo: 'link' });
                }}
                className="flex-shrink-0 w-5 h-5"
                aria-hidden="true"
              />

              <Transition
                as="div"
                className="absolute -top-5 whitespace-nowrap"
                show={copied}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="translate-y-full opacity-0"
                enterTo="translate-y-0 opacity-1"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-y-0 opacity-1"
                leaveTo="-translate-y-full opacity-0"
              >
                {t('product.copied')}
              </Transition>
            </div>

            <a
              onClick={(ev) => {
                ev.stopPropagation();
                sendDataToGTM({ ...productGtmPayload, productSharedTo: 'fb' });
              }}
              href={`https://www.facebook.com/sharer/sharer.php?u=${url}`}
              rel="noreferrer"
              target="_blank"
            >
              <Facebook className="flex-shrink-0 w-5 h-5 ml-1" aria-hidden="true" />
            </a>

            <a
              onClick={(ev) => {
                ev.stopPropagation();
                sendDataToGTM({ ...productGtmPayload, productSharedTo: 'pinterest' });
              }}
              href={`https://pinterest.com/pin/create/button/?media=${
                image?.src
              }&is_video=false&description=${title.replaceAll(' ', '%20')}&url=${url}`}
              rel="noreferrer"
              target="_blank"
            >
              <Pinterest className="flex-shrink-0 w-6 h-6 ml-1" aria-hidden="true" />
            </a>

            <a
              onClick={(ev) => {
                ev.stopPropagation();
                sendDataToGTM(productGtmPayload);
              }}
              href={`mailto:?subject=${t('share.title')}&body=${t('share.text')}%0A%0A${url}`}
              rel="noreferrer"
              target="_blank"
            >
              <Mail className="flex-shrink-0 w-6 h-6 ml-1" aria-hidden="true" />
            </a>
          </>
        )}
      </div>
    </div>
  );
};
