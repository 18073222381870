import { useEffect, useState } from 'react';
import { X } from './generated/icons';

const LOCAL_STORAGE_DISMISS_KEY = 'sprenger_shop_preview_dismissed';

export const StagingBanner = () => {
  const [dismissedBanner, setDismissedBanner] = useState<boolean>(false);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setDismissedBanner(localStorage.getItem(LOCAL_STORAGE_DISMISS_KEY) === 'true');
    }
  }, []);

  return !dismissedBanner ? (
    <div className="relative w-full h-16 md:h-10 text-white justify-center items-center flex flex-col md:flex-row bg-special-danger px-2 text-h-16">
      Sie befinden sich auf einer Testumgebung.
      <a className="ml-2 underline" href="https://shop.sprenger.de">
        Hier geht es zum Shop
      </a>
      <div className="absolute top-0 right-4 flex items-center h-full">
        <X
          data-test="staging-banner-dismiss"
          onClick={() => {
            setDismissedBanner(true);
            localStorage.setItem(LOCAL_STORAGE_DISMISS_KEY, 'true');
          }}
          className="w-4 h-4"
        />
      </div>
    </div>
  ) : (
    <></>
  );
};
