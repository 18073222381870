import { MoneyV2, UnitPriceMeasurement } from '@generated/graphql/types';
import { usePriceFormat } from '@hooks/usePriceFormat';
import { T, useTranslate } from '@tolgee/react';
import classNames from '@utils/ClassNames';

type UnitPriceProps = {
  price?: MoneyV2 | null;
  variantPrice: MoneyV2;
  measurement?: Omit<UnitPriceMeasurement, 'quantityValue' | 'quantityUnit'> | null;
  referenceUnit?: string;
  wrap?: boolean;
  taxIncluded?: boolean;
};

const UnitPrice = ({
  price,
  measurement,
  variantPrice,
  referenceUnit,
  wrap,
  taxIncluded,
}: UnitPriceProps) => {
  const hasBasePrice = !!price;
  const { formattedPrice } = usePriceFormat(price ?? variantPrice, undefined, taxIncluded);
  const { t } = useTranslate();

  return (
    <span
      className={classNames(
        'text-p-12 !normal-case inline-flex text-right whitespace-nowrap',
        wrap && 'md:whitespace-pre-wrap',
      )}
    >
      {formattedPrice && (
        <T
          keyName="formattedPrice_per_unit"
          params={{
            price: formattedPrice,
            unit: hasBasePrice
              ? `${measurement?.referenceValue} ${t(
                  `reference_unit_${measurement?.referenceUnit ?? referenceUnit ?? ''}`,
                )}`
              : t(`reference_unit_${referenceUnit}`) ?? t('fallbackUnit'),
          }}
        />
      )}
    </span>
  );
};

export default UnitPrice;
