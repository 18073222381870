import classNames from 'classnames';

interface ProductSaleChipProps {
  sale: number;
  className?: string;
}

const ProductSaleChip = ({ sale, className }: ProductSaleChipProps) => {
  const absoluteSale = Math.abs(sale);

  return (
    <div
      className={classNames(
        'px-2 py-1 bg-special-dog rounded text-white text-p-12 h-[1.625rem] max-w-min',
        className,
      )}
    >
      -{absoluteSale}%
    </div>
  );
};

export default ProductSaleChip;
