import { ChevronLeft } from '@components/icons';
import Link from 'next/link';
import { ButtonHTMLAttributes, DetailedHTMLProps } from 'react';

export type NavListHeaderProps = {
  title?: string;
  href?: string;
  back: boolean;
} & DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>;

export const NavListHeader = (props: NavListHeaderProps) => {
  const { href, title, back, onClick } = props;
  return (
    <div className="flex flex-row justify-between h-8 mt-6 mb-6">
      {back && (
        <button onClick={onClick} className="lg:hidden">
          <ChevronLeft className="w-6 h-6" />
        </button>
      )}
      {href ? (
        <Link href={href}>
          <a className="text-h-24 cursor-pointer hover:underline">{title}</a>
        </Link>
      ) : (
        <span className="text-h-24">{title}</span>
      )}

      {back && (
        <button onClick={onClick} className="invisible lg:visible">
          <ChevronLeft className="w-6 h-6 " />
        </button>
      )}
    </div>
  );
};
