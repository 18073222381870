import React from 'react';
import { StoryblokComponentType, storyblokEditable } from '@storyblok/react';
import { T } from '@tolgee/react';
import { ProductShareButton } from '../../Product/ShareButton';

export const MagazinShare: React.FC<{
  blok: StoryblokComponentType<string>;
  title: string;
  image: { src: any };
}> = ({ blok, title, image }) => {
  return (
    <div
      /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
      /* @ts-ignore*/
      {...storyblokEditable(blok)}
    >
      <div className="flex flex-col-reverse w-full space-y-reverse md:flex-col box-x-wrapper">
        <div className="flex flex-row justify-center w-full h-full z-0">
          <div className="md:flex md:flex-col w-full md:w-2/3 md:pl-1.5 text-center">
            <h3 className="text-h-16 mb-4">
              <T keyName="magazinShareHeadline" />
            </h3>
            <ProductShareButton title={title} image={image} showShareIcons={true} />
          </div>
        </div>
      </div>
    </div>
  );
};
