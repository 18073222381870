import { useCallback, useEffect, useState } from 'react';
import { debounce } from '@lib/utils';

const useResponsive = (debounceTime = 500): number => {
  const [width, setWidth] = useState<number>(1000);

  const { func } = debounce((value: number) => setWidth(value), debounceTime);
  const handleResizeEvent = useCallback(() => func(document?.body.clientWidth || 0), []);

  useEffect(() => {
    if (document) {
      setWidth(document.body.clientWidth);
      window.addEventListener('resize', handleResizeEvent);
      return () => window.removeEventListener('resize', handleResizeEvent);
    }
  }, [handleResizeEvent]);

  return width;
};

export default useResponsive;
