import { getCartIdFromShopifyUri } from '@lib/utils';
import { CartState } from '@zustand/cart';
import { GtmCartDetails, GtmEventType } from './types';

interface ProductRemovedParams {
  cartData: NonNullable<CartState['data']>;
  removedProductSku?: string;
}

interface ProductRemovedPayload {
  event: GtmEventType;
  cart: GtmCartDetails;
  removedProductSku?: string;
}

export const getGtmPayloadCartProductRemoved = ({
  removedProductSku,
  cartData,
}: ProductRemovedParams): ProductRemovedPayload => ({
  cart: getGtmCartDetails(cartData),
  event: GtmEventType.CartProductRemoved,
  removedProductSku,
});

interface CartViewedParams {
  cartData: NonNullable<CartState['data']>;
}

interface CartViewedPayload {
  event: GtmEventType;
  cart: GtmCartDetails;
}

export const getGtmPayloadCartViewed = ({ cartData }: CartViewedParams): CartViewedPayload => ({
  cart: getGtmCartDetails(cartData),
  event: GtmEventType.CartViewed,
});

interface BuyXGetYParams {
  cartData: NonNullable<CartState['data']>;
  variant_id: string;
}

interface BuyXGetYPayload {
  event: GtmEventType;
  cart: GtmCartDetails;
  productVariantDetails: { variant_id: string };
}

export const getGtmPayloadBuyXGetY = ({
  cartData,
  variant_id,
}: BuyXGetYParams): BuyXGetYPayload => ({
  cart: getGtmCartDetails(cartData),
  event: GtmEventType.BuyXGetY,
  productVariantDetails: { variant_id },
});

interface CartVoucherCodeSubmittedParams {
  cartData: NonNullable<CartState['data']>;
  enteredValue: string;
}

interface CartVoucherCodeSubmittedPayload {
  event: GtmEventType;
  enteredValue: string;
  cart: GtmCartDetails;
}

export const getGtmPayloadCartVoucherCodeSubmitted = ({
  cartData,
  enteredValue,
}: CartVoucherCodeSubmittedParams): CartVoucherCodeSubmittedPayload => ({
  cart: getGtmCartDetails(cartData),
  enteredValue,
  event: GtmEventType.CartVoucherCodeSubmitted,
});

export const getGtmCartDetails = (cartData: NonNullable<CartState['data']>) => {
  const getCartQuantity = () => {
    const edges = cartData.lines.edges;
    let total = 0;

    if (!edges || edges.length === 0) {
      return total;
    }

    for (const element of edges) {
      const edge = element;
      total += edge.node.quantity;
    }

    return total;
  };

  const productsCount = getCartQuantity();
  const totalValue = cartData.cost.subtotalAmount.amount;
  const allProducts = cartData.lines.edges.map(({ node }) => node.merchandise);

  return {
    id: getCartIdFromShopifyUri(cartData.id),
    productSkus: allProducts
      .map(({ product: { justSku } }) => justSku?.value)
      .filter((sku): sku is NonNullable<typeof sku> => !!sku),
    productVariantSkus: allProducts
      .map(({ justSku }) => justSku?.value)
      .filter((sku): sku is NonNullable<typeof sku> => !!sku),
    productVariantsCount: productsCount,
    totalValue,
  };
};
