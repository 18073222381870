import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Link from 'next/link';
import { LazySearchBar } from '@components/Search';
import { useCart } from '@hooks/useCart';
import { useRouter } from 'next/router';
import { useCustomer } from '@hooks/useCustomer';
import { useCustomerSessionDrawer } from '@hooks/useCustomerSessionDrawer';
import { LayoutNavbarButton } from './Button';
import LogoHorizontal from '@components/generated/logos/LogoHorizontal';
import { layoutNavbarLinks } from './links';
import { NavMenuProduct } from '@components/NavMenu/Product';
import { NavMenuFaq } from '@components/NavMenu/FAQ';
import { NavMenuCollection } from '@components/NavMenu/Collection';
import { T, useTranslate } from '@tolgee/react';
import classNames from 'classnames';
import useBreakpoint, { Breakpoints } from '@hooks/useBreakpoint';
import { useScrollAtStartZustand } from '@zustand/scrollAtStart';
import { useNavMenuProductZustand } from '@zustand/navMenuProduct';
import { useNavMenuCollectionZustand } from '@zustand/navMenuCollection';
import { useMenuZustand } from '@zustand/menu';
import { Menu, ShoppingBag, User, UserLoggedIn, X } from '@components/icons';
import { useNavMenuFAQZustand } from '@zustand/navMenuFAQ';
import { InfoBanner } from '@components/InfoBanner';
import Dropdown, { MenuItemProps } from '@components/Common/Dropdown';
import { useCustomerAccessToken } from '@hooks/useCustomerAccessToken';
import { NOTIFICATION, NOTIFICATION_DURATION, useNotifications } from '@lib/NotificationContext';
import {
  currencyCodeToSymbol,
  debounce,
  getCookie,
  isProd,
  languageCodeToCountryCode,
} from '@lib/utils';
import { StagingBanner } from '../../StagingBanner';
import { shippingMarkets } from '@lib/shippingCountries';
import { useLocale } from '@zustand/useLocale';
import { CountryCode, LanguageCode } from '@generated/graphql/types';
import { DiscountBar } from '@components/DiscountBar';
import { FeatureFlag, isEnabledFlag } from '@lib/feature';
import { LANGUAGE_AND_REGION_COOKIE_NAME, SUPPORTED_LANGUAGES } from '@lib/constants';
import { PickerState, useShippingCountryPopUp } from '@zustand/shippingCountryPopUp';
import languages from '../../../i18n';
import useClientSide from '@hooks/useClientSide';

export const CountryFlag = ({ country }: { country: string }) => (
  <img
    className="rounded-sm mr-2 overflow-hidden flex flex-shrink-0 w-[30px]"
    width="30"
    height="22.5"
    src={`/static/flags/${country}.svg`}
  />
);

export const ActiveShippingIcon = ({
  country,
  text,
  scrollAtStart,
}: {
  country: string;
  text: string;
  scrollAtStart: boolean;
}) => (
  <LayoutNavbarButton
    Icon={CountryFlag}
    IconProps={{ country: country.toLowerCase() }}
    text={text}
    data-test="open-shipping-drawer"
    isScrolled={!scrollAtStart}
    isActive={false}
    className="gap-2"
    iconClass="w-7 h-7"
  />
);

export const LayoutNavbar = () => {
  const router = useRouter();
  const { language, locale, setLocale, setLang } = useLocale();
  const { pathname, asPath, query } = router;
  const { t } = useTranslate();
  const [customer] = useCustomer();
  const [, , { logout }] = useCustomerAccessToken();
  const isMedium = useBreakpoint(Breakpoints.md);
  const isSmall = useBreakpoint(Breakpoints.sm);
  const shouldRender = useClientSide();
  const cart = useCart();
  const { openCustomerSessionDrawer } = useCustomerSessionDrawer();
  const { openMenu, toggleMenu, setHeight } = useMenuZustand();
  const setNavMenuProductState = useNavMenuProductZustand((store) => store.setOpenNavMenuProduct);
  const setNavMenuFAQState = useNavMenuFAQZustand((store) => store.setOpenNavMenuFAQ);
  const setNavMenuCollectionState = useNavMenuCollectionZustand(
    (store) => store.setOpenNavMenuCollection,
  );
  const isMobile = useBreakpoint();
  const { scrollAtStart, setScrollAtStart } = useScrollAtStartZustand();
  const notifications = useNotifications();
  const navbarContainer = useRef<HTMLDivElement>(null);
  const [ActiveItem, setActiveItem] = useState(
    <ActiveShippingIcon
      country={locale}
      text={`${locale} | ${
        shippingMarkets.find((c) => c.regions.nodes.find((n) => n.code === locale))
          ?.currencySettings.baseCurrency.currencyCode
      }`}
      scrollAtStart={scrollAtStart}
    />,
  );

  useEffect(() => {
    if (navbarContainer.current) {
      const observer = new ResizeObserver((e) => {
        setHeight(e[0].contentRect.height);
      });
      observer.observe(navbarContainer.current);
    }
  }, [navbarContainer]);

  useEffect(() => {
    setActiveItem(
      <ActiveShippingIcon
        country={locale}
        text={`${locale} | ${
          shippingMarkets.find((c) => c.regions.nodes.find((n) => n.code === locale))
            ?.currencySettings.baseCurrency.currencyCode
        }`}
        scrollAtStart={scrollAtStart}
      />,
    );
  }, [locale, language]);
  const [isScrolledToTop, setIsScrolledToTop] = useState(true);
  const lastYPosition = useRef(0);
  const isScrollingUpwards = useRef(false);
  const navbarHeight = useRef(0);
  const observer = useRef<ResizeObserver>();
  const { setPickerState: setStep } = useShippingCountryPopUp();

  useEffect(() => {
    observer.current = new ResizeObserver((entries) => {
      entries.forEach((entry) => {
        if (navbarPlaceholder.current) {
          navbarHeight.current = entry.contentRect.height;
          navbarPlaceholder.current.style.height = `${entry.contentRect.height}px`;
        }
      });
    });
    window.addEventListener('scroll', handleScroll);
    const localeByCookie = getCookie(LANGUAGE_AND_REGION_COOKIE_NAME);
    if (localeByCookie && localeByCookie !== router.locale) {
      setStep(PickerState.INITIAL);
    } else {
      setLang(router.locale?.split('-')[0] as LanguageCode);
      setLocale(router.locale?.split('-')[1] as CountryCode);
    }
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const { func } = debounce(() => {
    lastYPosition.current = window.scrollY;
  }, 1000);

  const handleScroll = useCallback(() => {
    setScrollAtStart(window.scrollY <= 0);
    const y = window.scrollY;
    func();
    if (y <= navbarHeight.current) {
      isScrollingUpwards.current = true;
    } else {
      if (!isScrollingUpwards.current && lastYPosition.current > y + 50) {
        isScrollingUpwards.current = true;
        lastYPosition.current = y;
      } else if (isScrollingUpwards.current && lastYPosition.current < y - 1) {
        isScrollingUpwards.current = false;
        lastYPosition.current = y;
      } else if (Math.abs(lastYPosition.current - y) > 1) {
        lastYPosition.current = y;
      }
    }
    setIsScrolledToTop(y <= 0);
  }, [navbarHeight.current]);

  const getMenuItemProps = () => [
    {
      text: (
        <span className="text-left whitespace-nowrap">
          {t('hello')}, <b>{customer?.displayName ?? ''}</b>
        </span>
      ),
    },
    { text: t('customer.customer'), href: '/customer' },
    { text: t('orders.headline'), href: '/customer/orders' },
    { text: t('customer.address-book'), href: '/customer/address-book' },
    { text: t('customer.newsletter'), href: '/customer/newsletter-settings' },
    { text: t('customer.profile.title'), href: '/customer/profile' },
    {
      text: 'Logout',
      onClick: async () => {
        await logout();
        notifications.setNotification({
          hideAfter: NOTIFICATION_DURATION.MEDIUM,
          text: t('modals.logout.success.content'),
          title: t('modals.logout.success.title'),
          variant: NOTIFICATION.SUCCESS,
        });
        router.push('/');
      },
    },
  ];
  const loggedInEntries = useRef<Array<MenuItemProps>>(getMenuItemProps());

  const setShippingCountry = (country: CountryCode, selectedLanguage: string, text: string) => {
    console.log('setShippingCountry', country, selectedLanguage, text);
    if (country === locale) {
      return;
    }
    setLocale(country);
    if (language !== selectedLanguage) {
      setLang(selectedLanguage as LanguageCode);
    }
    router.push({ pathname, query }, asPath, { locale: `${selectedLanguage}-${country}` });

    setActiveItem(
      <LayoutNavbarButton
        Icon={CountryFlag}
        IconProps={{ country: country.toLowerCase() }}
        text={text}
        data-test="open-shipping-drawer"
        isScrolled={!scrollAtStart}
        isActive={false}
        className="gap-2"
        iconClass="w-7 h-7"
      />,
    );
  };

  const localeNames = useMemo(
    () =>
      typeof window === 'undefined'
        ? { of: () => '' }
        : new Intl.DisplayNames(!!language ? language.toLowerCase() : 'de', { type: 'region' }),
    [language],
  );
  const shippingToCountries: Array<MenuItemProps> = useMemo(
    () => [
      {
        text: (
          <div>
            <div className="flex flex-col items-start gap-4">
              <span className="text-left whitespace-nowrap font-normal">
                <T keyName="languagePicker.title" />
              </span>
            </div>
          </div>
        ),
      },
      ...SUPPORTED_LANGUAGES.map((lang) => {
        const countryicon = (
          <img
            className="rounded-sm mr-2 overflow-hidden flex flex-shrink-0 w-[30px]"
            width="30"
            height="22.5"
            src={`/static/flags/${languageCodeToCountryCode(lang)}.svg`}
          />
        );
        return {
          text: t(`languagePicker.languages.${lang}`),
          Icon: countryicon as any,
          active: lang === language,
          onClick: () => {
            setLang(lang as LanguageCode);
            router.replace({ pathname: router.pathname, query: router.query }, router.asPath, {
              locale: `${lang}-${locale.toUpperCase()}`,
            });
          },
        };
      }),
      {
        text: (
          <div>
            <hr className="w-full mb-2" />
            <div className="flex flex-col items-start gap-4">
              <span className="text-left whitespace-nowrap font-normal">
                <T keyName="shipping_selection_headline" />
              </span>
              <span className="text-p-12 text-dark-tertiary -mb-2">
                <T keyName="shipping_selection_subline" />
              </span>
            </div>
          </div>
        ),
      },
      ...shippingMarkets?.map((country) => {
        const countrycode = country.regions.nodes[0].code as CountryCode;
        const currencycode = currencyCodeToSymbol(
          country.currencySettings.baseCurrency.currencyCode,
        );
        const countryicon = (
          <img
            className="rounded-sm mr-2 overflow-hidden flex flex-shrink-0 w-[30px]"
            width="30"
            height="22.5"
            src={`/static/flags/${countrycode.toLowerCase()}.svg`}
          />
        );
        return {
          text: country.regions.nodes[0].code
            ? localeNames.of(country.regions.nodes[0].code)
            : country.name,
          Icon: countryicon as any,
          textRight: `${currencycode} ${country.currencySettings.baseCurrency.currencyCode}`,
          onClick: () => {
            let lang = language;
            if (countrycode.toUpperCase() !== 'DE' && language.toLowerCase() === 'de') {
              lang = 'en' as LanguageCode;
            } else if (countrycode.toUpperCase() === 'DE' && language.toLowerCase() !== 'de') {
              lang = 'de' as LanguageCode;
            }
            setShippingCountry(countrycode, lang, countrycode + ' | ' + currencycode);
          },
        };
      }),
    ],
    [locale, language],
  );

  useEffect(() => {
    loggedInEntries.current = getMenuItemProps();
  }, [customer]);

  useEffect(() => {
    if (router.query.login) {
      openCustomerSessionDrawer();
    }
  }, [router.query.login]);

  const handleUserIconClicked = () => {
    if (customer) {
      router.push('/customer');
    } else {
      openCustomerSessionDrawer();
    }
  };

  const handleNavMenuHover = (url?: string) => () => {
    if (url === 'dog-sport') {
      setNavMenuProductState('hundesport');
      setNavMenuCollectionState(false);
      setNavMenuFAQState(false);
      return;
    }

    if (url === 'equine-sport') {
      setNavMenuProductState('pferdesport');
      setNavMenuCollectionState(false);
      setNavMenuFAQState(false);
      return;
    }

    if (url === 'boat-sport') {
      setNavMenuProductState('bootsport');
      setNavMenuCollectionState(false);
      return;
    }

    if (url === 'collections') {
      setNavMenuProductState(null);
      setNavMenuCollectionState(true);
      setNavMenuFAQState(false);
      return;
    }

    if (url === 'faq') {
      setNavMenuProductState(null);
      setNavMenuCollectionState(false);
      setNavMenuFAQState(true);
      return;
    }

    setNavMenuProductState(null);
    setNavMenuCollectionState(false);
    setNavMenuFAQState(false);
  };

  const navbar = useRef<HTMLDivElement>(null);
  const navbarPlaceholder = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (observer.current && navbar.current && navbarPlaceholder.current) {
      observer.current.unobserve(navbar.current);
      observer.current.observe(navbar.current);
    }
  }, [observer.current, navbarPlaceholder, navbar]);

  return (
    <>
      <div ref={navbarPlaceholder} id="navbarPlaceholder" className="relative w-full" />
      <div
        ref={navbar}
        className={classNames(
          'w-full transition-all duration-300 top-0 left-0 right-0 z-50 fixed',
          isScrollingUpwards.current || isScrolledToTop ? 'translate-y-0' : '-translate-y-full',
        )}
      >
        <div
          ref={navbarContainer}
          className={classNames(
            'flex flex-col w-full relative top-0 z-50 pb-2 lg:py-0 shadow transition-colors duration-300',
            scrollAtStart && !(isMobile && openMenu)
              ? 'bg-white text-dark-black'
              : 'bg-accent-primary text-light-white',
            openMenu && 'pb-0',
          )}
          data-testid="container"
        >
          {!isProd() && <StagingBanner />}
          <DiscountBar />
          <InfoBanner isScrolledToTop={isScrolledToTop} />
          <div className="flex items-center">
            {!isSmall && (
              <LayoutNavbarButton
                data-test="hamburger-navbar"
                className={classNames(
                  'flex-0 pl-6 lg:-mr-11 relative hidden sm:block z-[80]',
                  scrollAtStart
                    ? 'text-inherit hover:text-accent-primary'
                    : 'text-white border-white',
                )}
                Icon={openMenu ? X : Menu}
                onClick={() => toggleMenu()}
              />
            )}
            <div className="flex items-center justify-between w-full h-16 gap-5 pr-wrapper pl-2 sm:pl-5 lg:pl-16">
              {!isSmall && (
                <div
                  className={classNames(
                    'w-4/12 lg:w-2/12 items-center gap-4 xl:gap-8 hidden h-full flex-0 sm:flex',
                    scrollAtStart ? 'text-inherit' : 'text-white',
                  )}
                  onMouseEnter={handleNavMenuHover()}
                >
                  <Link href={'/'}>
                    <a className="hidden sm:block flex-1 w-full max-w-[10rem]">
                      <LogoHorizontal />
                    </a>
                  </Link>
                </div>
              )}

              {isSmall && (
                <div className="flex items-center gap-5">
                  <Link href={'/'}>
                    <a className="flex-0 sm:hidden" onMouseEnter={handleNavMenuHover()}>
                      <LogoHorizontal
                        className={classNames('w-40 h-full', !scrollAtStart && 'text-white')}
                      />
                    </a>
                  </Link>
                </div>
              )}

              <div className="items-center justify-evenly xl:pr-3 lg:w:4/12 lg:gap-2 xl:gap-4 xl:w-6/12 flex-1 hidden font-normal h-7 text-p-16 lg:flex">
                {layoutNavbarLinks
                  .filter((l) => l !== null)
                  .map((l, i) => {
                    if (!l) {
                      return <></>;
                    }

                    // TODO: Redundant
                    const branchToLink: Record<string, string> = {
                      'boot-sport': 'bootsport-root',
                      'dog-sport': 'hundesport-root',
                      'equine-sport': 'pferdesport-root',
                    };
                    const possibleMatch = branchToLink[l.navPart];
                    const isRootCategory =
                      (possibleMatch && router.asPath.includes(possibleMatch)) || false;
                    const anyIsRootCategory = Object.values(branchToLink).some((link) =>
                      router.asPath.includes(link),
                    );
                    const isActive =
                      (router.asPath.includes(l.navPart) && !anyIsRootCategory) ||
                      (l.url && router.asPath.includes(l.url) && !anyIsRootCategory) ||
                      isRootCategory ||
                      false;

                    return (
                      <LayoutNavbarButton
                        className={classNames(
                          'uppercase hover:pt-[2px] hover:border-b-2 hover:font-extrabold hover:border-current !flex-0',
                          scrollAtStart
                            ? !isActive && 'text-inherit hover:text-accent-primary'
                            : 'text-white border-white',
                          isActive &&
                            `pt-[2px] border-b-2 font-extrabold border-current ${
                              scrollAtStart ? 'text-accent-primary' : 'text-white border-white'
                            }`,
                        )}
                        isActive={isActive || false}
                        onClick={handleNavMenuHover(l.navPart)}
                        onMouseEnter={handleNavMenuHover(l.navPart)}
                        text={t(l.name)}
                        href={l.url ? `/${l.url}` : undefined}
                        key={`link-${i}`}
                      />
                    );
                  })}
              </div>

              {!openMenu && (
                <div
                  className={classNames('w-2/12 xl:w-3/12 hidden lg:block')}
                  onMouseEnter={handleNavMenuHover()}
                >
                  <LazySearchBar />
                </div>
              )}

              <div
                className={classNames(
                  'flex items-center justify-end h-full w-fit space-x-8 md:-mr-11 flex-0 md:pr-6',
                  scrollAtStart ? 'text-inherit' : 'text-white',
                )}
                onMouseEnter={handleNavMenuHover()}
              >
                {!isMedium && isEnabledFlag(FeatureFlag.INTERNATIONAL) && (
                  <Dropdown
                    menuClassName="mt-6"
                    menuButtonClassName={classNames(
                      'bg-transparent text-current !min-w-[250px]',
                      scrollAtStart ? 'hover:text-accent-primary' : 'hover:!font-bold',
                      router.asPath.includes('customer') &&
                        (scrollAtStart ? '!text-accent-primary' : '!text-white'),
                    )}
                    ActiveItem={ActiveItem}
                    items={shippingToCountries}
                  />
                )}
                {customer ? (
                  <Dropdown
                    menuClassName="mt-6"
                    menuButtonClassName={classNames(
                      'bg-transparent text-current',
                      scrollAtStart ? 'hover:text-accent-primary' : 'hover:!font-bold',
                      router.asPath.includes('customer') &&
                        (scrollAtStart ? '!text-accent-primary' : '!text-white'),
                    )}
                    ActiveItem={
                      <LayoutNavbarButton
                        Icon={UserLoggedIn}
                        data-test="open-login-drawer"
                        isScrolled={!scrollAtStart}
                        isActive={router.asPath.includes('customer') || false}
                      />
                    }
                    iconOnly
                    items={loggedInEntries.current}
                  />
                ) : (
                  <LayoutNavbarButton
                    Icon={User}
                    data-test="open-login-drawer"
                    isScrolled={!scrollAtStart}
                    onClick={handleUserIconClicked}
                    isActive={router.asPath.includes('customer') || false}
                    onKeyPress={(e) => e.key === 'Enter' && handleUserIconClicked()}
                  />
                )}

                <LayoutNavbarButton
                  Icon={ShoppingBag}
                  href="/cart"
                  isScrolled={!scrollAtStart || (isMobile && openMenu)}
                  isActive={router.asPath.includes('cart') || false}
                  counter={cart.getQuantity() || undefined}
                />

                <LayoutNavbarButton
                  onClick={() => toggleMenu()}
                  Icon={openMenu ? X : Menu}
                  className={'sm:hidden'}
                />
              </div>
            </div>
          </div>
          <div className="px-2 lg:hidden md:px-6">
            <LazySearchBar />
          </div>
        </div>
        <NavMenuProduct />
        <NavMenuCollection />
        <NavMenuFaq />
      </div>
    </>
  );
};
