import React, { useEffect } from 'react';
import { ContentBlock7Props } from '@customTypes/storyblok-types';
import { storyblokEditable } from '@storyblok/react';
import { useProductCardLazyQuery } from '../../../generated/graphql/apollo';
import useBreakpoint, { Breakpoints } from '@hooks/useBreakpoint';
import classNames from '@utils/ClassNames';
import { StoryblokImageOrVideo } from '@components/Image/StoryblokImageOrVideo';
import { ProductCardVertical } from '@components/Product/Card/ProductCardVertical';
import { RichText } from '@components/RichText';

export const ContentBlock7: React.FC<{ blok: ContentBlock7Props }> = ({ blok }) => {
  const [productOneQuery, { data: product_1_data }] = useProductCardLazyQuery({
    variables: { handle: blok.product_1.split("/")[0] },
  });
  const [productTwoQuery, { data: product_2_data }] = useProductCardLazyQuery({
    variables: { handle: blok.product_2.split("/")[0] },
  });

  const isMobileViewport = useBreakpoint(Breakpoints.md);

  useEffect(() => {
    productOneQuery({ variables: { handle: blok.product_1.split("/")[0] } });
  }, [blok.product_1]);

  useEffect(() => {
    productTwoQuery({ variables: { handle: blok.product_2.split("/")[0] } });
  }, [blok.product_2]);

  return (
    <div
      /* @ts-ignore*/
      {...storyblokEditable(blok)}
      className={classNames(blok.blue_background && "bg-accent-primary_005")}
    >
      <div
        className={classNames(
          'relative flex flex-col w-full gap-4 py-11 md:py-16 box-x-wrapper',
          blok.image_emphasis === 'left' ? 'flex-col-reverse sm:flex-row-reverse' : 'flex-col sm:flex-row',
        )}
      >
        {(blok.image.filename || blok.shopTheLook || blok.video_id) && (
          <div className="flex-1 w-full aspect-square md:aspect-auto">
            <div className="relative w-full h-full">
              <StoryblokImageOrVideo
                filename={blok.image?.filename}
                alt={blok.image?.alt ?? undefined}
                layout="fill"
                focus={blok.image.focus}
                sizes={isMobileViewport ? '100vw' : '55vw'}
                objectFit={blok.fill_image ? 'contain' : 'cover'}
                parallax={blok.image_parallax}
                video_id={blok.video_id}
                autoplay={blok.autoplay}
                shopTheLook={blok.shopTheLook}
              />
            </div>
          </div>
        )}
        <div className="flex flex-col justify-between flex-1 w-full gap-10" id={blok.id}>
          <div className="grid w-full grid-cols-6">
            <h3 className={classNames('col-span-6 text-h-24 lg:text-h-72', blok.split_headline ? 'hyphens-auto' : '')}>{blok.headline}</h3>
            {typeof blok.text === 'string' ? (
              <p className="col-span-6 mt-4 text-p-16 lg:col-span-4">{blok.text}</p>
            ) : (
              <RichText className="col-span-6 mt-4 text-p-16 lg:col-span-4" text={blok.text} />
            )}
          </div>
          <div className="flex flex-row w-full md:mt-auto">
            {product_1_data?.product && (
              <div className="flex-1 -mb-6">
                {/* TODO: Add tracking for storyblok components that use productCards */}
                <ProductCardVertical
                  collections={product_1_data.product.collections}
                  handle={product_1_data.product.handle}
                  highlights={
                    product_1_data.product.highlights?.value?.length
                      ? JSON.parse(product_1_data.product.highlights.value)
                      : []
                  }
                  price={product_1_data.product.priceRange}
                  title={product_1_data.product.title}
                  variantSku={blok.product_1.split("/")[1]}
                />
              </div>
            )}
            {product_2_data?.product && (
              <div className="flex-1 -mb-6">
                <ProductCardVertical
                  collections={product_2_data.product.collections}
                  handle={product_2_data.product.handle}
                  highlights={
                    product_2_data.product.highlights?.value?.length
                      ? JSON.parse(product_2_data.product.highlights.value)
                      : []
                  }
                  price={product_2_data.product.priceRange}
                  title={product_2_data.product.title}
                  variantSku={blok.product_2.split("/")[1]}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div >
  );
};
