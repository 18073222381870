import { useEffect, useState } from 'react';
import classNames from 'classnames';
import useBreakpoint, { Breakpoints } from '@hooks/useBreakpoint';
import SliderDots from '@components/Common/Dots';
import HeroSliderTextOverlay from '@components/HeroSlider/TextOverlay';
import HeroSliderBackground from '@components/HeroSlider/Background';
import HeroSliderForegroundImage from '@components/HeroSlider/ForegroundImage';
import { primaryColorDict, secondaryColorDict } from '@lib/color';
import HeroSliderPrimaryLine from '@components/HeroSlider/PrimaryLine';
import HeroSliderSecondaryLine from '@components/HeroSlider/SecondaryLine';
import { useWindowSize } from 'react-use';
import resolveConfig from 'tailwindcss/resolveConfig';
import tailwindConfig from '../../tailwind.config';
import { HeroSlide } from '@customTypes/storyblok-types';

const tailwind = resolveConfig(tailwindConfig as any);

const screenWidth = tailwind.theme?.screens as any;
const lgWidth = Number(screenWidth.lg.replace('px', ''));
const smWidth = Number(screenWidth.sm.replace('px', ''));

type HeroSliderProps = {
  slides: HeroSlide[];
};

const HeroSlider = ({ slides }: HeroSliderProps) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const { width: windowWidth } = useWindowSize();
  const isMobile = useBreakpoint(Breakpoints.lg);
  const [heroMinHeight, setHeroMinHeight] = useState<string | undefined>(undefined);
  const slideColors = slides.map((e) => ({
    primary: primaryColorDict[e.content_branch] || primaryColorDict.dog,
    secondary: secondaryColorDict[e.content_branch] || secondaryColorDict.dog,
  }));
  const [touchStartX, setTouchStartX] = useState(0);
  const [currentTouchX, setCurrentTouchX] = useState(0);
  const [ignoreFirstSlide, setIgnoreFirstSlide] = useState(false);

  useEffect(() => {
    const timer = setInterval(() => {
      if (ignoreFirstSlide) {
        setIgnoreFirstSlide(false);
      } else {
        setCurrentIndex((currentIndex + 1) % slides.length);
      }
    }, 5000);
    return () => clearInterval(timer);
  }, [ignoreFirstSlide, currentIndex]);

  useEffect(() => {
    setHeroMinHeight(
      windowWidth > smWidth
        ? `max(calc(100vh - ${windowWidth < lgWidth ? '7.6875rem' : '4rem'} - ${windowWidth >= lgWidth ? '40px' : '0px'
        }), 720px)` // Window height minus header height
        : undefined,
    );
  }, [typeof window !== 'undefined' && windowWidth]);

  const primarySlideColor = slideColors[currentIndex].primary;
  const secondarySlideColor = slideColors[currentIndex].secondary;
  return (
    <div
      className={classNames('w-full flex flex-col overflow-hidden')}
      style={{
        minHeight: heroMinHeight,
      }}
    >
      <div
        className="relative lg-after:flex-1 flex flex-col"
        onTouchStart={(e) => {
          setTouchStartX(e.touches[0].clientX);
        }}
        onTouchMove={(e) => {
          setCurrentTouchX(e.touches[0].clientX);
        }}
        onTouchEnd={() => {
          if (touchStartX - currentTouchX > 50) {
            setCurrentIndex((currentIndex - 1 + slides.length) % slides.length);
            setIgnoreFirstSlide(true);
          } else if (touchStartX - currentTouchX < -50) {
            setCurrentIndex((currentIndex + 1) % slides.length);
            setIgnoreFirstSlide(true);
          }
        }}
      >
        <HeroSliderForegroundImage currentIndex={currentIndex} slides={slides} />
        <HeroSliderBackground currentIndex={currentIndex} slides={slides} />
        {!isMobile && (
          <>
            <HeroSliderSecondaryLine color={secondarySlideColor}>
              {slides.length > 1 && (
                <SliderDots
                  totalSlides={slides.length}
                  currentIndex={currentIndex}
                  onFirstSlideChange={() => {
                    setIgnoreFirstSlide(true);
                  }}
                  onIndexChange={(index) => {
                    setCurrentIndex(index);
                  }}
                />
              )}
            </HeroSliderSecondaryLine>
            <HeroSliderTextOverlay currentIndex={currentIndex} slides={slides} />
          </>
        )}
      </div>
      <HeroSliderPrimaryLine color={primarySlideColor} />
      {isMobile && (
        <>
          {slides.length > 1 ? (
            <SliderDots
              currentIndex={currentIndex}
              totalSlides={slides.length}
              onFirstSlideChange={() => {
                setIgnoreFirstSlide(true);
              }}
              onIndexChange={(index) => {
                setCurrentIndex(index);
              }}
            />
          ) : (
            <div className="pt-12" />
          )}
          <HeroSliderTextOverlay slides={slides} currentIndex={currentIndex} />
        </>
      )}
    </div>
  );
};

export default HeroSlider;
