import { SubmitHandler, useForm } from 'react-hook-form';
import { FormFieldTextInput } from '@components/Form/Field/TextInput';
import { ButtonPrimary } from '@components/Button/Primary';
import { useState } from 'react';
import { useCustomerResetPasswordMutation } from '@generated/graphql/apollo';
import { CustomerUserError } from '@generated/graphql/types';
import { useTranslate } from '@tolgee/react';

type Inputs = {
  customerEmail: string;
};

const ForgotPassword = () => {
  const [resetPassword, { loading, data }] = useCustomerResetPasswordMutation();
  const [fetchErrors, setFetchErrors] = useState<CustomerUserError[]>([]);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<Inputs>();
  const { t } = useTranslate();

  const onSubmit: SubmitHandler<Inputs> = async (formData) => {
    const { customerEmail } = formData;
    try {
      const res = await resetPassword({ variables: { email: customerEmail } });
      if (
        res.data?.customerRecover?.customerUserErrors &&
        res.data.customerRecover.customerUserErrors.length > 0
      ) {
        setFetchErrors(res.data.customerRecover.customerUserErrors);
      } else {
        setFetchErrors([]);
      }
    } catch (e) {
      console.log('An error occured requesting a password reset');
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormFieldTextInput
          helperText={t('forgotPassword.helper')}
          label={t('customer.email')}
          type="email"
          name="customerEmail"
          formRegister={register}
          formWatch={watch}
          errors={errors}
          required
        />
        {fetchErrors.length ? (
          <div className="text-special-danger">
            {fetchErrors
              .filter((e) => !!e.code)
              .map((err, index) => (
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                <div key={index}>{t(`customerErrorCodeMapping.${err.code!}`)}</div>
              ))}
          </div>
        ) : (
          <></>
        )}
        {data ? (
          <h5 className="mt-2 text-center text-accent-primary">{t('forgotPassword.emailSent')}</h5>
        ) : (
          <ButtonPrimary
            className="w-full mt-2"
            type="submit"
            loading={loading}
            text={t('forgotPassword.submitButton')}
          />
        )}
      </form>
    </>
  );
};

export default ForgotPassword;
