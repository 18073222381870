import { SVGProps } from 'react';

const SvgChevronUp = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M13.251 9.213 19 14.787 17.749 16 12 10.427 6.251 16 5 14.787l5.749-5.574L12 8l1.251 1.213Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgChevronUp;
