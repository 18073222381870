import { MenuDrawer } from './Drawer';
import { NavList } from '@components/NavList';
import { Divider } from '@components/Divider';
import { NavListItemPrimary } from '../NavList/Item/Primary';
import { NavListItemSecondary } from '../NavList/Item/Secondary';
import { faqNavMenuList, menuCustomerLinkList, menuHelpLinkList } from './links';
import { useEffect, useState } from 'react';
import classNames from 'classnames';
import { getCollectionPath } from '@lib/getCollectionPath';
import { NavListSection } from '@components/NavList/Section';
import { CollectionTree, useCollectionTreeZustand } from '@zustand/shopData/collectionTree';
import { useScrollAtStartZustand } from '@zustand/scrollAtStart';
import { useMenuZustand } from '@zustand/menu';
import useBreakpoint from '@hooks/useBreakpoint';
import { useTranslate } from '@tolgee/react';
import { useRouter } from 'next/router';

export const Menu = () => {
  const { openMenu, setOpenMenu } = useMenuZustand();
  const rootElement = useCollectionTreeZustand();
  const isMobile = useBreakpoint();
  const { t } = useTranslate();
  const router = useRouter();

  const [menuList, setMenuList] = useState<CollectionTree[]>([rootElement]);
  const scrollAtStart = useScrollAtStartZustand((store) => store.scrollAtStart);

  useEffect(() => {
    setTimeout(() => {
      setMenuList([
        {
          items: rootElement.items ?? [],
          link: '/products',
          title: 'Produkte',
        },
      ]);
    }, 10);
  }, [openMenu]);

  const closeMenu = () => {
    setOpenMenu(false);
  };

  useEffect(() => {
    router.events.on('routeChangeStart', closeMenu);
    return () => router.events.off('routeChangeStart', closeMenu);
  }, []);

  return (
    <MenuDrawer
      show={openMenu}
      onClose={() => {
        if (!isMobile) {
          closeMenu();
        }
      }}
      onAfterLeave={() => setMenuList([rootElement])}
    >
      <div
        data-test="side-menu-drawer"
        className="flex flex-col h-full max-w-full lg:flex-row lg:bg-white"
      >
        {menuList.map((list: CollectionTree, layer) => {
          const sliceMenuToTargetLayer = menuList.slice(0, layer);
          return (
            <NavList
              key={layer}
              href={
                !list.link || list.link.startsWith('/faq')
                  ? undefined
                  : getCollectionPath(list.link)
              }
              title={layer > 0 ? t(list.title) : undefined}
              back={layer > 0}
              className={classNames(
                'md:px-4 lg:px-16 bg-accent-primary',
                layer === 0 && 'text-white',
                layer > 0 && 'text-accent-primary',
                layer === 1 && 'bg-accent-primary_02 lg:!w-80',
                layer === 2 && 'bg-accent-primary_01 lg:!w-80',
              )}
              onClick={() => {
                setMenuList(sliceMenuToTargetLayer);
              }}
              style={{
                zIndex: menuList.length - layer,
              }}
            >
              <>
                <NavListSection>
                  {list.items?.map((item: CollectionTree, index) => (
                    <NavListItemPrimary
                      text={item.title}
                      key={index}
                      directory={!!item.items}
                      className={layer === 0 ? 'text-h-24' : 'text-h-16'}
                      {...(!item.items && {
                        href: item.link.startsWith('/faq')
                          ? item.link
                          : getCollectionPath(item.link),
                      })}
                      onClick={
                        !item.items
                          ? closeMenu
                          : () => setMenuList([...menuList.slice(0, layer + 1), item])
                      }
                    />
                  ))}
                </NavListSection>

                <Divider
                  className={classNames(
                    layer !== 0 ? 'lg:hidden !border-dark-tertiary' : undefined,
                    'border-light-quaternary',
                  )}
                />

                {!menuList.some((l) => l.title.startsWith('nav.faq.')) && (
                  <NavListSection className={classNames(layer !== 0 && 'lg:hidden')}>
                    {faqNavMenuList.map((item: CollectionTree, index) => (
                      <NavListItemPrimary
                        text={t(item.title)}
                        key={index}
                        directory={!!item.items}
                        {...(!item.items && { href: item.link })}
                        onClick={() => {
                          if (!item.items) {
                            closeMenu();
                          } else {
                            if (item.link === '/faq') {
                              setMenuList([
                                ...menuList.slice(0, 1),
                                {
                                  ...item,
                                  items: item.items?.map((i) => ({
                                    ...i,
                                    link: i.link,
                                    title: t(i.title),
                                  })),
                                },
                              ]);
                              return;
                            }
                            setMenuList([
                              ...menuList.slice(0, layer + 1),
                              {
                                ...item,
                                items: item.items?.map((i) => ({
                                  ...i,
                                  link: i.link,
                                  title: t(i.title),
                                })),
                              },
                            ]);
                          }
                        }}
                      />
                    ))}
                    {menuHelpLinkList.map((item: CollectionTree, index) => (
                      <NavListItemPrimary
                        text={t(item.title)}
                        key={index}
                        href={item.link}
                        onClick={closeMenu}
                      />
                    ))}
                  </NavListSection>
                )}

                {menuHelpLinkList?.length ? (
                  <Divider
                    className={classNames(
                      layer !== 0 ? 'lg:hidden !border-dark-tertiary' : undefined,
                      'border-light-quaternary',
                    )}
                  />
                ) : (
                  <></>
                )}

                <NavListSection className={classNames(layer !== 0 && 'lg:hidden')}>
                  {menuCustomerLinkList.map((item: CollectionTree, index) => (
                    <NavListItemSecondary
                      text={t(item.title)}
                      key={index}
                      href={item.link}
                      onClick={closeMenu}
                    />
                  ))}
                </NavListSection>
              </>
            </NavList>
          );
        })}
      </div>
    </MenuDrawer>
  );
};
