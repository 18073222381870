import { useInitializeCart } from '@hooks/useCart';
import { ReactNode } from 'react';

/**
 * Initializes all shopping hooks like `useCart`. Without this component, those hooks will not work properly.
 */
export const ShoppingHooksInitializer = ({ children }: { children: ReactNode }) => {
  useInitializeCart();
  return <>{children}</>;
};
