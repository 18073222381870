import React from 'react';
import { YoutubeBlockProps } from '@customTypes/storyblok-types';
import useBreakpoint from '@hooks/useBreakpoint';
import useHasConsent from '@hooks/useHasConsent';
import useUserCentricsInteracted from '@hooks/useUserCentricsInteracted';
import classNames from '../../utils/ClassNames';

export const StoryblokVideo: React.FC<YoutubeBlockProps & { className?: string }> = ({
  video_id,
  autoplay,
  className,
}) => {
  const isMobile = useBreakpoint();
  const hasAcceptedYoutube = useHasConsent('YouTube Video');
  const hasInteractedWithCookies = useUserCentricsInteracted();

  return hasInteractedWithCookies && hasAcceptedYoutube ? (
    <div className={classNames('h-full w-full', className)}>
      <iframe
        src={`https://www.youtube-nocookie.com/embed/${video_id}?${isMobile ? '' : 'controls=1&'}${
          autoplay ? 'autoplay=1&' : ''
        }mute=1&rel=0&loop=1&playlist=${video_id}`}
        title="YouTube video"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        className="w-full h-full"
      />
    </div>
  ) : (
    <div></div>
  );
};
