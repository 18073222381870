import { KeyboardEvent, useEffect, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import QueryResultList from '@components/SearchBar/QueryResultList';
import { useTranslate } from '@tolgee/react';
import { Search, X } from '@components/icons';
import useBreakpoint from '@hooks/useBreakpoint';
import { useScrollAtStartZustand } from '@zustand/scrollAtStart';
import classNames from 'classnames';
import { useMenuZustand } from '@zustand/menu';
import dynamic from 'next/dynamic';
import { useQuickSearch } from '@hooks/useSearch';
import useABTest from '@hooks/useABTest';
import useGtm from '../hooks/useGTM';
import { getGtmPayloadSearchUsed } from '../lib/gtm/productList';
import { useDebounce } from 'react-use';

type SearchWrapperProps = {
  noOverlay?: boolean;
  exposeFocusChange?: (hasFocus: boolean) => void;
};

const SearchWrapper = ({ noOverlay, exposeFocusChange }: SearchWrapperProps) => {
  const isMobile = useBreakpoint();
  const resultCount = isMobile ? 5 : 10;
  const [query, setQuery] = useState('');
  const { searchResults, autoSuggestions } = useQuickSearch(query, resultCount);
  const [hasFocus, setFocus] = useState(false);
  const [hasResultFocus, setResultFocus] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const tablist = useRef<HTMLDivElement>(null);
  const [isClosing, setIsClosing] = useState(false);
  const { setOpenMenu: setState } = useMenuZustand();
  const scrollAtStart = useScrollAtStartZustand((store) => store.scrollAtStart);
  const sendDataToGTM = useGtm();

  const sendEvent = () => sendDataToGTM(getGtmPayloadSearchUsed({ query }));

  useDebounce(
    () => {
      if (query !== '') {
        sendEvent();
      }
    },
    400,
    [query],
  );

  const router = useRouter();
  const { t } = useTranslate();

  const abTest = useABTest('checkout');

  useEffect(() => {
    setTimeout(() => {
      // Prevent autofocus of new search input field when opening menu immediately after first render
      inputRef.current?.blur();
    });
  }, [inputRef]);

  // Tab through all relevant items -> in b2b ignore the fast buy button, in b2c include it
  const handleKeyDown = async (e: KeyboardEvent) => {
    if (!searchResults || !searchResults.length) {
      return;
    }
    if (e.key === 'ArrowDown' || e.key === 'ArrowUp') {
      e.preventDefault();
      e.stopPropagation();
      const focusableElements = '#searchInput, a.quickSearchItem';

      if (tablist.current) {
        const focusable = Array.from<HTMLElement>(
          tablist.current.querySelectorAll(focusableElements),
        ).filter(
          (element) =>
            element.offsetWidth > 0 ||
            element.offsetHeight > 0 ||
            element === document.activeElement,
        );
        const index = focusable.indexOf(document.activeElement as HTMLElement);
        if (index > -1) {
          const nextElement =
            focusable[e.key === 'ArrowDown' ? index + 1 : index - 1] ||
            (e.key === 'ArrowDown' ? focusable[0] : focusable[focusable.length - 1]);
          nextElement.focus();
        }
      }
    }
  };
  if (abTest === 'shopify' && router.pathname === '/checkout') {
    return <></>;
  }

  return (
    <div ref={tablist} onKeyDown={handleKeyDown} role="none">
      {!noOverlay && (hasFocus || hasResultFocus) && (
        <div
          role="none"
          onClick={() => {
            setResultFocus(false);
            setFocus(false);
            if (exposeFocusChange) {
              exposeFocusChange(false);
            }
          }}
          className="absolute top-0 left-0 w-full h-screen bg-black bg-opacity-20"
        />
      )}
      <div className="relative">
        <div
          className={classNames(
            'absolute inset-y-0 left-0 flex items-center pl-3',
            scrollAtStart ? 'text-[#27293759]' : 'text-[#FFFFFFA6]',
          )}
        >
          {query.length > 0 ? (
            <button
              onClick={() => {
                setQuery('');
                setTimeout(() => {
                  inputRef.current!.focus();
                }, 201);
              }}
            >
              <X className="w-5 h-5" aria-hidden="true" />
            </button>
          ) : (
            <Search className="w-5 h-5 pointer-events-none" aria-hidden="true" />
          )}
        </div>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            if (inputRef.current) {
              inputRef.current.blur();
            }
            setState(false);
            if (query.trim().length === 0) {
              return;
            }
            router.push(`/search?q=${query}`);
          }}
          action="#"
          method="get"
        >
          <input
            type="text"
            name="search"
            id="searchInput"
            className={classNames(
              'search-bar block w-full pl-10 rounded-[4px] focus:border-accent-primary focus:border-b-2 focus:ring-0 sm:text-p-14',
              !scrollAtStart
                ? 'bg-accent-primary border border-light-quaternary hover:border-light-tertiary text-white placeholder-light-secondary'
                : 'border border-dark-quaternary hover:border-dark-tertiary placeholder-dark-secondary',
            )}
            placeholder={t('search.placeholder')}
            value={query}
            ref={inputRef}
            onChange={(e) => {
              setQuery(e.target.value);
            }}
            onFocus={() => {
              setFocus(true);
              if (exposeFocusChange) {
                exposeFocusChange(true);
              }
            }}
            onBlur={() => {
              // allow clicks to pass through
              setTimeout(() => {
                setFocus(false);
              }, 200);
            }}
          />
        </form>
      </div>
      {(hasFocus || hasResultFocus) && (
        <QueryResultList
          query={query}
          suggestions={autoSuggestions}
          products={searchResults}
          onProductSelected={() => setResultFocus(false)}
          onResultSelect={(e) => {
            setResultFocus(false);
            setQuery(e);
            setState(false);
          }}
          onFocus={() => {
            setResultFocus(true);
            setIsClosing(false);
          }}
          onBlur={() => {
            setIsClosing(true);
            setTimeout(() => {
              if (isClosing) {
                setResultFocus(false);
              }
            }, 200);
          }}
        />
      )}
    </div>
  );
};

export default SearchWrapper;

export const LazySearchBar = dynamic(() => import('./Search'), {
  ssr: false,
});
