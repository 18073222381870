import { create } from 'zustand';

export enum PickerState {
  INITIAL,
  CHOOSE_COUNTRY,
  FINISH,
  DONE = -1,
}

type ShippingCountryPickerState = {
  state: PickerState;
  setPickerState: (state: PickerState) => void;
};

export const useShippingCountryPopUp = create<ShippingCountryPickerState>((set) => ({
  state: PickerState.INITIAL,
  setPickerState: (openMenu) => set((state) => ({ ...state, state: openMenu })),
}));
