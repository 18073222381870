import classNames from 'classnames';
import Link from 'next/link';
import { ButtonHTMLAttributes, ComponentType, DetailedHTMLProps, ReactNode, SVGProps } from 'react';

export type ButtonProps = {
  text?: string;
  loading?: boolean;
  disabled?: boolean;
  Icon?: ComponentType<SVGProps<SVGSVGElement>>;
  iconPosition?: 'left' | 'right';
  iconClass?: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
} & Omit<ContainerProps, 'children'>;

export const Button = ({
  className,
  text,
  loading,
  Icon,
  iconPosition = 'left',
  disabled,
  ...containerProps
}: ButtonProps) => (
  <Container
    className={classNames(
      'inline-flex justify-center items-center px-10 py-2.5 min-w-35 font-semibold focus:outline focus:outline-4 focus:outline-accent-primary_01 rounded-full tracking-[.03125em] transition duration-500',
      className,
      disabled &&
      'disabled:text-dark-tertiary border disabled:border-dark-background disabled:bg-dark-background',
    )}
    disabled={disabled || loading}
    {...containerProps}
  >
    {!loading && Icon != null && iconPosition === 'left' && (
      <Icon className={classNames('w-4 h-4 -ml-1', containerProps.iconClass)} />
    )}
    {loading && (
      <svg
        className="w-4 h-4 -ml-1 animate-spin"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
      >
        <circle
          className="opacity-25"
          cx="12"
          cy="12"
          r="10"
          stroke="currentColor"
          strokeWidth="4"
        ></circle>
        <path
          className="opacity-75"
          fill="currentColor"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
        ></path>
      </svg>
    )}

    {text && (
      <span
        className={
          (Icon || loading) && iconPosition === 'left'
            ? 'ml-2'
            : Icon && iconPosition === 'right'
              ? 'mr-2'
              : ''
        }
      >
        {text}
      </span>
    )}
    {!loading && Icon != null && iconPosition === 'right' && (
      <Icon className={classNames('w-4 h-4 -mr-1', containerProps.iconClass)} />
    )}
  </Container>
);

type ContainerProps = {
  className?: string;
  href?: string;
  openInNew?: boolean;
  children: ReactNode;
  disabled?: boolean;
} & Pick<
  DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>,
  'onClick' | 'type'
>;

const Container = ({
  className,
  href,
  children,
  onClick,
  type,
  disabled,
  openInNew,
}: ContainerProps) => {
  const anchorProps = {
    href,
    rel: openInNew ? 'noopener noreferrer' : '',
    target: openInNew ? '_blank' : '_self',
  };
  const buttonProps = { disabled, onClick, type };
  const commonProps = { className };

  if (href && !disabled) {
    return (
      <Link href={href}>
        <a {...anchorProps} {...commonProps}>
          {children}
        </a>
      </Link>
    );
  }

  return (
    <button {...buttonProps} {...commonProps}>
      {children}
    </button>
  );
};
