import { StoryblokComponentType } from '@storyblok/react';
import { MouseEvent, useEffect, useRef, useState, useMemo, Ref, RefObject } from 'react';
import { useImageSkuMinimalDataLazyQuery } from '@generated/graphql/apollo';
import { StoryblokImage } from '../Image/StoryblokImage';
import { ProductPrice } from '../Product/Price';
import Link from 'next/link';
import { getProductPath } from '@lib/getProductPath';
import { useWindowSize } from 'react-use';
import { autoPlacement, autoUpdate, computePosition, offset, shift } from '@floating-ui/dom';
import classNames from '@utils/ClassNames';
import useBreakpoint from '@hooks/useBreakpoint';
import { useLocale } from '@zustand/useLocale';
import { ensureLanguage } from '@lib/utils';
import UnitPrice from '../Product/PageElements/UnitPrice';
import Head from 'next/head';

export interface ShopTheLookPoint {
  desktop?: {
    x: number;
    y: number;
  };
  mobile?: {
    x: number;
    y: number;
  };
  productSKU: string;
}

export interface ShopTheLookProps extends StoryblokComponentType<string> {
  asset: {
    filename: string;
  };
  points: Array<ShopTheLookPoint>;
  className?: string;
}

export const ShopTheLook = (blok: ShopTheLookProps) => {
  const wrapper = useRef<HTMLDivElement>(null);
  const [loaded, setLoaded] = useState(false);
  const [mounted, setMounted] = useState(false);
  const windowSize = useWindowSize();

  const wrapperDimensions = useMemo(() => {
    if (!wrapper.current) {
      return [0, 0];
    }
    const { height, width } = wrapper.current.getBoundingClientRect();
    return [height, width];
  }, [windowSize, wrapper.current, mounted]);

  const imageDimensions = useMemo(() => {
    const imageElement = wrapper.current?.getElementsByTagName('img')?.[0];
    return [imageElement?.naturalHeight ?? 0, imageElement?.naturalWidth ?? 0];
  }, [loaded, wrapper.current, windowSize, mounted]);

  const wrapperRatio = (wrapperDimensions[0] || 1) / (wrapperDimensions[1] || 1);
  const imageRatio = (imageDimensions[0] || 1) / (imageDimensions[1] || 1);

  const imageWidthInContainer = useMemo(
    () => (imageRatio > wrapperRatio ? wrapperDimensions[1] : wrapperDimensions[0] / imageRatio),
    [wrapperDimensions, wrapperRatio, imageRatio],
  );
  const imageHeightInContainer = useMemo(
    () => (imageRatio > wrapperRatio ? wrapperDimensions[1] * imageRatio : wrapperDimensions[1]),
    [wrapperDimensions, wrapperRatio, imageRatio],
  );

  const isMobile = useBreakpoint();

  const [objectPositions, setObjectPositions] = useState({ desktop: "center", mobile: "center" });

  useEffect(() => {
    const isLeftishDesktop = blok.points.every(p => !p.desktop || p.desktop.x < 0.33);
    const isRightishDesktop = blok.points.every(p => !p.desktop || p.desktop.x > 0.66);
    const isTopishDesktop = blok.points.every(p => !p.desktop || p.desktop.y < 0.33);
    const isBottomishDesktop = blok.points.every(p => !p.desktop || p.desktop.y > 0.66);
    const isLeftishMobile = blok.points.every(p => !p.mobile || p.mobile.x < 0.33);
    const isRightishMobile = blok.points.every(p => !p.mobile || p.mobile.x > 0.66);
    const isTopishMobile = blok.points.every(p => !p.mobile || p.mobile.y < 0.33);
    const isBottomishMobile = blok.points.every(p => !p.mobile || p.mobile.y > 0.66);

    // calculate image positions
    setObjectPositions({
      desktop:
        isLeftishDesktop
          ? isTopishDesktop
            ? "top left"
            : isBottomishDesktop
              ? "bottom left"
              : "center left"
          : isRightishDesktop
            ? isTopishDesktop
              ? "top right"
              : isBottomishDesktop
                ? "bottom right"
                : "center right"
            : isTopishDesktop
              ? "top center"
              : isBottomishDesktop
                ? "bottom center"
                : "center center",
      mobile:
        isLeftishMobile
          ? isTopishMobile
            ? "top left"
            : isBottomishMobile
              ? "bottom left"
              : "center left"
          : isRightishMobile
            ? isTopishMobile
              ? "top right"
              : isBottomishMobile
                ? "bottom right"
                : "center right"
            : isTopishMobile
              ? "top center"
              : isBottomishMobile
                ? "bottom center"
                : "center center"
    });
  }, [blok.points]);

  const activeObjectPosition = useMemo(() => isMobile ? objectPositions.mobile : objectPositions.desktop, [isMobile, objectPositions]);
  const isTop = useMemo(() => activeObjectPosition.split(" ")[0] === "top", [activeObjectPosition]);
  const isBottom = useMemo(() => activeObjectPosition.split(" ")[0] === "bottom", [activeObjectPosition]);
  const isLeft = useMemo(() => activeObjectPosition.split(" ")[1] === "left", [activeObjectPosition]);
  const isRight = useMemo(() => activeObjectPosition.split(" ")[1] === "right", [activeObjectPosition]);

  if (!blok.asset?.filename) {
    return <></>;
  }

  return (
    <div
      className={classNames('relative w-full h-full overflow-hidden', blok.className)}
      ref={wrapper}
    >
      <StoryblokImage
        onLoad={() => setTimeout(() => setLoaded(true), 1000)}
        src={blok.asset?.filename}
        placeholder="blur"
        sizes="100vw"
        focusSection={activeObjectPosition}
        layout="fill"
        objectFit="cover"
        objectPosition="center"
      />
      <div
        className="w-full h-full block absolute top-0 left-0 right-0 bottom-0"
        style={{
          top: `${isTop ? 0 : -(imageHeightInContainer - (wrapper.current?.clientHeight ?? 0)) / (isBottom ? 1 : 2)}px`,
          left: `${isLeft ? 0 : -(imageWidthInContainer - (wrapper.current?.clientWidth ?? 0)) / (isRight ? 1 : 2)}px`,
        }}
      >
        <div
          className="min-w-full min-h-full overflow-hidden relative"
          style={{ width: `${imageWidthInContainer}px`, height: `${imageHeightInContainer}px` }}
        >
          {
            isMobile &&
            blok.points
              ?.filter((p) => p.mobile)
              .map((point, index) =>
                blok.points[index] ? (
                  <StoryBlokImageProductAnnotation
                    key={`${point.mobile!.x}-${point.mobile!.y}`}
                    point={point.mobile!}
                    productSKU={blok.points[index].productSKU}
                  />
                ) : (
                  <></>
                ),
              )
          }
          {
            !isMobile &&
            blok.points
              ?.filter((p) => p.desktop)
              .map((point, index) =>
                blok.points[index] ? (
                  <StoryBlokImageProductAnnotation
                    key={`${point.desktop!.x}-${point.desktop!.y}`}
                    point={point.desktop!}
                    productSKU={blok.points[index].productSKU}
                  />
                ) : (
                  <></>
                ),
              )
          }
        </div >
      </div >
    </div >
  );
};

const StoryBlokImageProductAnnotation = ({
  productSKU,
  point,
}: Pick<ShopTheLookPoint, 'productSKU'> & { point: { x: number; y: number } }) => {
  const { language, locale } = useLocale();
  const [query, data] = useImageSkuMinimalDataLazyQuery({
    variables: {
      handle: productSKU.split('/')[0],
      country: ensureLanguage(locale),
      language: ensureLanguage(language),
    },
  });
  const [variant, setVariant] = useState<
    | ArrayElementOf<
      NonNullable<NonNullable<(typeof data)['data']>['product']>['variants']['edges']
    >['node']
    | null
  >();
  const [showData, setShowData] = useState(false);
  const overlay = useRef<HTMLAnchorElement>(null);
  const button = useRef<HTMLButtonElement>(null);

  const openPopover = async (e?: MouseEvent) => {
    e?.stopPropagation();
    e?.preventDefault();
    if (!data.data) {
      await query();
    }
    setShowData(true);
    // Wait for the popover to be visible
    setTimeout(() => {
      updatePosition();
    }, 1);
  };

  useEffect(() => {
    const variantSKU = productSKU.split('|')[1];
    if (variantSKU && data.data?.product) {
      const variant =
        data.data.product.variants.edges.find((e) => e.node.sku?.value === variantSKU)?.node ??
        null;
      setVariant(variant);
    }
  }, [data]);

  const toggleShowData = () => {
    setShowData((oldShowData) => !oldShowData);
  };

  function updatePosition() {
    if (button.current && overlay.current) {
      computePosition(button.current, overlay.current, {
        placement: 'top',
        strategy: 'fixed',
        middleware: [offset(12), autoPlacement({ padding: 20 }), shift()],
      }).then((placement) => {
        if (overlay.current) {
          overlay.current.style.top = `${placement.y}px`;
          overlay.current.style.left = `${placement.x}px`;
        }
      });
    }
  }

  useEffect(() => {
    if (button.current && overlay.current) {
      updatePosition();
      const cleanup = autoUpdate(button.current, overlay.current, updatePosition);
      return cleanup;
    }
  }, [overlay.current, button.current]);

  useEffect(() => {
    if (showData) {
      window.addEventListener('click', toggleShowData, { once: true });
    } else {
      window.removeEventListener('click', toggleShowData);
    }
    return () => window.removeEventListener('click', toggleShowData);
  }, [showData]);

  return (
    <>
      <button
        ref={button}
        onClick={(e) => openPopover(e)}
        onMouseEnter={() => openPopover()}
        className="absolute z-20 rounded-full w-6 h-6 bg-[#e8e8ea] focus:scale-110 hover:scale-110 transition-transform items-center flex justify-center"
        style={{
          top: `calc(${point.y * 100}% - 0.75rem)`,
          left: `calc(${point.x * 100}% - 0.75rem)`,
        }}
      >
        <div className="w-3 h-3 rounded-full bg-white" />
      </button>
      {showData && data.data?.product && (
        <Link href={getProductPath(productSKU, data.data.product.collections)}>
          <a
            href={getProductPath(productSKU, data.data.product.collections)}
            ref={overlay}
            className="fixed z-30"
          >
            <div className="p-4 gap-2 text-left items-start flex flex-col min-w-[300px] max-w-[300px] min-h-[135px] bg-white border-dark-quaternary border rounded-md shadow-l3">
              <span className="line-clamp-2 text-h-16 text-dark-primary">
                {data.data.product.title}
                {variant ? ` - ${variant.title}` : ''}
              </span>
              <span className="line-clamp-2 text-p-14 text-dark-secondary">
                {data.data.product.description}
              </span>
              {variant ? (
                <UnitPrice
                  variantPrice={variant.priceV2}
                  measurement={variant.unitPriceMeasurement}
                  price={variant.unitPrice}
                  referenceUnit={variant.bundle_desc?.value}
                />
              ) : (
                <ProductPrice
                  shouldWrap={false}
                  className="!text-h-14 text-accent-primary !font-bold flex-col"
                  priceRange={data.data.product.priceRange}
                  compareAtPriceV2={data.data.product.compareAtPriceRange}
                />
              )}
            </div>
          </a>
        </Link>
      )}
    </>
  );
};
