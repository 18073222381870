import { CrossSellProduct, CrossSellReference } from '@customTypes/CrossSelling';
import {
  CrossSellProductQuery,
  CrossSellProductQueryVariables,
  CrossSellProductVariantQuery,
  CrossSellProductVariantQueryVariables,
  WithCrossSellProductsFragment,
} from '@generated/graphql/types';
import { initializeApollo } from '@graphql/apollo-client';
import { CROSS_SELL_PRODUCT, CROSS_SELL_PRODUCT_VARIANT } from '@graphql/queries/product';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { useLocale } from '@zustand/useLocale';
import { ensureLanguage } from '@lib/utils';

export type UseCrossSellProductsPayload = {
  loading: boolean;
  error: boolean;
  data?: CrossSellProduct[];
};

type CrossSellItem =
  | Partial<WithCrossSellProductsFragment['crossSellReferences']>
  | null
  | undefined;

export function firstWithElements(
  ...items: CrossSellItem[]
): WithCrossSellProductsFragment['crossSellReferences'] {
  for (const item of items) {
    try {
      const possible = JSON.parse(item?.value ?? '[]');
      if (possible.length !== 0) {
        return item as WithCrossSellProductsFragment['crossSellReferences'];
      }
      // eslint-disable-next-line no-empty
    } catch (e) {}
  }
  return null;
}

export const useCrossSellProducts = (
  input?: Array<WithCrossSellProductsFragment['crossSellReferences']>,
): UseCrossSellProductsPayload => {
  const { locale } = useRouter();

  const apolloClient = initializeApollo(locale);
  const { locale: shopifyLocale, language } = useLocale();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [data, setData] = useState<CrossSellProduct[]>();

  useEffect(() => {
    const fetchCrossSellProducts = async (
      referencesInput: Array<WithCrossSellProductsFragment['crossSellReferences']>,
    ) => {
      const referencesAsArray = referencesInput.flatMap((rawReferences) => {
        if (!rawReferences) {
          return undefined;
        }

        return JSON.parse(rawReferences.value) as CrossSellReference[];
      });

      const queries = referencesAsArray.map((reference) => {
        if (!reference) {
          return undefined;
        }

        const { parentHandle, selectedOptions } = reference;

        if (!selectedOptions) {
          return apolloClient.query<CrossSellProductQuery, CrossSellProductQueryVariables>({
            query: CROSS_SELL_PRODUCT,
            variables: {
              handle: parentHandle,
              language: ensureLanguage(language),
              country: ensureLanguage(shopifyLocale),
            },
          });
        }

        return apolloClient.query<
          CrossSellProductVariantQuery,
          CrossSellProductVariantQueryVariables
        >({
          query: CROSS_SELL_PRODUCT_VARIANT,
          variables: {
            handle: parentHandle,
            selectedOptions,
            language: ensureLanguage(language),
            country: ensureLanguage(shopifyLocale),
          },
        });
      });

      const results = await Promise.all(queries);

      const products = results
        .map((result) => {
          if (!result?.data.product) {
            return undefined;
          }

          const { product } = result.data;

          if ('variantBySelectedOptions' in product) {
            // Filter archived variants from cross sell references
            if (product.variantBySelectedOptions?.isArchived?.value === 'true') {
              return undefined;
            }
            return product.variantBySelectedOptions;
          }

          if ('title' in product) {
            // Filter archived variants from cross sell references
            if (product.variants.edges.every((v) => v.node.isArchived?.value === 'true')) {
              return undefined;
            }
            return product;
          }

          return undefined;
        })
        .filter((output): output is NonNullable<typeof output> => !!output)
        .filter(
          (output): output is typeof output & { __typename: 'Product' | 'ProductVariant' } =>
            output.__typename === 'Product' || output.__typename === 'ProductVariant',
        );

      return Array.from(new Set(products));
    };

    if (!input?.length) {
      setData(undefined);
      setLoading(false);
      setError(false);
      return;
    }

    setLoading(true);

    fetchCrossSellProducts(input)
      .then((products) => {
        setData(products);
        setLoading(false);
      })
      .catch(() => {
        setError(true);
        setLoading(false);
      });
  }, [JSON.stringify(input)]);

  return { data, error, loading };
};
