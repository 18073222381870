import { Disclosure, Transition } from '@headlessui/react';
import classNames from '@utils/ClassNames';
import SvgChevronDown from '@components/icons/ChevronDown';
import SvgChevronUp from '@components/icons/ChevronUp';

interface DisclosureContentProps {
  children: React.ReactNode;
  className?: string;
  buttonClassName?: string;
  seo_answer?: string;
  title: string;
}
export const DisclosureContent = ({
  children,
  title,
  seo_answer,
  className,
  buttonClassName,
}: DisclosureContentProps) => (
  <Disclosure>
    {({ open }) => (
      <div itemScope itemProp="mainEntity" itemType="https://schema.org/Question">
        <Disclosure.Button
          id={title}
          className={classNames(
            'w-full min-h-[5rem] hover:text-dark-secondary border-dark-quaternary text-h-16 leading-6 flex justify-between items-center',
            open ? '' : 'border-b',
            buttonClassName,
          )}
        >
          <h4 className="font-bold text-h-16 flex-1 text-left" itemProp="name">
            {title}
          </h4>
          <div
            className="h-0 w-0 overflow-hidden"
            itemScope
            itemProp="acceptedAnswer"
            itemType="https://schema.org/Answer"
          >
            <div itemProp="text">{seo_answer}</div>
          </div>
          {!open && <SvgChevronDown className="flex-0 w-6 h-6" />}
          {open && <SvgChevronUp className="flex-0 w-6 h-6" />}
        </Disclosure.Button>
        <Transition
          show={open}
          enter="transition duration-300 ease-out"
          enterFrom="transform opacity-0"
          enterTo="transform opacity-100"
          leave="transition duration-75 ease-out"
          leaveFrom="transform opacity-100"
          leaveTo="transform opacity-0"
        >
          <Disclosure.Panel
            className={classNames(
              'text-p-14 gap-y-8 flex flex-col border-dark-quaternary border-b',
              className,
            )}
          >
            {children}
          </Disclosure.Panel>
        </Transition>
      </div>
    )}
  </Disclosure>
);
