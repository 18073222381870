import React from 'react';
import { MagazinTitleProps } from '@customTypes/storyblok-types';
import { storyblokEditable } from '@storyblok/react';
import { EditorialSectionMagazinTitle } from '../../EditorialSection/MagazinTitle';

export const MagazinTitle: React.FC<{ blok: MagazinTitleProps }> = ({ blok }) => (
  <EditorialSectionMagazinTitle
    /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
    /* @ts-ignore*/
    {...storyblokEditable(blok)}
    title={blok.title}
    author={blok.author}
    preview_text={blok.preview_text}
    date={blok.date}
    preview_image={blok.preview_image}
    category={blok.category}
  />
);
