import { SideDrawer } from '@components/SideDrawer';
import { CustomerSession, CustomerSessionType } from '.';
import { Button } from '@components/Button';
import { useTranslate } from '@tolgee/react';
import { useCustomerSessionDrawerZustand } from '@zustand/customer/sessionDrawer';
import { ChevronRight, X } from '@components/generated/icons';
import Link from 'next/link';
import { B2BLinkLocation, PostHogEvent, track } from '@lib/posthog';

interface HeaderOptions {
  currentSessionType: NonNullable<CustomerSessionType | 'default'>;
}

export const CustomerSessionDrawer = () => {
  const { open, sessionType, setSessionType, setOpen, openWithSessionType } =
    useCustomerSessionDrawerZustand();
  const { t } = useTranslate();

  const HeaderTitle: Record<HeaderOptions['currentSessionType'], string> = {
    default: t('customerSession.drawer.default.title'),
    login: t('customerSession.drawer.login.title'),
    register: t('customerSession.drawer.register.title'),
    reset: t('customerSession.drawer.reset.title'),
  };
  const HeaderSubLinkLabel: Record<HeaderOptions['currentSessionType'], string> = {
    default: t('customerSession.drawer.default.subLinkLabel'),
    login: t('customerSession.drawer.login.subLinkLabel'),
    register: t('customerSession.drawer.register.subLinkLabel'),
    reset: t('customerSession.drawer.reset.subLinkLabel'),
  };
  const HeaderSubmitAction: Record<HeaderOptions['currentSessionType'], () => void> = {
    default: () => openWithSessionType('register'),
    login: () => openWithSessionType('register'),
    register: () => openWithSessionType('login'),
    reset: () => openWithSessionType('login'),
  };

  const Header = ({ currentSessionType }: HeaderOptions) => (
    <div className="flex flex-col w-full">
      <div className="flex flex-row items-center">
        <div className="py-2 text-white text-h-24">{HeaderTitle[currentSessionType]}</div>
        {currentSessionType === 'default' && (
          <Button
            className="text-accent-primary border-2 border-transparent uppercase bg-white hover:bg-accent-primary hover:text-white hover:border-white px-6 ml-auto md:mr-[5.125rem]"
            text={t('customerSession.drawer.default.submitLabel')}
            onClick={() => openWithSessionType('login')}
          />
        )}
      </div>
      <hr className="my-5" />
      <div className="flex flex-row items-center">
        <div className="text-white text-h-14">{HeaderSubLinkLabel[currentSessionType]}</div>
        <button
          className="flex items-center w-10 h-10 ml-auto md:mr-[5.125rem] border-2 border-transparent bg-white text-accent-primary hover:bg-accent-primary hover:text-white hover:border-white rounded-full justify-centers"
          onClick={HeaderSubmitAction[currentSessionType]}
        >
          <ChevronRight className="flex-1 w-5 h-5" />
        </button>
      </div>
      <hr className="my-5" />
      <div className="flex flex-row items-center">
        <div className="text-white text-h-14">{t('customerSession.drawer.b2b.link')}</div>
        <Link href="https://sprenger.de">
          <a
            target="_blank"
            onClick={() =>
              track(PostHogEvent.B2BLinkClicked, {
                location: B2BLinkLocation.CustomerDrawer,
              })
            }
            className="flex items-center w-10 h-10 ml-auto md:mr-[5.125rem] border-2 border-transparent bg-white text-accent-primary hover:bg-accent-primary hover:text-white hover:border-white rounded-full justify-centers"
          >
            <ChevronRight className="flex-1 w-5 h-5" />
          </a>
        </Link>
      </div>
    </div>
  );

  return (
    <SideDrawer
      show={open}
      onClose={() => {
        setSessionType(null);
        setOpen(false);
      }}
      header={<Header currentSessionType={sessionType ?? 'default'} />}
      CloseIcon={X}
      closeIconClassName={'w-5 h-5 text-white'}
      className="h-full"
      showBack={!!sessionType}
      onBack={() => {
        setSessionType(null);
        setOpen(true);
      }}
    >
      <CustomerSession
        onPasswordReset={() => openWithSessionType('reset')}
        onActionDone={() => {
          setSessionType('login');
          setOpen(false);
        }}
        initialSessionType={sessionType}
        onLinkClick={() => {
          setSessionType(null);
          setOpen(false);
        }}
      />
    </SideDrawer>
  );
};
