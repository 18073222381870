import { MoneyV2 } from '@generated/graphql/types';
import { usePriceFormat } from '@hooks/usePriceFormat';
import classNames from '@utils/ClassNames';

interface PriceProps {
  price: MoneyV2;
  compareAtPrice?: MoneyV2 | null;
  variantAmount?: number;
  className?: string;
  shouldWrap?: boolean;
  taxIncluded?: boolean;
}

export const Price = ({
  price,
  compareAtPrice,
  taxIncluded,
  variantAmount,
  shouldWrap = true,
  className,
}: PriceProps) => {
  const priceTemp = { ...price };
  const compareAtPriceTemp = compareAtPrice ? { ...compareAtPrice } : undefined;
  priceTemp.amount = variantAmount ? priceTemp.amount * variantAmount : priceTemp.amount;

  if (compareAtPriceTemp) {
    compareAtPriceTemp.amount = variantAmount
      ? compareAtPriceTemp.amount * variantAmount
      : compareAtPriceTemp.amount;
  }
  const { formattedPrice, formattedComparePrice } = usePriceFormat(
    priceTemp,
    compareAtPriceTemp,
    taxIncluded,
  );

  if (!formattedPrice) {
    return null;
  }
  if (formattedComparePrice !== formattedPrice) {
    return (
      <span
        className={classNames(
          'flex items-end gap-x-2.5',
          className,
          shouldWrap ? 'flex-col md:flex-row' : 'flex-row',
        )}
      >
        <h4
          style={{
            fontSize: 'inherit',
            fontWeight: 'inherit',
            letterSpacing: 'inherit',
            lineHeight: 'inherit',
          }}
        >
          {formattedPrice}
        </h4>
        <span className="font-medium text-dark-tertiary/35">
          <del>{formattedComparePrice}</del>
        </span>
      </span>
    );
  }
  return (
    <h4
      className={classNames('flex flex-col md:flex-row items-end md:gap-x-2.5', className)}
      style={{
        fontSize: 'inherit',
        fontWeight: 'inherit',
        letterSpacing: 'inherit',
        lineHeight: 'inherit',
      }}
    >
      {formattedPrice}
    </h4>
  );
};
