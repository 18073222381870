import { useEffect, useState } from 'react';
import { useWindowSize } from 'react-use';
import { ButtonPrimary } from './Button/Primary';
import SignatureHermannSprengerGermanyBlack from './generated/logos/SignatureHermannSprengerGermanyBlack';
import resolveConfig from 'tailwindcss/resolveConfig';
import tailwindConfig from '../tailwind.config';
import Link from 'next/link';
import { StoryblokImage, withBlur } from './Image/StoryblokImage';
import { ContentBranch, ImageStoryblok, ContentBranchCore } from '@customTypes/storyblok-types';
import { primaryColorDict, secondaryColorDict, signatureDict } from '@lib/color';
import useBreakpoint from '@hooks/useBreakpoint';
import { useAutoEnableAfter } from '@hooks/useAutoEnableAfter';
import classNames from 'classnames';
import useResponsive from '@hooks/useResponsive';
import { getFontSize } from '@components/HeroSlider/TextOverlay';
import { ISbRichtext } from '@storyblok/react';
import { RichText } from '@components/RichText';

const tailwind = resolveConfig(tailwindConfig as any);

const screenWidth = tailwind.theme?.screens as any;
const lgWidth = Number(screenWidth.lg.replace('px', ''));
const smWidth = Number(screenWidth.sm.replace('px', ''));

export type HeroProps = {
  headline: string;
  title?: string;
  description: ISbRichtext;
  button?: ButtonProps;
  bg_image?: ImageStoryblok;
  floating_image?: FloatingImageProps;
  content_branch: ContentBranch;
  signature: ContentBranchCore;
};

export const Hero = ({
  headline,
  title,
  description,
  bg_image,
  button,
  content_branch,
  floating_image,
  signature,
}: HeroProps) => {
  const { width: windowWidth } = useWindowSize();
  const isMobile = useBreakpoint();
  const shouldShow = useAutoEnableAfter();
  const [showBackground, setShowBackground] = useState(false);
  const [fontSize, setFontSize] = useState("1.5rem");
  const width = useResponsive();

  const Signature = signatureDict[signature] || SignatureHermannSprengerGermanyBlack;

  useEffect(() => {
    setFontSize(getFontSize(headline, width));
  }, [headline, width])

  return (
    <div
      className="relative flex flex-col overflow-hidden sm:border-b-24"
      style={{
        borderBottomColor: !isMobile
          ? primaryColorDict[content_branch] || primaryColorDict.dog
          : '',
        minHeight:
          windowWidth > smWidth
            ? `max(calc(100vh - ${windowWidth < lgWidth ? '7.6875rem' : '4rem'} - ${windowWidth >= lgWidth ? '40px' : '0px'
            }), 720px)` // Window height minus header height
            : undefined,
      }}
    >
      <div className="z-10 sm:w-1/2 right-1/2 sm:pl-wrapper sm:absolute sm:h-full">
        <div className="flex flex-col items-center px-4 py-6 space-y-10 text-center bg-white text-dark-black sm:justify-between sm:py-10 sm:h-full">
          <div className="flex items-center justify-center flex-1">
            <h1
              className="font-black text-h-72 lg:text-h-152 hyphens-auto"
              style={{ fontSize: `${fontSize}`, lineHeight: "100%" }}
            >
              {headline}
            </h1>
          </div>

          <Signature className="w-32 lg:w-48" />
        </div>
      </div>
      {floating_image && (
        <div
          className={classNames(
            'absolute z-20 md:top-[55%] top-1/3 right-[12%] h-1/2 w-1/3 transition duration-1000 transform',
            shouldShow ? '' : 'translate-x-full',
          )}
        >
          <div className="relative h-2/3">
            <StoryblokImage
              src={floating_image.src}
              alt={floating_image?.alt}
              sizes="25vw"
              priority={true}
              placeholder="blur"
              blurDataURL={withBlur(floating_image?.src)}
              layout="fill"
              objectFit="contain"
            />
          </div>
        </div>
      )}

      <div
        style={{
          backgroundColor: secondaryColorDict[content_branch] || secondaryColorDict.dog,
          borderColor: primaryColorDict[content_branch] || primaryColorDict.dog,
        }}
        className="flex flex-col flex-1 py-6 space-y-6 border-t-16 sm:border-0 sm:py-0 sm:justify-end"
      >
        {bg_image && (
          <div className="min-h-[15rem] flex flex-col flex-1">
            <div className="relative flex-1 sm:hidden">
              <BgImage {...bg_image} />
            </div>

            <div className="flex-1 hidden h-auto sm:flex">
              <div className="relative w-1/2">{showBackground && <BgImage {...bg_image} />}</div>

              <div className="relative w-1/2">
                <BgImage
                  {...bg_image}
                  onLoadingComplete={() => {
                    // Prevent left image from loading the image as well thus show it only after the right image is loaded
                    setShowBackground(true);
                  }}
                />
              </div>
            </div>
          </div>
        )}

        <div className="grid grid-cols-2 place-items-center w-full">
          <div className="sm:col-start-2 col-span-2 sm:col-span-1 flex flex-col w-full sm:w-[328px] items-center px-4 space-y-6 sm:items-start sm:px-0 text-accent-primary sm:py-10">
            <div className="space-y-2">
              {title && <p className="uppercase font-extrabold text-h-16">{title}</p>}
              {typeof description === 'string' ? (
                <p className="text-p-16">{description}</p>
              ) : (
                <RichText className="text-p-16" text={description} />
              )}
            </div>

            {button && <Button {...button} />}
          </div>
        </div>
      </div>
    </div>
  );
};

interface ButtonProps {
  text: string;
  url?: string;
}

const Button = ({ text, url }: ButtonProps) => {
  if (url) {
    return (
      <Link href={url}>
        <a>
          <ButtonPrimary text={text} />
        </a>
      </Link>
    );
  }

  return <ButtonPrimary text={text} />;
};

interface FloatingImageProps {
  src: string;
  alt: string;
  dimensions: {
    width: number;
    height: number;
  };
}

const BgImage = ({
  filename,
  alt,
  focus,
  onLoadingComplete,
}: ImageStoryblok & { onLoadingComplete?: () => void }) => (
  <StoryblokImage
    src={filename!}
    alt={alt ?? ''}
    sizes="50vw"
    onLoadingComplete={onLoadingComplete}
    priority={true}
    placeholder="blur"
    focus={focus}
    blurDataURL={withBlur(filename!)}
    layout="fill"
    objectFit="cover"
  />
);
