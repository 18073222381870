import { ProductPriceRange } from '@generated/graphql/types';
import { usePriceFormat } from '@hooks/usePriceFormat';
import classNames from 'classnames';

interface PriceProps {
  priceRange: ProductPriceRange;
  compareAtPriceV2?: ProductPriceRange;
  shouldWrap?: boolean;
  className?: string;
  taxIncluded?: boolean;
}

export const PriceWithSale = ({
  priceRange,
  compareAtPriceV2,
  shouldWrap,
  className,
  taxIncluded,
}: PriceProps) => {
  const { formattedPrice: lowestPrice, formattedComparePrice: lowestComparePrice } = usePriceFormat(
    priceRange.minVariantPrice,
    compareAtPriceV2?.minVariantPrice,
  );
  const { formattedPrice: highestPrice, formattedComparePrice: highestComparePrice } =
    usePriceFormat(priceRange.maxVariantPrice, compareAtPriceV2?.maxVariantPrice, taxIncluded);

  return (
    <span className={classNames('flex flex-wrap', className)}>
      <div
        className={classNames('flex', shouldWrap ? 'flex-col items-end' : 'flex-row justify-end')}
      >
        <span className={classNames(!shouldWrap && 'whitespace-pre')}>{lowestPrice} </span>
        <span className="whitespace-nowrap"> - {highestPrice}</span>
      </div>
      {lowestComparePrice && highestComparePrice && (
        <div className="font-medium text-dark-tertiary/35">
          <del>
            {lowestComparePrice} - {highestComparePrice}
          </del>
        </div>
      )}
    </span>
  );
};
