import Cookies from 'js-cookie';
import { useMemo } from 'react';
import { ABExperiment, ABTestKeys } from '../lib/abTests';
import { AB_TEST_COOKIE_NAME } from '../lib/constants';

/**
 * Hook to get ab test that should be displayed for this user.
 */
function useABTest<T extends ABTestKeys>(
  testName: T,
): ArrayElementOf<(typeof ABExperiment)[T]> | undefined {
  const test = useMemo(() => {
    const localState = JSON.parse(Cookies.get(AB_TEST_COOKIE_NAME) ?? '{}') as Record<
      ABTestKeys,
      ArrayElementOf<(typeof ABExperiment)[T]>
    >;
    if (localState[testName]) {
      return localState[testName];
    }
  }, [testName]);

  return test;
}

export default useABTest;
