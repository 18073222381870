import { CustomerAccessToken } from '@generated/graphql/types';
import { create } from 'zustand';

type CustomerAccessTokenState = {
  customerAccessToken: CustomerAccessToken | null;
  setCustomerAccessToken: (customerAccessToken: CustomerAccessToken | null) => void;
};

export const useCustomerAccessTokenZustand = create<CustomerAccessTokenState>((set) => ({
  customerAccessToken: null,
  setCustomerAccessToken: (customerAccessToken) =>
    set((state) => ({ ...state, customerAccessToken })),
}));
