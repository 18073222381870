import { useEffect, useMemo, useRef, useState } from 'react';
import Image, { ImageProps } from 'next/image';
import { ImageFallback } from './Fallback';
import { LoadingIndicator } from '../LoadingIndicator';
import classNames from 'classnames';

type LoaderArgs = { quality?: number; src: string; width: number };

const storyblokLoader = ({ src, width, quality = 75 }: LoaderArgs) =>
  `${src}/m/${Math.min(width, 2048)}x0/filters:quality(${quality})`;

const cloudflaredLoader = ({ src, width }: LoaderArgs) =>
  `${src}?width=${Math.min(width, 2048)}&no_upscale=True`;

export function getLoaderForUrl(url: ImageProps['src']) {
  if (typeof url !== 'string' || url.endsWith('.svg')) {
    return undefined;
  }
  const parsed = new URL(url);
  switch (parsed.host) {
    case 'cdn.thearc.dev':
      return cloudflaredLoader;
    case 'a.storyblok.com':
      return storyblokLoader;
    default:
      return undefined;
  }
}

export function withBlur(url?: string | null, width = 50, blur = 10) {
  if (!url) return undefined;
  const loaderUrl = getLoaderForUrl(url)!({ src: url, width });
  const parsed = new URL(loaderUrl);
  switch (parsed.host) {
    case 'cdn.thearc.dev':
      return `${loaderUrl}&blur=${blur}`;
    case 'a.storyblok.com':
      return `${url}/m/${width}x0/filters:blur(${blur})`;
    default:
      return undefined;
  }
}

export const StoryblokImage = ({
  src,
  parallax,
  focus,
  focusSection,
  ...imageProps
}: ImageProps & { parallax?: boolean; focus?: string, focusSection?: string }) => {
  const [imageSrc, setImagesSrc] = useState<typeof src | undefined>();
  const [fallback, setFallback] = useState<boolean>(!src);
  const [loading, setLoading] = useState<boolean>(true);
  const [scrollTop, setScrollTop] = useState<number>(0);
  const svgSrc = useRef<string | undefined>(undefined);
  const anchorRef = useRef<HTMLDivElement>(null);
  const [intersectionObserver, setIntersectionObserver] = useState<IntersectionObserver>();

  const imageSizes = useMemo(() => {
    if (!src || typeof src !== 'string') return;
    const matches = src.match(/\/(\d+)x(\d+)\//);
    if (!matches || matches.length !== 3) return;
    return {
      width: parseInt(matches[1]),
      height: parseInt(matches[2]),
    };
  }, [src]);

  useEffect(() => {
    if (!focus || typeof focus !== 'string' || !imageSizes) return;
    const [x, y] = focus
      .split(':')[0]
      .split('x')
      .map((val) => parseInt(val));
    if (!x || !y) return;

    let objectPosition = '';
    if (y < imageSizes.height / 3) {
      objectPosition += 'top';
    } else if (y > (imageSizes.height / 3) * 2) {
      objectPosition += 'bottom';
    } else {
      objectPosition += 'center';
    }
    if (x < imageSizes.width / 3) {
      objectPosition += ' left';
    } else if (x > (imageSizes.width / 3) * 2) {
      objectPosition += ' right';
    } else {
      objectPosition += ' center';
    }
    setImageFocusSection(objectPosition);
  });

  const [imageFocusSection, setImageFocusSection] = useState(focusSection ?? 'center');

  const onScroll = () => {
    if (!anchorRef.current || !anchorRef.current.nextSibling) {
      return;
    }
    const { top, height } = (anchorRef.current.nextSibling as HTMLElement).getBoundingClientRect();
    const parentHeight = (
      anchorRef.current.nextSibling as HTMLElement
    ).parentElement?.getBoundingClientRect()?.height;
    if (!parentHeight) {
      return;
    }
    const newScrollTop = -0.1 * (parentHeight - (top * height) / window.innerHeight);
    setScrollTop(newScrollTop);
  };

  useEffect(() => {
    if (intersectionObserver && anchorRef.current?.nextSibling) {
      intersectionObserver.observe(anchorRef.current?.nextSibling as HTMLElement);
    }
  }, [intersectionObserver]);

  useEffect(() => {
    if (parallax && !focus) {
      setIntersectionObserver(
        new IntersectionObserver(
          (mutationList) => {
            if (mutationList[0].isIntersecting) {
              window.addEventListener('scroll', onScroll);
            } else {
              window.removeEventListener('scroll', onScroll);
            }
          },
          { threshold: 0 },
        ),
      );
      return () => window.removeEventListener('scroll', onScroll);
    }
  }, [parallax]);

  useEffect(() => {
    let unsubscribeTimeout: NodeJS.Timeout;
    if (src !== imageSrc) {
      // trick next js to force recreation of image element
      setImagesSrc(undefined);
      setLoading(true);
      if (typeof src === 'string' && src.endsWith('.svg')) {
        fetch(`/api/image-proxy/${encodeURIComponent(src)}`)
          .then((res) => res.json())
          .then((r) => {
            let areaElement = document.createElement('textarea');
            areaElement.innerHTML = r.svg
              .replace(/width="\d+"/, 'width="100%"')
              .replace(/height="\d+"/, 'height="100%"');
            svgSrc.current = areaElement.value;
            setLoading(false);
          });
      }
      unsubscribeTimeout = setTimeout(() => {
        setImagesSrc(src);
      });
    }
    return () => unsubscribeTimeout && clearTimeout(unsubscribeTimeout);
  }, [src]);

  if (fallback || !src) {
    return (
      <ImageFallback
        width={imageProps.width}
        height={imageProps.height}
        className={imageProps.className}
      />
    );
  }
  return (
    <>
      {parallax && !focus && !focusSection && <div ref={anchorRef} />}
      {!svgSrc.current && imageSrc
        ? !(typeof imageSrc === 'string' && imageSrc.endsWith('.svg')) && (
          <Image
            priority={imageProps.priority}
            loader={getLoaderForUrl(src)}
            blurDataURL={getLoaderForUrl(imageSrc.toString())?.({
              quality: 25,
              src: imageSrc.toString(),
              width: 4,
            })}
            {...imageProps}
            // @ts-ignore
            parallax={!focus && !focusSection ? `${parallax}` : undefined}
            onLoadingComplete={(res) => {
              setLoading(false);
              imageProps.onLoadingComplete?.(res);
            }}
            loading={imageProps.loading}
            src={imageSrc}
            style={{
              ...imageProps.style,
              transform: parallax && !focus ? `scale(1.3) translateY(${scrollTop}px)` : undefined,
              transformOrigin: 'top',
            }}
            objectPosition={
              focusSection ? focusSection :
                focus && !parallax
                  ? imageFocusSection
                  : parallax
                    ? `top ${imageFocusSection?.split(' ')?.[1] ?? ''}`
                    : 'center'
            }
            alt={imageProps.alt ?? ''}
            className={classNames(loading ? 'invisible' : '', imageProps.className)}
            onError={() => setFallback(true)}
          />
        )
        : svgSrc.current && (
          <div
            className="w-full h-full aspect-square object-center path"
            dangerouslySetInnerHTML={{ __html: svgSrc.current }}
          />
        )}
      {loading && (
        <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center">
          <LoadingIndicator />
        </div>
      )}
    </>
  );
};
