import { create } from 'zustand';
import { ShopDataQuery } from '@generated/graphql/types';

export type ShopDataState = ShopDataQuery['shop'] | null;

interface ShopDataStore {
  shopData: ShopDataState;
  setShopData: (shopData: ShopDataState) => void;
}

export const useShopDataZustand = create<ShopDataStore>((set) => ({
  setShopData: (shopData: ShopDataState) => set((state) => ({ ...state, shopData })),
  shopData: null,
}));
