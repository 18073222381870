import classNames from 'classnames';
import useBreakpoint, { Breakpoints } from '@hooks/useBreakpoint';
import { StoryblokImage } from '@components/Image/StoryblokImage';
import { HeroSlide } from '@customTypes/storyblok-types';

type HeroSliderBackgroundProps = {
  currentIndex: number;
  slides: HeroSlide[];
};

const HeroSliderBackground = ({ currentIndex, slides }: HeroSliderBackgroundProps) => {
  const isMobile = useBreakpoint(Breakpoints.lg);

  return (
    <div className="w-full lg-after:h-auto h-56 sm:h-76 md:h-[25rem] relative lg-after:flex-1 bg-gray-200">
      {slides.map(({ bg_image }, i) => (
        <div
          key={i}
          className={classNames(
            i === currentIndex ? 'opacity-100' : 'opacity-0',
            'transition duration-1000 absolute w-full h-full justify-between top-0 left-0 flex',
          )}
        >
          {!isMobile && (
            <StoryblokImage
              width={30}
              height="100%"
              src={bg_image.filename!}
              focus={bg_image.focus}
              priority={true}
              className={classNames(
                'transition object-cover object-left duration-1000',
                i === currentIndex ? 'opacity-100' : 'opacity-0',
              )}
            />
          )}
          <div className="h-full w-full lg-after:w-1/2 relative">
            <StoryblokImage
              layout="fill"
              sizes="50vw"
              src={bg_image.filename!}
              focus={bg_image.focus}
              objectFit="cover"
              priority={true}
              className={classNames(
                'transition duration-1000',
                i === currentIndex ? 'opacity-100' : 'opacity-0',
              )}
            />
          </div>
        </div>
      ))}
    </div>
  );
};

export default HeroSliderBackground;
