import { create } from 'zustand';

type DiscountBarDismissState = {
  isDismissed: boolean;
  setDismissed: (dismissed: boolean) => void;
};

export const useDiscountBarDismissZustand = create<DiscountBarDismissState>((set) => ({
  isDismissed: false,
  setDismissed: (dismissed: boolean) => set((state) => ({ isDismissed: dismissed })),
}));
