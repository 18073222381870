import posthog, { CaptureOptions } from 'posthog-js';

export enum PostHogEvent {
  B2BLinkClicked = 'B2B Link Clicked',
}

export enum B2BLinkLocation {
  Footer = 'Footer',
  CustomerDrawer = 'Customer Drawer',
  CollectionMenuDrawer = 'Collection Menu Drawer',
}

type Properties = {
  location: B2BLinkLocation;
} | null; // Add your own intersection types here

export const track = (event: PostHogEvent, properties?: Properties, options?: CaptureOptions) => {
  posthog.capture(event, properties, options);
};
