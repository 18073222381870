import { ContentBlock2Props, ImageStoryblok } from '@customTypes/storyblok-types';
import { getImageDimensions } from '@lib/storyblokUtil';
import useBreakpoint, { Breakpoints } from '@hooks/useBreakpoint';
import classNames from '@utils/ClassNames';
import { StoryblokImage, withBlur } from '@components/Image/StoryblokImage';
import { StoryblokImageOrVideo } from '@components/Image/StoryblokImageOrVideo';
import { StoryblokButton } from '@components/Button/StoryblokButton';
import { storyblokEditable } from '@storyblok/react';
import { RichText } from '@components/RichText';

export const ContentBlock2 = ({ blok }: { blok: ContentBlock2Props }) => {
  const isMobileViewport = useBreakpoint(Breakpoints.md);
  const {
    image_emphasis,
    video_id,
    autoplay,
    bg_image,
    floating_image,
    subtitle,
    title,
    description,
    button_link,
    button_text,
    desktopImageSize,
    blue_background
  } = blok;
  return (
    <div
      /* @ts-ignore*/
      {...storyblokEditable(blok)}
      className={classNames("py-11 md:py-16", blue_background && "bg-accent-primary_005")}
    >
      <div className="flex flex-col space-y-6 space-y lg:space-y-16 box-x-wrapper">
        <div
          className={classNames(
            'flex w-full md:gap-x-3 lg:gap-x-4 items-start',
            image_emphasis === 'left' ? 'flex-row-reverse' : 'flex-row',
          )}
        >
          {(bg_image.filename || video_id || blok.shopTheLook) && (
            <div className="relative w-full aspect-[1/1] lg:aspect-auto md:w-3/4 lg:min-h-[41rem]">
              <StoryblokImageOrVideo
                shopTheLook={blok.shopTheLook}
                filename={bg_image.filename}
                placeholder="blur"
                blurDataURL={withBlur(bg_image.filename)}
                alt={bg_image.alt}
                focus={bg_image.focus}
                layout="fill"
                sizes={isMobileViewport ? '100vw' : '75vw'}
                objectFit="cover"
                parallax={blok.image_parallax}
                video_id={video_id}
                autoplay={autoplay}
              />
            </div>
          )}

          <div className="flex-col justify-between w-1/6 space-y-8 md:w-1/3 lg:w-1/4 hidden md:flex">
            {floating_image.filename && (
              <div className="relative flex-0 p-3 md:p-4 lg:p-4 bg-radial-gradient-content-block justify-end">
                <div className={classNames("relative w-full h-full", image_emphasis === 'right' ? 'float-right' : 'float-left',)} style={{ width: `${50 + (desktopImageSize?.value ?? 100)}%`, aspectRatio: `${getImageDimensions(blok.floating_image).width} / ${getImageDimensions(blok.floating_image).height}` }}>
                  <FloatingImage
                    {...floating_image}
                    dimensions={getImageDimensions(blok.floating_image)}
                    className={classNames(
                      'absolute w-full',
                      image_emphasis === 'right' ? 'right-0' : 'left-0',
                    )}
                  />
                </div>
              </div>
            )}
            <div className="justify-center flex-1 lg:flex-0">
              <div className="flex-col">
                <span className="text-h-16">{subtitle}</span>
                <h4 className="text-h-24 lg:text-h-32 mb-6 hyphens-auto">{title}</h4>
                {typeof description === 'string' ? (
                  <p>{description}</p>
                ) : (
                  <RichText text={description} />
                )}
                <div className="flex flex-col w-full items-stretch">
                  {button_link && button_text && (
                    <StoryblokButton
                      text={button_text}
                      url={button_link.url}
                      className="w-full my-6"
                    />
                  )}
                </div>
              </div >
            </div >
          </div >
        </div >

        <div className="flex flex-col justify-center md:hidden flex-0">
          <span className="text-h-16">{subtitle}</span>
          <span className="mb-2 text-h-24 lg:text-h-32">{title}</span>
          {typeof description === 'string' ? <p>{description}</p> : <RichText text={description} />}
          <div className="flex flex-col w-full items-stretch">
            {button_link && button_text && (
              <StoryblokButton text={button_text} url={button_link.url} className="w-full my-6" />
            )}
          </div>
        </div>
      </div >
    </div >
  );
};

type FloatingImageProps = ImageStoryblok & {
  dimensions: {
    width: number;
    height: number;
  };
  className?: string;
};

const FloatingImage = ({ filename, alt, dimensions, focus, className }: FloatingImageProps) => (
  <div
    className={className}
    style={{
      aspectRatio: `${dimensions.width} / ${dimensions.height}`,
    }}
  >
    <StoryblokImage
      src={filename!}
      alt={alt ?? ''}
      layout="responsive"
      focus={focus}
      width={dimensions.width}
      height={dimensions.height}
    />
  </div>
);
