import { InfoPopup } from '@components/Language/InfoPopup';
import { CountryChooser } from '@components/Language/CountryChooser';
import { useLocale } from '@zustand/useLocale';
import { PickerState, useShippingCountryPopUp } from '@zustand/shippingCountryPopUp';
import { useMountedState } from 'react-use';

type ShippingCountryPopupProps = {};

const ShippingCountryPopup = () => {
  const { state, setPickerState: setStep } = useShippingCountryPopUp();
  const { saveCurrentLanguage } = useLocale();

  const isMounted = useMountedState()

  if (!isMounted()) {
    return <></>;
  }

  switch (state) {
    case PickerState.INITIAL:
      return (
        <InfoPopup
          showAdvancedOptions={true}
          onChange={() => setStep(PickerState.CHOOSE_COUNTRY)}
          onClose={() => {
            setStep(PickerState.DONE);
            saveCurrentLanguage();
          }}
        />
      );
    case PickerState.CHOOSE_COUNTRY:
      return (
        <CountryChooser
          onClose={() => setStep(PickerState.FINISH)}
          onSelected={() => {
            setStep(2);
          }}
        />
      );
    case PickerState.FINISH:
      return (
        <InfoPopup
          showAdvancedOptions={false}
          onChange={() => setStep(PickerState.CHOOSE_COUNTRY)}
          onClose={() => {
            setStep(PickerState.DONE);
            saveCurrentLanguage();
          }}
        />
      );
    default:
      return null;
  }
};

export default ShippingCountryPopup;
