import { CustomerQuery } from '@generated/graphql/types';
import { create } from 'zustand';

export type CustomerState = {
  customer: CustomerQuery['customer'] | null;
  setCustomer: (customer: CustomerQuery['customer'] | null) => void;
};

export const useCustomerZustand = create<CustomerState>((set) => ({
  customer: null,
  setCustomer: (customer) => set((state) => ({ ...state, customer })),
}));
