import classNames from 'classnames';
import { ISbRichtext, renderRichText } from '@storyblok/react';

export type RichTextProps = {
  text: ISbRichtext;
  className?: string;
};

export const RichText = ({ className, text }: RichTextProps) => {
  const html = renderRichText(text);

  return (
    <div
      dangerouslySetInnerHTML={{ __html: html }}
      className={classNames('rich-text', className)}
    />
  );
};
