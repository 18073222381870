import { ContentBranch, ContentBranchCore } from '@customTypes/storyblok-types';
import SignatureHermannSprengerGermanyBlack from '@components/generated/logos/SignatureHermannSprengerGermanyBlack';
import { ComponentType } from 'react';
import SignatureHermannSprengerEquestrianBlack from '@components/generated/logos/SignatureHermannSprengerEquestrianBlack';
import SignatureHermannSprengerDogsportsBlack from '@components/generated/logos/SignatureHermannSprengerDogsportsBlack';
import SignatureHermannSprengerBoatBlack from '@components/generated/logos/SignatureHermannSprengerBoatBlack';

export const primaryColorDict: Record<ContentBranch, string> = {
  boat: '#00C8FF',
  dog: '#FF6A5B',
  horse: '#00DEB4',
  sprenger: '#1D1EA0',
  noBranding: '#1D1EA0'
};

export const signatureDict: Record<ContentBranchCore, ComponentType<{ className?: string }>> = {
  boat: SignatureHermannSprengerBoatBlack,
  dog: SignatureHermannSprengerDogsportsBlack,
  horse: SignatureHermannSprengerEquestrianBlack,
  sprenger: SignatureHermannSprengerGermanyBlack,
};

/**
 * Derives all colorDict entries to 10% opacity
 */
export const secondaryColorDict = Object.fromEntries(
  Object.entries(primaryColorDict).map(([key, value]) => {
    // split value and add 10% opacity
    const afterHashTag = value.split('#')[1];
    return [key, `#${afterHashTag}1A`];
  }),
);
