import { useShopDataZustand } from '@zustand/shopData/index';

export type CollectionTree = {
  items?: CollectionTree[];
  link: string;
  title: string;
};

const _desiredOrder = ['pferdesport-root', 'hundesport-root', 'bootsport-root', 'sale-root'];

export const useCollectionTreeZustand = (): CollectionTree => {
  const categoryTreeJson = useShopDataZustand((store) => store.shopData?.category_tree?.value);
  const rootElement = categoryTreeJson
    ? JSON.parse(categoryTreeJson)
    : { items: [], title: 'Produkte' };

  return {
    ...rootElement,

    items: rootElement.items?.sort(
      (a1: any, a2: any) => _desiredOrder.indexOf(a1.link) - _desiredOrder.indexOf(a2.link),
    ),
  };
};
