import { useCustomerSessionDrawerZustand } from '@zustand/customer/sessionDrawer';

export const useCustomerSessionDrawer = () => {
  const { openWithSessionType, setOpen, setSessionType } = useCustomerSessionDrawerZustand(
    (store) => ({
      openWithSessionType: store.openWithSessionType,
      setOpen: store.setOpen,
      setSessionType: store.setSessionType,
    }),
  );

  const openCustomerSessionDrawer = () => {
    setOpen(true);
    setSessionType(null);
  };
  const openCustomerLoginDrawer = () => openWithSessionType('login');
  const openCustomerRegisterDrawer = () => openWithSessionType('register');

  return { openCustomerLoginDrawer, openCustomerRegisterDrawer, openCustomerSessionDrawer };
};
