import classNames from 'classnames';
import useBreakpoint from '../../hooks/useBreakpoint';
import { StoryblokImage } from '../Image/StoryblokImage';
import { HeroSlide } from '@customTypes/storyblok-types';

type HeroSliderForegroundImageProps = {
  currentIndex: number;
  slides: HeroSlide[];
};

const HeroSliderForegroundImage = ({ currentIndex, slides }: HeroSliderForegroundImageProps) => {
  const isMobile = useBreakpoint();

  return (
    <div className="absolute -bottom-16 -right-16 h-3/4 lg:h-2/3 lg:-bottom-32 w-[75%] md:w-[60%] z-20 lg-after:z-10">
      {slides.map(({ foreground_image }, i) => (
        <StoryblokImage
          src={foreground_image.filename!}
          focus={foreground_image.focus}
          key={i}
          className={classNames(
            'absolute bottom-0 right-0 transform transition duration-1000',
            i === currentIndex
              ? 'translate-x-0 opacity-100 delay-[1200ms]'
              : 'translate-x-full opacity-0',
          )}
          sizes={isMobile ? '35vw' : '30vw'}
          priority={true}
          layout="fill"
          objectFit="contain"
        />
      ))}
    </div>
  );
};

export default HeroSliderForegroundImage;
