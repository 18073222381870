import { create } from 'zustand';

type ProductDetailState = {
  openProductDetail: boolean;
  setOpenProductDetail: (openProductDetail: boolean) => void;
};

export const useProductDetailZustand = create<ProductDetailState>((set) => ({
  openProductDetail: false,
  setOpenProductDetail: (openProductDetail: boolean) =>
    set((state) => ({ ...state, openProductDetail })),
}));
