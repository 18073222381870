import { SVGProps } from 'react';

const SvgPlus = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M12.888 21.011v-8.126h8.12l-.002-1.768-8.118.002v-8.13h-1.766v8.13l-8.139-.002v1.766l8.14.002-.002 8.128 1.767-.002Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgPlus;
