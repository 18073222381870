import React from 'react';
import { ContentBlock1Props } from '@customTypes/storyblok-types';
import { ISbRichtext, storyblokEditable } from '@storyblok/react';
import { ImagePair } from '@components/Image/ImagePair';
import { StoryblokButton, StoryblokButtonProps } from '@components/Button/StoryblokButton';
import { RichText } from '@components/RichText';
import classNames from '@utils/ClassNames';

export const ContentBlock1: React.FC<{ blok: ContentBlock1Props }> = ({ blok }) => {
  const button = blok.button_text
    ? { text: blok.button_text, url: blok.button_link.url, className: 'w-full my-6' }
    : undefined;

  return (
    <div
      /* @ts-ignore*/
      {...storyblokEditable(blok)}
      className={classNames(blok.blue_background && "bg-accent-primary_005")}
    >
      <div className="flex flex-col-reverse w-full space-y-reverse md:space-y-8 md:flex-col box-x-wrapper py-11 md:py-16">
        {blok.image_emphasis === 'right' &&
          (blok.image1.filename || blok.shopTheLook1) &&
          (blok.image2?.filename || blok.shopTheLook2 || blok.video_id) && (
            <div className="space-y-6">
              <ImagePair
                shopTheLook1={blok.shopTheLook1}
                shopTheLook2={blok.shopTheLook2}
                image1={blok.image1}
                image2={blok.image2}
                image_1_parallax={blok.image_1_parallax}
                image_2_parallax={blok.image_2_parallax}
                breakpoint={768}
                layout="medium-medium"
                video_id1={blok.video_id}
                autoplay1={blok.autoplay}
              />
              <div className="flex flex-row justify-end w-full h-full z-0">
                <InformationComponent
                  text={blok.text}
                  headline={blok.headline}
                  button={button}
                ></InformationComponent>
              </div>
            </div>
          )
        }
        {
          blok.image_emphasis === 'left' &&
          (blok.image1.filename || blok.shopTheLook1) &&
          (blok.image2?.filename || blok.shopTheLook2 || blok.video_id) && (
            <div className="space-y-6">
              <ImagePair
                shopTheLook1={blok.shopTheLook2}
                shopTheLook2={blok.shopTheLook1}
                image_1_parallax={blok.image_2_parallax}
                image_2_parallax={blok.image_1_parallax}
                image1={blok.image2}
                image2={blok.image1}
                breakpoint={768}
                layout="medium-medium"
                video_id2={blok.video_id}
                autoplay2={blok.autoplay}
              />
              <div className="flex flex-row justify-start w-full h-full z-0">
                <InformationComponent
                  text={blok.text}
                  headline={blok.headline}
                  button={button}
                ></InformationComponent>
              </div>
            </div>
          )
        }
      </div >
    </div >
  );
};

interface InformationComponentProps {
  text: ISbRichtext;
  headline: string;
  button?: StoryblokButtonProps;
}

const InformationComponent = ({ text, headline, button }: InformationComponentProps) => (
  <div className="w-full space-y-4 md:w-1/2 md:pl-1.5">
    <h3 className="text-h-24 lg:text-h-32 mb-6 hyphens-auto">{headline}</h3>
    {typeof text === 'string' ? <p>{text}</p> : <RichText text={text} />}
    <div className="flex flex-col md:items-start">{button && <StoryblokButton {...button} />}</div>
  </div>
);
