import { ComponentType, Fragment, ReactNode } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import classNames from 'classnames';
import { ChevronLeft, XCircle } from '@components/generated/icons';

interface SideDrawerProps {
  show: boolean;
  showBack?: boolean;
  preventOverflow?: boolean;
  maxWidthClass?: string;
  onBack?: () => void;
  onClose: () => void;
  CloseIcon?: ComponentType<{ className?: string }>;
  closeIconClassName?: string;
  className?: string;
  header?: ReactNode;
  children: ReactNode;
}

/**
 * Use this component to dynamically slide in content from the side (like a cart's contents for e.g).
 * This component will call `onClose` when a click outside is detected or when the closing button is triggered.
 * @param args
 * @param args.className The className to be added to the component.
 * @param args.show Controls the opening/closing of the drawer.
 * @param args.showBack Wether or not the back arrow should be shown
 * @param args.maxWidthClass The max width of the drawer.
 * @param args.onBack A function that is called on back of the drawer.
 * @param args.onClose A function that is called to close the drawer. You should pass in a function that sets `show` to `false`.
 * @param args.CloseIcon The icon to use for the close button.
 * @param args.closeIconClassName The class name to use for the close button.
 * @param args.header The content to be displayed in the top part of the container.
 * @param args.children The content to be displayed in the bottom part of the container.
 */
export const SideDrawer = ({
  show,
  showBack,
  maxWidthClass,
  onBack,
  onClose,
  preventOverflow,
  CloseIcon = XCircle,
  closeIconClassName = 'w-10 h-10 text-accent-primary mt-1',
  className = 'h-full',
  header,
  children,
}: SideDrawerProps) => (
  <Transition.Root appear show={show} as={Fragment}>
    <Dialog as="div" className="fixed inset-0 overflow-hidden z-[2147483641]" onClose={onClose}>
      <div className="absolute inset-0 overflow-hidden" onScroll={(e) => e.preventDefault()}>
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="absolute inset-0 transition-opacity bg-dark-quaternary" />
        </Transition.Child>

        <div
          className={classNames(
            'fixed inset-x-0 bottom-0 flex w-full md:max-w-full md:w-auto md:right-0 md:inset-x-auto md:inset-y-0 md:pl-10',
            className,
          )}
        >
          <Transition.Child
            as={Fragment}
            enter="transform transition ease-in-out duration-500 sm:duration-700"
            enterFrom="translate-y-full md:translate-y-0 md:translate-x-full"
            enterTo="translate-y-0 md:translate-x-0"
            leave="transform transition ease-in-out duration-500 sm:duration-700"
            leaveFrom="translate-y-0 md:translate-x-0"
            leaveTo="translate-y-full md:translate-y-0 md:translate-x-full"
          >
            <div
              className={classNames(
                'relative flex flex-col w-screen h-full',
                preventOverflow ? 'overflow-hidden' : 'overflow-y-auto',
                header ? 'bg-accent-primary' : 'bg-light-white',
                maxWidthClass ? maxWidthClass : 'md:max-w-[25.375rem]',
              )}
            >
              {showBack && (
                <button
                  onClick={onBack}
                  className={classNames(
                    'absolute top-0 left-0 p-3',
                    header ? 'text-light-white' : 'text-accent-primary',
                  )}
                >
                  <ChevronLeft className="w-6 h-6" />
                </button>
              )}
              <button
                onClick={onClose}
                className="flex gap-2 p-3 self-end focus-visible:outline-none"
              >
                <CloseIcon className={closeIconClassName} />
              </button>

              {header && (
                <div className="flex items-start px-6 pt-5 md:pt-12 pb-4 bg-accent-primary text-light-white">
                  {header}
                </div>
              )}
              <div
                className={classNames(
                  'flex-1 p-6 bg-light-white',
                  preventOverflow && 'overflow-hidden flex flex-col',
                )}
              >
                {children}
              </div>
            </div>
          </Transition.Child>
        </div>
      </div>
    </Dialog>
  </Transition.Root>
);
