import { Hero } from '@components/Hero';
import { HeroBlockProps } from '@customTypes/storyblok-types';
import { getImageDimensions } from '@lib/storyblokUtil';
import { storyblokEditable } from '@storyblok/react';

export const HeroBlock = ({ blok }: { blok: HeroBlockProps }) => (
  <Hero
    /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
    /* @ts-ignore*/
    {...storyblokEditable(blok)}
    headline={blok.headline}
    title={blok.title}
    description={blok.description}
    bg_image={blok.bg_image}
    button={blok.button_text ? { text: blok.button_text, url: blok.button_link.url } : undefined}
    floating_image={
      blok.floating_image.filename
        ? {
            alt: blok.floating_image.alt || '',
            dimensions: getImageDimensions(blok.floating_image),
            src: blok.floating_image.filename,
          }
        : undefined
    }
    content_branch={blok.content_branch}
    signature={blok.signature}
  />
);
