import type { ProductCardProps } from './shared';
import { AmountSelection } from '../Form/AddToCart/AmountSelection';
import { Button } from '@components/Button';
import { useCart } from '@hooks/useCart';
import { useTranslate } from '@tolgee/react';
import { useCartLoadingOverlayZustand } from '@zustand/cartLoadingOverlay';
import { useEffect, useMemo, useState } from 'react';
import { ProductCardHorizontal } from './ProductCardHorizontal';
import classNames from '@utils/ClassNames';
import { RequiredCardDataFragment } from '@generated/graphql/types';
import { useRetrieveSdsInfoQuery } from '@generated/graphql/apollo';
import { mapToNode } from '@lib/utils';

const SDSEnabledAmountSelection = ({
  variant,
  actionsClassName,
}: {
  variant: ArrayElementOf<RequiredCardDataFragment['lines']['edges']>['node'];
  actionsClassName?: string;
}) => {
  const otherHandle = variant.merchandise.product.handle.endsWith('-sds')
    ? variant.merchandise.product.handle.slice(0, -4)
    : `${variant.merchandise.product.handle}-sds`;
  const { data } = useRetrieveSdsInfoQuery({ variables: { handle: otherHandle } });
  const cart = useCart();
  const { adhsMode, shown } = useCartLoadingOverlayZustand();
  const { t } = useTranslate();
  const [isRemoving, setIsRemoving] = useState<string | null>(null);
  const otherVariant = useMemo(
    () =>
      mapToNode(data?.product?.variants).find(
        (item) => item.justSku?.value === variant.merchandise.justSku?.value,
      ),
    [data],
  );
  const maxInventory = useMemo(
    () =>
      Math.max(otherVariant?.quantityAvailable ?? 0, variant.merchandise.quantityAvailable ?? 0),
    [otherVariant, variant],
  );

  return (
    <div
      className={classNames(
        'flex flex-col lg:flex-row items-center justify-start gap-2 pl-24',
        actionsClassName,
      )}
    >
      <AmountSelection
        className="w-full lg:w-auto"
        inputWidthClassName="w-1/2 max-w-[100px]"
        inventory={maxInventory}
        availableForSale={true}
        initialAmount={variant.quantity}
        shouldDebounce={true}
        isADHSEnabled={true}
        onChange={(value) => {
          if (!otherVariant) {
            return;
          }
          cart.updateItemQuantity(
            variant.id,
            Number(value),
            variant.merchandise.product.handle.endsWith('-sds'),
            Number(otherVariant?.sds_qty?.value) || 10,
            otherVariant?.quantityAvailable ?? 0,
            otherVariant.id,
          );
        }}
      />

      <div className="mt-2 lg:mt-0 text-center">
        <Button
          onClick={async () => {
            setIsRemoving(variant.id);
            await cart.removeItem(variant.id);
          }}
          data-test="__cart-remove-item"
          disabled={adhsMode || shown}
          className="-mr-4 text-h-14 text-accent-primary hover:underline"
          loading={isRemoving === variant.id}
          text={t('cart.lines.remove')}
        />
      </div>
    </div>
  );
};

export const ProductCardWithAmountSelection = ({
  className,
  actionsClassName,
  wrapUnitPrice,
  image_urls,
  ...args
}: ProductCardProps & { actionsClassName?: string; image_urls?: string }) => {
  const cart = useCart();
  const variant = useMemo(() => {
    return cart?.data?.lines.edges.find((l) => l.node.merchandise.justSku?.value === args.sku)
      ?.node;
  }, [JSON.stringify(cart.data?.lines)]);

  return (
    <div className={classNames('flex flex-col gap-4 py-4', className)}>
      <ProductCardHorizontal
        showSelectedOptions
        variant={variant?.merchandise}
        wrapUnitPrice={wrapUnitPrice}
        showUnitPrice
        totalInventory={variant?.merchandise.quantityAvailable}
        image_urls={image_urls ?? variant?.merchandise.image_urls?.value}
        {...args}
      />
      {!!variant && <SDSEnabledAmountSelection variant={variant} />}
    </div>
  );
};
