import { ButtonPrimary } from '@components/Button/Primary';
import classNames from 'classnames';
import Link from 'next/link';
import useBreakpoint from '../../hooks/useBreakpoint';
import { StoryblokImage } from '../Image/StoryblokImage';
import { ImageStoryblok } from '../../customTypes/storyblok-types';

interface EditorialSectionNineProps {
  className?: string;
  image?: ImageStoryblok & {
    dimensions: {
      width: number;
      height: number;
    };
  };
  no_mobile_padding?: boolean;
  mobile_image?: ImageStoryblok & {
    dimensions: {
      width: number;
      height: number;
    };
  };
  button?: ButtonProps;
}

export const EditorialSectionNine = ({
  className,
  image,
  button,
  mobile_image,
  no_mobile_padding,
}: EditorialSectionNineProps) => {
  const isMobile = useBreakpoint();

  return (
    <div
      className={classNames(
        'bg-accent-primary_005 py-11 md:py-16',
        className,
        no_mobile_padding || (!isMobile && !button)
          ? ''
          : !isMobile
            ? 'pb-16'
            : button
              ? 'pb-16'
              : 'py-16',
      )}
    >
      <div
        className={classNames(
          no_mobile_padding ? '' : 'box-x-wrapper',
          'flex flex-col items-center space-y-8 lg:space-y-16',
        )}
      >
        <div className={classNames(no_mobile_padding ? '' : 'px-4', 'relative w-full sm:w-2/3')}>
          {(!isMobile || !mobile_image) && !!image?.filename && (
            <StoryblokImage
              src={image.filename}
              focus={image.focus}
              alt={image.alt}
              layout="responsive"
              width={image.dimensions.width}
              height={image.dimensions.height}
            />
          )}
          {isMobile && !!mobile_image?.filename && (
            <StoryblokImage
              src={mobile_image.filename}
              focus={mobile_image.focus}
              alt={mobile_image.alt}
              layout="responsive"
              width={mobile_image.dimensions.width}
              height={mobile_image.dimensions.height}
            />
          )}
        </div>

        {button && <Button {...button} />}
      </div>
    </div>
  );
};

interface ButtonProps {
  text: string;
  url?: string;
}

const Button = ({ text, url }: ButtonProps) => {
  if (url) {
    return (
      <Link href={url} passHref>
        <a className="!-mt-6 !-mb-6 md:!-mb-0 md:!-mt-16 z-0">
          <ButtonPrimary text={text} />
        </a>
      </Link>
    );
  }

  return <ButtonPrimary text={text} />;
};
