import React from 'react';

import { RichText } from '@components/RichText';
import { ISbRichtext, storyblokEditable } from '@storyblok/react';

export interface RichTextBlok {
  text: ISbRichtext;
}

export const RichTextBlock: React.FC<{ blok: RichTextBlok }> = ({ blok }) => (
  /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
  /* @ts-ignore*/
  <div {...storyblokEditable(blok)} className="pr-4">
    <RichText text={blok.text} className="richTextBlock" />
  </div>
);
