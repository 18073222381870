export enum FeatureFlag {
  MULTILANG,
  INTERNATIONAL,
  COLLECTION_META,
  MAGAZIN,
}

export function isEnabledFlag(featureFlag: FeatureFlag): boolean {
  if (featureFlag === FeatureFlag.MULTILANG) {
    return true || process.env.NEXT_PUBLIC_MULTILANG_ENABLED !== 'false';
  }
  if (featureFlag === FeatureFlag.COLLECTION_META) {
    return process.env.NEXT_PUBLIC_COLLECTION_META_ENABLED !== 'false';
  }
  // if (featureFlag === FeatureFlag.INTERNATIONAL) {
  //   return process.env.NEXT_PUBLIC_MULTILANG_ENABLED !== 'false';
  // }
  if (featureFlag === FeatureFlag.MAGAZIN) {
    return process.env.NEXT_PUBLIC_MAGAZIN_ENABLED !== 'false';
  }
  return true;
}
