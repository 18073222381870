import SearchAutoSuggestionItemList from '@components/SearchBar/SearchAutoSuggestionItemList';
import QuickSearchItemList from '@components/SearchBar/QuickSearchItemList';
import { HTMLProps } from 'react';
import { T } from '@tolgee/react';
import { Product } from '@hooks/useSearch';
import useGtm from '@hooks/useGTM';
import { getGtmPayloadSearchResultClicked } from '@lib/gtm/productList';

type QueryResultListProps = {
  query: string;
  suggestions: string[];
  products: Product[];
  onResultSelect: (result: string) => void;
  onProductSelected?: (id: string) => void;
};

const QueryResultList = ({
  query,
  suggestions,
  products,
  onResultSelect,
  onProductSelected,
  ...props
}: QueryResultListProps & HTMLProps<HTMLDivElement>) => {
  const sendDataToGTM = useGtm();

  const sendData = (id: string) =>
    sendDataToGTM(getGtmPayloadSearchResultClicked({ handle: id, query }));

  const handleProductSelected = (id: string) => {
    sendData(id);
    onProductSelected?.(id);
  };

  if (products.length === 0 && suggestions.length === 0) {
    return <></>;
  }

  return (
    <div className="relative" {...props}>
      <div className="absolute py-2 rounded-b-lg w-full bg-white" role="none">
        <span className="ml-3 text-h-12 font-light py-0.5 text-gray-900">
          <T keyName="search.quickResults" />
        </span>
        <QuickSearchItemList products={products} onProductSelected={handleProductSelected} />
        <SearchAutoSuggestionItemList suggestions={suggestions} onResultSelect={onResultSelect} />
      </div>
    </div>
  );
};

export default QueryResultList;
