import { MaterialAndCareBlockProps } from '@customTypes/storyblok-types';
import { storyblokEditable } from '@storyblok/react';

export const MaterialAndCareBaseBlock = ({ blok }: { blok: MaterialAndCareBlockProps }) => (
  /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
  /* @ts-ignore*/
  <div {...storyblokEditable(blok)} key={blok.title}>
    <dt className="text-h-14">{blok.title}</dt>
    <dd className="mt-2 text-p-14 text-dark-primary">{blok.description}</dd>
  </div>
);
