import React from 'react';
import { YoutubeBlockProps } from '@customTypes/storyblok-types';
import useBreakpoint from '@hooks/useBreakpoint';
import useHasConsent from '@hooks/useHasConsent';
import useUserCentricsInteracted from '@hooks/useUserCentricsInteracted';
import { storyblokEditable } from '@storyblok/react';
import { StoryblokVideo } from '../../Image/StoryblokVideo';

export const YoutubeBlock: React.FC<{ blok: YoutubeBlockProps }> = ({ blok }) => {
  const isMobile = useBreakpoint();
  const hasAcceptedYoutube = useHasConsent('YouTube Video');
  const hasInteractedWithCookies = useUserCentricsInteracted();

  return hasInteractedWithCookies && hasAcceptedYoutube ? (
    <div
      /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
      /* @ts-ignore*/
      {...storyblokEditable(blok)}
    >
      <StoryblokVideo
        video_id={blok.video_id}
        autoplay={blok.autoplay}
        className="w-full h-full min-h-max aspect-18/9"
      />
    </div>
  ) : (
    <div></div>
  );
};
