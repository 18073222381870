import { ReactElement, useEffect, useRef, useState } from 'react';
import useBreakpoint, { Breakpoints } from '@hooks/useBreakpoint';
import classNames from 'classnames';
import SignatureHermannSprengerGermanyBlack from '@components/generated/logos/SignatureHermannSprengerGermanyBlack';
import { ButtonPrimary } from '@components/Button/Primary';
import { HeroSlide } from '@customTypes/storyblok-types';
import { signatureDict } from '@lib/color';
import useResponsive from '@hooks/useResponsive';

type HeroSliderTextOverlayProps = {
  currentIndex: number;
  slides: HeroSlide[];
};

/**
 * Calculates the font size for the headline
 * @param text The headline text
 * @param width The current window width
 *
 * @returns The font size
 */
export const getFontSize = (text: string, width: number) => {
  const viewWidth = typeof window === 'undefined' ? 1440 : window.innerWidth;
  const sectionWidth = width >= 640 ? width / 2 - width * 0.05 : width - 32;
  const percentageOfViewport = sectionWidth / viewWidth;

  const maxFontSize = 9.5;
  const minFontSize = 1.5;

  const longestWord = Math.max(...text.split(' ').map((l) => l.length));
  const textLimit = 30;

  const textSizeFactor = 1 - Math.max(0.16, longestWord / textLimit) + 0.16;

  const fontSize = `${textSizeFactor * percentageOfViewport * 26}vw`;
  return `clamp(${minFontSize}rem, ${maxFontSize}rem, ${fontSize})`;
};

const HeroText = ({
  title,
  call_to_action,
  href,
  i,
  currentIndex,
  currentTitle,
}: HeroSlide & { i: number; currentIndex: number; currentTitle: any }) => {
  const isMobile = useBreakpoint(Breakpoints.lg);
  const [fontSize, setFontSize] = useState('23rem');

  useEffect(() => {
    setFontSize(getFontSize(title, window.innerWidth));
  }, []);

  return (
    <div
      key={i}
      className={classNames(
        i === currentIndex ? 'opacity-100' : 'opacity-0',
        'transition duration-1000 absolute w-full grid place-items-center bottom-0 transform',
        i === currentIndex
          ? 'translate-x-0'
          : currentIndex < i
          ? isMobile ? '-translate-x-[500px]' : '-translate-x-40'
          : isMobile ? 'translate-x-[500px]' : 'translate-x-40',
      )}
    >
      {isMobile && (
        <ButtonPrimary
          text={call_to_action}
          href={href.url || `${href.cached_url}`}
          className="my-10"
        />
      )}
      <div
        ref={currentTitle}
        style={{ fontSize: `${fontSize}`, lineHeight: '100%' }}
        className="text-h-72 md:text-h-126 lg-after:text-h-152 break-all text-center max-w-[25rem] -z-10 lg:pb-12"
      >
        {title.split(' ').map((word, index) => (
          <span key={index}>
            {word} <br />
          </span>
        ))}
      </div>
    </div>
  );
};

const HeroSliderTextOverlay = ({ currentIndex, slides }: HeroSliderTextOverlayProps) => {
  const isMobile = useBreakpoint(Breakpoints.lg);
  const width = useResponsive();
  const currentTitle = useRef<HTMLDivElement | null>(null);
  const [heroSlides, setHeroSlides] = useState<ReactElement[]>([]);

  useEffect(() => {
    setHeroSlides(
      slides.map(({ subtitle, call_to_action, href, signature }, i) => {
        const Signature = signatureDict[signature] || SignatureHermannSprengerGermanyBlack;

        return (
          <div
            key={i}
            className={classNames(
              'transition absolute top-0 w-full grid place-items-center duration-1000',
              i === currentIndex ? 'opacity-100 z-10' : 'opacity-0 z-0',
            )}
          >
            {isMobile && <Signature className="w-28 mt-8 mb-14" />}
            {currentTitle.current || isMobile ? (
              <>
                <div
                  style={
                    !isMobile
                      ? { width: currentTitle.current!.getBoundingClientRect().width }
                      : undefined
                  }
                  className="text-accent-primary text-h-16 text-center mb-7 px-4 sm:px-none"
                >
                  {subtitle}
                </div>
                {!isMobile && (
                  <ButtonPrimary text={call_to_action} href={href.url || `${href.cached_url}`} />
                )}
              </>
            ) : (
              <div className="bg-gray-300 animate-pulse w-1/2 h-12"></div>
            )}
            {!isMobile && <Signature className="w-48 mt-[5.75rem] mb-4" />}
          </div>
        );
      }),
    );
  }, [slides, isMobile, currentIndex]);
  return (
    <div
      className={classNames(
        'bg-white flex flex-col items-center justify-end',
        !isMobile && 'left-6 h-full absolute',
      )}
      style={!isMobile ? { width: 'calc(50% - 1.5rem)' } : undefined}
    >
      {/* START: Big text title slide animation */}
      <div className={classNames('relative', !isMobile ? 'w-full' : 'h-60 w-full')}>
        {slides.map(({ title, call_to_action, href, ...props }, i) => {
          return (
            <HeroText
              key={`${href.url}-${i}`}
              title={title}
              call_to_action={call_to_action}
              href={href}
              i={i}
              currentIndex={currentIndex}
              currentTitle={currentTitle}
              {...props}
            />
          );
        })}
      </div>
      <div className="relative w-full min-h-[14rem] md:min-h[16rem] lg:min-h-[22rem]">
        {heroSlides}
      </div>
    </div>
  );
};

export default HeroSliderTextOverlay;
