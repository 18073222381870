import classNames from 'classnames';
import { Button, ButtonProps } from '.';

export const ButtonPrimary = ({ className, ...props }: ButtonProps) => (
  <Button
    className={classNames(
      'h-12 border border-transparent text-light-white bg-accent-primary uppercase',
      !props.disabled &&
      'hover:border-accent-hover hover:text-accent-hover hover:bg-white active:bg-accent-pressed active:text-light-white active:outline-none',
      className,
    )}
    {...props}
  />
);
