import classNames from 'classnames';
import { ReactNode } from 'react';

type NavMenuProductListProps = {
  title: string;
  className: string;
  children?: ReactNode;
};

export const NavMenuProductList = ({ className, children, title }: NavMenuProductListProps) => (
  <div className={classNames(className, 'flex flex-col pb-16')}>
    <p className="px-20 mb-6 font-extrabold uppercase text-h-24 mt-5">{title}</p>
    {children}
  </div>
);
