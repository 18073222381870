import { CollectionCardVertical } from '@components/Collection/Card/Vertical';
import { CollectionCardProps } from '@components/Collection/Card';
interface NavMenuCollectionScrollerProps {
  items: Item[];
}

type Item = { id: string } & CollectionCardProps;

export const NavMenuCollectionScroller = ({ items }: NavMenuCollectionScrollerProps) => (
  <div className="inline-block px-20 pb-20 overflow-scroll mb-6">
    <div className="flex flex-row space-x-6">
      {items.map((_, index) => {
        const { id, ...item } = items[index];
        return (
          <CollectionCardVertical {...item} key={id} className="flex-0 w-72" imageLoading="eager" />
        );
      })}
    </div>
  </div>
);
