import classNames from '@utils/ClassNames';
import { useState } from 'react';
import { StoryblokImage, withBlur } from './StoryblokImage';
import { ImageStoryblok } from '../../customTypes/storyblok-types';

interface ImageHoverProps {
  className?: string;
  style?: Record<string, string>;
  objectFit?: 'contain' | 'cover' | 'fill' | 'none' | 'scale-down';
  image_1?: ImageStoryblok;
  image_2?: ImageStoryblok;
}

export const ImageHover = ({
  image_1,
  image_2,
  className,
  style,
  objectFit = 'contain',
}: ImageHoverProps) => {
  const [visibleImage, setVisibleImage] = useState(0);

  return (
    <div className={classNames('relative ', className)} style={style}>
      {image_1?.filename && (
        <div
          className="relative bottom-0 right-0 w-full"
          onMouseEnter={() => {
            setVisibleImage(1);
          }}
          onMouseLeave={() => {
            setVisibleImage(0);
          }}
          onTouchStart={() => setVisibleImage((visibleImage + 1) % 2)}
        >
          <StoryblokImage
            className={classNames(
              'transition-opacity duration-500 ease-in-out',
              visibleImage === 0 ? 'opacity-100' : 'opacity-0',
            )}
            id="image-1"
            width="2560"
            height="1440"
            src={image_1.filename}
            placeholder="blur"
            sizes="75vw"
            focus={image_1.focus}
            blurDataURL={withBlur(image_1.filename)}
            alt={image_1.alt}
            layout="responsive"
            objectFit={objectFit}
          />
          {image_2?.filename && (
            <div className="absolute w-full bottom-0 right-0">
              <StoryblokImage
                className={classNames(
                  'transition-opacity duration-500 ease-in-out',
                  visibleImage === 1 ? 'opacity-100' : 'opacity-0',
                )}
                width="2560"
                height="1440"
                src={image_2.filename}
                placeholder="blur"
                sizes="75vw"
                focus={image_2.focus}
                blurDataURL={withBlur(image_2.filename)}
                alt={image_2.alt}
                layout="responsive"
                objectFit={objectFit}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};
