import Head from 'next/head';
import NextApp, { AppContext, AppInitialProps, AppProps } from 'next/app';
import '@styles/global.css';
import Layout from '@components/Layout';
import { ApolloProvider } from '@apollo/client';
import { useApollo } from '@graphql/apollo-client';
import { ShoppingHooksInitializer } from '@components/ShoppingHooksInitializer';
import NotificationsWrapper from '@lib/NotificationContext';
import { DevTools, Tolgee, TolgeeProvider, TolgeeStaticData, useTolgeeSSR } from '@tolgee/react';
import { InContextTools } from '@tolgee/web/tools';
import { useRouter } from 'next/router';
import Script from 'next/script';
import { GTMProvider } from '@elgorditosalsero/react-gtm-hook';
import { useEffect, useRef, useState } from 'react';
import { useConsentZustand } from '@zustand/consent';
import { getCookie, isProd, isStg } from '@lib/utils';
import languages from '../i18n';
import deJson from '../i18n/de.json';
import enJson from '../i18n/en.json';
import { apiPlugin, storyblokInit } from '@storyblok/react';
import { StoryBlokComponents } from '@customTypes/storyblok-types';
import { useRouterHistory } from '@zustand/routerHistory';
import { LocaleResponse } from '@lib/shippingCountries';
import { useLocale } from '@zustand/useLocale';
import { LANGUAGE_AND_REGION_COOKIE_NAME, SUPPORTED_COUNTRIES } from '@lib/constants';
import { LanguageCode } from '@generated/graphql/types';
import { FormatIcu } from '@tolgee/format-icu';
import posthog from 'posthog-js';

type Props = AppProps & {
  props: {
    locales: TolgeeStaticData;
    shippingCountries: LocaleResponse;
  };
  staticData: {
    [key: string]: any;
  };
};

storyblokInit({
  accessToken: process.env.NEXT_PUBLIC_STORYBLOK_ACCESS_TOKEN_PREVIEW,
  components: StoryBlokComponents,
  use: [apiPlugin],
});

const tolgee = Tolgee()
  .use(DevTools())
  .use(isProd() ? undefined : InContextTools())
  .use(FormatIcu())
  .init({
    apiKey: isProd() ? undefined : process.env.NEXT_PUBLIC_TOLGEE_API_KEY,
    apiUrl: process.env.NEXT_PUBLIC_TOLGEE_API_URL,
    availableLanguages: SUPPORTED_COUNTRIES,
    defaultLanguage: 'de',
    language: (getCookie(LANGUAGE_AND_REGION_COOKIE_NAME)?.split('-')[0] as LanguageCode) || 'de',
    staticData: {
      en: enJson,
      de: deJson,
    },
  });

const App = ({ Component, pageProps, staticData }: Props) => {
  const router = useRouter();
  const { asPath } = router;
  const { language } = useLocale();
  const { addPath } = useRouterHistory();
  const [shouldInject, setShouldInject] = useState<boolean>(false); // react-gtm-hook injects the GTM script by default
  const [wasInjected, setWasInjected] = useState<boolean>(false);
  const [gtmParams, setGtmParams] = useState<
    | {
        dataLayerName: string;
        id: string;
        injectScript: boolean;
      }
    | undefined
  >(undefined);
  const { consentGiven, setConsentGiven } = useConsentZustand();
  const ssrTolgee = useTolgeeSSR(tolgee, router.locale?.split('-')[0]!, staticData);

  const apolloClient = useApollo(pageProps);
  const [shouldLoadZe, setShouldLoadZe] = useState(false);
  const maxPercentage = useRef(0);
  const maxPixels = useRef(0);

  useEffect(() => {
    function handleScroll() {
      const lastPercentage = Math.min(
        1,
        (window.innerHeight + window.pageYOffset) / document.body.offsetHeight,
      );
      const lastPixels = window.innerHeight + window.pageYOffset;
      if (lastPercentage > maxPercentage.current) {
        maxPercentage.current = lastPercentage;
      }

      if (lastPixels > maxPixels.current) {
        maxPixels.current = lastPixels;
      }
    }

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    const handleRouteChange = () => {
      posthog.capture('$pageleave', {
        'max scroll percentage': maxPercentage.current,
        'max scroll pixels': maxPixels.current,
        'last scroll percentage': Math.min(
          1,
          (window.innerHeight + window.pageYOffset) / document.body.offsetHeight,
        ),
        'last scroll pixels': window.innerHeight + window.pageYOffset,
        scrolled: maxPixels.current > 0,
      });
    };

    router.events.on('routeChangeStart', handleRouteChange);
    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
  }, [router.events]);

  // Add new path to our own history zustand
  useEffect(() => {
    addPath(asPath);
  }, [asPath]);

  useEffect(() => {
    const _idleCallback = window.requestIdleCallback;
    if (typeof _idleCallback !== 'undefined') {
      _idleCallback(() => {
        setShouldLoadZe(true);
      });
    } else {
      setShouldLoadZe(true);
    }
  }, []);

  const updateGTMConsent = () => {
    const baseServiceInfo = (window as any).UC_UI?.getServicesBaseInfo();
    const tagManagerInfo = baseServiceInfo?.find((info: any) => info.name === 'Google Tag Manager');
    const wasConsentGiven = Boolean(tagManagerInfo?.consent.status);
    setConsentGiven(wasConsentGiven);
  };

  const initTripleWhale = () => {
    // @ts-ignore
    window.TriplePixelData = { TripleName: "hs-sprenger.myshopify.com", ver: "1.9" }, function (W, H, A, L, E, _, B, N) { function O(U, T, P, H, R) { void 0 === R && (R = !1), H = new XMLHttpRequest, P ? (H.open("POST", U, !0), H.setRequestHeader("Content-Type", "application/json")) : H.open("GET", U, !0), H.send(JSON.stringify(P || {})), H.onreadystatechange = function () { 4 === H.readyState && 200 === H.status ? (R = H.responseText, U.includes(".txt") ? eval(R) : P || (N[B] = R)) : (299 < H.status || H.status < 200) && T && !R && (R = !0, O(U, T - 1)) } } if (N = window, !N[H + "sn"]) { N[H + "sn"] = 1; try { A.setItem(H, 1 + (0 | A.getItem(H) || 0)), (E = JSON.parse(A.getItem(H + "U") || "[]")).push(location.href), A.setItem(H + "U", JSON.stringify(E)) } catch (e) { } var i, m, p; A.getItem('"!nC`') || (_ = A, A = N, A[H] || (L = function () { return Date.now().toString(36) + "_" + Math.random().toString(36) }, E = A[H] = function (t, e) { return "State" == t ? E.s : (W = L(), (E._q = E._q || []).push([W, t, e]), W) }, E.s = "Installed", E._q = [], E.ch = W, B = "configSecurityConfModel", N[B] = 1, O("https://conf.config-security.com/model", 5), i = L(), m = A[atob("c2NyZWVu")], _.setItem("di_pmt_wt", i), p = { id: i, action: "profile", avatar: _.getItem("auth-security_rand_salt_"), time: m[atob("d2lkdGg=")] + ":" + m[atob("aGVpZ2h0")], host: A.TriplePixelData.TripleName, url: window.location.href, ref: document.referrer, ver: window.TriplePixelData.ver }, O("https://api.config-security.com/", 5, p), O("https://triplewhale-pixel.web.app/dot1.9.txt", 5))) } }("", "TriplePixel", localStorage);
  };

  useEffect(() => {
    if (consentGiven && isProd()) {
      posthog.init('phc_kYKgioAfTWPsXSEOsgn2tZFCBbTeRjnDRLonGtFVpYj', {
        api_host: 'https://eu.posthog.com',
        capture_pageleave: false,
      });

      setGtmParams({
        dataLayerName: 'dataLayer',
        id: 'GTM-N52VL68',
        injectScript: shouldInject,
      });

      initTripleWhale();
    } else if (consentGiven && isStg()) {
      posthog.init('phc_kYKgioAfTWPsXSEOsgn2tZFCBbTeRjnDRLonGtFVpYj', {
        api_host: 'https://eu.posthog.com',
        capture_pageleave: false,
      }); // init in staging for now to collect some data for tests
      setGtmParams({
        dataLayerName: 'dataLayer',
        id: 'GTM-5F6FD67',
        injectScript: shouldInject,
      });
    } else {
      setGtmParams(undefined);
    }
  }, [shouldInject]);

  useEffect(() => {
    if (consentGiven && !wasInjected) {
      setShouldInject(true);
      setWasInjected(true);
    } else {
      if (wasInjected) {
        setWasInjected(false);
        location.reload();
      }
    }
  }, [consentGiven]);

  useEffect(() => {
    window.addEventListener('UC_UI_CMP_EVENT', () => {
      updateGTMConsent();
    });
    window.addEventListener('UC_UI_INITIALIZED', () => {
      updateGTMConsent();
    });
    window.zESettings = {
      webWidget: {
        offset: {
          horizontal: '20px',
          mobile: {
            horizontal: '8px',
            vertical: '10px',
          },
          vertical: '30px',
        },
      },
    };

    updateGTMConsent();
  }, []);

  return (
    <>
      {language && (
        <Script
          id="usercentrics-cmp"
          data-language={language}
          src="https://app.usercentrics.eu/browser-ui/latest/loader.js"
          data-settings-id="dS5F08OyI"
          async
          strategy="beforeInteractive"
        />
      )}
      {shouldLoadZe ? (
        <Script
          onLoad={() => {
            window.zE('webWidget', 'setLocale', language ?? 'de');
          }}
          id="ze-snippet"
          type="application/javascript"
          src="https://static.zdassets.com/ekr/snippet.js?key=3823b184-333a-407a-a4aa-30066c650596"
          async
        />
      ) : null}
      <Head>
        <link rel="icon" type="image/svg+xml" href="/icons/favicon.svg" />
        <link rel="apple-touch-icon" sizes="180x180" href="/icons/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/icons/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/icons/favicon-16x16.png" />
        <link rel="icon" href="/icons/favicon.ico" />
        <link rel="manifest" href="/icons/site.webmanifest" />
        <link rel="mask-icon" href="/icons/safari-pinned-tab.svg" color="#000000" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="msapplication-TileColor" content="#000000" />
        <meta name="theme-color" content="#000000"></meta>
        <link rel="preconnect" href="https://cdn.thearc.dev" />
        <link rel="preconnect" href="https://a.storyblok.com" />
        <link
          rel="preconnect dns-prefetch"
          href="https://api.config-security.com/"
          crossOrigin=""
        />
        <link
          rel="preconnect dns-prefetch"
          href="https://conf.config-security.com/"
          crossOrigin=""
        />
        <link
          rel="preconnect dns-prefetch"
          href="https://triplewhale-pixel.web.app/"
          crossOrigin=""
        />
        <meta name="facebook-domain-verification" content="3qokudcm0jc4vad2wmidx8enfnhlyp" />
        <meta
          name="google-site-verification"
          content="pKnFzaSGjj9EY0nt22U4KY1Jhygpwq05_r7x9VKBRZM"
        />
        <script type='text/javascript' dangerouslySetInnerHTML={{ __html: `TripleHeadless = 'hs-sprenger.myshopify.com'` }}></script>
        <script type='text/javascript' dangerouslySetInnerHTML={{ __html: `jdgm = window.jdgm || {};jdgm.SHOP_DOMAIN = 'sprenger-store.myshopify.com';jdgm.PLATFORM = 'shopify';jdgm.PUBLIC_TOKEN = 'r5167DE40khXVD2CHPIkNT_9hiw';` }}></script>
        <script data-cfasync='false' type='text/javascript' async src='https://cdn.judge.me/widget_preloader.js' />
        <script data-cfasync='false' type='text/javascript' async src='https://cdn1.judge.me/assets/installed.js' />
      </Head>
      <TolgeeProvider tolgee={ssrTolgee} fallback={<div>!!!PRE-RENDERING FAILED!!!</div>} options={{ useSuspense: false }}>
        <ApolloProvider client={apolloClient}>
          <ShoppingHooksInitializer>
            <NotificationsWrapper>
              <GTMProvider state={gtmParams}>
                <Layout>
                  <Component {...pageProps} />
                </Layout>
              </GTMProvider>
            </NotificationsWrapper>
          </ShoppingHooksInitializer>
        </ApolloProvider>
      </TolgeeProvider>
    </>
  );
};
export default App;
