import { create } from 'zustand';

type MenuState = {
  openMenu: boolean;
  height: number;
  setOpenMenu: (openMenu: boolean) => void;
  toggleMenu: () => void;
  setHeight: (height: number) => void;
};

export const useMenuZustand = create<MenuState>((set, get) => ({
  openMenu: false,
  height: 0,
  setOpenMenu: (openMenu) => set((state) => ({ ...state, openMenu })),
  toggleMenu: () => set((state) => ({ ...state, openMenu: !state.openMenu })),
  setHeight: (height: number) => set((state) => ({ ...state, height }))
}));
