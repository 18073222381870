import { SimpleSelection } from '@components/inputs/SimpleSelection';
import useBreakpoint from '@hooks/useBreakpoint';
import { SUPPORTED_LANGUAGES } from '@lib/constants';
import classNames from 'classnames';
import { ReactNode } from 'react';
import { FeatureFlag, isEnabledFlag } from '@lib/feature';
import { NavListHeader, NavListHeaderProps } from './Header';
import { shippingMarkets } from '@lib/shippingCountries';
import { ActiveShippingIcon } from '@components/Layout/Navbar';
import { useLocale } from '@zustand/useLocale';
import { useScrollAtStartZustand } from '@zustand/scrollAtStart';
import { PickerState, useShippingCountryPopUp } from '@zustand/shippingCountryPopUp';
import { useMenuZustand } from '@zustand/menu';
import { currencyCodeToSymbol } from '@lib/utils';
import { ChevronDown } from '@components/icons';

type NavListProps = NavListHeaderProps & {
  children: ReactNode;
  style: object;
  className?: string;
};

export const NavList = (props: NavListProps) => {
  const { children, style, className, title } = props;
  const isMobile = useBreakpoint();
  const { locale } = useLocale();
  const { scrollAtStart } = useScrollAtStartZustand();
  const { setOpenMenu } = useMenuZustand();
  const { setPickerState } = useShippingCountryPopUp();

  return (
    <div
      className={classNames(
        'flex-col items-stretch hidden h-full px-3 py-8 pb-56 lg:flex last:flex lg:w-[28rem] overflow-y-auto',
        className,
      )}
      style={style}
    >
      {title && <NavListHeader {...props} />}
      {children}
      {isMobile && isEnabledFlag(FeatureFlag.INTERNATIONAL) && (
        <button
          onClick={() => {
            setOpenMenu(false);
            setPickerState(PickerState.CHOOSE_COUNTRY);
          }}
          className="mr-auto mb-8 -mt-4 flex items-center gap-2"
        >
          <ActiveShippingIcon
            country={locale}
            text={`${locale} | ${currencyCodeToSymbol(
              shippingMarkets.find((c) => c.regions.nodes.find((n) => n.code === locale))
                ?.currencySettings.baseCurrency.currencyCode || 'EUR',
            )}`}
            scrollAtStart={scrollAtStart}
          />
          <ChevronDown className="w-4 h-4" />
        </button>
      )}
    </div>
  );
};
