import Chip from '@components/Common/Chip';
import { isColorProp, mapToNode } from '@lib/utils';
import { VariantsByParentQuery } from '@generated/graphql/types';
import { useCart } from '@hooks/useCart';
import { useMemo, useState } from 'react';
import classNames from '../../../utils/ClassNames';

interface ProductCardSelectionProps {
  className?: string;
  filteredData: VariantsByParentQuery | undefined;
  setSelectedVariantSku: (sku: string) => void;
  setShowAddedToCart: (show: boolean) => void;
  currentSelectedColor: { name: string; value: string }[] | undefined;
  setCurrentSelectedOptions: (
    arg:
      | {
          name: string;
          value: string;
        }[]
      | undefined,
  ) => void;
}

export const ProductCardSelection = ({
  className,
  filteredData,
  setSelectedVariantSku,
  setShowAddedToCart,
  setCurrentSelectedOptions: setExternalOptions,
  currentSelectedColor,
}: ProductCardSelectionProps) => {
  const cart = useCart();
  const [currentSelectedOptions, setCurrentSelectedOptions] = useState<
    { name: string; value: string }[] | undefined
  >([]);

  const nonColorOptions = useMemo(
    () => filteredData?.product?.options?.filter((o) => !isColorProp(o.name)) ?? [],
    [filteredData],
  );

  const updateSelectedOption = (name: string, value: string | number) => {
    setCurrentSelectedOptions((last) => {
      if (!last) {
        setExternalOptions(undefined);
        return undefined;
      }
      const newValue = {
        name,
        value: value as string,
      };
      const newOptions = [...last.filter((item) => item.name !== name), newValue].sort(
        (a, b) =>
          nonColorOptions.findIndex((o) => o.name === a.name) -
          nonColorOptions.findIndex((o) => o.name === b.name),
      );
      const val = newOptions.slice(0, newOptions.indexOf(newValue) + 1);
      setExternalOptions(val);
      return val;
    });
  };

  return (
    <div className={classNames('flex flex-col gap-2 flex-wrap', className)}>
      {filteredData &&
        nonColorOptions.map((currentOption, categoryIndex) => {
          const relevantSelectedOptions =
            currentSelectedOptions?.filter(
              (o) => nonColorOptions.findIndex((o2) => o2.name === o.name) <= categoryIndex,
            ) ?? [];
          return (
            <div key={categoryIndex}>
              <div className="flex gap-2.5 flex-wrap items-center">
                <span className="text-h-12">{currentOption.name}</span>
                <ul
                  key={currentOption.name + categoryIndex}
                  className="flex flex-row gap-2 flex-wrap"
                >
                  {currentOption.values.map((currentOptionValue, index) => {
                    const valueId = `${currentOption.name}-${index}`;
                    const variantEdgesForCurrentColor =
                      filteredData.product?.variants.edges
                        ?.map(({ node }) => node)
                        .filter((variant) =>
                          currentSelectedColor?.every((o) =>
                            variant.selectedOptions.find(
                              (option) => option.name === o.name && option.value === o.value,
                            ),
                          ),
                        ) ?? [];

                    const variants = variantEdgesForCurrentColor.filter((variant) =>
                      relevantSelectedOptions?.every(
                        (o) =>
                          variant.selectedOptions.findIndex(
                            (variantOption) =>
                              variantOption.name === o.name && variantOption.value === o.value,
                          ) !== -1,
                      ),
                    );
                    // .filter((variant) => variant.selectedOptions.findIndex(variantOption => variantOption.name === currentOption.name && variantOption.value === currentOptionValue) !== -1 || (relevantSelectedOptions?.length ?? 0) - 1 === categoryIndex);

                    if (
                      categoryIndex > (currentSelectedOptions?.length ?? 0) &&
                      variants.every(
                        (variant) =>
                          variant.selectedOptions.findIndex(
                            (o) => o.name === currentOption.name && o.value === currentOptionValue,
                          ) === -1,
                      )
                    )
                      return <></>;

                    const sdsVariants =
                      mapToNode(filteredData?.sdsProduct?.variants).filter((variant) =>
                        variants.find((v) => v.sku?.value === variant.sku?.value),
                      ) ?? [];

                    if (
                      categoryIndex >= nonColorOptions.length - 1 &&
                      variants.every(
                        (v) =>
                          v.selectedOptions.findIndex(
                            (o) => o.name === currentOption.name && o.value === currentOptionValue,
                          ) === -1,
                      )
                    ) {
                      return <></>;
                    }
                    const variant = variants.find(
                      (v) =>
                        v.selectedOptions.findIndex(
                          (o) => o.name === currentOption.name && o.value === currentOptionValue,
                        ) !== -1,
                    );
                    const sdsVariant = sdsVariants.find(
                      (v) => v.sku?.value === variant?.sku?.value,
                    );

                    const variantWithoutQuantity =
                      variant || sdsVariant
                        ? !(
                            (variant?.quantityAvailable ?? 0) > 0 ||
                            (sdsVariant?.quantityAvailable ?? 0) > 0
                          )
                        : false;
                    const isNextLayer = categoryIndex > (currentSelectedOptions?.length ?? 0);
                    const isLastLayer = categoryIndex === nonColorOptions.length - 1;
                    const isLastSelectedLayer =
                      categoryIndex === (currentSelectedOptions?.length ?? 0) - 1;
                    const hasVariantsWithQuantity =
                      variants.some((node) => (node.quantityAvailable ?? 0) > 0) ||
                      sdsVariants.some((node) => (node.quantityAvailable ?? 0) > 0);

                    const disabled =
                      (variantWithoutQuantity && isLastLayer) ||
                      isNextLayer ||
                      (isLastSelectedLayer && !hasVariantsWithQuantity);
                    return (
                      <li
                        key={valueId}
                        className="flex flex-row flex-wrap items-center min-h-[2.5rem]"
                      >
                        <input
                          type="radio"
                          className="hidden"
                          id={valueId}
                          value={currentOptionValue}
                          disabled={disabled}
                          onClick={async () => {
                            if (disabled) return;
                            if (categoryIndex === nonColorOptions.length - 1) {
                              if (!variant) return;
                              setSelectedVariantSku(variant.sku?.value ?? '');

                              const quantityThreshold = Number(variant.sds_qty?.value) || 10;
                              const quantity = 1;
                              const sdsAvailable = sdsVariant?.quantityAvailable ?? 0;
                              const normalAvailable = variant.quantityAvailable ?? 0;

                              const sdsHasEnoughStock = sdsAvailable >= quantity;
                              const cartHasSDSProduct = cart.shouldUseSDS;
                              const quantityExceedsThreshold = quantity >= quantityThreshold;
                              const normalHasNotEnoughStock = normalAvailable < quantity;

                              if (
                                sdsVariant?.id &&
                                (cartHasSDSProduct ||
                                  quantityExceedsThreshold ||
                                  normalHasNotEnoughStock) &&
                                sdsHasEnoughStock
                              ) {
                                await cart.addItem(sdsVariant?.id, quantity, true);
                              } else {
                                await cart.addItem(variant.id, quantity);
                              }
                              setShowAddedToCart(true);
                            } else {
                              updateSelectedOption(currentOption.name, currentOptionValue);
                            }
                          }}
                        />
                        {
                          <label htmlFor={valueId}>
                            <Chip
                              label={currentOptionValue}
                              disabled={disabled}
                              selected={
                                currentSelectedOptions?.find((o) => o.name === currentOption.name)
                                  ?.value === currentOptionValue
                              }
                            />
                          </label>
                        }
                      </li>
                    );
                  })}
                </ul>
              </div>
              {categoryIndex !== nonColorOptions.length - 1 && <hr className="" />}
            </div>
          );
        })}
    </div>
  );
};
