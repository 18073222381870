import { FilterGroupType } from '@lib/search';
import { useMemo } from 'react';
import useSWR from 'swr';

interface SearchConfigData {
  data: {
    [key: string]: FilterGroupType;
  };
}

export const useSearchConfig: () => SearchConfigData | undefined = () => {
  const { data } = useSWR<SearchConfigData>('/api/searchConfig', (key: string) =>
    fetch(key).then((s) => s.json()),
  );
  return useMemo(() => data, [data]);
};
