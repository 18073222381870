import { SVGProps } from 'react';
const SvgMagazintitleline = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 467 25" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M424.011 18.0034C343.324 21.2897 263.08 22.192 181.675 22.6379C151.603 22.8026 120.853 23.6858 91.1758 21.8898C71.3173 20.688 51.0866 20.2418 32.0634 18.0179C22.7915 16.934 13.5224 15.8846 4.18522 14.8697C0.777057 14.4993 1.68037 14.5928 4.66361 14.5928C49.0806 14.5928 93.0123 13.1575 137.357 12.5086C214.935 11.3733 292.201 10.3525 369.472 7.65513C399.348 6.61221 488.862 3.14115 458.978 4.15714C448.539 4.51205 437.973 4.50788 427.49 4.56524C375.156 4.85157 322.735 4.68251 270.398 4.59437C215.946 4.50267 161.501 4.09331 107.13 3.04941C90.3832 2.72787 73.6383 2.00001 56.8537 2"
      stroke="#1D1EA0"
      strokeWidth={4}
      strokeLinecap="round"
    />
  </svg>
);
export default SvgMagazintitleline;
