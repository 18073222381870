import DynamicComponent from '../DynamicComponent';
import { BoxProps, DynamicComponentBlok } from '@customTypes/storyblok-types';
import { storyblokEditable } from '@storyblok/react';

const fullWidthBlocks = new Set<string>(['contentBlock6', 'contentBlock8']);

export const Box = ({ blok }: { blok: BoxProps }) => (
  /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
  /* @ts-ignore*/
  <div
    /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
    /* @ts-ignore*/
    {...storyblokEditable(blok)}
  >
    {blok?.items?.map((subBlok: DynamicComponentBlok) =>
      fullWidthBlocks.has(subBlok.component?.toString() ?? '') ? (
        <DynamicComponent blok={subBlok} key={subBlok._uid} />
      ) : (
        <div className={'box-x-wrapper'}>
          <DynamicComponent blok={subBlok} key={subBlok._uid} />
        </div>
      ),
    )}
  </div>
);
