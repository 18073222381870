import * as Types from './types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export const ProductListItemFragmentDoc = gql`
    fragment ProductListItem on Product {
  id
  handle
  description
  availableForSale
  priceRange {
    maxVariantPrice {
      amount
      currencyCode
    }
    minVariantPrice {
      amount
      currencyCode
    }
  }
  placeholderImages: images(first: 10) {
    edges {
      node {
        src
        id
      }
    }
  }
  images(first: 10) {
    edges {
      node {
        src
        altText
        id
        width
        height
      }
    }
  }
  variants(first: 10) {
    edges {
      node {
        id
      }
    }
  }
  title
  tags
  totalInventory
}
    `;
export const RequiredCardDataFragmentDoc = gql`
    fragment RequiredCardData on Cart {
  id
  buyerIdentity {
    customer {
      id
    }
  }
  lines(first: 10) {
    edges {
      node {
        id
        discountAllocations {
          discountedAmount {
            amount
            currencyCode
          }
        }
        merchandise {
          ... on ProductVariant {
            id
            quantityAvailable
            product {
              id
              title
              handle
              sku: metafield(key: "sku", namespace: "sprenger") {
                key
                value
              }
              justSku: metafield(key: "just_sku", namespace: "sprenger") {
                key
                value
              }
              crossSellReferences: metafield(
                key: "crosssell_references"
                namespace: "sprenger"
              ) {
                key
                value
              }
              highlights: metafield(key: "highlights", namespace: "sprenger") {
                key
                value
              }
              collections(first: 100) {
                edges {
                  node {
                    title
                    handle
                    description
                  }
                }
              }
            }
            priceV2 {
              amount
              currencyCode
            }
            compareAtPriceV2 {
              amount
              currencyCode
            }
            justSku: metafield(key: "just_sku", namespace: "sprenger") {
              key
              value
            }
            crossSellReferences: metafield(
              key: "crosssell_references"
              namespace: "sprenger"
            ) {
              key
              value
            }
            image_urls: metafield(key: "image_urls", namespace: "sprenger") {
              key
              value
            }
            sds_qty: metafield(key: "sds_qty", namespace: "sprenger") {
              key
              value
            }
            selectedOptions {
              name
              value
            }
            sku
            bundle_desc: metafield(key: "bundle_desc", namespace: "sprenger") {
              key
              value
            }
            unitPrice {
              amount
              currencyCode
            }
            unitPriceMeasurement {
              referenceUnit
              referenceValue
            }
          }
        }
        quantity
        cost {
          subtotalAmount {
            amount
            currencyCode
          }
          totalAmount {
            amount
            currencyCode
          }
        }
      }
      cursor
    }
  }
  discountCodes {
    applicable
    code
  }
  cost {
    subtotalAmount {
      amount
      currencyCode
    }
    totalAmount {
      amount
      currencyCode
    }
    totalDutyAmount {
      amount
      currencyCode
    }
    totalTaxAmount {
      amount
      currencyCode
    }
  }
}
    `;
export const ProductDrawerStoriesFragmentDoc = gql`
    fragment ProductDrawerStories on HasMetafields {
  measure_d2c: metafield(key: "measure_d2c", namespace: "sprenger") {
    key
    value
  }
}
    `;
export const ProductVariantDataFragmentDoc = gql`
    fragment ProductVariantData on ProductVariant {
  id
  selectedOptions {
    name
    value
  }
  sku
  priceV2 {
    amount
    currencyCode
  }
  compareAtPriceV2 {
    amount
    currencyCode
  }
  availableForSale
  quantityAvailable
  currentlyNotInStock
  storeAvailability(first: 10) {
    edges {
      node {
        available
      }
    }
  }
  title
  product {
    title
    handle
    collections(first: 10) {
      edges {
        node {
          title
          handle
          description
        }
      }
    }
  }
  isArchived: metafield(key: "is_archived", namespace: "sprenger") {
    key
    value
  }
  justSku: metafield(key: "just_sku", namespace: "sprenger") {
    key
    value
  }
  bundle_desc: metafield(key: "bundle_desc", namespace: "sprenger") {
    key
    value
  }
  care_d2c: metafield(key: "care_d2c", namespace: "sprenger") {
    key
    value
  }
  content_d2c: metafield(key: "content_d2c", namespace: "sprenger") {
    key
    value
  }
  measure_d2c: metafield(key: "measure_d2c", namespace: "sprenger") {
    key
    value
  }
  material_d2c: metafield(key: "material_d2c", namespace: "sprenger") {
    key
    value
  }
  faq_d2c: metafield(key: "faq_d2c", namespace: "sprenger") {
    key
    value
  }
  colorcode: metafield(key: "colorcode", namespace: "sprenger") {
    key
    value
  }
  technical_data: metafield(key: "technical_data", namespace: "sprenger") {
    key
    value
  }
  image_urls: metafield(key: "image_urls", namespace: "sprenger") {
    key
    value
  }
  bulletPoints: metafield(key: "bullets", namespace: "sprenger") {
    key
    value
  }
  dreid: metafield(key: "dreid", namespace: "sprenger") {
    key
    value
  }
  video: metafield(key: "video", namespace: "sprenger") {
    key
    value
  }
  hazard_data: metafield(key: "hazard_data", namespace: "sprenger") {
    key
    value
  }
  crossSellReferences: metafield(
    key: "crosssell_references"
    namespace: "sprenger"
  ) {
    key
    value
  }
  highlights: metafield(key: "highlights", namespace: "sprenger") {
    key
    value
  }
  sds_qty: metafield(key: "sds_qty", namespace: "sprenger") {
    key
    value
  }
  unitPrice {
    amount
    currencyCode
  }
  unitPriceMeasurement {
    referenceValue
    referenceUnit
  }
}
    `;
export const WithCrossSellProductsFragmentDoc = gql`
    fragment WithCrossSellProducts on HasMetafields {
  crossSellReferences: metafield(
    key: "crosssell_references"
    namespace: "sprenger"
  ) {
    key
    value
  }
}
    `;
export const CartCreateDocument = gql`
    mutation CartCreate($input: CartInput, $country: CountryCode, $language: LanguageCode) @inContext(country: $country, language: $language) {
  cartCreate(input: $input) {
    cart {
      id
    }
    userErrors {
      code
      field
      message
    }
  }
}
    `;
export type CartCreateMutationFn = Apollo.MutationFunction<Types.CartCreateMutation, Types.CartCreateMutationVariables>;

/**
 * __useCartCreateMutation__
 *
 * To run a mutation, you first call `useCartCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCartCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cartCreateMutation, { data, loading, error }] = useCartCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *      country: // value for 'country'
 *      language: // value for 'language'
 *   },
 * });
 */
export function useCartCreateMutation(baseOptions?: Apollo.MutationHookOptions<Types.CartCreateMutation, Types.CartCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CartCreateMutation, Types.CartCreateMutationVariables>(CartCreateDocument, options);
      }
export type CartCreateMutationHookResult = ReturnType<typeof useCartCreateMutation>;
export type CartCreateMutationResult = Apollo.MutationResult<Types.CartCreateMutation>;
export type CartCreateMutationOptions = Apollo.BaseMutationOptions<Types.CartCreateMutation, Types.CartCreateMutationVariables>;
export const CartLinesAddDocument = gql`
    mutation CartLinesAdd($lines: [CartLineInput!]!, $cartId: ID!, $country: CountryCode!, $language: LanguageCode!) @inContext(country: $country, language: $language) {
  cartLinesAdd(lines: $lines, cartId: $cartId) {
    cart {
      ...RequiredCardData
    }
    userErrors {
      code
      field
      message
    }
  }
}
    ${RequiredCardDataFragmentDoc}`;
export type CartLinesAddMutationFn = Apollo.MutationFunction<Types.CartLinesAddMutation, Types.CartLinesAddMutationVariables>;

/**
 * __useCartLinesAddMutation__
 *
 * To run a mutation, you first call `useCartLinesAddMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCartLinesAddMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cartLinesAddMutation, { data, loading, error }] = useCartLinesAddMutation({
 *   variables: {
 *      lines: // value for 'lines'
 *      cartId: // value for 'cartId'
 *      country: // value for 'country'
 *      language: // value for 'language'
 *   },
 * });
 */
export function useCartLinesAddMutation(baseOptions?: Apollo.MutationHookOptions<Types.CartLinesAddMutation, Types.CartLinesAddMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CartLinesAddMutation, Types.CartLinesAddMutationVariables>(CartLinesAddDocument, options);
      }
export type CartLinesAddMutationHookResult = ReturnType<typeof useCartLinesAddMutation>;
export type CartLinesAddMutationResult = Apollo.MutationResult<Types.CartLinesAddMutation>;
export type CartLinesAddMutationOptions = Apollo.BaseMutationOptions<Types.CartLinesAddMutation, Types.CartLinesAddMutationVariables>;
export const CartLinesRemoveDocument = gql`
    mutation CartLinesRemove($cartId: ID!, $lineIds: [ID!]!, $country: CountryCode!, $language: LanguageCode!) @inContext(country: $country, language: $language) {
  cartLinesRemove(cartId: $cartId, lineIds: $lineIds) {
    cart {
      ...RequiredCardData
    }
    userErrors {
      code
      field
      message
    }
  }
}
    ${RequiredCardDataFragmentDoc}`;
export type CartLinesRemoveMutationFn = Apollo.MutationFunction<Types.CartLinesRemoveMutation, Types.CartLinesRemoveMutationVariables>;

/**
 * __useCartLinesRemoveMutation__
 *
 * To run a mutation, you first call `useCartLinesRemoveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCartLinesRemoveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cartLinesRemoveMutation, { data, loading, error }] = useCartLinesRemoveMutation({
 *   variables: {
 *      cartId: // value for 'cartId'
 *      lineIds: // value for 'lineIds'
 *      country: // value for 'country'
 *      language: // value for 'language'
 *   },
 * });
 */
export function useCartLinesRemoveMutation(baseOptions?: Apollo.MutationHookOptions<Types.CartLinesRemoveMutation, Types.CartLinesRemoveMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CartLinesRemoveMutation, Types.CartLinesRemoveMutationVariables>(CartLinesRemoveDocument, options);
      }
export type CartLinesRemoveMutationHookResult = ReturnType<typeof useCartLinesRemoveMutation>;
export type CartLinesRemoveMutationResult = Apollo.MutationResult<Types.CartLinesRemoveMutation>;
export type CartLinesRemoveMutationOptions = Apollo.BaseMutationOptions<Types.CartLinesRemoveMutation, Types.CartLinesRemoveMutationVariables>;
export const CartLinesUpdateDocument = gql`
    mutation CartLinesUpdate($cartId: ID!, $lines: [CartLineUpdateInput!]!, $country: CountryCode!, $language: LanguageCode!) @inContext(country: $country, language: $language) {
  cartLinesUpdate(cartId: $cartId, lines: $lines) {
    cart {
      ...RequiredCardData
    }
    userErrors {
      code
      field
      message
    }
  }
}
    ${RequiredCardDataFragmentDoc}`;
export type CartLinesUpdateMutationFn = Apollo.MutationFunction<Types.CartLinesUpdateMutation, Types.CartLinesUpdateMutationVariables>;

/**
 * __useCartLinesUpdateMutation__
 *
 * To run a mutation, you first call `useCartLinesUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCartLinesUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cartLinesUpdateMutation, { data, loading, error }] = useCartLinesUpdateMutation({
 *   variables: {
 *      cartId: // value for 'cartId'
 *      lines: // value for 'lines'
 *      country: // value for 'country'
 *      language: // value for 'language'
 *   },
 * });
 */
export function useCartLinesUpdateMutation(baseOptions?: Apollo.MutationHookOptions<Types.CartLinesUpdateMutation, Types.CartLinesUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CartLinesUpdateMutation, Types.CartLinesUpdateMutationVariables>(CartLinesUpdateDocument, options);
      }
export type CartLinesUpdateMutationHookResult = ReturnType<typeof useCartLinesUpdateMutation>;
export type CartLinesUpdateMutationResult = Apollo.MutationResult<Types.CartLinesUpdateMutation>;
export type CartLinesUpdateMutationOptions = Apollo.BaseMutationOptions<Types.CartLinesUpdateMutation, Types.CartLinesUpdateMutationVariables>;
export const CartDiscountCodesUpdateDocument = gql`
    mutation CartDiscountCodesUpdate($cartId: ID!, $discountCodes: [String!], $country: CountryCode!, $language: LanguageCode!) @inContext(country: $country, language: $language) {
  cartDiscountCodesUpdate(cartId: $cartId, discountCodes: $discountCodes) {
    cart {
      ...RequiredCardData
    }
    userErrors {
      code
      field
      message
    }
  }
}
    ${RequiredCardDataFragmentDoc}`;
export type CartDiscountCodesUpdateMutationFn = Apollo.MutationFunction<Types.CartDiscountCodesUpdateMutation, Types.CartDiscountCodesUpdateMutationVariables>;

/**
 * __useCartDiscountCodesUpdateMutation__
 *
 * To run a mutation, you first call `useCartDiscountCodesUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCartDiscountCodesUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cartDiscountCodesUpdateMutation, { data, loading, error }] = useCartDiscountCodesUpdateMutation({
 *   variables: {
 *      cartId: // value for 'cartId'
 *      discountCodes: // value for 'discountCodes'
 *      country: // value for 'country'
 *      language: // value for 'language'
 *   },
 * });
 */
export function useCartDiscountCodesUpdateMutation(baseOptions?: Apollo.MutationHookOptions<Types.CartDiscountCodesUpdateMutation, Types.CartDiscountCodesUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CartDiscountCodesUpdateMutation, Types.CartDiscountCodesUpdateMutationVariables>(CartDiscountCodesUpdateDocument, options);
      }
export type CartDiscountCodesUpdateMutationHookResult = ReturnType<typeof useCartDiscountCodesUpdateMutation>;
export type CartDiscountCodesUpdateMutationResult = Apollo.MutationResult<Types.CartDiscountCodesUpdateMutation>;
export type CartDiscountCodesUpdateMutationOptions = Apollo.BaseMutationOptions<Types.CartDiscountCodesUpdateMutation, Types.CartDiscountCodesUpdateMutationVariables>;
export const CartBuyerIdentityUpdateDocument = gql`
    mutation cartBuyerIdentityUpdate($cartId: ID!, $buyerIdentity: CartBuyerIdentityInput!, $country: CountryCode!, $language: LanguageCode!) @inContext(country: $country, language: $language) {
  cartBuyerIdentityUpdate(cartId: $cartId, buyerIdentity: $buyerIdentity) {
    cart {
      ...RequiredCardData
    }
    userErrors {
      code
      field
      message
    }
  }
}
    ${RequiredCardDataFragmentDoc}`;
export type CartBuyerIdentityUpdateMutationFn = Apollo.MutationFunction<Types.CartBuyerIdentityUpdateMutation, Types.CartBuyerIdentityUpdateMutationVariables>;

/**
 * __useCartBuyerIdentityUpdateMutation__
 *
 * To run a mutation, you first call `useCartBuyerIdentityUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCartBuyerIdentityUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cartBuyerIdentityUpdateMutation, { data, loading, error }] = useCartBuyerIdentityUpdateMutation({
 *   variables: {
 *      cartId: // value for 'cartId'
 *      buyerIdentity: // value for 'buyerIdentity'
 *      country: // value for 'country'
 *      language: // value for 'language'
 *   },
 * });
 */
export function useCartBuyerIdentityUpdateMutation(baseOptions?: Apollo.MutationHookOptions<Types.CartBuyerIdentityUpdateMutation, Types.CartBuyerIdentityUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CartBuyerIdentityUpdateMutation, Types.CartBuyerIdentityUpdateMutationVariables>(CartBuyerIdentityUpdateDocument, options);
      }
export type CartBuyerIdentityUpdateMutationHookResult = ReturnType<typeof useCartBuyerIdentityUpdateMutation>;
export type CartBuyerIdentityUpdateMutationResult = Apollo.MutationResult<Types.CartBuyerIdentityUpdateMutation>;
export type CartBuyerIdentityUpdateMutationOptions = Apollo.BaseMutationOptions<Types.CartBuyerIdentityUpdateMutation, Types.CartBuyerIdentityUpdateMutationVariables>;
export const CheckoutCreateDocument = gql`
    mutation CheckoutCreate($input: CheckoutCreateInput!, $country: CountryCode!, $language: LanguageCode!) @inContext(country: $country, language: $language) {
  checkoutCreate(input: $input) {
    checkout {
      id
    }
    checkoutUserErrors {
      code
      field
      message
    }
  }
}
    `;
export type CheckoutCreateMutationFn = Apollo.MutationFunction<Types.CheckoutCreateMutation, Types.CheckoutCreateMutationVariables>;

/**
 * __useCheckoutCreateMutation__
 *
 * To run a mutation, you first call `useCheckoutCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckoutCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkoutCreateMutation, { data, loading, error }] = useCheckoutCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *      country: // value for 'country'
 *      language: // value for 'language'
 *   },
 * });
 */
export function useCheckoutCreateMutation(baseOptions?: Apollo.MutationHookOptions<Types.CheckoutCreateMutation, Types.CheckoutCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CheckoutCreateMutation, Types.CheckoutCreateMutationVariables>(CheckoutCreateDocument, options);
      }
export type CheckoutCreateMutationHookResult = ReturnType<typeof useCheckoutCreateMutation>;
export type CheckoutCreateMutationResult = Apollo.MutationResult<Types.CheckoutCreateMutation>;
export type CheckoutCreateMutationOptions = Apollo.BaseMutationOptions<Types.CheckoutCreateMutation, Types.CheckoutCreateMutationVariables>;
export const CheckoutLineItemsReplaceDocument = gql`
    mutation CheckoutLineItemsReplace($lineItems: [CheckoutLineItemInput!]!, $checkoutId: ID!, $country: CountryCode!, $language: LanguageCode!) @inContext(country: $country, language: $language) {
  checkoutLineItemsReplace(lineItems: $lineItems, checkoutId: $checkoutId) {
    checkout {
      id
    }
    userErrors {
      code
      field
      message
    }
  }
}
    `;
export type CheckoutLineItemsReplaceMutationFn = Apollo.MutationFunction<Types.CheckoutLineItemsReplaceMutation, Types.CheckoutLineItemsReplaceMutationVariables>;

/**
 * __useCheckoutLineItemsReplaceMutation__
 *
 * To run a mutation, you first call `useCheckoutLineItemsReplaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckoutLineItemsReplaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkoutLineItemsReplaceMutation, { data, loading, error }] = useCheckoutLineItemsReplaceMutation({
 *   variables: {
 *      lineItems: // value for 'lineItems'
 *      checkoutId: // value for 'checkoutId'
 *      country: // value for 'country'
 *      language: // value for 'language'
 *   },
 * });
 */
export function useCheckoutLineItemsReplaceMutation(baseOptions?: Apollo.MutationHookOptions<Types.CheckoutLineItemsReplaceMutation, Types.CheckoutLineItemsReplaceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CheckoutLineItemsReplaceMutation, Types.CheckoutLineItemsReplaceMutationVariables>(CheckoutLineItemsReplaceDocument, options);
      }
export type CheckoutLineItemsReplaceMutationHookResult = ReturnType<typeof useCheckoutLineItemsReplaceMutation>;
export type CheckoutLineItemsReplaceMutationResult = Apollo.MutationResult<Types.CheckoutLineItemsReplaceMutation>;
export type CheckoutLineItemsReplaceMutationOptions = Apollo.BaseMutationOptions<Types.CheckoutLineItemsReplaceMutation, Types.CheckoutLineItemsReplaceMutationVariables>;
export const CheckoutShippingAddressUpdateDocument = gql`
    mutation CheckoutShippingAddressUpdate($shippingAddress: MailingAddressInput!, $checkoutId: ID!, $country: CountryCode!, $language: LanguageCode!) @inContext(country: $country, language: $language) {
  checkoutShippingAddressUpdateV2(
    shippingAddress: $shippingAddress
    checkoutId: $checkoutId
  ) {
    checkout {
      id
    }
    checkoutUserErrors {
      code
      field
      message
    }
  }
}
    `;
export type CheckoutShippingAddressUpdateMutationFn = Apollo.MutationFunction<Types.CheckoutShippingAddressUpdateMutation, Types.CheckoutShippingAddressUpdateMutationVariables>;

/**
 * __useCheckoutShippingAddressUpdateMutation__
 *
 * To run a mutation, you first call `useCheckoutShippingAddressUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckoutShippingAddressUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkoutShippingAddressUpdateMutation, { data, loading, error }] = useCheckoutShippingAddressUpdateMutation({
 *   variables: {
 *      shippingAddress: // value for 'shippingAddress'
 *      checkoutId: // value for 'checkoutId'
 *      country: // value for 'country'
 *      language: // value for 'language'
 *   },
 * });
 */
export function useCheckoutShippingAddressUpdateMutation(baseOptions?: Apollo.MutationHookOptions<Types.CheckoutShippingAddressUpdateMutation, Types.CheckoutShippingAddressUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CheckoutShippingAddressUpdateMutation, Types.CheckoutShippingAddressUpdateMutationVariables>(CheckoutShippingAddressUpdateDocument, options);
      }
export type CheckoutShippingAddressUpdateMutationHookResult = ReturnType<typeof useCheckoutShippingAddressUpdateMutation>;
export type CheckoutShippingAddressUpdateMutationResult = Apollo.MutationResult<Types.CheckoutShippingAddressUpdateMutation>;
export type CheckoutShippingAddressUpdateMutationOptions = Apollo.BaseMutationOptions<Types.CheckoutShippingAddressUpdateMutation, Types.CheckoutShippingAddressUpdateMutationVariables>;
export const CheckoutEmailUpdateDocument = gql`
    mutation CheckoutEmailUpdate($checkoutId: ID!, $email: String!, $country: CountryCode!, $language: LanguageCode!) @inContext(country: $country, language: $language) {
  checkoutEmailUpdateV2(checkoutId: $checkoutId, email: $email) {
    checkout {
      id
    }
    checkoutUserErrors {
      code
      field
      message
    }
  }
}
    `;
export type CheckoutEmailUpdateMutationFn = Apollo.MutationFunction<Types.CheckoutEmailUpdateMutation, Types.CheckoutEmailUpdateMutationVariables>;

/**
 * __useCheckoutEmailUpdateMutation__
 *
 * To run a mutation, you first call `useCheckoutEmailUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckoutEmailUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkoutEmailUpdateMutation, { data, loading, error }] = useCheckoutEmailUpdateMutation({
 *   variables: {
 *      checkoutId: // value for 'checkoutId'
 *      email: // value for 'email'
 *      country: // value for 'country'
 *      language: // value for 'language'
 *   },
 * });
 */
export function useCheckoutEmailUpdateMutation(baseOptions?: Apollo.MutationHookOptions<Types.CheckoutEmailUpdateMutation, Types.CheckoutEmailUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CheckoutEmailUpdateMutation, Types.CheckoutEmailUpdateMutationVariables>(CheckoutEmailUpdateDocument, options);
      }
export type CheckoutEmailUpdateMutationHookResult = ReturnType<typeof useCheckoutEmailUpdateMutation>;
export type CheckoutEmailUpdateMutationResult = Apollo.MutationResult<Types.CheckoutEmailUpdateMutation>;
export type CheckoutEmailUpdateMutationOptions = Apollo.BaseMutationOptions<Types.CheckoutEmailUpdateMutation, Types.CheckoutEmailUpdateMutationVariables>;
export const CheckoutCustomerAssociateDocument = gql`
    mutation CheckoutCustomerAssociate($checkoutId: ID!, $customerAccessToken: String!, $country: CountryCode!, $language: LanguageCode!) @inContext(country: $country, language: $language) {
  checkoutCustomerAssociateV2(
    checkoutId: $checkoutId
    customerAccessToken: $customerAccessToken
  ) {
    checkout {
      id
    }
    checkoutUserErrors {
      code
      field
      message
    }
    customer {
      id
    }
  }
}
    `;
export type CheckoutCustomerAssociateMutationFn = Apollo.MutationFunction<Types.CheckoutCustomerAssociateMutation, Types.CheckoutCustomerAssociateMutationVariables>;

/**
 * __useCheckoutCustomerAssociateMutation__
 *
 * To run a mutation, you first call `useCheckoutCustomerAssociateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckoutCustomerAssociateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkoutCustomerAssociateMutation, { data, loading, error }] = useCheckoutCustomerAssociateMutation({
 *   variables: {
 *      checkoutId: // value for 'checkoutId'
 *      customerAccessToken: // value for 'customerAccessToken'
 *      country: // value for 'country'
 *      language: // value for 'language'
 *   },
 * });
 */
export function useCheckoutCustomerAssociateMutation(baseOptions?: Apollo.MutationHookOptions<Types.CheckoutCustomerAssociateMutation, Types.CheckoutCustomerAssociateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CheckoutCustomerAssociateMutation, Types.CheckoutCustomerAssociateMutationVariables>(CheckoutCustomerAssociateDocument, options);
      }
export type CheckoutCustomerAssociateMutationHookResult = ReturnType<typeof useCheckoutCustomerAssociateMutation>;
export type CheckoutCustomerAssociateMutationResult = Apollo.MutationResult<Types.CheckoutCustomerAssociateMutation>;
export type CheckoutCustomerAssociateMutationOptions = Apollo.BaseMutationOptions<Types.CheckoutCustomerAssociateMutation, Types.CheckoutCustomerAssociateMutationVariables>;
export const CheckoutShippingLineUpdateDocument = gql`
    mutation CheckoutShippingLineUpdate($checkoutId: ID!, $shippingRateHandle: String!, $country: CountryCode!, $language: LanguageCode!) @inContext(country: $country, language: $language) {
  checkoutShippingLineUpdate(
    checkoutId: $checkoutId
    shippingRateHandle: $shippingRateHandle
  ) {
    checkout {
      id
    }
    checkoutUserErrors {
      code
      field
      message
    }
  }
}
    `;
export type CheckoutShippingLineUpdateMutationFn = Apollo.MutationFunction<Types.CheckoutShippingLineUpdateMutation, Types.CheckoutShippingLineUpdateMutationVariables>;

/**
 * __useCheckoutShippingLineUpdateMutation__
 *
 * To run a mutation, you first call `useCheckoutShippingLineUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckoutShippingLineUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkoutShippingLineUpdateMutation, { data, loading, error }] = useCheckoutShippingLineUpdateMutation({
 *   variables: {
 *      checkoutId: // value for 'checkoutId'
 *      shippingRateHandle: // value for 'shippingRateHandle'
 *      country: // value for 'country'
 *      language: // value for 'language'
 *   },
 * });
 */
export function useCheckoutShippingLineUpdateMutation(baseOptions?: Apollo.MutationHookOptions<Types.CheckoutShippingLineUpdateMutation, Types.CheckoutShippingLineUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CheckoutShippingLineUpdateMutation, Types.CheckoutShippingLineUpdateMutationVariables>(CheckoutShippingLineUpdateDocument, options);
      }
export type CheckoutShippingLineUpdateMutationHookResult = ReturnType<typeof useCheckoutShippingLineUpdateMutation>;
export type CheckoutShippingLineUpdateMutationResult = Apollo.MutationResult<Types.CheckoutShippingLineUpdateMutation>;
export type CheckoutShippingLineUpdateMutationOptions = Apollo.BaseMutationOptions<Types.CheckoutShippingLineUpdateMutation, Types.CheckoutShippingLineUpdateMutationVariables>;
export const CheckoutDiscountCodeApplyDocument = gql`
    mutation checkoutDiscountCodeApply($checkoutId: ID!, $discountCode: String!, $country: CountryCode!, $language: LanguageCode!) @inContext(country: $country, language: $language) {
  checkoutDiscountCodeApplyV2(
    checkoutId: $checkoutId
    discountCode: $discountCode
  ) {
    checkout {
      id
    }
    checkoutUserErrors {
      code
      field
      message
    }
  }
}
    `;
export type CheckoutDiscountCodeApplyMutationFn = Apollo.MutationFunction<Types.CheckoutDiscountCodeApplyMutation, Types.CheckoutDiscountCodeApplyMutationVariables>;

/**
 * __useCheckoutDiscountCodeApplyMutation__
 *
 * To run a mutation, you first call `useCheckoutDiscountCodeApplyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckoutDiscountCodeApplyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkoutDiscountCodeApplyMutation, { data, loading, error }] = useCheckoutDiscountCodeApplyMutation({
 *   variables: {
 *      checkoutId: // value for 'checkoutId'
 *      discountCode: // value for 'discountCode'
 *      country: // value for 'country'
 *      language: // value for 'language'
 *   },
 * });
 */
export function useCheckoutDiscountCodeApplyMutation(baseOptions?: Apollo.MutationHookOptions<Types.CheckoutDiscountCodeApplyMutation, Types.CheckoutDiscountCodeApplyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CheckoutDiscountCodeApplyMutation, Types.CheckoutDiscountCodeApplyMutationVariables>(CheckoutDiscountCodeApplyDocument, options);
      }
export type CheckoutDiscountCodeApplyMutationHookResult = ReturnType<typeof useCheckoutDiscountCodeApplyMutation>;
export type CheckoutDiscountCodeApplyMutationResult = Apollo.MutationResult<Types.CheckoutDiscountCodeApplyMutation>;
export type CheckoutDiscountCodeApplyMutationOptions = Apollo.BaseMutationOptions<Types.CheckoutDiscountCodeApplyMutation, Types.CheckoutDiscountCodeApplyMutationVariables>;
export const CustomerCreateDocument = gql`
    mutation CustomerCreate($input: CustomerCreateInput!, $country: CountryCode!, $language: LanguageCode!) @inContext(country: $country, language: $language) {
  customerCreate(input: $input) {
    customer {
      id
    }
    customerUserErrors {
      code
      field
      message
    }
  }
}
    `;
export type CustomerCreateMutationFn = Apollo.MutationFunction<Types.CustomerCreateMutation, Types.CustomerCreateMutationVariables>;

/**
 * __useCustomerCreateMutation__
 *
 * To run a mutation, you first call `useCustomerCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCustomerCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [customerCreateMutation, { data, loading, error }] = useCustomerCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *      country: // value for 'country'
 *      language: // value for 'language'
 *   },
 * });
 */
export function useCustomerCreateMutation(baseOptions?: Apollo.MutationHookOptions<Types.CustomerCreateMutation, Types.CustomerCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CustomerCreateMutation, Types.CustomerCreateMutationVariables>(CustomerCreateDocument, options);
      }
export type CustomerCreateMutationHookResult = ReturnType<typeof useCustomerCreateMutation>;
export type CustomerCreateMutationResult = Apollo.MutationResult<Types.CustomerCreateMutation>;
export type CustomerCreateMutationOptions = Apollo.BaseMutationOptions<Types.CustomerCreateMutation, Types.CustomerCreateMutationVariables>;
export const UpdateCustomerDocument = gql`
    mutation UpdateCustomer($customer: CustomerUpdateInput!, $customerAccessToken: String!, $country: CountryCode!, $language: LanguageCode!) @inContext(country: $country, language: $language) {
  customerUpdate(customerAccessToken: $customerAccessToken, customer: $customer) {
    customer {
      id
    }
    customerUserErrors {
      code
      field
      message
    }
  }
}
    `;
export type UpdateCustomerMutationFn = Apollo.MutationFunction<Types.UpdateCustomerMutation, Types.UpdateCustomerMutationVariables>;

/**
 * __useUpdateCustomerMutation__
 *
 * To run a mutation, you first call `useUpdateCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCustomerMutation, { data, loading, error }] = useUpdateCustomerMutation({
 *   variables: {
 *      customer: // value for 'customer'
 *      customerAccessToken: // value for 'customerAccessToken'
 *      country: // value for 'country'
 *      language: // value for 'language'
 *   },
 * });
 */
export function useUpdateCustomerMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateCustomerMutation, Types.UpdateCustomerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateCustomerMutation, Types.UpdateCustomerMutationVariables>(UpdateCustomerDocument, options);
      }
export type UpdateCustomerMutationHookResult = ReturnType<typeof useUpdateCustomerMutation>;
export type UpdateCustomerMutationResult = Apollo.MutationResult<Types.UpdateCustomerMutation>;
export type UpdateCustomerMutationOptions = Apollo.BaseMutationOptions<Types.UpdateCustomerMutation, Types.UpdateCustomerMutationVariables>;
export const CustomerDefaultAddressUpdateDocument = gql`
    mutation customerDefaultAddressUpdate($customerAccessToken: String!, $addressId: ID!, $country: CountryCode!, $language: LanguageCode!) @inContext(country: $country, language: $language) {
  customerDefaultAddressUpdate(
    customerAccessToken: $customerAccessToken
    addressId: $addressId
  ) {
    customer {
      id
    }
    customerUserErrors {
      code
      field
      message
    }
  }
}
    `;
export type CustomerDefaultAddressUpdateMutationFn = Apollo.MutationFunction<Types.CustomerDefaultAddressUpdateMutation, Types.CustomerDefaultAddressUpdateMutationVariables>;

/**
 * __useCustomerDefaultAddressUpdateMutation__
 *
 * To run a mutation, you first call `useCustomerDefaultAddressUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCustomerDefaultAddressUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [customerDefaultAddressUpdateMutation, { data, loading, error }] = useCustomerDefaultAddressUpdateMutation({
 *   variables: {
 *      customerAccessToken: // value for 'customerAccessToken'
 *      addressId: // value for 'addressId'
 *      country: // value for 'country'
 *      language: // value for 'language'
 *   },
 * });
 */
export function useCustomerDefaultAddressUpdateMutation(baseOptions?: Apollo.MutationHookOptions<Types.CustomerDefaultAddressUpdateMutation, Types.CustomerDefaultAddressUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CustomerDefaultAddressUpdateMutation, Types.CustomerDefaultAddressUpdateMutationVariables>(CustomerDefaultAddressUpdateDocument, options);
      }
export type CustomerDefaultAddressUpdateMutationHookResult = ReturnType<typeof useCustomerDefaultAddressUpdateMutation>;
export type CustomerDefaultAddressUpdateMutationResult = Apollo.MutationResult<Types.CustomerDefaultAddressUpdateMutation>;
export type CustomerDefaultAddressUpdateMutationOptions = Apollo.BaseMutationOptions<Types.CustomerDefaultAddressUpdateMutation, Types.CustomerDefaultAddressUpdateMutationVariables>;
export const CustomerAddressDeleteDocument = gql`
    mutation customerAddressDelete($id: ID!, $customerAccessToken: String!, $country: CountryCode!, $language: LanguageCode!) @inContext(country: $country, language: $language) {
  customerAddressDelete(id: $id, customerAccessToken: $customerAccessToken) {
    customerUserErrors {
      code
      field
      message
    }
    deletedCustomerAddressId
  }
}
    `;
export type CustomerAddressDeleteMutationFn = Apollo.MutationFunction<Types.CustomerAddressDeleteMutation, Types.CustomerAddressDeleteMutationVariables>;

/**
 * __useCustomerAddressDeleteMutation__
 *
 * To run a mutation, you first call `useCustomerAddressDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCustomerAddressDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [customerAddressDeleteMutation, { data, loading, error }] = useCustomerAddressDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *      customerAccessToken: // value for 'customerAccessToken'
 *      country: // value for 'country'
 *      language: // value for 'language'
 *   },
 * });
 */
export function useCustomerAddressDeleteMutation(baseOptions?: Apollo.MutationHookOptions<Types.CustomerAddressDeleteMutation, Types.CustomerAddressDeleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CustomerAddressDeleteMutation, Types.CustomerAddressDeleteMutationVariables>(CustomerAddressDeleteDocument, options);
      }
export type CustomerAddressDeleteMutationHookResult = ReturnType<typeof useCustomerAddressDeleteMutation>;
export type CustomerAddressDeleteMutationResult = Apollo.MutationResult<Types.CustomerAddressDeleteMutation>;
export type CustomerAddressDeleteMutationOptions = Apollo.BaseMutationOptions<Types.CustomerAddressDeleteMutation, Types.CustomerAddressDeleteMutationVariables>;
export const CustomerAddressCreateDocument = gql`
    mutation customerAddressCreate($customerAccessToken: String!, $address: MailingAddressInput!, $country: CountryCode!, $language: LanguageCode!) @inContext(country: $country, language: $language) {
  customerAddressCreate(
    customerAccessToken: $customerAccessToken
    address: $address
  ) {
    customerAddress {
      id
    }
    customerUserErrors {
      code
      field
      message
    }
  }
}
    `;
export type CustomerAddressCreateMutationFn = Apollo.MutationFunction<Types.CustomerAddressCreateMutation, Types.CustomerAddressCreateMutationVariables>;

/**
 * __useCustomerAddressCreateMutation__
 *
 * To run a mutation, you first call `useCustomerAddressCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCustomerAddressCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [customerAddressCreateMutation, { data, loading, error }] = useCustomerAddressCreateMutation({
 *   variables: {
 *      customerAccessToken: // value for 'customerAccessToken'
 *      address: // value for 'address'
 *      country: // value for 'country'
 *      language: // value for 'language'
 *   },
 * });
 */
export function useCustomerAddressCreateMutation(baseOptions?: Apollo.MutationHookOptions<Types.CustomerAddressCreateMutation, Types.CustomerAddressCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CustomerAddressCreateMutation, Types.CustomerAddressCreateMutationVariables>(CustomerAddressCreateDocument, options);
      }
export type CustomerAddressCreateMutationHookResult = ReturnType<typeof useCustomerAddressCreateMutation>;
export type CustomerAddressCreateMutationResult = Apollo.MutationResult<Types.CustomerAddressCreateMutation>;
export type CustomerAddressCreateMutationOptions = Apollo.BaseMutationOptions<Types.CustomerAddressCreateMutation, Types.CustomerAddressCreateMutationVariables>;
export const CustomerAddressUpdateDocument = gql`
    mutation customerAddressUpdate($customerAccessToken: String!, $id: ID!, $address: MailingAddressInput!, $country: CountryCode!, $language: LanguageCode!) @inContext(country: $country, language: $language) {
  customerAddressUpdate(
    customerAccessToken: $customerAccessToken
    id: $id
    address: $address
  ) {
    customerAddress {
      id
    }
    customerUserErrors {
      code
      field
      message
    }
  }
}
    `;
export type CustomerAddressUpdateMutationFn = Apollo.MutationFunction<Types.CustomerAddressUpdateMutation, Types.CustomerAddressUpdateMutationVariables>;

/**
 * __useCustomerAddressUpdateMutation__
 *
 * To run a mutation, you first call `useCustomerAddressUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCustomerAddressUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [customerAddressUpdateMutation, { data, loading, error }] = useCustomerAddressUpdateMutation({
 *   variables: {
 *      customerAccessToken: // value for 'customerAccessToken'
 *      id: // value for 'id'
 *      address: // value for 'address'
 *      country: // value for 'country'
 *      language: // value for 'language'
 *   },
 * });
 */
export function useCustomerAddressUpdateMutation(baseOptions?: Apollo.MutationHookOptions<Types.CustomerAddressUpdateMutation, Types.CustomerAddressUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CustomerAddressUpdateMutation, Types.CustomerAddressUpdateMutationVariables>(CustomerAddressUpdateDocument, options);
      }
export type CustomerAddressUpdateMutationHookResult = ReturnType<typeof useCustomerAddressUpdateMutation>;
export type CustomerAddressUpdateMutationResult = Apollo.MutationResult<Types.CustomerAddressUpdateMutation>;
export type CustomerAddressUpdateMutationOptions = Apollo.BaseMutationOptions<Types.CustomerAddressUpdateMutation, Types.CustomerAddressUpdateMutationVariables>;
export const CustomerResetPasswordDocument = gql`
    mutation customerResetPassword($email: String!) {
  customerRecover(email: $email) {
    customerUserErrors {
      code
      field
      message
    }
  }
}
    `;
export type CustomerResetPasswordMutationFn = Apollo.MutationFunction<Types.CustomerResetPasswordMutation, Types.CustomerResetPasswordMutationVariables>;

/**
 * __useCustomerResetPasswordMutation__
 *
 * To run a mutation, you first call `useCustomerResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCustomerResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [customerResetPasswordMutation, { data, loading, error }] = useCustomerResetPasswordMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useCustomerResetPasswordMutation(baseOptions?: Apollo.MutationHookOptions<Types.CustomerResetPasswordMutation, Types.CustomerResetPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CustomerResetPasswordMutation, Types.CustomerResetPasswordMutationVariables>(CustomerResetPasswordDocument, options);
      }
export type CustomerResetPasswordMutationHookResult = ReturnType<typeof useCustomerResetPasswordMutation>;
export type CustomerResetPasswordMutationResult = Apollo.MutationResult<Types.CustomerResetPasswordMutation>;
export type CustomerResetPasswordMutationOptions = Apollo.BaseMutationOptions<Types.CustomerResetPasswordMutation, Types.CustomerResetPasswordMutationVariables>;
export const CustomerConfirmPasswordResetDocument = gql`
    mutation customerConfirmPasswordReset($token: URL!, $password: String!) {
  customerResetByUrl(resetUrl: $token, password: $password) {
    customerAccessToken {
      accessToken
      expiresAt
    }
    customerUserErrors {
      code
      field
      message
    }
  }
}
    `;
export type CustomerConfirmPasswordResetMutationFn = Apollo.MutationFunction<Types.CustomerConfirmPasswordResetMutation, Types.CustomerConfirmPasswordResetMutationVariables>;

/**
 * __useCustomerConfirmPasswordResetMutation__
 *
 * To run a mutation, you first call `useCustomerConfirmPasswordResetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCustomerConfirmPasswordResetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [customerConfirmPasswordResetMutation, { data, loading, error }] = useCustomerConfirmPasswordResetMutation({
 *   variables: {
 *      token: // value for 'token'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useCustomerConfirmPasswordResetMutation(baseOptions?: Apollo.MutationHookOptions<Types.CustomerConfirmPasswordResetMutation, Types.CustomerConfirmPasswordResetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CustomerConfirmPasswordResetMutation, Types.CustomerConfirmPasswordResetMutationVariables>(CustomerConfirmPasswordResetDocument, options);
      }
export type CustomerConfirmPasswordResetMutationHookResult = ReturnType<typeof useCustomerConfirmPasswordResetMutation>;
export type CustomerConfirmPasswordResetMutationResult = Apollo.MutationResult<Types.CustomerConfirmPasswordResetMutation>;
export type CustomerConfirmPasswordResetMutationOptions = Apollo.BaseMutationOptions<Types.CustomerConfirmPasswordResetMutation, Types.CustomerConfirmPasswordResetMutationVariables>;
export const CustomerAccessTokenCreateDocument = gql`
    mutation customerAccessTokenCreate($input: CustomerAccessTokenCreateInput!) {
  customerAccessTokenCreate(input: $input) {
    customerAccessToken {
      accessToken
      expiresAt
    }
    customerUserErrors {
      code
      field
      message
    }
  }
}
    `;
export type CustomerAccessTokenCreateMutationFn = Apollo.MutationFunction<Types.CustomerAccessTokenCreateMutation, Types.CustomerAccessTokenCreateMutationVariables>;

/**
 * __useCustomerAccessTokenCreateMutation__
 *
 * To run a mutation, you first call `useCustomerAccessTokenCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCustomerAccessTokenCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [customerAccessTokenCreateMutation, { data, loading, error }] = useCustomerAccessTokenCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCustomerAccessTokenCreateMutation(baseOptions?: Apollo.MutationHookOptions<Types.CustomerAccessTokenCreateMutation, Types.CustomerAccessTokenCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CustomerAccessTokenCreateMutation, Types.CustomerAccessTokenCreateMutationVariables>(CustomerAccessTokenCreateDocument, options);
      }
export type CustomerAccessTokenCreateMutationHookResult = ReturnType<typeof useCustomerAccessTokenCreateMutation>;
export type CustomerAccessTokenCreateMutationResult = Apollo.MutationResult<Types.CustomerAccessTokenCreateMutation>;
export type CustomerAccessTokenCreateMutationOptions = Apollo.BaseMutationOptions<Types.CustomerAccessTokenCreateMutation, Types.CustomerAccessTokenCreateMutationVariables>;
export const CustomerAccessTokenDeleteDocument = gql`
    mutation customerAccessTokenDelete($customerAccessTokenDeleteInput: String!) {
  customerAccessTokenDelete(customerAccessToken: $customerAccessTokenDeleteInput) {
    userErrors {
      field
      message
    }
  }
}
    `;
export type CustomerAccessTokenDeleteMutationFn = Apollo.MutationFunction<Types.CustomerAccessTokenDeleteMutation, Types.CustomerAccessTokenDeleteMutationVariables>;

/**
 * __useCustomerAccessTokenDeleteMutation__
 *
 * To run a mutation, you first call `useCustomerAccessTokenDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCustomerAccessTokenDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [customerAccessTokenDeleteMutation, { data, loading, error }] = useCustomerAccessTokenDeleteMutation({
 *   variables: {
 *      customerAccessTokenDeleteInput: // value for 'customerAccessTokenDeleteInput'
 *   },
 * });
 */
export function useCustomerAccessTokenDeleteMutation(baseOptions?: Apollo.MutationHookOptions<Types.CustomerAccessTokenDeleteMutation, Types.CustomerAccessTokenDeleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CustomerAccessTokenDeleteMutation, Types.CustomerAccessTokenDeleteMutationVariables>(CustomerAccessTokenDeleteDocument, options);
      }
export type CustomerAccessTokenDeleteMutationHookResult = ReturnType<typeof useCustomerAccessTokenDeleteMutation>;
export type CustomerAccessTokenDeleteMutationResult = Apollo.MutationResult<Types.CustomerAccessTokenDeleteMutation>;
export type CustomerAccessTokenDeleteMutationOptions = Apollo.BaseMutationOptions<Types.CustomerAccessTokenDeleteMutation, Types.CustomerAccessTokenDeleteMutationVariables>;
export const CartDocument = gql`
    query Cart($id: ID!, $country: CountryCode, $language: LanguageCode) @inContext(country: $country, language: $language) {
  cart(id: $id) {
    ...RequiredCardData
  }
}
    ${RequiredCardDataFragmentDoc}`;

/**
 * __useCartQuery__
 *
 * To run a query within a React component, call `useCartQuery` and pass it any options that fit your needs.
 * When your component renders, `useCartQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCartQuery({
 *   variables: {
 *      id: // value for 'id'
 *      country: // value for 'country'
 *      language: // value for 'language'
 *   },
 * });
 */
export function useCartQuery(baseOptions: Apollo.QueryHookOptions<Types.CartQuery, Types.CartQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CartQuery, Types.CartQueryVariables>(CartDocument, options);
      }
export function useCartLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CartQuery, Types.CartQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CartQuery, Types.CartQueryVariables>(CartDocument, options);
        }
export type CartQueryHookResult = ReturnType<typeof useCartQuery>;
export type CartLazyQueryHookResult = ReturnType<typeof useCartLazyQuery>;
export type CartQueryResult = Apollo.QueryResult<Types.CartQuery, Types.CartQueryVariables>;
export const RetrieveSdsInfoDocument = gql`
    query RetrieveSDSInfo($handle: String!) {
  product(handle: $handle) {
    variants(first: 50) {
      edges {
        node {
          id
          quantityAvailable
          justSku: metafield(key: "just_sku", namespace: "sprenger") {
            key
            value
          }
          sds_qty: metafield(key: "sds_qty", namespace: "sprenger") {
            key
            value
          }
        }
      }
    }
  }
}
    `;

/**
 * __useRetrieveSdsInfoQuery__
 *
 * To run a query within a React component, call `useRetrieveSdsInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useRetrieveSdsInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRetrieveSdsInfoQuery({
 *   variables: {
 *      handle: // value for 'handle'
 *   },
 * });
 */
export function useRetrieveSdsInfoQuery(baseOptions: Apollo.QueryHookOptions<Types.RetrieveSdsInfoQuery, Types.RetrieveSdsInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.RetrieveSdsInfoQuery, Types.RetrieveSdsInfoQueryVariables>(RetrieveSdsInfoDocument, options);
      }
export function useRetrieveSdsInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.RetrieveSdsInfoQuery, Types.RetrieveSdsInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.RetrieveSdsInfoQuery, Types.RetrieveSdsInfoQueryVariables>(RetrieveSdsInfoDocument, options);
        }
export type RetrieveSdsInfoQueryHookResult = ReturnType<typeof useRetrieveSdsInfoQuery>;
export type RetrieveSdsInfoLazyQueryHookResult = ReturnType<typeof useRetrieveSdsInfoLazyQuery>;
export type RetrieveSdsInfoQueryResult = Apollo.QueryResult<Types.RetrieveSdsInfoQuery, Types.RetrieveSdsInfoQueryVariables>;
export const RetrieveBuyXGetYProductDocument = gql`
    query RetrieveBuyXGetYProduct($id: ID!, $handle: String!, $country: CountryCode!, $language: LanguageCode!) @inContext(country: $country, language: $language) {
  variant: node(id: $id) {
    ... on ProductVariant {
      id
      title
      image_urls: metafield(key: "image_urls", namespace: "sprenger") {
        key
        value
      }
      highlights: metafield(key: "highlights", namespace: "sprenger") {
        key
        value
      }
      isArchived: metafield(key: "is_archived", namespace: "sprenger") {
        key
        value
      }
      justSku: metafield(key: "just_sku", namespace: "sprenger") {
        key
        value
      }
      selectedOptions {
        value
        name
      }
      price {
        currencyCode
        amount
      }
      compareAtPrice {
        currencyCode
        amount
      }
      bundle_desc: metafield(key: "bundle_desc", namespace: "sprenger") {
        key
        value
      }
      unitPrice {
        amount
        currencyCode
      }
      unitPriceMeasurement {
        referenceUnit
        referenceValue
      }
      quantityAvailable
      product {
        collections(first: 100) {
          edges {
            node {
              title
              description
            }
          }
        }
        handle
        title
      }
    }
  }
  sdsProduct: product(handle: $handle) {
    variants(first: 100) {
      edges {
        node {
          id
          justSku: metafield(key: "just_sku", namespace: "sprenger") {
            key
            value
          }
          sds_qty: metafield(key: "sds_qty", namespace: "sprenger") {
            key
            value
          }
          quantityAvailable
        }
      }
    }
  }
}
    `;

/**
 * __useRetrieveBuyXGetYProductQuery__
 *
 * To run a query within a React component, call `useRetrieveBuyXGetYProductQuery` and pass it any options that fit your needs.
 * When your component renders, `useRetrieveBuyXGetYProductQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRetrieveBuyXGetYProductQuery({
 *   variables: {
 *      id: // value for 'id'
 *      handle: // value for 'handle'
 *      country: // value for 'country'
 *      language: // value for 'language'
 *   },
 * });
 */
export function useRetrieveBuyXGetYProductQuery(baseOptions: Apollo.QueryHookOptions<Types.RetrieveBuyXGetYProductQuery, Types.RetrieveBuyXGetYProductQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.RetrieveBuyXGetYProductQuery, Types.RetrieveBuyXGetYProductQueryVariables>(RetrieveBuyXGetYProductDocument, options);
      }
export function useRetrieveBuyXGetYProductLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.RetrieveBuyXGetYProductQuery, Types.RetrieveBuyXGetYProductQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.RetrieveBuyXGetYProductQuery, Types.RetrieveBuyXGetYProductQueryVariables>(RetrieveBuyXGetYProductDocument, options);
        }
export type RetrieveBuyXGetYProductQueryHookResult = ReturnType<typeof useRetrieveBuyXGetYProductQuery>;
export type RetrieveBuyXGetYProductLazyQueryHookResult = ReturnType<typeof useRetrieveBuyXGetYProductLazyQuery>;
export type RetrieveBuyXGetYProductQueryResult = Apollo.QueryResult<Types.RetrieveBuyXGetYProductQuery, Types.RetrieveBuyXGetYProductQueryVariables>;
export const CheckoutDocument = gql`
    query Checkout($id: ID!, $country: CountryCode, $language: LanguageCode) @inContext(country: $country, language: $language) {
  node(id: $id) {
    ... on Checkout {
      webUrl
      id
      email
      subtotalPriceV2 {
        amount
        currencyCode
      }
      totalPriceV2 {
        amount
        currencyCode
      }
      shippingAddress {
        firstName
        company
        lastName
        phone
        address1
        address2
        city
        zip
      }
      lineItemsSubtotalPrice {
        amount
        currencyCode
      }
      lineItems(first: 10) {
        edges {
          node {
            quantity
            title
            variant {
              id
              sku
              image_urls: metafield(key: "image_urls", namespace: "sprenger") {
                key
                value
              }
              selectedOptions {
                name
                value
              }
              product {
                handle
                sku: metafield(key: "sku", namespace: "sprenger") {
                  key
                  value
                }
                justSku: metafield(key: "just_sku", namespace: "sprenger") {
                  key
                  value
                }
                collections(first: 100) {
                  edges {
                    node {
                      title
                      description
                    }
                  }
                }
              }
              bundle_desc: metafield(key: "bundle_desc", namespace: "sprenger") {
                key
                value
              }
              justSku: metafield(key: "just_sku", namespace: "sprenger") {
                key
                value
              }
              unitPrice {
                amount
                currencyCode
              }
              unitPriceMeasurement {
                referenceUnit
                referenceValue
              }
              priceV2 {
                amount
                currencyCode
              }
            }
          }
        }
      }
      discountApplications(first: 99) {
        edges {
          node {
            allocationMethod
            targetSelection
            targetType
            value {
              ... on MoneyV2 {
                __typename
                amount
                currencyCode
              }
              ... on PricingPercentageValue {
                __typename
                percentage
              }
            }
            ... on DiscountCodeApplication {
              code
            }
          }
        }
      }
      appliedGiftCards {
        amountUsedV2 {
          amount
          currencyCode
        }
        balanceV2 {
          amount
          currencyCode
        }
        presentmentAmountUsed {
          amount
          currencyCode
        }
      }
    }
  }
}
    `;

/**
 * __useCheckoutQuery__
 *
 * To run a query within a React component, call `useCheckoutQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckoutQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckoutQuery({
 *   variables: {
 *      id: // value for 'id'
 *      country: // value for 'country'
 *      language: // value for 'language'
 *   },
 * });
 */
export function useCheckoutQuery(baseOptions: Apollo.QueryHookOptions<Types.CheckoutQuery, Types.CheckoutQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CheckoutQuery, Types.CheckoutQueryVariables>(CheckoutDocument, options);
      }
export function useCheckoutLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CheckoutQuery, Types.CheckoutQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CheckoutQuery, Types.CheckoutQueryVariables>(CheckoutDocument, options);
        }
export type CheckoutQueryHookResult = ReturnType<typeof useCheckoutQuery>;
export type CheckoutLazyQueryHookResult = ReturnType<typeof useCheckoutLazyQuery>;
export type CheckoutQueryResult = Apollo.QueryResult<Types.CheckoutQuery, Types.CheckoutQueryVariables>;
export const CheckoutAvailableShippingRatesDocument = gql`
    query CheckoutAvailableShippingRates($id: ID!, $country: CountryCode, $language: LanguageCode) @inContext(country: $country, language: $language) {
  node(id: $id) {
    ... on Checkout {
      id
      availableShippingRates {
        ready
        shippingRates {
          handle
          priceV2 {
            amount
            currencyCode
          }
          title
        }
      }
    }
  }
}
    `;

/**
 * __useCheckoutAvailableShippingRatesQuery__
 *
 * To run a query within a React component, call `useCheckoutAvailableShippingRatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckoutAvailableShippingRatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckoutAvailableShippingRatesQuery({
 *   variables: {
 *      id: // value for 'id'
 *      country: // value for 'country'
 *      language: // value for 'language'
 *   },
 * });
 */
export function useCheckoutAvailableShippingRatesQuery(baseOptions: Apollo.QueryHookOptions<Types.CheckoutAvailableShippingRatesQuery, Types.CheckoutAvailableShippingRatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CheckoutAvailableShippingRatesQuery, Types.CheckoutAvailableShippingRatesQueryVariables>(CheckoutAvailableShippingRatesDocument, options);
      }
export function useCheckoutAvailableShippingRatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CheckoutAvailableShippingRatesQuery, Types.CheckoutAvailableShippingRatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CheckoutAvailableShippingRatesQuery, Types.CheckoutAvailableShippingRatesQueryVariables>(CheckoutAvailableShippingRatesDocument, options);
        }
export type CheckoutAvailableShippingRatesQueryHookResult = ReturnType<typeof useCheckoutAvailableShippingRatesQuery>;
export type CheckoutAvailableShippingRatesLazyQueryHookResult = ReturnType<typeof useCheckoutAvailableShippingRatesLazyQuery>;
export type CheckoutAvailableShippingRatesQueryResult = Apollo.QueryResult<Types.CheckoutAvailableShippingRatesQuery, Types.CheckoutAvailableShippingRatesQueryVariables>;
export const CollectionNameDocument = gql`
    query CollectionName($handle: String!) {
  collection(handle: $handle) {
    title
    description
    meta_description: metafield(key: "meta_description", namespace: "sprenger") {
      value
    }
    meta_keywords: metafield(key: "meta_keywords", namespace: "sprenger") {
      value
    }
  }
  shop {
    category_tree: metafield(key: "category_tree", namespace: "sprenger") {
      value
      id
    }
  }
}
    `;

/**
 * __useCollectionNameQuery__
 *
 * To run a query within a React component, call `useCollectionNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useCollectionNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCollectionNameQuery({
 *   variables: {
 *      handle: // value for 'handle'
 *   },
 * });
 */
export function useCollectionNameQuery(baseOptions: Apollo.QueryHookOptions<Types.CollectionNameQuery, Types.CollectionNameQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CollectionNameQuery, Types.CollectionNameQueryVariables>(CollectionNameDocument, options);
      }
export function useCollectionNameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CollectionNameQuery, Types.CollectionNameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CollectionNameQuery, Types.CollectionNameQueryVariables>(CollectionNameDocument, options);
        }
export type CollectionNameQueryHookResult = ReturnType<typeof useCollectionNameQuery>;
export type CollectionNameLazyQueryHookResult = ReturnType<typeof useCollectionNameLazyQuery>;
export type CollectionNameQueryResult = Apollo.QueryResult<Types.CollectionNameQuery, Types.CollectionNameQueryVariables>;
export const CollectionDocument = gql`
    query Collection($id: ID, $handle: String, $after: String, $country: CountryCode!, $language: LanguageCode!) @inContext(country: $country, language: $language) {
  collection(id: $id, handle: $handle) {
    id
    handle
    description
    descriptionHtml
    title
    image {
      src
      altText
      id
    }
    includeInMenu: metafield(key: "include_in_menu", namespace: "sprenger") {
      key
      value
      id
    }
    products(first: 250, after: $after) {
      edges {
        node {
          id
          handle
          description
          availableForSale
          priceRange {
            maxVariantPrice {
              amount
              currencyCode
            }
            minVariantPrice {
              amount
              currencyCode
            }
          }
          compareAtPriceRange {
            maxVariantPrice {
              amount
              currencyCode
            }
            minVariantPrice {
              amount
              currencyCode
            }
          }
          placeholderImages: images(first: 10) {
            edges {
              node {
                src
              }
            }
          }
          colormap: metafield(key: "colormap", namespace: "sprenger") {
            key
            value
          }
          sku: metafield(key: "sku", namespace: "sprenger") {
            key
            value
          }
          image_urls: metafield(key: "image_urls", namespace: "sprenger") {
            key
            value
          }
          dreid: metafield(key: "dreid", namespace: "sprenger") {
            key
            value
          }
          video: metafield(key: "video", namespace: "sprenger") {
            key
            value
          }
          highlights: metafield(key: "highlights", namespace: "sprenger") {
            key
            value
          }
          images(first: 10) {
            edges {
              node {
                src
                altText
                id
                width
                height
              }
            }
          }
          collections(first: 100) {
            edges {
              node {
                title
                description
              }
            }
          }
          variants(first: 10) {
            edges {
              node {
                id
                technical_data: metafield(key: "technical_data", namespace: "sprenger") {
                  key
                  value
                }
                isArchived: metafield(key: "is_archived", namespace: "sprenger") {
                  key
                  value
                }
                quantityAvailable
                priceV2 {
                  amount
                  currencyCode
                }
                compareAtPriceV2 {
                  amount
                  currencyCode
                }
              }
            }
          }
          title
          tags
          totalInventory
          publishedAt
        }
        cursor
      }
      pageInfo {
        hasNextPage
      }
    }
  }
}
    `;

/**
 * __useCollectionQuery__
 *
 * To run a query within a React component, call `useCollectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useCollectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCollectionQuery({
 *   variables: {
 *      id: // value for 'id'
 *      handle: // value for 'handle'
 *      after: // value for 'after'
 *      country: // value for 'country'
 *      language: // value for 'language'
 *   },
 * });
 */
export function useCollectionQuery(baseOptions: Apollo.QueryHookOptions<Types.CollectionQuery, Types.CollectionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CollectionQuery, Types.CollectionQueryVariables>(CollectionDocument, options);
      }
export function useCollectionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CollectionQuery, Types.CollectionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CollectionQuery, Types.CollectionQueryVariables>(CollectionDocument, options);
        }
export type CollectionQueryHookResult = ReturnType<typeof useCollectionQuery>;
export type CollectionLazyQueryHookResult = ReturnType<typeof useCollectionLazyQuery>;
export type CollectionQueryResult = Apollo.QueryResult<Types.CollectionQuery, Types.CollectionQueryVariables>;
export const CollectionItemDocument = gql`
    query CollectionItem($handle: String!, $country: CountryCode, $language: LanguageCode) @inContext(country: $country, language: $language) {
  collection(handle: $handle) {
    id
    handle
    description
    descriptionHtml
    title
    placeholderImages: image {
      src
    }
    image {
      src
      altText
      id
    }
    includeInMenu: metafield(key: "include_in_menu", namespace: "sprenger") {
      key
      value
      id
    }
    position: metafield(key: "position", namespace: "sprenger") {
      key
      value
      id
    }
    products(first: 10) {
      edges {
        node {
          id
          handle
          description
          availableForSale
          priceRange {
            maxVariantPrice {
              amount
              currencyCode
            }
            minVariantPrice {
              amount
              currencyCode
            }
          }
          compareAtPriceRange {
            maxVariantPrice {
              amount
              currencyCode
            }
            minVariantPrice {
              amount
              currencyCode
            }
          }
          sku: metafield(key: "sku", namespace: "sprenger") {
            key
            value
          }
          image_urls: metafield(key: "image_urls", namespace: "sprenger") {
            key
            value
          }
          dreid: metafield(key: "dreid", namespace: "sprenger") {
            key
            value
          }
          video: metafield(key: "video", namespace: "sprenger") {
            key
            value
          }
          placeholderImages: images(first: 10) {
            edges {
              node {
                src
              }
            }
          }
          images(first: 10) {
            edges {
              node {
                src
                altText
                id
                width
                height
              }
            }
          }
          collections(first: 100) {
            edges {
              node {
                title
                description
              }
            }
          }
          variants(first: 10) {
            edges {
              node {
                id
                isArchived: metafield(key: "is_archived", namespace: "sprenger") {
                  key
                  value
                }
              }
            }
          }
          title
          tags
          totalInventory
        }
      }
    }
  }
}
    `;

/**
 * __useCollectionItemQuery__
 *
 * To run a query within a React component, call `useCollectionItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useCollectionItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCollectionItemQuery({
 *   variables: {
 *      handle: // value for 'handle'
 *      country: // value for 'country'
 *      language: // value for 'language'
 *   },
 * });
 */
export function useCollectionItemQuery(baseOptions: Apollo.QueryHookOptions<Types.CollectionItemQuery, Types.CollectionItemQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CollectionItemQuery, Types.CollectionItemQueryVariables>(CollectionItemDocument, options);
      }
export function useCollectionItemLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CollectionItemQuery, Types.CollectionItemQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CollectionItemQuery, Types.CollectionItemQueryVariables>(CollectionItemDocument, options);
        }
export type CollectionItemQueryHookResult = ReturnType<typeof useCollectionItemQuery>;
export type CollectionItemLazyQueryHookResult = ReturnType<typeof useCollectionItemLazyQuery>;
export type CollectionItemQueryResult = Apollo.QueryResult<Types.CollectionItemQuery, Types.CollectionItemQueryVariables>;
export const CollectionsDocument = gql`
    query Collections($first: Int!) {
  collections(first: $first) {
    edges {
      node {
        id
        handle
        description
        descriptionHtml
        title
        placeholderImages: image {
          src
        }
        image {
          src
          altText
          id
        }
        includeInMenu: metafield(key: "include_in_menu", namespace: "sprenger") {
          key
          value
          id
        }
        position: metafield(key: "position", namespace: "sprenger") {
          key
          value
          id
        }
        products(first: 10) {
          edges {
            node {
              id
              handle
              description
              availableForSale
              priceRange {
                maxVariantPrice {
                  amount
                  currencyCode
                }
                minVariantPrice {
                  amount
                  currencyCode
                }
              }
              compareAtPriceRange {
                maxVariantPrice {
                  amount
                  currencyCode
                }
                minVariantPrice {
                  amount
                  currencyCode
                }
              }
              sku: metafield(key: "sku", namespace: "sprenger") {
                key
                value
              }
              image_urls: metafield(key: "image_urls", namespace: "sprenger") {
                key
                value
              }
              dreid: metafield(key: "dreid", namespace: "sprenger") {
                key
                value
              }
              video: metafield(key: "video", namespace: "sprenger") {
                key
                value
              }
              placeholderImages: images(first: 10) {
                edges {
                  node {
                    src
                  }
                }
              }
              images(first: 10) {
                edges {
                  node {
                    src
                    altText
                    id
                    width
                    height
                  }
                }
              }
              collections(first: 100) {
                edges {
                  node {
                    title
                    description
                  }
                }
              }
              variants(first: 10) {
                edges {
                  node {
                    id
                  }
                }
              }
              title
              tags
              totalInventory
            }
            cursor
          }
        }
      }
    }
  }
}
    `;

/**
 * __useCollectionsQuery__
 *
 * To run a query within a React component, call `useCollectionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCollectionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCollectionsQuery({
 *   variables: {
 *      first: // value for 'first'
 *   },
 * });
 */
export function useCollectionsQuery(baseOptions: Apollo.QueryHookOptions<Types.CollectionsQuery, Types.CollectionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CollectionsQuery, Types.CollectionsQueryVariables>(CollectionsDocument, options);
      }
export function useCollectionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CollectionsQuery, Types.CollectionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CollectionsQuery, Types.CollectionsQueryVariables>(CollectionsDocument, options);
        }
export type CollectionsQueryHookResult = ReturnType<typeof useCollectionsQuery>;
export type CollectionsLazyQueryHookResult = ReturnType<typeof useCollectionsLazyQuery>;
export type CollectionsQueryResult = Apollo.QueryResult<Types.CollectionsQuery, Types.CollectionsQueryVariables>;
export const CollectionHandlesDocument = gql`
    query CollectionHandles($first: Int!) {
  collections(first: $first) {
    edges {
      node {
        handle
        includeInMenu: metafield(key: "include_in_menu", namespace: "sprenger") {
          key
          value
          id
        }
      }
    }
  }
}
    `;

/**
 * __useCollectionHandlesQuery__
 *
 * To run a query within a React component, call `useCollectionHandlesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCollectionHandlesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCollectionHandlesQuery({
 *   variables: {
 *      first: // value for 'first'
 *   },
 * });
 */
export function useCollectionHandlesQuery(baseOptions: Apollo.QueryHookOptions<Types.CollectionHandlesQuery, Types.CollectionHandlesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CollectionHandlesQuery, Types.CollectionHandlesQueryVariables>(CollectionHandlesDocument, options);
      }
export function useCollectionHandlesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CollectionHandlesQuery, Types.CollectionHandlesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CollectionHandlesQuery, Types.CollectionHandlesQueryVariables>(CollectionHandlesDocument, options);
        }
export type CollectionHandlesQueryHookResult = ReturnType<typeof useCollectionHandlesQuery>;
export type CollectionHandlesLazyQueryHookResult = ReturnType<typeof useCollectionHandlesLazyQuery>;
export type CollectionHandlesQueryResult = Apollo.QueryResult<Types.CollectionHandlesQuery, Types.CollectionHandlesQueryVariables>;
export const CustomerDocument = gql`
    query Customer($customerAccessToken: String!, $country: CountryCode!, $language: LanguageCode!) @inContext(country: $country, language: $language) {
  customer(customerAccessToken: $customerAccessToken) {
    email
    firstName
    createdAt
    id
    lastName
    phone
    displayName
    birthday: metafield(key: "birthday", namespace: "my_fields") {
      key
      value
      id
    }
    gender: metafield(key: "gender", namespace: "my_fields") {
      key
      value
      id
    }
    addresses(first: 10) {
      edges {
        node {
          zip
          provinceCode
          province
          phone
          name
          longitude
          latitude
          lastName
          id
          formattedArea
          formatted
          firstName
          countryCodeV2
          countryCode
          country
          company
          city
          address2
          address1
        }
      }
    }
    defaultAddress {
      zip
      provinceCode
      province
      phone
      name
      longitude
      latitude
      lastName
      id
      formattedArea
      formatted
      firstName
      countryCodeV2
      countryCode
      country
      company
      city
      address2
      address1
    }
    acceptsMarketing
    orders(first: 99) {
      edges {
        node {
          id
        }
      }
    }
  }
}
    `;

/**
 * __useCustomerQuery__
 *
 * To run a query within a React component, call `useCustomerQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerQuery({
 *   variables: {
 *      customerAccessToken: // value for 'customerAccessToken'
 *      country: // value for 'country'
 *      language: // value for 'language'
 *   },
 * });
 */
export function useCustomerQuery(baseOptions: Apollo.QueryHookOptions<Types.CustomerQuery, Types.CustomerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CustomerQuery, Types.CustomerQueryVariables>(CustomerDocument, options);
      }
export function useCustomerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CustomerQuery, Types.CustomerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CustomerQuery, Types.CustomerQueryVariables>(CustomerDocument, options);
        }
export type CustomerQueryHookResult = ReturnType<typeof useCustomerQuery>;
export type CustomerLazyQueryHookResult = ReturnType<typeof useCustomerLazyQuery>;
export type CustomerQueryResult = Apollo.QueryResult<Types.CustomerQuery, Types.CustomerQueryVariables>;
export const OrdersDocument = gql`
    query ORDERS($customerAccessToken: String!, $first: Int = 5, $after: String, $query: String, $country: CountryCode!, $language: LanguageCode!) @inContext(country: $country, language: $language) {
  customer(customerAccessToken: $customerAccessToken) {
    orders(first: $first, after: $after, query: $query, reverse: true) {
      edges {
        node {
          name
          email
          orderNumber
          processedAt
          currentTotalPrice {
            amount
            currencyCode
          }
          currentTotalDuties {
            currencyCode
            amount
          }
          discountApplications(first: 10) {
            edges {
              node {
                value {
                  ... on MoneyV2 {
                    __typename
                    currencyCode
                    amount
                  }
                  ... on PricingPercentageValue {
                    __typename
                    percentage
                  }
                }
                allocationMethod
                targetSelection
                targetType
              }
            }
          }
          totalPriceV2 {
            currencyCode
            amount
          }
          totalShippingPriceV2 {
            currencyCode
            amount
          }
          totalRefundedV2 {
            currencyCode
            amount
          }
          totalTaxV2 {
            currencyCode
            amount
          }
          subtotalPriceV2 {
            currencyCode
            amount
          }
          successfulFulfillments {
            trackingCompany
            trackingInfo(first: 10) {
              number
              url
            }
          }
          shippingAddress {
            address1
            address2
            company
            city
            country
            name
            province
            firstName
            lastName
            zip
            formatted
          }
          cancelReason
          canceledAt
          financialStatus
          fulfillmentStatus
          lineItems(first: 100) {
            edges {
              node {
                title
                quantity
                currentQuantity
                originalTotalPrice {
                  currencyCode
                  amount
                }
                discountedTotalPrice {
                  currencyCode
                  amount
                }
                discountAllocations {
                  discountApplication {
                    value {
                      ... on MoneyV2 {
                        __typename
                        currencyCode
                        amount
                      }
                    }
                  }
                  allocatedAmount {
                    currencyCode
                    amount
                  }
                }
                customAttributes {
                  value
                  key
                }
                variant {
                  id
                  currentlyNotInStock
                  quantityAvailable
                  image_urls: metafield(key: "image_urls", namespace: "sprenger") {
                    key
                    value
                  }
                  title
                  priceV2 {
                    amount
                    currencyCode
                  }
                  crossSellReferences: metafield(
                    key: "crosssell_references"
                    namespace: "sprenger"
                  ) {
                    key
                    value
                  }
                  product {
                    handle
                    title
                    crossSellReferences: metafield(
                      key: "crosssell_references"
                      namespace: "sprenger"
                    ) {
                      key
                      value
                    }
                    collections(first: 100) {
                      edges {
                        node {
                          title
                          description
                        }
                      }
                    }
                  }
                  selectedOptions {
                    name
                    value
                  }
                }
              }
            }
          }
          id
        }
        cursor
      }
      pageInfo {
        hasNextPage
      }
    }
    id
  }
}
    `;

/**
 * __useOrdersQuery__
 *
 * To run a query within a React component, call `useOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrdersQuery({
 *   variables: {
 *      customerAccessToken: // value for 'customerAccessToken'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      query: // value for 'query'
 *      country: // value for 'country'
 *      language: // value for 'language'
 *   },
 * });
 */
export function useOrdersQuery(baseOptions: Apollo.QueryHookOptions<Types.OrdersQuery, Types.OrdersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.OrdersQuery, Types.OrdersQueryVariables>(OrdersDocument, options);
      }
export function useOrdersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.OrdersQuery, Types.OrdersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.OrdersQuery, Types.OrdersQueryVariables>(OrdersDocument, options);
        }
export type OrdersQueryHookResult = ReturnType<typeof useOrdersQuery>;
export type OrdersLazyQueryHookResult = ReturnType<typeof useOrdersLazyQuery>;
export type OrdersQueryResult = Apollo.QueryResult<Types.OrdersQuery, Types.OrdersQueryVariables>;
export const VariantTechnicalDetailsDocument = gql`
    query VariantTechnicalDetails($variantId: ID!) {
  variant: node(id: $variantId) {
    ... on ProductVariant {
      id
      technical_data: metafield(key: "technical_data", namespace: "sprenger") {
        key
        value
      }
    }
  }
}
    `;

/**
 * __useVariantTechnicalDetailsQuery__
 *
 * To run a query within a React component, call `useVariantTechnicalDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useVariantTechnicalDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVariantTechnicalDetailsQuery({
 *   variables: {
 *      variantId: // value for 'variantId'
 *   },
 * });
 */
export function useVariantTechnicalDetailsQuery(baseOptions: Apollo.QueryHookOptions<Types.VariantTechnicalDetailsQuery, Types.VariantTechnicalDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.VariantTechnicalDetailsQuery, Types.VariantTechnicalDetailsQueryVariables>(VariantTechnicalDetailsDocument, options);
      }
export function useVariantTechnicalDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.VariantTechnicalDetailsQuery, Types.VariantTechnicalDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.VariantTechnicalDetailsQuery, Types.VariantTechnicalDetailsQueryVariables>(VariantTechnicalDetailsDocument, options);
        }
export type VariantTechnicalDetailsQueryHookResult = ReturnType<typeof useVariantTechnicalDetailsQuery>;
export type VariantTechnicalDetailsLazyQueryHookResult = ReturnType<typeof useVariantTechnicalDetailsLazyQuery>;
export type VariantTechnicalDetailsQueryResult = Apollo.QueryResult<Types.VariantTechnicalDetailsQuery, Types.VariantTechnicalDetailsQueryVariables>;
export const VariantHazardDataDocument = gql`
    query VariantHazardData($variantId: ID!) {
  variant: node(id: $variantId) {
    ... on ProductVariant {
      id
      hazard_data: metafield(key: "hazard_data", namespace: "sprenger") {
        key
        value
      }
    }
  }
}
    `;

/**
 * __useVariantHazardDataQuery__
 *
 * To run a query within a React component, call `useVariantHazardDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useVariantHazardDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVariantHazardDataQuery({
 *   variables: {
 *      variantId: // value for 'variantId'
 *   },
 * });
 */
export function useVariantHazardDataQuery(baseOptions: Apollo.QueryHookOptions<Types.VariantHazardDataQuery, Types.VariantHazardDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.VariantHazardDataQuery, Types.VariantHazardDataQueryVariables>(VariantHazardDataDocument, options);
      }
export function useVariantHazardDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.VariantHazardDataQuery, Types.VariantHazardDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.VariantHazardDataQuery, Types.VariantHazardDataQueryVariables>(VariantHazardDataDocument, options);
        }
export type VariantHazardDataQueryHookResult = ReturnType<typeof useVariantHazardDataQuery>;
export type VariantHazardDataLazyQueryHookResult = ReturnType<typeof useVariantHazardDataLazyQuery>;
export type VariantHazardDataQueryResult = Apollo.QueryResult<Types.VariantHazardDataQuery, Types.VariantHazardDataQueryVariables>;
export const StoryblokMaterialAndCareMetafieldUrlsDocument = gql`
    query StoryblokMaterialAndCareMetafieldUrls($productId: ID!, $variantId: ID!) {
  product(id: $productId) {
    id
    care_d2c: metafield(key: "care_d2c", namespace: "sprenger") {
      key
      value
    }
    material_d2c: metafield(key: "material_d2c", namespace: "sprenger") {
      key
      value
    }
  }
  variant: node(id: $variantId) {
    ... on ProductVariant {
      id
      care_d2c: metafield(key: "care_d2c", namespace: "sprenger") {
        key
        value
      }
      material_d2c: metafield(key: "material_d2c", namespace: "sprenger") {
        key
        value
      }
    }
  }
}
    `;

/**
 * __useStoryblokMaterialAndCareMetafieldUrlsQuery__
 *
 * To run a query within a React component, call `useStoryblokMaterialAndCareMetafieldUrlsQuery` and pass it any options that fit your needs.
 * When your component renders, `useStoryblokMaterialAndCareMetafieldUrlsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStoryblokMaterialAndCareMetafieldUrlsQuery({
 *   variables: {
 *      productId: // value for 'productId'
 *      variantId: // value for 'variantId'
 *   },
 * });
 */
export function useStoryblokMaterialAndCareMetafieldUrlsQuery(baseOptions: Apollo.QueryHookOptions<Types.StoryblokMaterialAndCareMetafieldUrlsQuery, Types.StoryblokMaterialAndCareMetafieldUrlsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.StoryblokMaterialAndCareMetafieldUrlsQuery, Types.StoryblokMaterialAndCareMetafieldUrlsQueryVariables>(StoryblokMaterialAndCareMetafieldUrlsDocument, options);
      }
export function useStoryblokMaterialAndCareMetafieldUrlsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.StoryblokMaterialAndCareMetafieldUrlsQuery, Types.StoryblokMaterialAndCareMetafieldUrlsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.StoryblokMaterialAndCareMetafieldUrlsQuery, Types.StoryblokMaterialAndCareMetafieldUrlsQueryVariables>(StoryblokMaterialAndCareMetafieldUrlsDocument, options);
        }
export type StoryblokMaterialAndCareMetafieldUrlsQueryHookResult = ReturnType<typeof useStoryblokMaterialAndCareMetafieldUrlsQuery>;
export type StoryblokMaterialAndCareMetafieldUrlsLazyQueryHookResult = ReturnType<typeof useStoryblokMaterialAndCareMetafieldUrlsLazyQuery>;
export type StoryblokMaterialAndCareMetafieldUrlsQueryResult = Apollo.QueryResult<Types.StoryblokMaterialAndCareMetafieldUrlsQuery, Types.StoryblokMaterialAndCareMetafieldUrlsQueryVariables>;
export const StoryblokFaqMetafieldUrlsDocument = gql`
    query StoryblokFaqMetafieldUrls($productId: ID!, $variantId: ID!) {
  product(id: $productId) {
    id
    faq_d2c: metafield(key: "faq_d2c", namespace: "sprenger") {
      key
      value
    }
  }
  variant: node(id: $variantId) {
    ... on ProductVariant {
      id
      faq_d2c: metafield(key: "faq_d2c", namespace: "sprenger") {
        key
        value
      }
    }
  }
}
    `;

/**
 * __useStoryblokFaqMetafieldUrlsQuery__
 *
 * To run a query within a React component, call `useStoryblokFaqMetafieldUrlsQuery` and pass it any options that fit your needs.
 * When your component renders, `useStoryblokFaqMetafieldUrlsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStoryblokFaqMetafieldUrlsQuery({
 *   variables: {
 *      productId: // value for 'productId'
 *      variantId: // value for 'variantId'
 *   },
 * });
 */
export function useStoryblokFaqMetafieldUrlsQuery(baseOptions: Apollo.QueryHookOptions<Types.StoryblokFaqMetafieldUrlsQuery, Types.StoryblokFaqMetafieldUrlsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.StoryblokFaqMetafieldUrlsQuery, Types.StoryblokFaqMetafieldUrlsQueryVariables>(StoryblokFaqMetafieldUrlsDocument, options);
      }
export function useStoryblokFaqMetafieldUrlsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.StoryblokFaqMetafieldUrlsQuery, Types.StoryblokFaqMetafieldUrlsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.StoryblokFaqMetafieldUrlsQuery, Types.StoryblokFaqMetafieldUrlsQueryVariables>(StoryblokFaqMetafieldUrlsDocument, options);
        }
export type StoryblokFaqMetafieldUrlsQueryHookResult = ReturnType<typeof useStoryblokFaqMetafieldUrlsQuery>;
export type StoryblokFaqMetafieldUrlsLazyQueryHookResult = ReturnType<typeof useStoryblokFaqMetafieldUrlsLazyQuery>;
export type StoryblokFaqMetafieldUrlsQueryResult = Apollo.QueryResult<Types.StoryblokFaqMetafieldUrlsQuery, Types.StoryblokFaqMetafieldUrlsQueryVariables>;
export const StoryblokContentMetafieldUrlsDocument = gql`
    query StoryblokContentMetafieldUrls($productId: ID!, $variantId: ID!) {
  product(id: $productId) {
    id
    content_d2c: metafield(key: "content_d2c", namespace: "sprenger") {
      key
      value
    }
  }
  variant: node(id: $variantId) {
    ... on ProductVariant {
      id
      content_d2c: metafield(key: "content_d2c", namespace: "sprenger") {
        key
        value
      }
    }
  }
}
    `;

/**
 * __useStoryblokContentMetafieldUrlsQuery__
 *
 * To run a query within a React component, call `useStoryblokContentMetafieldUrlsQuery` and pass it any options that fit your needs.
 * When your component renders, `useStoryblokContentMetafieldUrlsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStoryblokContentMetafieldUrlsQuery({
 *   variables: {
 *      productId: // value for 'productId'
 *      variantId: // value for 'variantId'
 *   },
 * });
 */
export function useStoryblokContentMetafieldUrlsQuery(baseOptions: Apollo.QueryHookOptions<Types.StoryblokContentMetafieldUrlsQuery, Types.StoryblokContentMetafieldUrlsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.StoryblokContentMetafieldUrlsQuery, Types.StoryblokContentMetafieldUrlsQueryVariables>(StoryblokContentMetafieldUrlsDocument, options);
      }
export function useStoryblokContentMetafieldUrlsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.StoryblokContentMetafieldUrlsQuery, Types.StoryblokContentMetafieldUrlsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.StoryblokContentMetafieldUrlsQuery, Types.StoryblokContentMetafieldUrlsQueryVariables>(StoryblokContentMetafieldUrlsDocument, options);
        }
export type StoryblokContentMetafieldUrlsQueryHookResult = ReturnType<typeof useStoryblokContentMetafieldUrlsQuery>;
export type StoryblokContentMetafieldUrlsLazyQueryHookResult = ReturnType<typeof useStoryblokContentMetafieldUrlsLazyQuery>;
export type StoryblokContentMetafieldUrlsQueryResult = Apollo.QueryResult<Types.StoryblokContentMetafieldUrlsQuery, Types.StoryblokContentMetafieldUrlsQueryVariables>;
export const CrossSellProductForAllDocument = gql`
    query CrossSellProductForAll($id: ID, $handle: String, $variant: ID!) {
  product(id: $id, handle: $handle) {
    crossSellReferences: metafield(
      key: "crosssell_references"
      namespace: "sprenger"
    ) {
      key
      value
    }
  }
  variant: node(id: $variant) {
    ... on ProductVariant {
      crossSellReferences: metafield(
        key: "crosssell_references"
        namespace: "sprenger"
      ) {
        key
        value
      }
    }
  }
}
    `;

/**
 * __useCrossSellProductForAllQuery__
 *
 * To run a query within a React component, call `useCrossSellProductForAllQuery` and pass it any options that fit your needs.
 * When your component renders, `useCrossSellProductForAllQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCrossSellProductForAllQuery({
 *   variables: {
 *      id: // value for 'id'
 *      handle: // value for 'handle'
 *      variant: // value for 'variant'
 *   },
 * });
 */
export function useCrossSellProductForAllQuery(baseOptions: Apollo.QueryHookOptions<Types.CrossSellProductForAllQuery, Types.CrossSellProductForAllQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CrossSellProductForAllQuery, Types.CrossSellProductForAllQueryVariables>(CrossSellProductForAllDocument, options);
      }
export function useCrossSellProductForAllLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CrossSellProductForAllQuery, Types.CrossSellProductForAllQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CrossSellProductForAllQuery, Types.CrossSellProductForAllQueryVariables>(CrossSellProductForAllDocument, options);
        }
export type CrossSellProductForAllQueryHookResult = ReturnType<typeof useCrossSellProductForAllQuery>;
export type CrossSellProductForAllLazyQueryHookResult = ReturnType<typeof useCrossSellProductForAllLazyQuery>;
export type CrossSellProductForAllQueryResult = Apollo.QueryResult<Types.CrossSellProductForAllQuery, Types.CrossSellProductForAllQueryVariables>;
export const ImageSkuMinimalDataDocument = gql`
    query ImageSKUMinimalData($handle: String, $country: CountryCode!, $language: LanguageCode!) @inContext(country: $country, language: $language) {
  product(handle: $handle) {
    id
    title
    description
    collections(first: 100) {
      edges {
        node {
          title
          handle
          description
        }
      }
    }
    variants(first: 100) {
      edges {
        node {
          id
          title
          sku: metafield(key: "just_sku", namespace: "sprenger") {
            key
            value
          }
          image_urls: metafield(key: "image_urls", namespace: "sprenger") {
            key
            value
          }
          isArchived: metafield(key: "is_archived", namespace: "sprenger") {
            key
            value
          }
          quantityAvailable
          priceV2 {
            amount
            currencyCode
          }
          compareAtPriceV2 {
            amount
            currencyCode
          }
          unitPrice {
            amount
            currencyCode
          }
          unitPriceMeasurement {
            referenceValue
            referenceUnit
          }
          bundle_desc: metafield(key: "bundle_desc", namespace: "sprenger") {
            key
            value
          }
        }
      }
    }
    priceRange {
      maxVariantPrice {
        amount
        currencyCode
      }
      minVariantPrice {
        amount
        currencyCode
      }
    }
    compareAtPriceRange {
      maxVariantPrice {
        amount
        currencyCode
      }
      minVariantPrice {
        amount
        currencyCode
      }
    }
  }
}
    `;

/**
 * __useImageSkuMinimalDataQuery__
 *
 * To run a query within a React component, call `useImageSkuMinimalDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useImageSkuMinimalDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useImageSkuMinimalDataQuery({
 *   variables: {
 *      handle: // value for 'handle'
 *      country: // value for 'country'
 *      language: // value for 'language'
 *   },
 * });
 */
export function useImageSkuMinimalDataQuery(baseOptions: Apollo.QueryHookOptions<Types.ImageSkuMinimalDataQuery, Types.ImageSkuMinimalDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ImageSkuMinimalDataQuery, Types.ImageSkuMinimalDataQueryVariables>(ImageSkuMinimalDataDocument, options);
      }
export function useImageSkuMinimalDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ImageSkuMinimalDataQuery, Types.ImageSkuMinimalDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ImageSkuMinimalDataQuery, Types.ImageSkuMinimalDataQueryVariables>(ImageSkuMinimalDataDocument, options);
        }
export type ImageSkuMinimalDataQueryHookResult = ReturnType<typeof useImageSkuMinimalDataQuery>;
export type ImageSkuMinimalDataLazyQueryHookResult = ReturnType<typeof useImageSkuMinimalDataLazyQuery>;
export type ImageSkuMinimalDataQueryResult = Apollo.QueryResult<Types.ImageSkuMinimalDataQuery, Types.ImageSkuMinimalDataQueryVariables>;
export const TechnicalDetailsVariantsDocument = gql`
    query TechnicalDetailsVariants($productId: ID!) {
  product(id: $productId) {
    variants(first: 100) {
      edges {
        node {
          id
          technical_data: metafield(key: "technical_data", namespace: "sprenger") {
            key
            value
          }
        }
      }
    }
  }
}
    `;

/**
 * __useTechnicalDetailsVariantsQuery__
 *
 * To run a query within a React component, call `useTechnicalDetailsVariantsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTechnicalDetailsVariantsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTechnicalDetailsVariantsQuery({
 *   variables: {
 *      productId: // value for 'productId'
 *   },
 * });
 */
export function useTechnicalDetailsVariantsQuery(baseOptions: Apollo.QueryHookOptions<Types.TechnicalDetailsVariantsQuery, Types.TechnicalDetailsVariantsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.TechnicalDetailsVariantsQuery, Types.TechnicalDetailsVariantsQueryVariables>(TechnicalDetailsVariantsDocument, options);
      }
export function useTechnicalDetailsVariantsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.TechnicalDetailsVariantsQuery, Types.TechnicalDetailsVariantsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.TechnicalDetailsVariantsQuery, Types.TechnicalDetailsVariantsQueryVariables>(TechnicalDetailsVariantsDocument, options);
        }
export type TechnicalDetailsVariantsQueryHookResult = ReturnType<typeof useTechnicalDetailsVariantsQuery>;
export type TechnicalDetailsVariantsLazyQueryHookResult = ReturnType<typeof useTechnicalDetailsVariantsLazyQuery>;
export type TechnicalDetailsVariantsQueryResult = Apollo.QueryResult<Types.TechnicalDetailsVariantsQuery, Types.TechnicalDetailsVariantsQueryVariables>;
export const ProductDocument = gql`
    query Product($id: ID, $handle: String, $sdsHandle: String!, $country: CountryCode!, $language: LanguageCode!) @inContext(country: $country, language: $language) {
  product(id: $id, handle: $handle) {
    id
    title
    tags
    totalInventory
    handle
    description
    descriptionHtml
    availableForSale
    priceRange {
      maxVariantPrice {
        amount
        currencyCode
      }
      minVariantPrice {
        amount
        currencyCode
      }
    }
    compareAtPriceRange {
      maxVariantPrice {
        amount
        currencyCode
      }
      minVariantPrice {
        amount
        currencyCode
      }
    }
    options(first: 10) {
      id
      name
      values
    }
    dreid: metafield(key: "dreid", namespace: "sprenger") {
      key
      value
    }
    video: metafield(key: "video", namespace: "sprenger") {
      key
      value
    }
    measure_d2c: metafield(key: "measure_d2c", namespace: "sprenger") {
      key
      value
    }
    upSellSkus: metafield(key: "upsell_skus", namespace: "sprenger") {
      key
      value
    }
    sku: metafield(key: "sku", namespace: "sprenger") {
      key
      value
    }
    is_single: metafield(key: "is_single", namespace: "sprenger") {
      key
      value
    }
    colormap: metafield(key: "colormap", namespace: "sprenger") {
      key
      value
    }
    highlights: metafield(key: "highlights", namespace: "sprenger") {
      key
      value
    }
    image_urls: metafield(key: "image_urls", namespace: "sprenger") {
      key
      value
    }
    bulletPoints: metafield(key: "bullets", namespace: "sprenger") {
      key
      value
    }
    measurement_table: metafield(key: "measurement_table", namespace: "sprenger") {
      key
      value
    }
    placeholderImages: images(first: 10) {
      edges {
        node {
          src
        }
      }
    }
    images(first: 10) {
      edges {
        node {
          src
          altText
          id
        }
      }
    }
    collections(first: 100) {
      edges {
        node {
          title
          handle
          description
        }
      }
    }
    variants(first: 100) {
      edges {
        node {
          id
          sku
          selectedOptions {
            name
            value
          }
          priceV2 {
            amount
            currencyCode
          }
          justSku: metafield(key: "just_sku", namespace: "sprenger") {
            key
            value
          }
          availableForSale
        }
      }
    }
  }
  sdsProduct: product(handle: $sdsHandle) {
    variants(first: 100) {
      edges {
        node {
          id
          justSku: metafield(key: "just_sku", namespace: "sprenger") {
            key
            value
          }
          selectedOptions {
            name
            value
          }
          quantityAvailable
          availableForSale
        }
      }
    }
  }
}
    `;

/**
 * __useProductQuery__
 *
 * To run a query within a React component, call `useProductQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductQuery({
 *   variables: {
 *      id: // value for 'id'
 *      handle: // value for 'handle'
 *      sdsHandle: // value for 'sdsHandle'
 *      country: // value for 'country'
 *      language: // value for 'language'
 *   },
 * });
 */
export function useProductQuery(baseOptions: Apollo.QueryHookOptions<Types.ProductQuery, Types.ProductQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ProductQuery, Types.ProductQueryVariables>(ProductDocument, options);
      }
export function useProductLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ProductQuery, Types.ProductQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ProductQuery, Types.ProductQueryVariables>(ProductDocument, options);
        }
export type ProductQueryHookResult = ReturnType<typeof useProductQuery>;
export type ProductLazyQueryHookResult = ReturnType<typeof useProductLazyQuery>;
export type ProductQueryResult = Apollo.QueryResult<Types.ProductQuery, Types.ProductQueryVariables>;
export const ProductCollectionsDocument = gql`
    query ProductCollections($id: ID, $handle: String, $sdsHandle: String!) {
  product(id: $id, handle: $handle) {
    id
    sku: metafield(key: "sku", namespace: "sprenger") {
      key
      value
    }
    collections(first: 100) {
      edges {
        node {
          title
          handle
          description
        }
      }
    }
  }
}
    `;

/**
 * __useProductCollectionsQuery__
 *
 * To run a query within a React component, call `useProductCollectionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductCollectionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductCollectionsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      handle: // value for 'handle'
 *      sdsHandle: // value for 'sdsHandle'
 *   },
 * });
 */
export function useProductCollectionsQuery(baseOptions: Apollo.QueryHookOptions<Types.ProductCollectionsQuery, Types.ProductCollectionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ProductCollectionsQuery, Types.ProductCollectionsQueryVariables>(ProductCollectionsDocument, options);
      }
export function useProductCollectionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ProductCollectionsQuery, Types.ProductCollectionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ProductCollectionsQuery, Types.ProductCollectionsQueryVariables>(ProductCollectionsDocument, options);
        }
export type ProductCollectionsQueryHookResult = ReturnType<typeof useProductCollectionsQuery>;
export type ProductCollectionsLazyQueryHookResult = ReturnType<typeof useProductCollectionsLazyQuery>;
export type ProductCollectionsQueryResult = Apollo.QueryResult<Types.ProductCollectionsQuery, Types.ProductCollectionsQueryVariables>;
export const ProductCardDocument = gql`
    query ProductCard($id: ID, $handle: String, $country: CountryCode, $language: LanguageCode) @inContext(country: $country, language: $language) {
  product(id: $id, handle: $handle) {
    id
    title
    tags
    totalInventory
    handle
    description
    descriptionHtml
    availableForSale
    priceRange {
      maxVariantPrice {
        amount
        currencyCode
      }
      minVariantPrice {
        amount
        currencyCode
      }
    }
    compareAtPriceRange {
      maxVariantPrice {
        amount
        currencyCode
      }
      minVariantPrice {
        amount
        currencyCode
      }
    }
    options(first: 10) {
      id
      name
      values
    }
    dreid: metafield(key: "dreid", namespace: "sprenger") {
      key
      value
    }
    video: metafield(key: "video", namespace: "sprenger") {
      key
      value
    }
    measure_d2c: metafield(key: "measure_d2c", namespace: "sprenger") {
      key
      value
    }
    upSellSkus: metafield(key: "upsell_skus", namespace: "sprenger") {
      key
      value
    }
    sku: metafield(key: "sku", namespace: "sprenger") {
      key
      value
    }
    is_single: metafield(key: "is_single", namespace: "sprenger") {
      key
      value
    }
    colormap: metafield(key: "colormap", namespace: "sprenger") {
      key
      value
    }
    highlights: metafield(key: "highlights", namespace: "sprenger") {
      key
      value
    }
    image_urls: metafield(key: "image_urls", namespace: "sprenger") {
      key
      value
    }
    bulletPoints: metafield(key: "bullets", namespace: "sprenger") {
      key
      value
    }
    measurement_table: metafield(key: "measurement_table", namespace: "sprenger") {
      key
      value
    }
    placeholderImages: images(first: 10) {
      edges {
        node {
          src
        }
      }
    }
    images(first: 10) {
      edges {
        node {
          src
          altText
          id
        }
      }
    }
    collections(first: 100) {
      edges {
        node {
          title
          handle
          description
        }
      }
    }
    variants(first: 100) {
      edges {
        node {
          id
          sku
          selectedOptions {
            name
            value
          }
          priceV2 {
            amount
            currencyCode
          }
          compareAtPriceV2 {
            amount
            currencyCode
          }
          justSku: metafield(key: "just_sku", namespace: "sprenger") {
            key
            value
          }
        }
      }
    }
  }
}
    `;

/**
 * __useProductCardQuery__
 *
 * To run a query within a React component, call `useProductCardQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductCardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductCardQuery({
 *   variables: {
 *      id: // value for 'id'
 *      handle: // value for 'handle'
 *      country: // value for 'country'
 *      language: // value for 'language'
 *   },
 * });
 */
export function useProductCardQuery(baseOptions?: Apollo.QueryHookOptions<Types.ProductCardQuery, Types.ProductCardQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ProductCardQuery, Types.ProductCardQueryVariables>(ProductCardDocument, options);
      }
export function useProductCardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ProductCardQuery, Types.ProductCardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ProductCardQuery, Types.ProductCardQueryVariables>(ProductCardDocument, options);
        }
export type ProductCardQueryHookResult = ReturnType<typeof useProductCardQuery>;
export type ProductCardLazyQueryHookResult = ReturnType<typeof useProductCardLazyQuery>;
export type ProductCardQueryResult = Apollo.QueryResult<Types.ProductCardQuery, Types.ProductCardQueryVariables>;
export const ProductVariantDocument = gql`
    query ProductVariant($variantId: ID!, $country: CountryCode, $language: LanguageCode) @inContext(country: $country, language: $language) {
  variant: node(id: $variantId) {
    ... on ProductVariant {
      ...ProductVariantData
    }
  }
}
    ${ProductVariantDataFragmentDoc}`;

/**
 * __useProductVariantQuery__
 *
 * To run a query within a React component, call `useProductVariantQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductVariantQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductVariantQuery({
 *   variables: {
 *      variantId: // value for 'variantId'
 *      country: // value for 'country'
 *      language: // value for 'language'
 *   },
 * });
 */
export function useProductVariantQuery(baseOptions: Apollo.QueryHookOptions<Types.ProductVariantQuery, Types.ProductVariantQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ProductVariantQuery, Types.ProductVariantQueryVariables>(ProductVariantDocument, options);
      }
export function useProductVariantLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ProductVariantQuery, Types.ProductVariantQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ProductVariantQuery, Types.ProductVariantQueryVariables>(ProductVariantDocument, options);
        }
export type ProductVariantQueryHookResult = ReturnType<typeof useProductVariantQuery>;
export type ProductVariantLazyQueryHookResult = ReturnType<typeof useProductVariantLazyQuery>;
export type ProductVariantQueryResult = Apollo.QueryResult<Types.ProductVariantQuery, Types.ProductVariantQueryVariables>;
export const ProductSafetyInstructionDataDocument = gql`
    query ProductSafetyInstructionData($handle: String!, $selected: [SelectedOptionInput!]!) {
  product(handle: $handle) {
    variantBySelectedOptions(selectedOptions: $selected) {
      hazard_data: metafield(key: "hazard_data", namespace: "sprenger") {
        key
        value
      }
      justSku: metafield(key: "just_sku", namespace: "sprenger") {
        key
        value
      }
    }
  }
}
    `;

/**
 * __useProductSafetyInstructionDataQuery__
 *
 * To run a query within a React component, call `useProductSafetyInstructionDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductSafetyInstructionDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductSafetyInstructionDataQuery({
 *   variables: {
 *      handle: // value for 'handle'
 *      selected: // value for 'selected'
 *   },
 * });
 */
export function useProductSafetyInstructionDataQuery(baseOptions: Apollo.QueryHookOptions<Types.ProductSafetyInstructionDataQuery, Types.ProductSafetyInstructionDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ProductSafetyInstructionDataQuery, Types.ProductSafetyInstructionDataQueryVariables>(ProductSafetyInstructionDataDocument, options);
      }
export function useProductSafetyInstructionDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ProductSafetyInstructionDataQuery, Types.ProductSafetyInstructionDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ProductSafetyInstructionDataQuery, Types.ProductSafetyInstructionDataQueryVariables>(ProductSafetyInstructionDataDocument, options);
        }
export type ProductSafetyInstructionDataQueryHookResult = ReturnType<typeof useProductSafetyInstructionDataQuery>;
export type ProductSafetyInstructionDataLazyQueryHookResult = ReturnType<typeof useProductSafetyInstructionDataLazyQuery>;
export type ProductSafetyInstructionDataQueryResult = Apollo.QueryResult<Types.ProductSafetyInstructionDataQuery, Types.ProductSafetyInstructionDataQueryVariables>;
export const CrossSellProductDocument = gql`
    query CrossSellProduct($id: ID, $handle: String, $country: CountryCode, $language: LanguageCode) @inContext(country: $country, language: $language) {
  product(id: $id, handle: $handle) {
    id
    handle
    title
    totalInventory
    sku: metafield(key: "sku", namespace: "sprenger") {
      key
      value
    }
    justSku: metafield(key: "just_sku", namespace: "sprenger") {
      key
      value
    }
    image_urls: metafield(key: "image_urls", namespace: "sprenger") {
      key
      value
    }
    highlights: metafield(key: "highlights", namespace: "sprenger") {
      key
      value
    }
    compareAtPriceRange {
      maxVariantPrice {
        amount
        currencyCode
      }
      minVariantPrice {
        amount
        currencyCode
      }
    }
    variants(first: 10) {
      edges {
        node {
          id
          quantityAvailable
          isArchived: metafield(key: "is_archived", namespace: "sprenger") {
            key
            value
          }
        }
      }
    }
    collections(first: 100) {
      edges {
        node {
          title
          description
        }
      }
    }
    priceRange {
      maxVariantPrice {
        amount
        currencyCode
      }
      minVariantPrice {
        amount
        currencyCode
      }
    }
  }
}
    `;

/**
 * __useCrossSellProductQuery__
 *
 * To run a query within a React component, call `useCrossSellProductQuery` and pass it any options that fit your needs.
 * When your component renders, `useCrossSellProductQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCrossSellProductQuery({
 *   variables: {
 *      id: // value for 'id'
 *      handle: // value for 'handle'
 *      country: // value for 'country'
 *      language: // value for 'language'
 *   },
 * });
 */
export function useCrossSellProductQuery(baseOptions?: Apollo.QueryHookOptions<Types.CrossSellProductQuery, Types.CrossSellProductQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CrossSellProductQuery, Types.CrossSellProductQueryVariables>(CrossSellProductDocument, options);
      }
export function useCrossSellProductLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CrossSellProductQuery, Types.CrossSellProductQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CrossSellProductQuery, Types.CrossSellProductQueryVariables>(CrossSellProductDocument, options);
        }
export type CrossSellProductQueryHookResult = ReturnType<typeof useCrossSellProductQuery>;
export type CrossSellProductLazyQueryHookResult = ReturnType<typeof useCrossSellProductLazyQuery>;
export type CrossSellProductQueryResult = Apollo.QueryResult<Types.CrossSellProductQuery, Types.CrossSellProductQueryVariables>;
export const VariantsByParentDocument = gql`
    query VariantsByParent($handle: String!, $handleSDS: String!, $country: CountryCode, $language: LanguageCode) @inContext(country: $country, language: $language) {
  product(handle: $handle) {
    id
    description
    colormap: metafield(key: "colormap", namespace: "sprenger") {
      key
      value
    }
    highlights: metafield(key: "highlights", namespace: "sprenger") {
      key
      value
    }
    dreid: metafield(key: "dreid", namespace: "sprenger") {
      key
      value
    }
    image_urls: metafield(key: "image_urls", namespace: "sprenger") {
      key
      value
    }
    is_single: metafield(key: "is_single", namespace: "sprenger") {
      key
      value
    }
    collections(first: 100) {
      edges {
        node {
          title
          handle
          description
        }
      }
    }
    title
    priceRange {
      maxVariantPrice {
        amount
        currencyCode
      }
      minVariantPrice {
        amount
        currencyCode
      }
    }
    compareAtPriceRange {
      maxVariantPrice {
        amount
        currencyCode
      }
      minVariantPrice {
        amount
        currencyCode
      }
    }
    options(first: 100) {
      name
      values
    }
    totalInventory
    variants(first: 100) {
      edges {
        node {
          product {
            handle
            title
            collections(first: 100) {
              edges {
                node {
                  title
                  handle
                }
              }
            }
          }
          id
          title
          justSku: metafield(key: "just_sku", namespace: "sprenger") {
            key
            value
          }
          sku: metafield(key: "just_sku", namespace: "sprenger") {
            key
            value
          }
          image_urls: metafield(key: "image_urls", namespace: "sprenger") {
            key
            value
          }
          isArchived: metafield(key: "is_archived", namespace: "sprenger") {
            key
            value
          }
          sds_qty: metafield(key: "sds_qty", namespace: "sprenger") {
            key
            value
          }
          selectedOptions {
            name
            value
          }
          quantityAvailable
          priceV2 {
            amount
            currencyCode
          }
          compareAtPriceV2 {
            amount
            currencyCode
          }
        }
      }
    }
  }
  sdsProduct: product(handle: $handleSDS) {
    totalInventory
    variants(first: 100) {
      edges {
        node {
          id
          sku: metafield(key: "just_sku", namespace: "sprenger") {
            key
            value
          }
          isArchived: metafield(key: "is_archived", namespace: "sprenger") {
            key
            value
          }
          quantityAvailable
        }
      }
    }
  }
}
    `;

/**
 * __useVariantsByParentQuery__
 *
 * To run a query within a React component, call `useVariantsByParentQuery` and pass it any options that fit your needs.
 * When your component renders, `useVariantsByParentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVariantsByParentQuery({
 *   variables: {
 *      handle: // value for 'handle'
 *      handleSDS: // value for 'handleSDS'
 *      country: // value for 'country'
 *      language: // value for 'language'
 *   },
 * });
 */
export function useVariantsByParentQuery(baseOptions: Apollo.QueryHookOptions<Types.VariantsByParentQuery, Types.VariantsByParentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.VariantsByParentQuery, Types.VariantsByParentQueryVariables>(VariantsByParentDocument, options);
      }
export function useVariantsByParentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.VariantsByParentQuery, Types.VariantsByParentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.VariantsByParentQuery, Types.VariantsByParentQueryVariables>(VariantsByParentDocument, options);
        }
export type VariantsByParentQueryHookResult = ReturnType<typeof useVariantsByParentQuery>;
export type VariantsByParentLazyQueryHookResult = ReturnType<typeof useVariantsByParentLazyQuery>;
export type VariantsByParentQueryResult = Apollo.QueryResult<Types.VariantsByParentQuery, Types.VariantsByParentQueryVariables>;
export const CrossSellProductVariantDocument = gql`
    query CrossSellProductVariant($id: ID, $handle: String, $selectedOptions: [SelectedOptionInput!]!, $country: CountryCode, $language: LanguageCode) @inContext(country: $country, language: $language) {
  product(id: $id, handle: $handle) {
    id
    handle
    totalInventory
    highlights: metafield(key: "highlights", namespace: "sprenger") {
      key
      value
    }
    variantBySelectedOptions(selectedOptions: $selectedOptions) {
      quantityAvailable
      id
      sku
      justSku: metafield(key: "just_sku", namespace: "sprenger") {
        key
        value
      }
      title
      compareAtPriceV2 {
        amount
        currencyCode
      }
      price: priceV2 {
        amount
        currencyCode
      }
      image_urls: metafield(key: "image_urls", namespace: "sprenger") {
        key
        value
      }
      highlights: metafield(key: "highlights", namespace: "sprenger") {
        key
        value
      }
      isArchived: metafield(key: "is_archived", namespace: "sprenger") {
        key
        value
      }
      product {
        id
        handle
        totalInventory
        title
        collections(first: 100) {
          edges {
            node {
              title
              description
            }
          }
        }
      }
      selectedOptions {
        name
        value
      }
    }
  }
}
    `;

/**
 * __useCrossSellProductVariantQuery__
 *
 * To run a query within a React component, call `useCrossSellProductVariantQuery` and pass it any options that fit your needs.
 * When your component renders, `useCrossSellProductVariantQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCrossSellProductVariantQuery({
 *   variables: {
 *      id: // value for 'id'
 *      handle: // value for 'handle'
 *      selectedOptions: // value for 'selectedOptions'
 *      country: // value for 'country'
 *      language: // value for 'language'
 *   },
 * });
 */
export function useCrossSellProductVariantQuery(baseOptions: Apollo.QueryHookOptions<Types.CrossSellProductVariantQuery, Types.CrossSellProductVariantQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CrossSellProductVariantQuery, Types.CrossSellProductVariantQueryVariables>(CrossSellProductVariantDocument, options);
      }
export function useCrossSellProductVariantLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CrossSellProductVariantQuery, Types.CrossSellProductVariantQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CrossSellProductVariantQuery, Types.CrossSellProductVariantQueryVariables>(CrossSellProductVariantDocument, options);
        }
export type CrossSellProductVariantQueryHookResult = ReturnType<typeof useCrossSellProductVariantQuery>;
export type CrossSellProductVariantLazyQueryHookResult = ReturnType<typeof useCrossSellProductVariantLazyQuery>;
export type CrossSellProductVariantQueryResult = Apollo.QueryResult<Types.CrossSellProductVariantQuery, Types.CrossSellProductVariantQueryVariables>;
export const DataLoadedAvailabilityDocument = gql`
    query DataLoadedAvailability($first: Int, $query: String) {
  products(first: $first, query: $query) {
    edges {
      node {
        handle
        variants(first: 20) {
          edges {
            node {
              quantityAvailable
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useDataLoadedAvailabilityQuery__
 *
 * To run a query within a React component, call `useDataLoadedAvailabilityQuery` and pass it any options that fit your needs.
 * When your component renders, `useDataLoadedAvailabilityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDataLoadedAvailabilityQuery({
 *   variables: {
 *      first: // value for 'first'
 *      query: // value for 'query'
 *   },
 * });
 */
export function useDataLoadedAvailabilityQuery(baseOptions?: Apollo.QueryHookOptions<Types.DataLoadedAvailabilityQuery, Types.DataLoadedAvailabilityQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.DataLoadedAvailabilityQuery, Types.DataLoadedAvailabilityQueryVariables>(DataLoadedAvailabilityDocument, options);
      }
export function useDataLoadedAvailabilityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.DataLoadedAvailabilityQuery, Types.DataLoadedAvailabilityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.DataLoadedAvailabilityQuery, Types.DataLoadedAvailabilityQueryVariables>(DataLoadedAvailabilityDocument, options);
        }
export type DataLoadedAvailabilityQueryHookResult = ReturnType<typeof useDataLoadedAvailabilityQuery>;
export type DataLoadedAvailabilityLazyQueryHookResult = ReturnType<typeof useDataLoadedAvailabilityLazyQuery>;
export type DataLoadedAvailabilityQueryResult = Apollo.QueryResult<Types.DataLoadedAvailabilityQuery, Types.DataLoadedAvailabilityQueryVariables>;
export const GetProductVariantSkuByIdDocument = gql`
    query getProductVariantSKUById($id: ID!) {
  variant: node(id: $id) {
    ... on ProductVariant {
      selectedOptions {
        name
        value
      }
      product {
        sku: metafield(namespace: "sprenger", key: "sku") {
          id
          value
        }
        justSku: metafield(key: "just_sku", namespace: "sprenger") {
          key
          value
        }
        collections(first: 100) {
          edges {
            node {
              title
              description
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetProductVariantSkuByIdQuery__
 *
 * To run a query within a React component, call `useGetProductVariantSkuByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductVariantSkuByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductVariantSkuByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetProductVariantSkuByIdQuery(baseOptions: Apollo.QueryHookOptions<Types.GetProductVariantSkuByIdQuery, Types.GetProductVariantSkuByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetProductVariantSkuByIdQuery, Types.GetProductVariantSkuByIdQueryVariables>(GetProductVariantSkuByIdDocument, options);
      }
export function useGetProductVariantSkuByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetProductVariantSkuByIdQuery, Types.GetProductVariantSkuByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetProductVariantSkuByIdQuery, Types.GetProductVariantSkuByIdQueryVariables>(GetProductVariantSkuByIdDocument, options);
        }
export type GetProductVariantSkuByIdQueryHookResult = ReturnType<typeof useGetProductVariantSkuByIdQuery>;
export type GetProductVariantSkuByIdLazyQueryHookResult = ReturnType<typeof useGetProductVariantSkuByIdLazyQuery>;
export type GetProductVariantSkuByIdQueryResult = Apollo.QueryResult<Types.GetProductVariantSkuByIdQuery, Types.GetProductVariantSkuByIdQueryVariables>;
export const ProductsDocument = gql`
    query Products($first: Int!, $after: String, $country: CountryCode, $language: LanguageCode) @inContext(country: $country, language: $language) {
  products(first: $first, after: $after) {
    edges {
      node {
        id
        handle
        description
        availableForSale
        priceRange {
          maxVariantPrice {
            amount
            currencyCode
          }
          minVariantPrice {
            amount
            currencyCode
          }
        }
        compareAtPriceRange {
          maxVariantPrice {
            amount
            currencyCode
          }
          minVariantPrice {
            amount
            currencyCode
          }
        }
        dreid: metafield(key: "dreid", namespace: "sprenger") {
          key
          value
        }
        care_d2c: metafield(key: "care_d2c", namespace: "sprenger") {
          key
          value
        }
        content_d2c: metafield(key: "content_d2c", namespace: "sprenger") {
          key
          value
        }
        crossSellReferences: metafield(
          key: "crosssell_references"
          namespace: "sprenger"
        ) {
          key
          value
        }
        faq_d2c: metafield(key: "faq_d2c", namespace: "sprenger") {
          key
          value
        }
        upSellReferences: metafield(key: "upsell_references", namespace: "sprenger") {
          key
          value
        }
        video: metafield(key: "video", namespace: "sprenger") {
          key
          value
        }
        upSellSkus: metafield(key: "upsell_skus", namespace: "sprenger") {
          key
          value
        }
        crossSellSkus: metafield(key: "crosssell_skus", namespace: "sprenger") {
          key
          value
        }
        sku: metafield(key: "sku", namespace: "sprenger") {
          key
          value
        }
        colormap: metafield(key: "colormap", namespace: "sprenger") {
          key
          value
        }
        highlights: metafield(key: "highlights", namespace: "sprenger") {
          key
          value
        }
        bulletPoints: metafield(key: "bullets", namespace: "sprenger") {
          key
          value
        }
        measurement_table: metafield(key: "measurement_table", namespace: "sprenger") {
          key
          value
        }
        measure_d2c: metafield(key: "measure_d2c", namespace: "sprenger") {
          key
          value
        }
        material_d2c: metafield(key: "material_d2c", namespace: "sprenger") {
          key
          value
        }
        image_urls: metafield(key: "image_urls", namespace: "sprenger") {
          key
          value
        }
        variants(first: 10) {
          edges {
            node {
              id
              colormap: metafield(key: "colormap", namespace: "sprenger") {
                key
                value
              }
              isArchived: metafield(key: "is_archived", namespace: "sprenger") {
                key
                value
              }
              technical_data: metafield(key: "technical_data", namespace: "sprenger") {
                key
                value
              }
              quantityAvailable
              priceV2 {
                amount
                currencyCode
              }
              compareAtPriceV2 {
                amount
                currencyCode
              }
            }
          }
        }
        collections(first: 100) {
          edges {
            node {
              title
              description
            }
          }
        }
        title
        tags
        publishedAt
      }
      cursor
    }
    pageInfo {
      hasNextPage
    }
  }
}
    `;

/**
 * __useProductsQuery__
 *
 * To run a query within a React component, call `useProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      country: // value for 'country'
 *      language: // value for 'language'
 *   },
 * });
 */
export function useProductsQuery(baseOptions: Apollo.QueryHookOptions<Types.ProductsQuery, Types.ProductsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ProductsQuery, Types.ProductsQueryVariables>(ProductsDocument, options);
      }
export function useProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ProductsQuery, Types.ProductsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ProductsQuery, Types.ProductsQueryVariables>(ProductsDocument, options);
        }
export type ProductsQueryHookResult = ReturnType<typeof useProductsQuery>;
export type ProductsLazyQueryHookResult = ReturnType<typeof useProductsLazyQuery>;
export type ProductsQueryResult = Apollo.QueryResult<Types.ProductsQuery, Types.ProductsQueryVariables>;
export const ProductHandlesDocument = gql`
    query ProductHandles($first: Int!, $after: String) {
  products(first: $first, after: $after) {
    edges {
      node {
        handle
        collections(first: 100) {
          edges {
            node {
              title
            }
          }
        }
      }
    }
    pageInfo {
      hasNextPage
      endCursor
    }
  }
}
    `;

/**
 * __useProductHandlesQuery__
 *
 * To run a query within a React component, call `useProductHandlesQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductHandlesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductHandlesQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useProductHandlesQuery(baseOptions: Apollo.QueryHookOptions<Types.ProductHandlesQuery, Types.ProductHandlesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ProductHandlesQuery, Types.ProductHandlesQueryVariables>(ProductHandlesDocument, options);
      }
export function useProductHandlesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ProductHandlesQuery, Types.ProductHandlesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ProductHandlesQuery, Types.ProductHandlesQueryVariables>(ProductHandlesDocument, options);
        }
export type ProductHandlesQueryHookResult = ReturnType<typeof useProductHandlesQuery>;
export type ProductHandlesLazyQueryHookResult = ReturnType<typeof useProductHandlesLazyQuery>;
export type ProductHandlesQueryResult = Apollo.QueryResult<Types.ProductHandlesQuery, Types.ProductHandlesQueryVariables>;
export const ShopDataDocument = gql`
    query ShopData($country: CountryCode, $language: LanguageCode) @inContext(country: $country, language: $language) {
  shop {
    category_tree: metafield(key: "category_tree", namespace: "sprenger") {
      value
      id
    }
  }
}
    `;

/**
 * __useShopDataQuery__
 *
 * To run a query within a React component, call `useShopDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useShopDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShopDataQuery({
 *   variables: {
 *      country: // value for 'country'
 *      language: // value for 'language'
 *   },
 * });
 */
export function useShopDataQuery(baseOptions?: Apollo.QueryHookOptions<Types.ShopDataQuery, Types.ShopDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ShopDataQuery, Types.ShopDataQueryVariables>(ShopDataDocument, options);
      }
export function useShopDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ShopDataQuery, Types.ShopDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ShopDataQuery, Types.ShopDataQueryVariables>(ShopDataDocument, options);
        }
export type ShopDataQueryHookResult = ReturnType<typeof useShopDataQuery>;
export type ShopDataLazyQueryHookResult = ReturnType<typeof useShopDataLazyQuery>;
export type ShopDataQueryResult = Apollo.QueryResult<Types.ShopDataQuery, Types.ShopDataQueryVariables>;