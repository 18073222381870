import { useEffect, useState } from 'react';
import { ButtonPrimary } from '@components/Button/Primary';
import { FormFieldCheckbox } from '@components/Form/Field/Checkbox';
import { useCustomerCreateMutation } from '@generated/graphql/apollo';
import { useCustomerAccessToken } from '@hooks/useCustomerAccessToken';
import { SubmitHandler, useForm } from 'react-hook-form';
import {
  CustomerAccessToken,
  CustomerCreateInput,
  CustomerUserError,
} from '@generated/graphql/types';
import { useTranslate } from '@tolgee/react';
import { FormFieldTextInput } from '@components/Form/Field/TextInput';
import { base64Encode } from '@lib/base64';
import { useLocale } from '@zustand/useLocale';
import { ensureLanguage } from '@lib/utils';

type Inputs = Omit<CustomerCreateInput, 'email'> & { customerEmail: string };

interface SignupArgs {
  onSuccess?: () => void;
  customerEmail?: string | null;
}

const Signup = ({ onSuccess, customerEmail }: SignupArgs) => {
  const [createCustomer, { data, loading }] = useCustomerCreateMutation();
  const [, , { login }] = useCustomerAccessToken();
  const { locale, language } = useLocale();
  const [fetchErrors, setFetchErrors] = useState<Array<CustomerUserError>>([]);
  const { t } = useTranslate();

  const {
    register,
    handleSubmit,
    getValues,
    watch,
    formState: { errors },
  } = useForm<Inputs>({
    defaultValues: {
      customerEmail: customerEmail || '',
    },
  });

  const onSubmit: SubmitHandler<Inputs> = async (formData) => {
    const input = { ...formData, email: formData.customerEmail };
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    delete input.customerEmail;
    const res = await createCustomer({
      variables: {
        input,
        language: ensureLanguage(language),
        country: ensureLanguage(locale),
      },
    });
    if (res.data?.customerCreate?.customer?.id) {
      await fetch(`/api/customer/create/${base64Encode(res.data.customerCreate.customer.id)}`, {
        body: JSON.stringify({ locale: language }),
        method: 'POST',
      });
    }

    if (
      res.data?.customerCreate?.customerUserErrors &&
      res.data.customerCreate.customerUserErrors.length > 0
    ) {
      setFetchErrors(res.data.customerCreate.customerUserErrors);
    } else {
      setFetchErrors([]);
    }
  };

  useEffect(() => {
    if (data?.customerCreate?.customer?.id) {
      login(getValues().customerEmail, getValues().password)
        .then((r) => {
          if ((r as CustomerAccessToken).accessToken) {
            onSuccess?.();
          }
        })
        .catch(console.log);
    }
  }, [data]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="flex flex-col gap-6">
        {!customerEmail && (
          <FormFieldTextInput
            label={t('customer.email')}
            helperText={t('forgotPassword.helper')}
            type="email"
            name="customerEmail"
            formRegister={register}
            formWatch={watch}
            errors={errors}
            required
          />
        )}

        <FormFieldTextInput
          label={t('changeEmail.form.password')}
          type="password"
          name="password"
          formRegister={register}
          formWatch={watch}
          errors={errors}
          required
        />

        <FormFieldCheckbox
          name="acceptsMarketing"
          formRegister={register}
          formWatch={watch}
          errors={errors}
        >
          <span className="font-normal text-p-14">{t('signUp.acceptsMarketing')}</span>
        </FormFieldCheckbox>

        <p className="text-gray-500 text-p-12">{t('signUp.privacy')}</p>
      </div>
      <div className="flex flex-col gap-6 pt-6">
        {fetchErrors.length ? (
          <div className="text-special-danger">
            {fetchErrors
              .filter((e) => !!e.code)
              .map((err, index) => (
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                <div key={index}>{t(`customerErrorCodeMapping.${err.code!}`)}</div>
              ))}
          </div>
        ) : (
          <></>
        )}
        <div className="flex justify-end">
          <ButtonPrimary
            className="w-full"
            type="submit"
            loading={loading}
            text={t('signUp.submitButton')}
          />
        </div>
      </div>
    </form>
  );
};

export default Signup;
