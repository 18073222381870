import { VariantsByParentQuery } from '@generated/graphql/types';
import { deduplicate, image_urlsAsArray, isColorProp, isEqual, mapToNode } from '@lib/utils';
import classNames from 'classnames';
import { useAvailableColors } from '@zustand/filters';

type ProductCardColorPickerProps = {
  onColorChange: (propName: string, colorName: string) => void;
  onImageChange: (imageUrls: string[]) => void;
  onVariantSelected: (variantSku: string) => void;
  currentSelectedOption?: { name: string; value: string | number }[];
  options: NonNullable<NonNullable<VariantsByParentQuery['product']>['options']>;
  variantData?: VariantsByParentQuery;
  variantSku?: string;
};

type ColorData = {
  variantSku: string[];
  data: any;
};

const ProductCardColorPicker = ({
  currentSelectedOption,
  options,
  onColorChange,
  onVariantSelected,
  onImageChange,
  variantData,
  variantSku,
}: ProductCardColorPickerProps) => {
  const isMultiColor = !!options && options?.filter((o) => isColorProp(o.name)).length > 1;
  const insertColor = useAvailableColors((store) => store.insertColor);
  const colorMap = variantData?.product?.colormap?.value
    ? JSON.parse(variantData?.product?.colormap?.value)
    : undefined;
  const colorFromMap = (color: string) => {
    return colorMap?.[color] ?? '#ffffff';
  };

  // Default way for items with two color properties
  if (isMultiColor) {
    if (!variantData?.product?.variants) {
      return <div className="flex flex-wrap justify-center gap-2" />;
    }
    const mappedVariants = mapToNode(variantData.product.variants).map((node) => ({
      id: node.id,
      imageUrls: image_urlsAsArray(node.image_urls?.value),
      options: node.selectedOptions.filter(onlyColor),
      isArchived: node.isArchived?.value === 'true',
      sku: node.sku,
    }));
    const variants = mappedVariants
      // @ts-ignore
      .reduce((acc, curr) => {
        // @ts-ignore
        const existing = (Array.isArray(acc) ? acc : []).find((e) =>
          isEqual(e.data.options, curr.options),
        );
        if (existing) {
          return [
            // @ts-ignore
            ...acc.filter((e) => !isEqual(e.data.options, curr.options)),
            {
              ...existing,
              variantSku: [...existing.variantSku, curr.sku?.value],
            },
          ];
        }
        return [
          // @ts-ignore
          ...(Array.isArray(acc) ? acc : []),
          {
            variantSku: [curr.sku?.value],
            data: curr,
          },
        ];
      })
      // @ts-ignore
      .filter((e) => !e.isArchived);
    return (
      <div className="flex flex-wrap items-center justify-center gap-2">
        {
          // @ts-ignore
          variants.map(({ data: { id, options: variant, imageUrls }, variantSku: skuData }, i) => {
            const firstVariantElement = variant[0];
            const firstColorName = firstVariantElement.value;
            const firstColorPropName = firstVariantElement.name;
            const firstColor = colorFromMap(firstColorName) ?? '#000000';

            const secondVariantElement = variant[1];
            const secondColorName = secondVariantElement.value;
            const secondColorPropName = secondVariantElement.name;
            const secondColor = colorFromMap(secondColorName) ?? '#000000';

            if (firstColorName === secondColorName && firstColor === secondColor) {
              insertColor(firstColorName, firstColor);
            } else {
              insertColor(`${firstColorName}-${secondColorName}`, firstColor, secondColor);
            }

            const isSelected = skuData.includes(variantSku);
            return (
              <button
                key={`${JSON.stringify(firstVariantElement)}-${JSON.stringify(
                  secondVariantElement,
                )}-${i}-${isSelected}`}
                title={`${firstColorName} / ${secondColorName}`}
                onClick={() => {
                  onColorChange(firstColorPropName, firstColorName);
                  onColorChange(secondColorPropName, secondColorName);
                  onImageChange(imageUrls);
                  onVariantSelected(id);
                }}
                className={classNames(
                  'rounded-full flex items-center aspect-square',
                  isSelected
                    ? 'border-accent-primary border-2 -m-[1px]'
                    : 'border border-accent-primary_005 hover:border-accent-primary_02',
                )}
              >
                <div
                  className="rounded-l-full h-5 w-2.5"
                  style={{ backgroundColor: firstColor }}
                ></div>
                <div
                  className="rounded-r-full h-5 w-2.5"
                  style={{ backgroundColor: secondColor }}
                ></div>
              </button>
            );
          })
        }
      </div>
    );
  }

  const selected = mapToNode(variantData?.product?.variants).find(({ sku }) => variantSku === sku?.value);
  // console.log(selected, selected.includes(variantSku), variantSku, variantData?.product?.variants);

  return (
    <div className="flex flex-wrap items-center justify-center gap-2">
      {Object.entries(colorMap ?? {})
        .filter(([key]) => !key.includes('-') && !key.includes('/'))
        .map(([colorName, hex], i) => {
          if (!colorName || (!hex && !colorName.includes('-')) || typeof hex !== 'string') {
            return null;
          }
          insertColor(colorName, hex);
          const variantsWithCorrectColor = mapToNode(variantData?.product?.variants).filter((item, count) =>
            item.selectedOptions.some(
              (option) => option.name === 'Farbe' && option.value.includes(colorName),
            ),
          ) ?? { sku: undefined, id: undefined };
          const isSelected = selected?.selectedOptions.find((prop) => isColorProp(prop.name) && prop.value === colorName);
          const id = variantsWithCorrectColor.length > 0 && variantsWithCorrectColor[0].id;

          return (
            <button
              key={`${i}`}
              onClick={() => {

                onColorChange('Farbe', colorName);
                onImageChange(
                  image_urlsAsArray(
                    variantData?.product?.variants?.edges?.find((item) => item.node.id === id)?.node
                      ?.image_urls?.value,
                  ),
                );
                if (id) {
                  onVariantSelected(id || '');
                }
              }}
              className={classNames(
                'rounded-full aspect-square',
                isSelected
                  ? 'border-accent-primary border-2 -m-[1px]'
                  : 'border border-accent-primary_005 hover:border-accent-primary_02',
              )}
            >
              <div className="rounded-full h-5 w-5" style={{ backgroundColor: hex }}></div>
            </button>
          );
        })}
    </div>
  );
};

const onlyColor = (
  option: ArrayElementOf<NonNullable<ProductCardColorPickerProps['currentSelectedOption']>>,
) => isColorProp(option.name);

export default ProductCardColorPicker;
