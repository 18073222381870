import DynamicComponent from '../DynamicComponent';
import { BoxProps, DynamicComponentBlok } from '@customTypes/storyblok-types';
import { storyblokEditable } from '@storyblok/react';

export const Section = ({ blok }: { blok: BoxProps }) => (
  <div
    /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
    /* @ts-ignore*/
    {...storyblokEditable(blok)}
    className="w-full"
  >
    {blok?.items?.map((subBlok: DynamicComponentBlok) => (
      <DynamicComponent blok={subBlok} key={subBlok._uid} />
    ))}
  </div>
);
