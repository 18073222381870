import { FeatureFlag, isEnabledFlag } from './feature';

export const SHOP_URL = 'https://hs-sprenger.myshopify.com';
export const SUPPORTED_LANGUAGES = isEnabledFlag(FeatureFlag.MULTILANG)
  ? process.env.NEXT_PUBLIC_SUPPORTED_LANGUAGES?.split(',') ?? ['de', 'en']
  : ['de'];
export const SUPPORTED_COUNTRIES = process.env.NEXT_PUBLIC_SHOPIFY_LOCALES?.split(',') ?? ['DE'];
export type SupportedLanguages = ElementType<typeof SUPPORTED_LANGUAGES>;
export const AB_TEST_COOKIE_NAME = 'shop_sprenger_ab_optimize';
export const LANGUAGE_AND_REGION_COOKIE_NAME = 'shop_sprenger_language_region';
