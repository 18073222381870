import { useTranslate } from '@tolgee/react';
import classNames from 'classnames';
import { ReactNode } from 'react';
import { FormFieldConfig, FormFieldRequiredProps } from '.';

type FormFieldCheckboxProps = {
  children: ReactNode;
  className?: string;
  disabled?: boolean;
} & FormFieldRequiredProps &
  FormFieldConfig;

export const FormFieldCheckbox = ({
  formRegister,
  name,
  errors,
  required,
  requiredMessage,
  children,
  className,
  disabled,
}: FormFieldCheckboxProps) => {
  const error = errors[name];
  const { t } = useTranslate();

  return (
    <div className={classNames('space-y-2', className)}>
      <div className="flex items-start space-x-2 select-none">
        <input
          id={name}
          type="checkbox"
          disabled={disabled}
          className={classNames(
            'w-5 h-5 rounded focus:shadow-none cursor-pointer text-accent-primary hover:border-dark-secondary disabled:hover:border-dark-background disabled:text-dark-background disabled:border-dark-background focus:outline-accent-primary_01 focus:shadow-none',
            error
              ? 'text-special-danger border-special-danger focus:outline-special-danger focus:border-special-danger'
              : '',
          )}
          aria-invalid={!!error}
          aria-describedby={`${name}-error`}
          {...formRegister(name, {
            required: {
              message: requiredMessage || t('formField.selectionRequired'),
              value: required || false,
            },
          })}
        />

        <label htmlFor={name} className="cursor-pointer text-p-14 text-dark-primary">
          {children}
        </label>
      </div>

      {error?.message && (
        <p className="text-special-danger text-p-12" id={`${name}-error`}>
          {error.message as string}
        </p>
      )}
    </div>
  );
};
