import { Mastercard, Maestro, Visa, Sofort, Paypal, Klarna } from '@components/icons';
import classNames from 'classnames';
import { T } from '@tolgee/react';

const PaymentMethods = ({ className }: { className?: string }) => (
  <div className={classNames('flex flex-col gap-2', className)}>
    <span className="text-h-16">
      <T keyName="footer_paymentmethods_headline" />
    </span>
    <div className="flex gap-1">
      <Mastercard className="w-11" />
      <Visa className="w-11" />
      <Maestro className="w-11" />
      <Paypal className="w-11" />
      <Klarna className="w-11" />
      <Sofort className="w-11" />
    </div>
  </div>
);

export default PaymentMethods;
