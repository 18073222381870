import { useEffect, useState } from 'react';
import { BaseService } from './useUserCentricsInteracted';

/**
 * Hook to return if the service with the given name has consent or not.
 */
const useHasConsent = (name: string) => {
  const [hasConsent, setHasConsent] = useState<boolean>();

  const updateGTMConsent = () => {
    const baseServiceInfo = (window as any).UC_UI?.getServicesBaseInfo() as Array<BaseService>;
    const newConsent =
      baseServiceInfo?.find((service) => service.name === name)?.consent.status ?? false;
    if (newConsent !== hasConsent) {
      setHasConsent(newConsent);
    }
  };
  if (typeof window !== 'undefined') {
    window.addEventListener('UC_UI_INITIALIZED', () => {
      updateGTMConsent();
    });
    window.addEventListener('UC_UI_CMP_EVENT', () => {
      updateGTMConsent();
    });
  }

  useEffect(() => {
    updateGTMConsent();
  }, []);

  return hasConsent;
};

export default useHasConsent;
