import classNames from 'classnames';
import { Button, ButtonProps } from '.';

export const ButtonSecondary = ({ className, ...props }: ButtonProps) => (
  <Button
    className={classNames(
      'text-accent-primary border border-transparent bg-transparent uppercase',
      !props.disabled &&
        'hover:text-dark-secondary hover:border-dark-secondary focus:text-dark-secondary focus:border-dark-secondary focus:!outline-dark-quaternary active:text-dark-secondary active:border-dark-primary active:outline-none',
      className,
    )}
    {...props}
  />
);
