import { StoryblokImage, withBlur } from '@components/Image/StoryblokImage';
import { ImageStoryblok, LinkStoryblok } from '@customTypes/storyblok-types';
import Link from 'next/link';
import useBreakpoint from '../../hooks/useBreakpoint';

interface EditorialSectionTenProps {
  headline: string;
  title_1: string;
  title_2: string;
  title_3?: string;
  link_1?: LinkStoryblok;
  link_2?: LinkStoryblok;
  link_3?: LinkStoryblok;
  image_1?: ImageStoryblok;
  image_2?: ImageStoryblok;
  image_3?: ImageStoryblok;
}

export const EditorialSectionTen = ({
  headline,
  title_1,
  title_2,
  title_3,
  link_1,
  link_2,
  link_3,
  image_1,
  image_2,
  image_3,
}: EditorialSectionTenProps) => {
  const titles = [title_1, title_2, title_3];
  const links = [link_1, link_2, link_3];
  const images = [image_1, image_2, image_3];
  const isMobile = useBreakpoint();
  return (
    <div className="flex flex-col md:py-16">
      <h2 className="ml-3 md:ml-11 mb-4">{headline}</h2>
      <div className="flex flex-col md:flex-row relative w-full">
        {images.map(
          (image, index) =>
            image?.filename &&
            links[index] && (
              <Link key={index} href={`${links[index]?.cached_url}` || ''} passHref>
                <div
                  key={index}
                  className="flex md:h-auto h-48 w-full flex-1 flex-col grow relative aspect-[375/200] sm:aspect-[1.2]"
                >
                  {image.filename && (
                    <div
                      key={index}
                      className="flex-1 relative transition-all duration-1000 hover:cursor-pointer hover:brightness-[0.75]"
                    >
                      <StoryblokImage
                        className="relative scale-100 transition-all duration-1000 ease-in-out transform hover:scale-[1.125]"
                        src={image.filename}
                        placeholder="blur"
                        focus={image.focus}
                        blurDataURL={withBlur(image.filename)}
                        alt={image.alt}
                        layout="fill"
                        sizes={isMobile ? '100vw' : '34vw'}
                        objectFit="cover"
                      />
                    </div>
                  )}
                  {titles[index] && (
                    <h2
                      key={titles[index]}
                      className="pointer-events-none md:text-h-32 text-h-16 flex-1 text-white absolute bottom-2 md:bottom-[24px] md:pd-0 mt-[-60px] ml-3 md:ml-11 z-10 hyphens-auto"
                    >
                      {titles[index]}
                    </h2>
                  )}
                </div>
              </Link>
            ),
        )}
      </div>
    </div>
  );
};
