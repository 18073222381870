import { create } from 'zustand';

export const useRouterHistory = create<{
  history: Array<string>;
  addPath: (path: string) => void;
}>((set) => ({
  history: [],
  addPath: (path: string) =>
    set((state) => ({
      history:
        path.split('?')[0] !== state.history[0]
          ? [path.split('?')[0], ...state.history]
          : state.history,
    })),
}));
