import Minus from '@components/generated/icons/Minus';
import Plus from '@components/generated/icons/Plus';
import { useTranslate } from '@tolgee/react';
import classNames from '@utils/ClassNames';
import { useEffect, useRef, useState } from 'react';
import { useCartLoadingOverlayZustand } from '@zustand/cartLoadingOverlay';

interface AmountSelectionProps {
  className?: string;
  inventory: number | null | undefined;
  availableForSale?: boolean;
  initialAmount?: number;
  onChange?: (value: number) => void;
  inputWidthClassName?: string;
  shouldDebounce?: boolean;
  isADHSEnabled?: boolean;
}

enum AmountAction {
  Increment,
  Decrement,
}

export const AmountSelection = ({
  className,
  inventory,
  availableForSale,
  initialAmount,
  inputWidthClassName,
  shouldDebounce = false,
  isADHSEnabled = false,
  onChange = (v) => v,
}: AmountSelectionProps) => {
  const [amount, setAmount] = useState<string | number>(initialAmount ? initialAmount : 1);
  const amountSelectionDisabled = !availableForSale || inventory === 0 || !inventory;
  const updater = useRef<number | undefined>(undefined);
  const [hasADHSLock, setHasADHSLock] = useState(false);
  const { adhsMode, enableADHS, shown } = useCartLoadingOverlayZustand();
  const { t } = useTranslate();

  const shouldDisableBecauseOfADHS = shown ? true : adhsMode ? !hasADHSLock : false;

  useEffect(() => {
    if (initialAmount === amount) {
      return;
    }
    if (shouldDisableBecauseOfADHS) {
      return;
    }
    if (isADHSEnabled) {
      setHasADHSLock(true);
      enableADHS();
    }
    if (shouldDebounce) {
      clearTimeout(updater.current);
      updater.current = setTimeout(() => {
        onChange(Number(amount));
      }, 1000) as unknown as number;
    } else {
      onChange(Number(amount));
    }
  }, [amount]);

  useEffect(() => {
    if (typeof initialAmount !== 'undefined') {
      setAmount(initialAmount);
    }
  }, [initialAmount]);

  const handleChange = (event: { target: { value: string } }) => {
    const result = event.target.value.replace(/\D/g, '');

    if (result === '') {
      setAmount('');
      return;
    }

    let numResult = Number(result);
    if (isNaN(numResult) || numResult <= 0) {
      numResult = 1;
    }
    if (inventory) {
      numResult = numResult > inventory ? inventory : numResult;
    }

    setAmount(numResult);
  };

  const exec = (action: AmountAction) => {
    switch (action) {
      case AmountAction.Increment:
        handleChange({ target: { value: `${Number(amount) + 1}` } });
        break;
      case AmountAction.Decrement:
        handleChange({ target: { value: `${Number(amount) - 1}` } });
        break;
      default:
        throw new Error('Unreachable');
    }
  };

  const _amount = Number(amount);
  const realAmount = isNaN(_amount) ? 0 : _amount;

  const decrementDisabled =
    (inventory ? amountSelectionDisabled || realAmount <= 1 : amountSelectionDisabled) ||
    amount === '' ||
    shouldDisableBecauseOfADHS;
  const incrementDisabled =
    (inventory ? realAmount >= inventory || amountSelectionDisabled : amountSelectionDisabled) ||
    amount === '' ||
    shouldDisableBecauseOfADHS;

  return (
    <div className={className}>
      <div
        className={classNames(
          'flex flex-row border border-colors-dark-quaternary rounded-md focus:outline-1 focus-within:border-accent-pressed justify-between',
          amountSelectionDisabled ? 'bg-dark-background' : '',
          !decrementDisabled && 'pl-1',
          !incrementDisabled && 'pr-1',
        )}
      >
        <button
          className={classNames(
            'select-none rounded px-2 justify-self-start ml-0 text-p-24 grow-0 border-none',
            decrementDisabled
              ? 'bg-dark-05 cursor-default rounded-r-none px-[10px]'
              : 'hover:bg-dark-05 my-1',
          )}
          type="button"
          disabled={amountSelectionDisabled || amount === 1}
          onClick={() => exec(AmountAction.Decrement)}
          data-test="decrement-button"
        >
          <Minus className={classNames('w-4 h-4', decrementDisabled ? 'text-dark-tertiary' : '')} />
        </button>

        <input
          className={classNames(
            'border-0 m-0 text-center outline-0 outline-none focus:outline-0 self-stretch flex-1 shrink-0 amount-selection-outline bg-transparent',
            amountSelectionDisabled ? 'text-dark-tertiary' : '',
            inputWidthClassName,
          )}
          onBlur={() => amount === '' && setAmount(1)}
          type="text"
          disabled={amountSelectionDisabled}
          placeholder={t('product.quantity')}
          value={amount}
          onChange={handleChange}
        />
        <button
          className={classNames(
            'rounded select-none justify-self-end mr-0 px-2 grow-0',
            incrementDisabled
              ? 'bg-dark-05 cursor-default rounded-l-none px-[10px]'
              : 'hover:bg-dark-05 my-1',
          )}
          type="button"
          disabled={amountSelectionDisabled || amount === inventory}
          onClick={() => exec(AmountAction.Increment)}
          data-test="increment-button"
        >
          <Plus className={classNames('w-4 h-4', incrementDisabled ? 'text-dark-tertiary' : '')} />
        </button>
      </div>
    </div>
  );
};
