import { GtmEvent, GtmEventType, ProductDetails, ProductVariantDetails } from './types';

interface ProductViewed {
  productDetails: ProductDetails;
}
export const getGtmPayloadProductViewed = ({
  productDetails,
}: ProductViewed): ProductViewed & GtmEvent => ({
  event: GtmEventType.ProductViewed,
  productDetails,
});

interface ProductShared {
  productDetails: ProductDetails;
  productSharedTo: 'fb' | 'pinterest' | 'link' | 'mail' | 'mobile_na';
}
export const getGtmPayloadProductShared = ({
  productDetails,
  productSharedTo,
}: ProductShared): ProductShared & GtmEvent => ({
  event: GtmEventType.ProductShared,
  productDetails,
  productSharedTo,
});

interface ProductAddedToCart {
  productVariantDetails: ProductVariantDetails;
  fbData?: any;
}
export const getGtmPayloadProductAddedToCart = ({
  productVariantDetails,
  fbData,
}: ProductAddedToCart): ProductAddedToCart & GtmEvent => ({
  event: GtmEventType.ProductAddedToCart,
  productVariantDetails,
  fbData,
});

interface ProductFAQ {
  productDetails: ProductDetails;
}
export const getGtmPayloadProductFAQ = ({ productDetails }: ProductFAQ): ProductFAQ & GtmEvent => ({
  event: GtmEventType.ProductFAQ,
  productDetails,
});

interface ProductNotifcationSignUp {
  productVariantDetails: ProductVariantDetails;
}
export const getGtmPayloadProductNotifcationSignUp = ({
  productVariantDetails,
}: ProductNotifcationSignUp): ProductNotifcationSignUp & GtmEvent => ({
  event: GtmEventType.ProductNotificationSignUp,
  productVariantDetails,
});
