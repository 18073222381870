import { useShopDataQuery } from '@generated/graphql/apollo';
import { useEffect } from 'react';
import { ShopDataState, useShopDataZustand } from '@zustand/shopData';
import { useDidMount } from '@hooks/useDidMount';
import { ensureLanguage } from '@lib/utils';
import { useLocale } from '@zustand/useLocale';

export const useShopData: () => ShopDataState = () => {
  const { setShopData, shopData } = useShopDataZustand();
  const didMount = useDidMount();
  const { locale, language } = useLocale();

  const { data, error } = useShopDataQuery({
    variables: {
      language: ensureLanguage(language),
      country: ensureLanguage(locale),
    },
  });

  useEffect(() => {
    if (didMount) {
      if (error) {
        console.error('error while fetching shop data:', error);
      } else if (data) {
        setShopData(data?.shop || null);
      }
    }
  }, [data, error, didMount]);
  return shopData;
};
