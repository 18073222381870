import { ContentBlock8Props } from '@customTypes/storyblok-types';
import { EditorialSectionEight } from '@components/EditorialSection/Eight';
import { storyblokEditable } from '@storyblok/react';

export const ContentBlock8 = ({ blok }: { blok: ContentBlock8Props }) => {
  return (
    <EditorialSectionEight
      /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
      /* @ts-ignore*/
      {...storyblokEditable(blok)}
      title={blok.title}
      image={blok.image}
      parallax={blok.image_parallax}
      logo={blok.logo}
      content_branch={blok.content_branch}
      layout={blok.image_emphasis}
      shopTheLook={blok.shopTheLook}
      split_headline={blok.split_headline}
    />
  );
};
