import { create } from 'zustand';

type ScrollAtStartState = {
  scrollAtStart: boolean;
  setScrollAtStart: (scrollAtStart: boolean) => void;
};

export const useScrollAtStartZustand = create<ScrollAtStartState>((set) => ({
  scrollAtStart: true,
  setScrollAtStart: (scrollAtStart: boolean) => set((state) => ({ ...state, scrollAtStart })),
}));
