import React, { useEffect, useState } from 'react';
import Link from 'next/link';
import { T, useTranslate } from '@tolgee/react';
import useBreakpoint, { Breakpoints } from '@hooks/useBreakpoint';
import { useRouter } from 'next/router';
import LogoHorizontal from '@components/generated/logos/LogoHorizontal';
import { LogoSmall } from '@components/icons';
import NewsletterSignUp, { NewsLetterSignUpInput } from './NewsletterSignUp';
import { subscribe, SubscriptionList } from '@lib/newsletter';
import PaymentMethods from './PaymentMethods';
import { useLocale } from '@zustand/useLocale';
import { SUPPORTED_LANGUAGES } from '@lib/constants';
import { FeatureFlag, isEnabledFlag } from '@lib/feature';
import { SimpleSelection } from '@components/inputs/SimpleSelection';
import { B2BLinkLocation, PostHogEvent, track } from '@lib/posthog';
import { NOTIFICATION, NOTIFICATION_DURATION, useNotifications } from '@lib/NotificationContext';

type UniqueSellingPointProps = {
  title: string;
  subtitle: string;
};

interface StoryDataContent {
  data: {
    stories: {
      content: {
        items: Array<{
          title: string;
          description: string;
          _uid: string;
        }>;
      };
    };
  };
}

export const LayoutFooter: React.FC = () => {
  const { t } = useTranslate();
  const { language, locale } = useLocale();
  const notifications = useNotifications();
  const isNotLarge = useBreakpoint(Breakpoints.xl);
  const isMobile = useBreakpoint();
  const router = useRouter();
  const [storyData, setStoryData] = useState<null | StoryDataContent>(null);

  async function signUpForNewsletter(data: NewsLetterSignUpInput): Promise<void> {
    if (/\S+@\S+\.\S+/.test(data.newsLetterEmail)) {
      const isEverythingSelected = !data.listDog && !data.listBoat && !data.listEquine;
      await subscribe(data.newsLetterEmail, 'newsletter', {
        listDog: isEverythingSelected || data.listDog,
        listBoat: isEverythingSelected || data.listBoat,
        listEquine: isEverythingSelected || data.listEquine,
      });
      await showVerifyEmail();
    }
  }

  const UniqueSellingPoint = ({ title, subtitle }: UniqueSellingPointProps) => (
    <div className="px-20 py-6 sm:py-[6rem] sm:px-24 md:px-16 flex flex-col h-full text-accent-primary">
      <div className="text-h-16 lg:h-16 text-center pb-1">{title}</div>
      <div className="text-p-16 font-[200] text-center">{subtitle}</div>
    </div>
  );

  const Contact = () => (
    <div className="flex flex-col">
      <div className="text-h-12 mb-1.5">
        <T keyName="footer.contact" />
      </div>
      <div className="text-p-12" dangerouslySetInnerHTML={{ __html: t('footer.address') }} />
    </div>
  );

  const showVerifyEmail = () =>
    notifications.setNotification({
      hideAfter: NOTIFICATION_DURATION.LONG,
      text: t('modals.newsletterSettings.confirm.content'),
      title: t('modals.newsletterSettings.confirm.title'),
      variant: NOTIFICATION.ALERT,
    });

  const FooterLinks = () => {
    const [shouldRenderLanguage, setShouldRenderLanguage] = useState(false);

    useEffect(() => {
      setShouldRenderLanguage(true);
    }, []);

    return (
      <div className="py-4 flex flex-col items-start md:items-center gap-x-9 gap-y-4 font-semibold uppercase md:flex-row md:flex-wrap text-h-12">
        <Link href="/imprint">
          <a className="hover:border-b border-b-white">
            <T keyName="footer.imprint" />
          </a>
        </Link>
        <Link href="/privacy">
          <a className="hover:border-b border-b-white">
            <T keyName="footer.privacyPolicy" />
          </a>
        </Link>
        <Link href="/agb">
          <a className="hover:border-b border-b-white">
            <T keyName="footer.abg" />
          </a>
        </Link>
        <Link href="/widerrufsrecht">
          <a className="hover:border-b border-b-white">
            <T keyName="footer.widerrufsrecht" />
          </a>
        </Link>
        <Link href="/zahlung-versand">
          <a className="hover:border-b border-b-white">
            <T keyName="footer.zahlungVersand" />
          </a>
        </Link>
        <Link href="https://shop-sprenger.returnsportal.online/">
          <a className="hover:border-b border-b-white">
            <T keyName="footer.refundPortal" />
          </a>
        </Link>
        <Link href="/about">
          <a className="hover:border-b border-b-white">
            <T keyName="footer.aboutUs" />
          </a>
        </Link>
        <Link href="https://sprenger.de">
          <a
            target="_blank"
            onClick={() => track(PostHogEvent.B2BLinkClicked, { location: B2BLinkLocation.Footer })}
            className="hover:border-b border-b-white"
          >
            <T keyName="links.b2b" />
          </a>
        </Link>
      </div>
    );
  };

  useEffect(() => {
    fetch('/api/storyblok/retrieve', {
      body: JSON.stringify({
        lang: language,
        path: `static/${locale.toLowerCase()}/footer`,
        slugs: undefined,
      }),
      method: 'POST',
    })
      .then((res) => res.json() as unknown as StoryDataContent)
      .then((res) => {
        if (res.data.stories.content) {
          setStoryData(res);
        }
      });
  }, [locale, language]);

  return (
    <footer aria-labelledby="footer-heading" className="">
      <div className="py-11 w-full bg-[#E8E9F6] grid md:grid-cols-2 lg:grid-cols-4 place-items-center">
        {storyData
          ? storyData.data.stories.content.items.map((e) => (
              <UniqueSellingPoint title={e.title} subtitle={e.description} key={e._uid} />
            ))
          : null}
      </div>
      <div className="flex flex-col bg-accent-primary text-white ">
        <div className="flex md:flex-row flex-col-reverse md:justify-evenly justify-center md:items-center py-10">
          {isNotLarge && !isMobile && <LogoSmall color="#FFFFFF" className="h-24 -ml-8 -mb-8" />}
          {!isNotLarge && !isMobile && (
            <LogoHorizontal color="#FFFFFF" className="h-14 justify-self-start" />
          )}
          {!isMobile && (
            <div className="flex flex-col gap-2">
              <Contact />
              <PaymentMethods className="mt-2" />
            </div>
          )}
          <div className="flex -mt-6 md:mt-0">
            {isMobile && <LogoSmall color="#FFFFFF" className="h-16 ml-2 mr-4" />}
            <div className={'flex flex-col md:mt-0 gap-6 w-full'}>
              <NewsletterSignUp onSignUp={signUpForNewsletter} />
              {isMobile && <PaymentMethods />}
              {isMobile && <Contact />}
            </div>
          </div>
        </div>
        <div className="pl-[5.05rem] md:pl-28 pr-3.5 md:px-[4.125rem]">
          <hr />
          <FooterLinks />
        </div>
      </div>
    </footer>
  );
};
