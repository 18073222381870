// eslint-disable-next-line max-classes-per-file

export enum Size {
  XXS = 'xxs',
  XS = 'xs',
  S = 's',
  M = 'm',
  L = 'l',
  XL = 'xl',
  XXL = 'xxl',
}

export enum FilterType {
  Length = 'length',
  Weight = 'weight',
  String = 'string',
  LengthRange = 'lengthRange',
  WeightRange = 'weightRange',
}

export enum SortBy {
  RELEVANCE,
  PRICE,
  ALPHABETICAL,
  POPULARITY,
  NEWEST,
}

export enum SortOrder {
  ASCENDING,
  DESCENDING,
}

export class SortOption {
  public readonly sortBy: SortBy;
  public readonly sortOrder: SortOrder;

  private constructor(sortBy: SortBy, sortOrder: SortOrder) {
    this.sortBy = sortBy;
    this.sortOrder = sortOrder;
  }

  /**
   * Returns the opposite sort order.
   */
  public get toggledOrder(): SortOption {
    return new SortOption(
      this.sortBy,
      this.sortOrder === SortOrder.ASCENDING ? SortOrder.DESCENDING : SortOrder.ASCENDING,
    );
  }

  public static create(sortBy: SortBy): SortOption {
    if (sortBy === SortBy.RELEVANCE || sortBy === SortBy.POPULARITY) {
      return new SortOption(sortBy, SortOrder.DESCENDING);
    }
    return new SortOption(sortBy, SortOrder.ASCENDING);
  }

  public static getSortText(sortBy: SortBy): string {
    switch (sortBy) {
      case SortBy.RELEVANCE:
        return 'Relevanz';
      case SortBy.PRICE:
        return 'Preis';
      case SortBy.ALPHABETICAL:
        return 'Alphabetisch';
      case SortBy.POPULARITY:
        return 'Popularität';
      case SortBy.NEWEST:
        return 'Neueste';
      default:
        return 'Unbekannt';
    }
  }

  private get fieldName(): string {
    switch (this.sortBy) {
      case SortBy.PRICE:
        return 'price';
      case SortBy.ALPHABETICAL:
        return 'parentTitle';
      case SortBy.NEWEST:
        return 'date';
      default:
        return 'popularityRank';
    }
  }

  private get orderString(): string {
    switch (this.sortOrder) {
      case SortOrder.ASCENDING:
        return 'asc';
      case SortOrder.DESCENDING:
        return 'desc';
      default:
        if (this.sortBy !== SortBy.RELEVANCE && this.sortBy !== SortBy.POPULARITY)
          return 'asc';
        return 'desc';
    }
  }

  public get shouldBeIncluded(): boolean {
    return true;
  }

  public get query(): string {
    return `${this.fieldName}:${this.orderString}`;
  }
}

const SEARCHABLE_NESTED_FIELD = 'searchableData';

export abstract class Filter {
  public readonly id: string;
  public readonly field: string;

  public static createFor(
    type: FilterGroupType,
    id: string,
    field: string,
    value: string | number | ColorSearchAbleData,
  ): Filter {
    switch (type) {
      case 'string':
        if (field.startsWith('collections')) {
          return new StringFilter(id, field, value as string, true);
        }
        return new StringFilter(id, field, value as string);
      case 'number':
      case 'length':
      case 'weight':
      case 'number_range':
      case 'weight_range':
      case 'length_range':
        return new ExactNumberFilter(id, field, value as number);
      case 'color':
        return new ColorCodeFilter(id, 'colorcode', value as ColorSearchAbleData, true);
      // case 'weight':
      //   throw new Error('Weight filter is not yet implemented');
      default:
        throw new Error(`Unknown filter type: ${type}`);
    }
  }

  protected constructor(id: string, field: string) {
    this.id = id;
    this.field = field;
  }

  protected get fieldPrefix(): string {
    return SEARCHABLE_NESTED_FIELD;
  }

  public abstract getQueryPart(): string;
}

export interface WithPublicValue {
  getValue(): string | number;
}

export class FilterGroup extends Filter {
  private readonly filters: Filter[] = [];

  constructor(field: string) {
    super(field, field);
  }

  public addFilter(filter: Filter) {
    if (filter.field !== this.field && !this.field.startsWith('collection')) {
      throw new Error('Filter field does not match group field');
    }
    this.filters.push(filter);
  }

  public removeFilter(filter: Filter) {
    const byId = this.filters.findIndex((value) => value.id === filter.id);
    if (byId >= 0) {
      this.filters.splice(byId, 1);
    }
  }

  public hasFilter(filter: Filter): boolean {
    return this.filters.some((f) => f.id === filter.id);
  }

  public get hasFilters(): boolean {
    return this.filters.length > 0;
  }

  public getFilters(): (Filter & WithPublicValue)[] {
    return this.filters.filter((f) => 'getValue' in f) as (Filter & WithPublicValue)[];
  }

  getQueryPart(): string {
    return `(${this.filters.map((f) => f.getQueryPart()).join(' OR ')})`;
  }
}

export class StringFilter extends Filter implements WithPublicValue {
  constructor(
    id: string,
    field: string,
    private readonly value: string,
    private readonly skipPrefix: boolean = false,
  ) {
    super(id, field);
  }

  getQueryPart(): string {
    const possiblePrefix = this.skipPrefix ? '' : `${this.fieldPrefix}.`;
    return `"${possiblePrefix}${this.field}" = "${this.value}"`;
  }

  getValue(): string | number {
    return this.value;
  }
}

export class ColorCodeFilter extends Filter implements WithPublicValue {
  constructor(
    id: string,
    field: string,
    private readonly value: ColorSearchAbleData,
    private readonly skipPrefix: boolean = false,
  ) {
    super(id, field);
  }

  getQueryPart(): string {
    let [color1, color2] = this.value.hexValues;
    let singleColor = `${color1 ?? color2}:${this.value.colorName}`;
    const possiblePrefix = this.skipPrefix ? '' : `${this.fieldPrefix}.`;
    if (!color1 || !color2) {
      return `"${possiblePrefix}${this.field}" = "${singleColor}" OR "${possiblePrefix}${this.field}2" = "${singleColor}"`;
    }
    const allPossibleColors = ['#000000', color1, color2]
      .map((e) => `"${e}:${this.value.colorName}"`)
      .join(',');

    return `("${possiblePrefix}${this.field}" IN [${allPossibleColors}] AND "${possiblePrefix}${this.field}2" IN [${allPossibleColors}])`;
  }

  getValue(): string {
    return this.value.colorName;
  }
}

export class ExactNumberFilter extends Filter implements WithPublicValue {
  constructor(id: string, field: string, private readonly value: number) {
    super(id, field);
  }

  getQueryPart(): string {
    return `"${this.fieldPrefix}.${this.field}" = ${this.value}`;
  }

  getValue(): string | number {
    return this.value;
  }
}

export class RangeNumberFilter extends Filter {
  constructor(
    id: string,
    field: string,
    private readonly min: number,
    private readonly max: number,
    private readonly skipPrefix: boolean = false,
  ) {
    super(id, field);
  }

  getQueryPart(): string {
    const possiblePrefix = this.skipPrefix ? '' : `${this.fieldPrefix}.`;
    return `"${possiblePrefix}${this.field}" ${this.min} TO ${this.max}`;
  }

  getValue(): [number, number] {
    return [this.min, this.max];
  }
}

export type FilterGroupType =
  | 'price'
  | 'string'
  | 'number'
  | 'color'
  | 'length'
  | 'length_range'
  | 'weight'
  | 'number_range'
  | 'weight_range'
  | 'collection';

export function isNumberLike(type: FilterGroupType) {
  return ['weight', 'number', 'length', 'length_range', 'weight_range', 'number_range'].includes(
    type,
  );
}

export type ColorSearchAbleData = {
  colorName: string;
  hexValues: [string, string] | [string];
};

export interface SearchAbleData {
  [key: string]: (string | number | ColorSearchAbleData)[];
}

export interface SearchAbleDataTypes {
  [key: string]: FilterGroupType;
}

export type CountedOption = {
  value: string | number | ColorSearchAbleData;
  count: number;
};

export interface FilterGroupOption {
  name: string;
  options: CountedOption[];
  type: FilterGroupType;
}
