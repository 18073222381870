import classNames from 'classnames';
import { ComponentType, SVGProps } from 'react';

export interface SliderArrowProps {
  onClick?: () => void;
  className?: string;
  ArrowIcon: ComponentType<SVGProps<SVGSVGElement>>;
}

export const SliderArrow = ({ onClick, className, ArrowIcon }: SliderArrowProps) => (
  <button
    aria-label="Next/Previous slide"
    onClick={onClick}
    className={classNames(
      'flex items-center justify-center w-7 h-7 border rounded-full text-white bg-accent-primary hover:bg-transparent hover:text-accent-primary border-accent-primary',
      className,
    )}
  >
    <ArrowIcon className="w-6 h-6" />
  </button>
);
